import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { PermissionService } from 'src/app/services/authentication/permission.service';
import {Title} from "@angular/platform-browser";

const API_GET_ALL_PROFILES = environment.Global.API_GET_ALL_PROFILES;
const API_GET_ALL_USER_INTERNAL = environment.Global.API_GET_ALL_USER_INTERNAL;
const API_GET_USER_INTERNAL_BY_ID = environment.Global.API_GET_USER_INTERNAL_BY_ID;
const API_CREATE_USER_INTERNAL = environment.Global.API_CREATE_USER_INTERNAL;
const API_UPDATE_ALL_USER_INTERNAL = environment.Global.API_UPDATE_ALL_USER_INTERNAL;
const API_UPDATE_USER_PASSWORD_INTERNAL = environment.Global.API_UPDATE_USER_PASSWORD_INTERNAL;
const API_UPDATE_STATUS_USER_INTERNAL = environment.Global.API_UPDATE_STATUS_USER_INTERNAL;
@Component({
  selector: 'app-internal-user',
  templateUrl: './internal-user.component.html',
  styleUrls: ['./internal-user.component.css']
})
export class InternalUserComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  imagePath: any = '../../../../assets/images/add_user.jpg';
  newImg = false;
  newImgSignature = false;
  editPass: boolean = true;
  profileList = [];
  pageSize = 5;
  password = '';
  imageSignaturePath:any= '../../../../assets/images/signature_user.png'
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public translate: TranslateService,
  ) {
    super(srv, modalService, storage, router);
  }


  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;

      });
    this.search_fields = ['UserId', 'username', 'name'];
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    await this.getList();

    // console.log(lista)
  }

  public async getList() {
    this.loadingList = true;
    this.messageShowLoading();
    await this.srv.getCall(API_GET_ALL_USER_INTERNAL).toPromise().then(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          console.log(this.rows)
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.closeLoadingMessage();
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  /**
   * Open creat modal for add user
   * @param modal internal user form container
   */
  public openCreateModal(modal) {
    this.c_obj = {};
    this.c_obj['profile'] = 1;
    this.modalService.open(modal, { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });
    this.getProfiles();
  }


  /**
   * Create internal user form
   * @param fields internal user form fields
   * @returns if internal user form is valid, return true otherwise return false
   */
  public internalUserForm(fields: any): boolean {
    let internalUserForm: any = {};
    internalUserForm = new FormGroup(
      {
        'address': new FormControl(fields.address, [Validators.required]),
        // 'areaid' :  new FormControl(fields.areaid, [Validators.required]), se quita validacion porque aun no se tienen las areas
        'birthday': new FormControl(fields.birthday, [Validators.required]),
        'identification': new FormControl(fields.identification, [Validators.required]),
        'lastname': new FormControl(fields.lastname, [Validators.required]),
        'mail': new FormControl(fields.mail, [Validators.required]),
        'name': new FormControl(fields.name, [Validators.required]),
        'phone': new FormControl(fields.phone, [Validators.required]),
        // 'sectionid' :  new FormControl(fields.sectionid, [Validators.required]), se quita validacion porque aun no se tienen las plantas
      }
    )
    return internalUserForm.valid;
  }

  /**
   * Save picture from PC to variable
   * @param param0 unstructured event in 'target'
   */
  public getImage({ target }) {
    let image = target.files;
    const reader = new FileReader();
    reader.readAsDataURL(image[0]);
    reader.onload = (_event) => {
      // cambia la foto cuando se va a editar
      if (this.u_obj['photo'] !== undefined) {
        this.u_obj['photo'] = reader.result;
      }
      this.imagePath = reader.result;
      // console.log(this.imagePath)
      this.newImg = true;
    }
  }
  private sizeCreate(){
    let createSize=false
    let create=document.getElementById("fileCreate") as HTMLInputElement;
    if(create!=null &&create.size>1000000){
        createSize=true;
    }
    return createSize;
  }
  private sizeUpdate(){
    let updateSize=false
    let update=document.getElementById("fileUpdate") as HTMLInputElement;
    if(update!=null && update.size>1000000){
      updateSize=true;
    }
    return updateSize;
  }
  public getImageSignature({ target }) {


    if(this.sizeCreate() || this.sizeUpdate()){
      Swal.fire(this.translations['pdi_img_toobig'], '', 'error');
      return;
    }
    let image = target.files;
    const reader = new FileReader();
    reader.readAsDataURL(image[0]);
    reader.onload = (_event) => {
      // cambia la foto cuando se va a editar
      if (this.u_obj['PhotoSignature'] !== undefined) {
        this.u_obj['PhotoSignature'] = reader.result;
      }
      this.imageSignaturePath = reader.result;
      // console.log(this.imagePath)
      this.newImgSignature = true;
    }
  }
  /**
   * Function to edit password
   */
  public editPassword() {
    this.editPass = false;
  }

  /**
   * method for close modal and reset image profile
   * @param modal modal container
   */
  public closeModal(modal) {
    this.modalService.dismissAll(modal);
    this.editPass = true;
    this.imagePath = '../../../../assets/images/add_user.jpg';
    this.imageSignaturePath='../../../../assets/images/signature_user.png';

  }

  /**
   * method for create a new user in db
   * @param create1 modal container
   * @param object objet with user data
   */
  public async createItem(modal, object) {

    let fileCreate=document.getElementById("fileCreate") as HTMLInputElement
    if(object['DocumentCertificate']==true && fileCreate.files.length==0){
    Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
       return;
  }
    if(this.sizeCreate()){
      Swal.fire(this.translations['pdi_img_toobig'], '', 'error');
      return;
    }
    this.isCreating = true;
    let validated = this.internalUserForm(object);
    if (!validated) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (object['name'].includes(' ')) {
      this.isCreating = false;
      Swal.fire(this.translations['only_one_name'], '', 'error');
      return;
    }



    let user = object['name'].substring(0, 1).toLowerCase();
    if (object['lastname'].includes(' ')) {
      let apellidos = object['lastname'].split(' ');
      user += apellidos[0].substring(0, 1).toLowerCase() + apellidos[0].toLowerCase();
    } else {
      user += object['lastname'].toLowerCase();
    }
    let new_user = {
      username: user,
      password: this.password,
      identification: object['identification'],
      name: object['name'].trim(),
      lastname: object['lastname'],
      phone: object['phone'],
      birthday: object['birthday'],
      sectionid: null,
      areaid: null,
      address: object['address'],
      email: object['mail'],
      photo: this.imagePath,
      creationuser: this.userinfo['logon'],
      profileId: object['profile'],
      DocumentApprove: object['DocumentApprove'],
      DocumentStatusReverse: object['DocumentStatusReverse'],
      DocumentCertificate:object['DocumentCertificate'],
      PhotoSignature:await this.toDataURL(this.imageSignaturePath)
    }
    // console.log(new_user)
    this.srv.postCall(API_CREATE_USER_INTERNAL, new_user).subscribe(x => {
      if (x.success) {
        this.isCreating = false;
        if (x.val !== null) {
          // console.log('entro aqui')
          Swal.fire(
            {
              title: this.translations['user_success'],
              icon: 'success'
            }
          ).then((result) => {
            if (result.value) {
              this.getList();
              this.closeModal(modal);
            }
          });
        }
      }
    }, err => {
      this.isCreating = false;
      Swal.fire('Error');
    });

  }

  public createPassword() {
    let nam = this.c_obj['name'].substring(0, 3).toLowerCase();
    let las = this.c_obj['lastname'].substring(0, 3).toLowerCase();
    let num = this.c_obj['identification'].substring(0, 5).toLowerCase();
    this.password = 'DG_' + (nam + las) + num;
    // if (this.c_obj['name'] == undefined || this.c_obj['identification'] == undefined) {
    //   this.c_obj['lastname'] = ''
    //   return Swal.fire(this.translations.addnamerucuser, '', 'error');
    // } else {

    // }
  }
  public ValidaCheck(val: any) {
    console.log(this.imageSignaturePath);
    console.log(val.target.checked)
    if (val.target.name == "DocumentApprove") {
      this.c_obj['DocumentApprove'] = val.target.checked
      this.u_obj['DocumentApprove'] = val.target.checked
    } else if (val.target.name == "DocumentStatusReverse") {
      this.c_obj['DocumentStatusReverse'] == val.target.checked
      this.u_obj['DocumentStatusReverse'] == val.target.checked
    }else if(val.target.name=="DocumentCertificate"){
      this.u_obj['DocumentCertificate'] = val.target.checked
      this.c_obj['DocumentCertificate'] = val.target.checked
    }

    // if (val.target.checked == true && ventana == "create") {
    //   if (opcion == 1) {
    //     this.c_obj['DocumentApprove'] = 1;
    //     this.c_obj['DocumentStatusReverse'] = 1;

    //   } else if (opcion == 2) {
    //     this.c_obj['DocumentApprove'] = 0
    //     this.c_obj['DocumentStatusReverse'] = 0;
    //   }
    // } else {
    //   if (opcion == 1) {
    //     this.u_obj['DocumentApprove'] = 1;
    //   } else {
    //     this.u_obj['DocumentApprove'] = 0;
    //   }
    //   if (opcion == 2) {
    //     this.u_obj['DocumentApprove'] = 0;
    //     this.u_obj['DocumentStatusReverse'] = 0;
    //   }
    // }
  }

  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  public getProfiles() {
    this.srv.getCall(API_GET_ALL_PROFILES).subscribe(x => {
      if (x.success) {
        if (x.val != null) {
          // console.log(x.val)
          this.profileList = x.val
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.profileList = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public deleteItem({ UserId }) {
    this.ActivarItem(UserId, 3);
  }

  public activarItem(event, { UserId }) {
    if (event.target.checked) {
      this.ActivarItem(UserId, 1);
    } else {
      this.ActivarItem(UserId, 2);
    }
  }

  public ActivarItem(id, status) {
    var to: any;
    to = {};
    to.UserId = id;
    to.Status = status;
    to.modificateuser = this.userinfo['logon'];
    // console.log(to)
    this.srv.postCall(API_UPDATE_STATUS_USER_INTERNAL, to).subscribe(x => {
      if (x.success) {
        Swal.fire(
          {
            title: this.translations['msg_update'],
            icon: 'success'
          }
        ).then((result) => {
          if (result.value) {
            this.getList();
          }
        });
      }
    }, err => {
      Swal.fire('Error');
    });
  }

  public openUpdateModal(modal, row) {


    // console.log(row)
    this.u_obj = {};
    this.getProfiles();
    this.u_obj['userId'] = row['UserId'];
    this.u_obj['photo'] = row['photo'];
    this.u_obj['identification'] = row['identification'];
    this.u_obj['birthday'] = row['birthday'].split('T')[0];
    this.u_obj['name'] = row['name'];
    this.u_obj['lastname'] = row['lastname'];
    this.u_obj['mail'] = row['email'];
    this.u_obj['phone'] = row['phone'];
    this.u_obj['address'] = row['address'];
    this.u_obj['profile'] = row['ProfileId'];
    this.u_obj['sectionid'] = row['sectionid'];
    this.u_obj['areaid'] = row['areaid'];
    this.u_obj['username'] = row['username'];
    this.imagePath = row['photo'] != null ? row['photo'] : '../../../../assets/images/add_user.jpg';
    this.u_obj['DocumentApprove'] = row['DocumentApprove'],
      this.u_obj['DocumentStatusReverse'] = row['DocumentStatusReverse']
    this.u_obj['DocumentCertificate']=row['DocumentCertificate']
    this.u_obj['PhotoSignature']=row['PhotoSignature']

    console.log(this.u_obj)
    this.modalService.open(modal, { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });

  }
  async toDataURL(url) {
    console.log("Downloading image...");
    var res = await fetch(url);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })

    return result
  };
  public async  updateItem(modal, object) {
    let fileUpdate=document.getElementById("fileUpdate") as HTMLInputElement;

    if(object['DocumentCertificate']==true && (fileUpdate.files.length==0 && object["PhotoSignature"]==null)){
      Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
      return;
    }
    if(this.sizeUpdate()){
      Swal.fire(this.translations['pdi_img_toobig'], '', 'error');
      return;
    }
    this.isUpdating = true;
    let validated = this.internalUserForm(object);
    if (!validated) {
      this.isUpdating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (object['name'].split(' ').length > 1 && object['name'].split(' ')[1] !== '') {
      this.isUpdating = false;
      Swal.fire(this.translations['only_one_name'], '', 'error');
      return;
    }

    let edit_user = {
      UserId: object['userId'],
      username: object['username'],
      password: object['password'] || null,
      identification: object['identification'],
      name: object['name'].trim(),
      lastname: object['lastname'],
      phone: object['phone'],
      birthday: object['birthday'],
      sectionid: null,
      areaid: null,
      address: object['address'],
      email: object['mail'],
      photo: this.imagePath,
      ProfileId: object['profile'],
      modificateuser: this.userinfo['logon'],
      DocumentApprove: object['DocumentApprove'],
      DocumentStatusReverse: object['DocumentStatusReverse'],
      DocumentCertificate: object['DocumentCertificate'],
      PhotoSignature:await this.toDataURL(this.imageSignaturePath)
    }
    console.log("UserEDIT:", edit_user)
    this.srv.postCall(API_UPDATE_ALL_USER_INTERNAL, edit_user).subscribe(x => {
      if (x.success) {
        this.isUpdating = false;
        if (x.val !== null) {
          Swal.fire(
            {
              title: this.translations['user_updated'],
              icon: 'success'
            }
          ).then((result) => {
            if (result.value) {
              this.getList();
              this.closeModal(modal);
            }
          });
        }
      }
    }, err => {
      this.isUpdating = false;
      Swal.fire('Error');
    });
  }
}
