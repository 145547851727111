<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button  [ladda]="loadingList"  data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('UnsafeConditionId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='UnsafeActId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('CategoryTypeName')">{{'category' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'level' | translate}}</th>
                                <th>{{'cmp_area' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.UnsafeConditionId}}</td>
                                <td >{{row.CategoryTypeName}}</td>
                                <td >{{row.Date.split('T')[0]}}</td>
                                <td >{{row.PlantaName}}</td>
                                <td >{{row.SeccionName}}</td>
                                <td >{{row.StatusInsp == 'C' ? 'Cerrada' : 'Abierta'}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="gotoPlan(row.UnsafeConditionId)" style="cursor:pointer;" class="fas fa-file text-warning m-r-10" title="{{'go_to_action_plan' | translate}}"></i> 
                                    <i (click)="edit(update1, row)" style="cursor:pointer;" class="fas fa-eye text-info m-r-10" title="{{'View' | translate}}"></i> 
                                    <i (click)="deleteInsp(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>                                   
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="7">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6" style="margin-bottom: 10px;">
                <h4>{{'select_type_worker' | translate}}</h4>
                <select class="custom-select custom-select-md" name="workerId" [(ngModel)]="workerId" (change)="setTypeWorker()">
                    <option *ngFor="let wks of list_workers" [value]="wks.id |json">{{wks.type}}</option>
                </select>
            </div>
            <div class="col-md-6" *ngIf="workerId == 2">
                <h4>{{'select_company' | translate}}</h4>
                <!-- <select class="custom-select custom-select-md" name="companyId" [(ngModel)]="companyId" (change)="setCompany()">
                    <option [value]="null" disabled selected>{{'select' | translate}}</option>
                    <option *ngFor="let company of companies_list" [value]="company.id |json">{{company.name}}</option>
                </select> -->
                <ng-select [(ngModel)]="companyId" placeholder="{{'select' | translate}}"  (change)="setCompany()">
                    <ng-option *ngFor="let company of companies_list" [value]="company.id |json">{{company.name}}</ng-option>
                </ng-select> 
            </div>
        </div>
        <hr>
        <h4>{{'detail_inspection'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="level"> {{'category' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="category" [(ngModel)]="c_obj['category']">
                    <option [value]="null" disabled selected>{{'Select_category' | translate}}</option>
                    <option *ngFor="let lt of list_type" [value]="lt.id">{{lt.type}}</option>
                </select> -->
                <ng-select [(ngModel)]="c_obj['category']" placeholder="{{'Select_category' | translate}}" >
                    <ng-option *ngFor="let lt of list_type" [value]="lt.id">{{lt.type}}</ng-option>
                </ng-select> 
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'date'|translate}}*</label>
                <input type="date"  class="form-control" name="date" [(ngModel)]="c_obj['date']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'report_by' | translate}}*</label>
                <input type="text"  class="form-control" name="reportby" [(ngModel)]="c_obj['reportby']" readonly >
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="level" > {{'level' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="searchArea()">
                    <option [value]="null" disabled selected>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</option>
                </select>  -->
                <ng-select [(ngModel)]="c_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="searchArea()" >
                    <ng-option  *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</ng-option>
                </ng-select> 
            </div>
        </div> 
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12 ha-ibox">
                        <label for="area" > {{'cmp_area' | translate}} *</label>
                        <!-- <select class="custom-select custom-select-md" name="area" [(ngModel)]="c_obj['area']">
                            <option [value]="null" disabled selected>{{'Select_position' | translate}}</option>
                            <option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</option>
                        </select> -->
                        <ng-select [(ngModel)]="c_obj['area']" placeholder="{{'Select_position' | translate}}" (change)="searchArea()" >
                            <ng-option  *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                        </ng-select> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ha-ibox">
                        <label for="fault_type" > {{'fault_type' | translate}} *</label>
                        <!-- <select class="custom-select custom-select-md" name="fault_type" [(ngModel)]="c_obj['fault_type']"  (change)="getFouls(c_obj['fault_type'])">
                            <option [value]="null" disabled selected>{{'Select_fault' | translate}}</option>
                            <option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</option>
                        </select>  -->
                        <ng-select [(ngModel)]="c_obj['fault_type']" placeholder="{{'Select_fault' | translate}}" (change)="getFouls(c_obj['fault_type'])">
                            <ng-option  *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</ng-option>
                        </ng-select> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ha-ibox">
                        <label for="job_area"> {{'fault_associated' | translate}} *</label>
                        <!-- <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['fault_associated']">
                            <option [value]="null" disabled selected>{{'Select_fault_detail' | translate}}</option>
                            <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</option>
                        </select>    -->
                        <ng-select [(ngModel)]="c_obj['fault_associated']" placeholder="{{'Select_fault_detail' | translate}}">
                            <ng-option  *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</ng-option>
                        </ng-select> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ha-ibox">
                        <label for="type_sanction" > {{'sanction' | translate}} *</label>
                        <!-- <select class="custom-select custom-select-md" name="type_sanction" [(ngModel)]="c_obj['type_sanction']">
                            <option [value]="null" disabled selected>{{'Select_type_sanction' | translate}}</option>
                            <option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</option>
                        </select>  -->
                        <ng-select [(ngModel)]="c_obj['type_sanction']" placeholder="{{'Select_type_sanction' | translate}}">
                            <ng-option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</ng-option>
                        </ng-select> 
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12 ha-ibox" style="text-align: center;" *ngIf="getCompany">
                        <h4 style="color: #003d6a; text-decoration: underline; font-size: bold;"> {{'info_foul' | translate}}</h4>
                        <div class="table-responsive" *ngIf="listfouls.length > 0, else mensaje">
                            <table class="table table-bordered">
                                <thead  style="font-size: 0.75rem;" class="thead-light">
                                    <tr>
                                        <th style=" color: #003d6a; padding: 10px;"><strong>{{'fault_type' | translate}}</strong></th>
                                        <th style=" color: #003d6a; padding: 10px;"><strong>{{'quantity' | translate}}</strong></th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 0.75rem;">
                                    <tr *ngFor="let foul of listfouls">
                                        <td style="padding: 14px;">{{foul.namefouls}}</td>
                                        <td style="padding: 14px;">{{foul.quantity}}</td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                        <ng-template #mensaje>
                            <p style="text-align: justify;">{{'user_has_no_faults' | translate}}</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <!-- <textarea name="" id="" cols="20"></textarea> -->
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <label for="observation_ha"> {{'observation_ha' | translate}}</label>
                <textarea class="col-md-12 ha-ibox" rows="3" [(ngModel)]="c_obj['observation_ha']"></textarea>
            </div>
        </div>
        <hr>
        <h4>{{'finish'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="level"> {{'close_fault' | translate}} *</label>
                <select class="custom-select custom-select-md" name="close_fault" [(ngModel)]="c_obj['close_fault']" (change)="changeValueFoult()">
                    <option *ngFor="let op of list_option_fault" [value]="op.id">{{op.type}}</option>
                </select> 
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'samp_comment' | translate}}</label>
                <textarea rows="4" type="text"  class="form-control" name="samp_comment" [(ngModel)]="c_obj['samp_comment']"  [disabled]="c_obj['close_fault']==2"></textarea>
            </div>
        </div>   
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="u_obj['CodeEmployee'] !== null">
            <h4>{{'worker'| translate}}</h4>
            <input type="text"  class="form-control" name="text" [(ngModel)]="u_obj['CodeEmployee']" readonly>
            <hr>
        </div>
        <div  *ngIf="u_obj['CompanyID'] !== null">
            <h4>{{'company'| translate}}</h4>
            <input type="text"  class="form-control" name="text" [(ngModel)]="u_obj['Name']" readonly>
            <hr>
        </div>

        <h4>{{'detail_inspection'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="level"> {{'category' | translate}} *</label>
                <select class="custom-select custom-select-md" name="category" [(ngModel)]="u_obj['category']" disabled>
                    <option [value]="null" disabled selected>{{'Select_category' | translate}}</option>
                    <option *ngFor="let lt of list_type" [value]="lt.id">{{lt.type}}</option>
                </select>  
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'date'|translate}}*</label>
                <input type="date"  class="form-control" name="date" [(ngModel)]="u_obj['date']" readonly>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'report_by' | translate}}*</label>
                <input type="text"  class="form-control" name="reportby" [(ngModel)]="u_obj['reportby']" readonly >
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="level" > {{'level' | translate}} *</label>
                <select class="custom-select custom-select-md" name="level" [(ngModel)]="u_obj['level']" (change)="searchArea()" disabled>
                    <option [value]="null" disabled selected>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</option>
                </select> 
            </div>
        </div> 
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="area" > {{'cmp_area' | translate}} *</label>
                <select class="custom-select custom-select-md" name="area" [(ngModel)]="u_obj['area']" disabled>
                    <option [value]="null" disabled selected>{{'Select_position' | translate}}</option>
                    <option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</option>
                </select> 
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="fault_type" > {{'fault_type' | translate}} *</label>
                <select class="custom-select custom-select-md" name="fault_type" [(ngModel)]="u_obj['fault_type']" disabled>
                    <option [value]="null" disabled selected>{{'Select_fault' | translate}}</option>
                    <option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</option>
                </select> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="job_area"> {{'fault_associated' | translate}} *</label>
                <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="u_obj['fault_associated']" disabled>
                    <option [value]="null" disabled selected>{{'Select_fault_detail' | translate}}</option>
                    <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</option>
                </select>   
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="type_sanction" > {{'sanction' | translate}} *</label>
                <select class="custom-select custom-select-md" name="type_sanction" [(ngModel)]="u_obj['type_sanction']" disabled>
                    <option [value]="null" disabled selected>{{'Select_type_sanction' | translate}}</option>
                    <option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</option>
                </select> 
            </div>
        </div>
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)" disabled>
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <!-- <textarea name="" id="" cols="20"></textarea> -->
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <label for="observation_ha"> {{'observation_ha' | translate}}</label>
                <textarea class="col-md-12 ha-ibox" rows="3" [(ngModel)]="u_obj['observation_ha']" readonly></textarea>
            </div>
        </div>
        <hr>
        <h4>{{'finish'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="level"> {{'close_fault' | translate}} *</label>
                <select class="custom-select custom-select-md" name="close_fault" [(ngModel)]="u_obj['close_fault']" (change)="changeValueFoult()" disabled>
                    <option *ngFor="let op of list_option_fault" [value]="op.id">{{op.type}}</option>
                </select> 
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'samp_comment' | translate}}</label>
                <textarea rows="4" type="text"  class="form-control" name="samp_comment" [(ngModel)]="u_obj['samp_comment']"  [disabled]="u_obj['close_fault']==2" readonly></textarea>
            </div>
        </div>   
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'edit' | translate}}</button>
    </div>
</ng-template>