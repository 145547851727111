import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import {saveAs as importedSaveAs  } from "file-saver";
import { environment } from 'src/environments/environment';
import { blobToB64 } from '../approvedocumentsbase/approvedocumentsbase.component';


const API_EMPLOYEE_GET_DOCUMENT_APPROVE = environment.Global.API_EMPLOYEE_GET_DOCUMENT_APPROVE;
const API_GET_FILE_EMPLOYEE_ID = environment.Global.API_GET_FILE_EMPLOYEE_ID;
const API_EMPLOYEE_APPROVE_DOCUMENT = environment.Global.API_EMPLOYEE_APPROVE_DOCUMENT;

@Component({
  selector: 'app-proapprovedocumentsemployees',
  templateUrl: './proapprovedocumentsemployees.component.html',
  styleUrls: ['./proapprovedocumentsemployees.component.css']
})
export class ProapprovedocumentsemployeesComponent extends BaseCrudComponent  {
  id:any;
  userinfo:any;
  name_company:any;
  flag:any;
  valor;

  isPDF = true;
  isImage = false;
  pdfSrc = "";
  row: any = [];

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.id = route.snapshot.params.id;
      this.search_fields = ['EmployeeDocumentId','identification','EmployeeName','NameDoc','plazo'];

      this.c_obj['EmployeeDocument'] = [];
    }


  async ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.userinfo['DocumentApprove'] = '';
    this.userinfo['DocumentStatusReverse'] = '';

    this.route.queryParams.subscribe(params => {
      this.name_company = params.state
    });
    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
    this.getList()
  }

  public getList()
  {
    var qst = API_EMPLOYEE_GET_DOCUMENT_APPROVE +"/"+ this.id;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x=>{
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.rows.forEach(element => {
            element.valor = null;
          });
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        this.messageDialog(x.msg, '' ,'error');
        return;
      }
    });
  }

  openViewModal(modal, object){
    const DocFileName = object.ParamDocumentID;
    const name = object.FileName;
    this.row = object;

    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_EMPLOYEE_ID, object.EmployeeId , DocFileName)
      .subscribe({
        next: async (blob) => {
          if (blob) {
            var extension = blob.type;
            if (extension == 'application/pdf') {
              this.isPDF = true;
              this.isImage = false;
            } else if (
              extension == 'image/jpeg' ||
              extension == 'image/jpg' ||
              extension == 'image/png'
            ) {
              this.isPDF = false;
              this.isImage = true;
            } else {
              this.messageDialog('Formato de archivo incorrecto!', '', 'error');
              return;
            }
            this.pdfSrc = await blobToB64(blob);
            this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
          } else {
            Swal.fire(
              'No se pudo obtener documento',
              'Contacte con el administrador',
              'error'
            );
          }
        },
        error: (error) => {
          Swal.fire(
            'No se pudo obtener documento',
            'Contacte con el administrador',
            'error'
          );
        }
      });
  }

  downloadDocFile(data) {
    const DocFileName = data.ParamDocumentID;
    const name = data.FileName;
    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_EMPLOYEE_ID, data.EmployeeId ,DocFileName).subscribe((data) => {
      importedSaveAs(data,name )
    });
  }

  verificateprocesardoc(row:any){
    var qst = API_EMPLOYEE_APPROVE_DOCUMENT;
    var mensaje:any;
    var to:any = {};
    let trans = this.translations;
    to.EmployeeDocumentId=row.EmployeeDocumentId;
    to.EmployeeId=row.EmployeeId;
    to.VerificateUser=this.userinfo.logon;
    to.DateDocument = row.date_doc != null?row.date_doc: row.DateDocument;debugger;
    to.ExpirationTime = row.date_doc_end != null?row.date_doc_end: row.ExpirationTime;
    if (this.flag == undefined) {
      return Swal.fire(this.translations.aprroveorrevoce,'' , 'error');
    }
    if(this.flag==0)
    {
      to.Status="V";
      to.StatusId=39;
      to.VerificateComment="";
      mensaje=trans.show_message_approve;
    }
    else{
      if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
      {
        Swal.fire(trans.docapprove_error_missingcomment, '', 'error');
        return;
      }
      to.Status="R";
      to.StatusId=40;
      to.VerificateComment=row.VerificateComment;
      mensaje=trans.show_message_reject;
    }
    Swal.fire({
        title: mensaje,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: trans.cancel,
        confirmButtonText: trans.ok,
        preConfirm: () => {
            this.procesardoc(qst,to);

        }
    }).then((result) => {
    });

  }

  procesardoc(qst:any,to:any){
    this.loadingList = true;
    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if(x.success)
      {
        this.confirmDialog(x.msg, '', 'success');
      }else {
        this.confirmDialog(x.msg, '', 'error');
      }
    }, error => {
      this.confirmDialog(error, '', 'error');
    });
  }

  changeSelect(row) {
    // console.log(row)
    if(row.valor == 0) {
      this.flag = 0;
    } else {
      this.flag = 1;
    }
  }


}
