<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <!--  <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>-->
                        {{this.name_company}}
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages"
                            (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' |
                            translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th class="sh">
                                    ID
                                </th>
                                <th (click)="setOrder('RegistrationNumber')" class="sh">
                                    {{'v_registration' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='RegistrationNumber')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('NameDoc')" class="sh">
                                    {{'document' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='NameDoc')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('DateDocument')" class="sh">
                                    {{'date_emision' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='ExpirationTime')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('ExpirationTime')" class="sh">
                                    {{'expiration_date' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DateEnd')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('VerificateTime')" class="sh">
                                    {{'date' | translate}} {{'verificate_' | translate}} {{'skt'}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='VerificateTime')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('VerificateUser')" class="sh">
                                    {{'vericate_user' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='VerificateUser')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('status_name')" class="sh">
                                    {{'status' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='status_name')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('status_name')" class="sh">
                                    {{'VerificateComment' | translate}}<i
                                        *ngIf="(orderKey!==null)&&(orderKey=='status_name')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                                <td>{{ i + 1}}</td>
                                <td>{{row.RegistrationNumber}}</td>
                                <td>{{row.NameDoc}}</td>
                                <td>{{row.DateDocument | date:'y-MM-dd'}}</td>
                                <td>{{row.ExpirationTime | date:'y-MM-dd'}}</td>
                                <td>{{row.VerificateTime | date:'y-MM-dd'}}</td>
                                <td>{{row.VerificateUser}}</td>
                                <td>{{row.status_name}}</td>
                                <td>{{row.VerificateComment}}</td>
                                <td class="action-buttons">
                                    <!-- <i (click)="downloadDocFile(row)" style="cursor:pointer;" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}" title="{{'export' | translate}}"></i> -->
                                    <div class="row">
                                        <div class="col-md-3 sk-ibox">
                                            <i (click)="openViewModal(view, row)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>
                                            <!-- <i (click)="downloadDocFile(row)" style="cursor:pointer;"
                                                [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}"
                                                title="{{'export' | translate}}"></i> -->
                                        </div>
                                        <div class="col-md-3 sk-ibox"
                                            *ngIf="row.status_name == 'VALIDADO' || row.status_name == 'RECHAZADO' && this.userinfo['DocumentStatusReverse']!=true">
                                            <i (click)="StatusReverse(row)" style="cursor:pointer;"
                                                [ngClass]="{'fas fa-history m-r-10': true, 'text-info': true}"
                                                title="{{'StatusReverse' | translate}}"></i>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="12">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize"
                                    [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #view let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
      <h4 class="modal-title">{{'preview' | translate}}</h4>
      <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
        <i (click)="downloadDocFile(this.row)" style="cursor:pointer;" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}" title="{{'export' | translate}}"></i>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <form class="form-horizontal">
        <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
          <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                      style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
        </ng-container>
        <ng-template #elseTemplate>
          <div style="display: flex;">
            <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
                 alt="">
          </div>
        </ng-template>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
    </div>
</ng-template>
