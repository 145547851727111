<a 
    [ngStyle]="styleTitle"
    (click)="openModal(update1, object)"
>
    {{labelTitle}}
</a>

<ng-template #update1 let-c="close" let-d="dismiss">
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{u_obj.Title}}</h4>
    </div>
    <div class="modal-body">
        <form class="form-horizontal">
            <div class="container">
                <img class="card-img-top mb-4" [src]="u_obj.UrlPicture || 'undefined'" alt="Card image cap">
                <h6 class="card-subtitle mb-2 text-muted pb-2">{{u_obj.dateformat}}</h6>
                <h6 class="card-subtitle mb-2 text-muted">{{u_obj.SubTitle}}</h6>
                <div class="modal-body">
                    {{u_obj.Description}}
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
    </div>
</ng-template>