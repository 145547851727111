import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "../../../../environments/environment";
const API_SEND_MAIL = environment.Global.API_COMPANY_APPROVE_MULTI_DOCUMENT;

@Component({
  selector: 'app-approvedocumentsemployees',
  templateUrl: './approvedocumentsemployees.component.html',
  styleUrls: ['./approvedocumentsemployees.component.css']
})
export class ApprovedocumentsemployeesComponent  extends BaseCrudComponent {

  userinfo:any;
  showButtonSendMail:boolean;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
      super(srv, modalService, storage, router);
      this.read_url = Global.API_ALLCOMPANIESWITHDOCUMENT_TYPE;
      this.search_fields = ['CompanyID','Ruc','Name'];
      this.c_obj['Company'] = [];
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
    });
    console.log(this.translations);
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
      if (this.userinfo.ProfileID == 1) { //ADMINISTRADOR
        //this.read_url = ALLCOMPANIES;
        this.showButtonSendMail = true;
      } else if (this.userinfo.ProfileID == 2 || this.userinfo.ProfileID == 21) { //CONTRATISTA O TRANSPORTISTA
        // this.read_url = API_GET_DOCUMENTS_GENERAL_COMPANY + this.userinfo.selectedCompanyId;
        this.showButtonSendMail = false;
      }
    });

    await this.getList();
  }

  public async getList() {
    var qst = this.read_url;
    this.loadingList = true;
    let to = {
      userId: this.userinfo['userId'],
      type: 'emp'
    }
    this.messageShowLoading();
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        this.messageDialog(x.msg, '' ,'error');
        return;
      }
    });
  }
  public sendMails(){
    var qst = API_SEND_MAIL + null;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      console.log(x);
      if(x.success){
        this.closeLoadingMessage();
        return Swal.fire(x.msg,'', 'success');
      } else {
        this.messageDialog(x.msg,'','error');
        return;
      }
    });
  }
}
