import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSPREGUNTA = Global.API_CONSPREGUNTA;
const API_REGPREGUNTA = Global.API_REGPREGUNTA;
const API_UPDATEPREGUNTAS = Global.API_UPDATEPREGUNTAS;
const API_UPDATESTATUS = Global.API_UPDATESTATUS;
const API_CONSPREGUNTAESPECIFICA = Global.API_CONSPREGUNTAESPECIFICA;

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  listTypeQuestion:any;
  listTypeParameter:any;


  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;
        this.read_url = API_CONSPREGUNTA;
        this.create_url = API_REGPREGUNTA;
        this.update_url = API_UPDATEPREGUNTAS;
        this.active_url = API_UPDATESTATUS;
       // this.active_url = API_ACTIVEPREGUNTAS;

        this.search_fields = ['PreguntaID', 'Pregunta', 'TypeParameterName','TypeName'];

        this.c_obj['ParamPregunta'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.loadingRequired = true;
              this.getTypeQuestionData().subscribe(x => {
                  //console.log(x);
                  if(x != false){
                      this.loadingRequired = false;
                      this.listTypeQuestion = x;
                  }
              });
          
    });
    this.loadingRequired = false;
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.loadingRequired = true;
              this.getTypeParameterData().subscribe(j => {
                  //console.log(j);
                  if(j != false){
                      this.loadingRequired = false;
                      this.listTypeParameter = j;
                  }
              });
              
              this.getList();
            });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });

    
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
  
  
    var id:any;
    id = {};
    id.TipoPreguntaID = this.id;
    this.messageShowLoading();
    this.srv.postCall(qst, id).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg,'','success')
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg,'','success')
          this.closeLoadingMessage();
          this.rows = [];
          return;
            }
      }else {
        this.messageDialog(x.msg,'','error')
      return;
        }
    });
  }

  public openCreateModal(content: any) {
    this.c_obj = {};
    this.openUpdateModalStatic(content, this.u_obj);
  }

  public createItem(content:any, form:any){
    //debugger;
    var validated = true;
  
    if( form == undefined ){
      form = {};
    }
  
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
  
    var to:any;
    form.CreatorUser = this.userinfo['logon'];
    to = form;
    //console.log("to:",to);
    var qst = this.create_url;
    this.isCreating = true;
  
    this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        if(x.success){
            this.getList();
            this.closeModal(content);
            Swal.fire(this.translations['MENSAJE'],x.msg, 'success');
        }//end success
        else{
            //Swal.fire(this.translations[x.msg], '', 'error');
            Swal.fire(this.translations['MENSAJE'],x.msg, 'error');
        }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'Pregunta': new FormControl(fields.Pregunta, [Validators.required]),
    });
  
    return mygroup.valid;
  }

  public updateItem(content:any, form:any){
    //debugger;
    this.isCreating = true;
    var qst = this.update_url;
    
    if( form == undefined ){
      form = {};
    }
  
    var to:any;
  
    if( (form.Pregunta == undefined) || (form.Pregunta == '') ){
        Swal.fire('Ingrese todos los campos requeridos', '', 'warning');
        return;
    }
    //debugger;
    to = {};
    to.Pregunta = form.Pregunta;
    to.TipoPreguntaID =  form.TipoPreguntaID;
    to.LastModifierUserID = this.userinfo['userId'];
    to.PreguntaID = form.PreguntaID;
    to.TypeParameterID=form.TypeParameterID;
    
  
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update5');
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
        
      } 
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  
  }

  toggleEditable(event, id) {
    
    if ( event.target.checked ) {
       this.ActivarItem(id);
   }else {
     this.deleteItem(id);
  
  }
  }
  
  deleteItem(obj: any){
    //debugger;
    //console.log("user info:",this.userinfo);
    var id:any;
    id = {};
    id.PreguntaID = obj;
    id.LastModifierUser = this.userinfo['userId'];
    id.Status=0;
    var qst = this.active_url;
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }
  
  ActivarItem(obj: any){
    var id:any;
    id = {};
    id.PreguntaID = obj;
    id.LastModifierUser =  this.userinfo['userId'];
    id.Status=1;
    var qst = this.active_url;
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }
  
  public getTypeQuestionData(){
    var par_url = 'ConsultasGenerales/ConsultaTipoPreguntaActive';

    return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;
                    // console.log(data);
                    
                    if(mydata.success == true ){
                      return of(mydata.val);
                    }
                    else{
                      return of(false);
                    }
                  }),
                  //timeout(MAX_TIME),
                  catchError(data => of(false))
            );
  }

  public getTypeParameterData(){
    var par_url = "SystemParameter/GetSystemParameterField";
    this.loadingList = true;
  
    var to:any;
    to = {};
    to._field = "PARMTYPEQUESTION";

    par_url=par_url+"/"+"PARMTYPEQUESTION";
    // console.log("to:",to);
    // console.log("url:",par_url);
    //debugger;
    return this.srv.getCall(par_url).pipe( 
      mergeMap(data => { 
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          // console.log("data:",data);
          // console.log("mydata:",mydata);
          
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        //timeout(MAX_TIME),
        catchError(data => of(false))
    );
  }

  public DeleteItem(id:any){
    //debugger;
    var to:any;
    to = {};
    to.PreguntaID = id;
    to.LastModifierUser =  this.userinfo['userId'];
    to.Status=3;
    var qst = this.active_url;

    // console.log("to::",to);
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });   
  
  }
}
