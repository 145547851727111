import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import { PermissionService } from 'src/app/services/authentication/permission.service';
import { environment } from 'src/environments/environment';
import {isNumeric} from "rxjs/util/isNumeric";
import {Storage} from "@ionic/storage";

@Component({
  selector: 'app-reportshome',
  templateUrl: './reportshome.component.html',
  styleUrls: ['./reportshome.component.css']
})
export class ReportshomeComponent implements OnInit {

  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items = [];
  permissions = [];
  userinfo: any;
  
  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: LoginService,
    public permission: PermissionService,
    private storage: Storage,
  ) { }

  ngOnInit() {
    let id = environment.Global.Menus.Reportes // menu reportes
    this.permission.getModulesByMenuId(id).then(x => {
      x.forEach(element => {
        if (element.principal == 1) {
          this.items.push(element)
        }
      });
    })
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.auth.getPermissions().then(x => {
      this.permissions = x;
    });
  }

  hasPermission(str:string){
    let flag = false;
    this.permissions.forEach(permission => {
      if(permission.Permission == str) {
        flag = true;
      }
    })
    return flag;
  }

}
