import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { saveAs as importedSaveAs } from "file-saver";
import Swal from 'sweetalert2';
import { blobToB64 } from '../approvedocumentsbase/approvedocumentsbase.component';
import { env } from 'process';
import {Global} from "../../../services/global";

const API_CONSDOCUMENEMP = environment.Global.API_CONSPARAMDOCUMENT_COMPANY;
const API_GETCOMPANYID = environment.Global.API_CONSULTACOMPANIESID;
const API_UPDATEEMP = environment.Global.API_UPDATECOMPANIE;
const API_FILE_COMPANY = environment.Global.API_FILE_COMPANY;
const API_UPDATE_DATA_COMPANY = environment.Global.API_UPDATE_DATA_COMPANY;
const API_GET_DOCUMENT_BY_COMPANY_ID = environment.Global.API_GET_DOCUMENT_BY_COMPANY_ID;
const API_GET_FILE_COMPANY_ID = environment.Global.API_GET_FILE_COMPANY_ID;
const API_UPDATE_COMPANY_DOCUMENTS = environment.Global.API_UPDATE_COMPANY_DOCUMENTS;
const API_DELETE_DOCUMET = environment.Global.API_DELETE_DOCUMET;
const API_GET_SEVERITY_TYPE_BY_ID=environment.Global.API_GET_SEVERITY_TYPE_BY_ID;
const API_GET_COMPANY_BY_ID=environment.Global.API_GET_COMPANY_BY_ID;


@Component({
  selector: 'app-regcompaniesemp',
  templateUrl: './regcompaniesemp.component.html',
  styleUrls: ['./regcompaniesemp.component.css']
})
export class RegcompaniesempComponent extends BaseCrudComponent {

  loadingRequired = false;
  translations: any;
  available_languages: any;
  mostarselectemp;
  currlang: any;
  version: any;
  userinfo: any;
  company: any;
  id;
  permissions = [];
  mostargridvacio = false;
  NewCompanie: FormGroup;
  directiontemp;
  phonetemp;
  namefile: any;
  cargados = false;
  archivos = [];
  tipotrabajo = [];
  mensaje = "";
  size_max = 4194304//3145728;//3mb
  curr_parameter: any;
  typeSelected = '';
  seeModalCar = false;
  position: any
  docName: any
  CompDocumentId: any

  isPDF = true;
  isImage = false;
  pdfSrc = "";
  row2: any = [];
  titlePolitics="";
  descriptionPolitics="";
  rutaArchivoMEDEVAC: string = Global.API_GENERAL_URL.replace('api/','')+'Documents/Templates/Ficha_MEDEVAC_proveedores_y_contratistas_VF.xlsx';
  rutaArchivoServicio: string = Global.API_GENERAL_URL.replace('api/','')+'Documents/Templates/INFORME_DE_ENTREGA_DE_SERVICIO_VF.xlsx';
  rutaArchivoReporte: string = Global.API_GENERAL_URL.replace('api/','')+'Documents/Templates/Formato_Reporte_de_Campo_Contratistas_VF.xlsx';
  rutaArchivoIndice: string = Global.API_GENERAL_URL.replace('api/','')+'Documents/Templates/GIS_Contratista_VF.xlsx';

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.select_url = API_GETCOMPANYID;
    this.update_url = API_UPDATEEMP;
    this.documentemp_url = API_CONSDOCUMENEMP;
    this.delete_url_details = API_DELETE_DOCUMET;
    this.curr_parameter = 0;
    this.search_fields = ['Name', 'Ruc'];

    this.c_obj['Company'] = [];
  }


  async ngOnInit() {
    this.company = [];
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
    this.userinfo = {};
    this.userinfo['logon'] = '';
    this.userinfo['typeCompany'] = '';
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(async x => {
      this.userinfo = x;
      if (isNumeric(this.userinfo.logon)) {
        this.mostarselectemp = false;
      }
      await this.getList();
      await this.srv.getCall(API_GET_SEVERITY_TYPE_BY_ID+"/POLITICA DE PRIVACIDAD DE DATOS").subscribe(resp=>{
        console.log(resp.val);
        this.titlePolitics=resp.val[0]["field"]
        this.descriptionPolitics=resp.val[0]["value"]
      } );
    });
    this.cargarCompany();

  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  async getList() {
    this.loadingList = true;
    var id: any = {};
    id.CompanyID = this.userinfo.selectedCompanyId;
    var par_url = this.select_url;
    var to: any = {};
    to.CompanyId = this.userinfo.selectedCompanyId;
    this.messageShowLoading();
    await this.srv.postCall(par_url, to).toPromise().then(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.u_obj = rs;
          // console.log(this.u_obj);
          this.userinfo.typeCompany = x.val['typeCompany'];
          this.typeSelected = x.val['typeCompany'];
          // this.messageDialog(x.msg, '' , 'success');
          this.closeLoadingMessage();
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.closeLoadingMessage();
          this.u_obj = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

    this.getListDocument();
    this.GetDocumentUpload();

  }

  public getListDocument() {
    var qst = this.documentemp_url;
    var to: any;
    to = {};
    to._IdComp = this.userinfo.selectedCompanyId;
    if (this.userinfo['typeCompany'] == 1) {
      to._Id = 25;
    } else {
      to._Id = 97;
    }
    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.rowdocument = rs;
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowdocument = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public GetDocumentUpload() {
    var par_url = API_GET_DOCUMENT_BY_COMPANY_ID + this.userinfo.selectedCompanyId;
    this.srv.getCall(par_url).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null && x.val.length > 0) {
          var rs = x.val;
          this.rowdocumentupload = rs;
          let flag = false;
          this.rowdocumentupload.forEach(element => {
            if (element.status_doc == "A" || element.status_doc == "R") {
              flag = true;
            }
          });

          if (flag) {
            this.cargados = true;
          } else {
            this.cargados = false;
          }
        } else {
          // Swal.fire(x.msg, '', 'success');
          this.rowdocumentupload = [];
          this.cargados = false;
          this.mensaje = "";
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public validateCreateInfo(fields: any): boolean {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'com_legalidentification': new FormControl(fields.com_legalidentification, [Validators.required]),
      'com_Securityidentification': new FormControl(fields.com_Securityidentification, [Validators.required]),
      'com_legalrepresentative': new FormControl(fields.com_legalrepresentative, [Validators.required]),
      'com_Securityrepresentative': new FormControl(fields.com_Securityrepresentative, [Validators.required]),
      'com_legalmail': new FormControl(fields.com_legalmail, [Validators.required]),
      'com_Securitymail': new FormControl(fields.com_Securitymail, [Validators.required])
    });
    return mygroup.valid;
  }

  public updateItem(content: any, form: any) {
    this.isUpdating = true;
    var qst = this.update_url;
    var validated = true;

    if (form == undefined) {
      form = {};
    }

    validated = this.validateCreateInfo(form);
    if (!validated) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    var to: any;


    to = {};
    to.CompanyID = this.userinfo.selectedCompanyId;
    to.Telephone = form.Telephone;
    to.Direction = form.Direction;
    to.typeCompany = form.typeCompany;
    to.com_legalidentification = form.com_legalidentification;
    to.com_Securityidentification = form.com_Securityidentification;
    to.com_legalrepresentative = form.com_legalrepresentative;
    to.com_Securityrepresentative = form.com_Securityrepresentative;
    to.com_legalmail = form.com_legalmail;
    to.com_Securitymail = form.com_Securitymail;
    to.LastModifierUserID = this.userinfo['userId'];
    // Contratistas a Transportistas: Transportistas a Contratistas
    // console.log("Valor anterior:", this.userinfo.typeCompany)
    // console.log("Valor Nuevo:", to.typeCompany)
    let title = ""

    if (this.userinfo.typeCompany != to.typeCompany && to.typeCompany == 1) {
      title = this.translations.messageDriversToWorker
    } else if (this.userinfo.typeCompany != to.typeCompany && to.typeCompany == 2) {
      title = this.translations.messageWorkerToDrivers;
    } else {
      title = this.translations.MessageConfirmTitle;
    }


    Swal.fire(
      {
        title: title,
        text: this.translations.TextConfirm,
        icon: 'info',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.translations.Cancel,
        cancelButtonColor: '#84888b',
        confirmButtonText: this.translations.Accept,
        confirmButtonColor: '#C8102E',
        showCloseButton: true,
      }
    ).then((result) => {

      if (result.value == true) {

        this.messageShowLoading();
        this.srv.postCall(qst, to).subscribe(x => {
          this.isUpdating = false;
          if (x.success) {
            this.closeModal('update5');
            Swal.fire(this.translations.msg_create, x.msg, 'success');
            this.curr_parameter = 0;
            this.getList();
          }
        }, err => {
          Swal.close();
          Swal.fire('Error');
        });
      } else {
        // console.log(result)
        this.isUpdating = false;
      }


    });




  }


  async onExpSubmit(row: any) {//, content

    //console.log(row)

    if (this.archivos.length == 0) {
      Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
      return;
    }
    var size_document = this.archivos[0].size;
    if (size_document > this.size_max) {
      Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
      this.archivos = [];
      return;
    }
    if (row.date_doc == undefined || row.date_doc_end == undefined) {
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }

    let formData = new FormData();
    formData.append('FileUpload', this.archivos[0]);
    formData.append('CompanyID', this.userinfo.selectedCompanyId);
    formData.append('ParamDocumentID', row.ParamDocumentID);


    await this.srv.AddFiles(API_FILE_COMPANY, formData).toPromise().then(async result => {
      if (result.STATUS) {
        Swal.fire({
          title: result.MENSAJE,
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.value) {
            this.uploadDataBase(row);
          }
        })
      }
      else {
        Swal.fire(result.MENSAJE, '', 'error');
        this.archivos = [];
        this.getList();
      }
    });

  }

  public async uploadDataBase(row: any) {
    var par_url = API_UPDATE_DATA_COMPANY;
    var to: any = {};
    to.ParamDocumentID = row.ParamDocumentID;
    to.CompanyID = this.userinfo.selectedCompanyId;
    to.LastModifierUser = this.userinfo.logon;
    to.CreatorUser = this.userinfo.logon;
    to.ImageFileName = this.namefile;
    to.DateDocument = row.date_doc;
    to.ExpirationTime = row.date_doc_end;

    await this.srv.postCall(par_url, to).toPromise().then(x => {
      if (x.success) {
        Swal.fire({
          title: x.msg,
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.archivos = [];
            this.getList();
          }
        })
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  handleInputChange(e, i :number) {
    this.archivos = [];
    const fileInput = e.target;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    if (!file) {
      this.messageDialog('No se ha seleccionado ningún archivo.', '', 'error');
      return;
    }

    const allowedExtensions = ['application/pdf'];
    const extension = file.type;

    if (allowedExtensions.includes(extension)) {
      this.archivos.push(file);
      this.namefile = file.name;
      let btnGuardar=document.getElementById("guardarButton"+i);
      console.log(btnGuardar!=null)
      btnGuardar.click();
    } else {
      this.messageDialog('Formato de archivo incorrecto!', '', 'error');
      fileInput.value = '';
      return;
    }
  }

  openViewModal(modal, object){
    const DocFileName = object.ParamDocumentID;
    this.row2 = object;

    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_COMPANY_ID, object.CompanyID, DocFileName)
      .subscribe({
        next: async (blob) => {
          if (blob) {
            var extension = blob.type;
            if (extension == 'application/pdf') {
              this.isPDF = true;
              this.isImage = false;
            } else if (
              extension == 'image/jpeg' ||
              extension == 'image/jpg' ||
              extension == 'image/png'
            ) {
              this.isPDF = false;
              this.isImage = true;
            } else {
              this.messageDialog('Formato de archivo incorrecto!', '', 'error');
              return;
            }
            this.pdfSrc = await blobToB64(blob);
            this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
          } else {
            Swal.fire(
              'No se pudo obtener documento',
              'Contacte con el administrador',
              'error'
            );
          }
        },
        error: (error) => {
          Swal.fire(
            'No se pudo obtener documento',
            'Contacte con el administrador',
            'error'
          );
        }
      });
  }

  downloadDocFile(data) {
    const DocFileName = data.ParamDocumentID;
    const name = data.ImageFileName;
    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_COMPANY_ID, data.CompanyID, DocFileName).subscribe((data) => {
      importedSaveAs(data, name)
    });
  }
  public PoliticsPrivate(){
    let trans = this.translations;
    if(this.company.approvedPolitics!=true){

      Swal.fire({
        title: this.titlePolitics,
        text: this.descriptionPolitics,
        icon: 'warning',
        // showCancelButton: true,
        showLoaderOnConfirm: true,
        // cancelButtonText: trans.cancel,
        confirmButtonText: trans.accept,
        allowOutsideClick: false,
        preConfirm: () => {
          this.company.approvedPolitics=true
          this.srv.postCall(API_UPDATEEMP, this.company).subscribe(resp=>{
            if(resp.success){
              this.UpdateStatus();
            }
          })
        }
      }).then((result) => {
      });
    }else{
      this.UpdateStatus();
    }
  }
  public UpdateStatus() {
    var to: any = {};
    to.CompanyID = this.userinfo.selectedCompanyId;
    to.Status = "P";
    to.StatusId = 38;
    if (this.u_obj.com_Securitymail == " " || this.u_obj.com_Securitymail == " " || this.u_obj.com_Securitymail == null) {
      Swal.fire(this.translations.important, this.translations['msg_mail'], 'warning');
      return;
    }

    let trans = this.translations;

    Swal.fire({
      title: trans.sampapp_refmethodswarn,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      allowOutsideClick: false,
      preConfirm: () => {
        this.saveVerificationAPI(to);
      }
    }).then((result) => {
    });
  }

  saveVerificationAPI(to: any) {
    this.isUpdating = true;
    this.srv.postCall(API_UPDATE_COMPANY_DOCUMENTS, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  public addParameter(obj: any) {
    if (this.curr_parameter == 0) {
      return;
    }
    else {
      let parm = this.tipotrabajo.filter(f => {
        return f.JobTypeID == this.curr_parameter;
      });
      let checker = obj['CompanyByJob'].filter(f => {
        return f.CompanyID == this.curr_parameter;
      });

      if (parm.length > 0) {//( (parm.length > 0) && (checker.length == 0) ){
        let parameter = parm[0];
        obj['CompanyByJob'].push({ JobTypeID: parameter['JobTypeID'], CompanyId: obj['CompanyId'], fullname: parameter['Name'] });
      }
    }

  }

  public delParameter(obj: any, position: number) {
    obj['CompanyByJob'].splice(position, 1);
  }



  public deleteFile(position, modal, docName, CompDocumentId) {
    //this.position = position
    //this.docName = docName
    //this.CompDocumentId = CompDocumentId
    //this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });

    let trans = this.translations;
    Swal.fire({
      title: '¿Está seguro que desea eliminar el archivo ' + docName + ' ?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      allowOutsideClick: false,
      preConfirm: () => {
        this.confirmDeleteFile(CompDocumentId);
        this.company.approvedPolitics=true;
        this.srv.postCall(API_UPDATEEMP, this.company);
      }
    }).then((result) => {
    });
  }

  confirmDeleteFile(CompDocumentId) {

    var qst = this.delete_url_details;
    var to: any;

    to = {};
    to.CompDocumentId = CompDocumentId
    to.DeleteUser = this.userinfo.logon;


    this.isUpdating = true;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  /*
  public confirmDeleteFile(position, modal, CompDocumentId){

    var qst = this.delete_url_details;
    var to:any;

    to = {};
    to.CompDocumentId = CompDocumentId
    to.DeleteUser = this.userinfo.logon;


    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.closeModal('modaldelete'); // Close Modal
        Swal.fire(this.translations.msg_create, x.msg, 'success');
        //this.curr_parameter = 0;
        this.ngOnInit(); // Reloaded
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }
  */

  public activateIconVehicle() {
    if (this.typeSelected == 'T') {
      this.seeModalCar = true;
    } else {
      this.seeModalCar = false;
    }
  }

  public openCreateCarModal(modal) {
    this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }
  public cargarCompany(){
    let company=this.userinfo.selectedCompanyId;
    this.srv.getCall(API_GET_COMPANY_BY_ID+company).subscribe(  resp=> {
      this.company=resp["val"]
    })
  }
}
