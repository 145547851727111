import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-hse',
  templateUrl: './hse.component.html',
  styleUrls: ['./hse.component.css']
})
export class HseComponent  extends BaseCrudComponent implements OnInit {

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });

  }



  openCreateModal(content) {
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

}
