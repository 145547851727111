<div class="card-info">
    <div class="card-flex" *ngFor="let row of items" [routerLink]="row.url" [hidden]="!hasPermission(row.perm)" >
        <div class="card-body">
        <div class="container-values">
            <div class="icon"><i class="{{row.icon}}"></i></div>
            <div class="title">{{ row.title | translate }}</div>
        </div>
        </div>
    </div>
</div>
