import { Component } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import {saveAs as importedSaveAs  } from "file-saver";
import { Cell, Img, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';

const API_GET_FILE = environment.Global.API_GET_FILE;
const API_GET_CERTIFIED = environment.Global.API_GET_CERTIFIED;
const API_GET_IMAGE_SIGNATURE=environment.Global.API_GET_IMAGE_SIGNATURE;

@Component({
  selector: 'app-myevaluation',
  templateUrl: './myevaluation.component.html',
  styleUrls: ['./myevaluation.component.css']
})
export class MyevaluationComponent extends BaseCrudComponent  {
  urlvideo: SafeUrl;
  userinfo:any;
  videoPlayer: HTMLVideoElement;
  apiLoaded = false;
  momentjs: any = moment;
  dataCertified = [];
  title_certified = "";
  user_signature_certified = "";
  user_profile_certified = "";
  imageSignature="";
  constructor(
    private _sanitizer: DomSanitizer,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.search_fields = ['EvaluationId','EvaluationName'];

      this.c_obj['Company'] = [];
    }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
    this.getList();
  }

  public getList()
  {
    var qst = "Request/AllByUser/"+this.userinfo.userCode;
    console.log(qst)
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      console.log(x);
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          console.log("rs:",rs);
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.messageDialog(x.msg, '', 'success');
          this.srv.getCall(API_GET_IMAGE_SIGNATURE).toPromise().then(resp => {
            this.imageSignature = resp.val["PhotoSignature"]
          })
        }else {
          this.messageDialog(x.msg, '', 'success');
          this.rows = [];
          return;
        }
      }else {
        this.messageDialog(x.msg, '', 'success');
        return;
      }
    });
  }

  openVideo (modal, obj) {
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }

  GoEvaluation(content:any,obj:any)
  {
    this.closeModal(content);
    this.requestID=obj.RequestId;
    this.router.navigateByUrl('myevaluationepecific/'+obj.EvaluationId+"-"+obj.RequestId);

  }

  openModalVideo(content: any, obj: any, size?:any) {
    console.log(obj)
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.urlVideo = this._sanitizer.bypassSecurityTrustResourceUrl(this.u_obj.UrlGeneral);
    if( size == undefined ){
      this.modalService.open(content, { backdrop: 'static', keyboard: false, centered:true });
    }
    else{
      this.modalService.open(content, { backdrop: 'static', keyboard: false, centered:true });
    }

  }

  downloadDocFile(data) {
    const name = data.fileName;
    this.srv.downloadFiles(API_GET_FILE + data.EvaluationId ).subscribe((data) => {
      importedSaveAs(data,name )
    });
  }

  async getdataCertified() {
    debugger
    let qst = API_GET_CERTIFIED;
    await this.srv.getCall(qst).toPromise().then(x => {
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.dataCertified = rs

          rs.forEach(element => {
            if (element.s.field == "CERTIFIED_TITLE") {
              this.title_certified = element.s.value;
            }
            else if (element.s.field == "CERTIFIED_USER") {
              this.user_signature_certified = element.s.value;
            }
            else if (element.s.field == "CERTIFIED_USER_POSITION") {
              this.user_profile_certified = element.s.value;
            }
          });

        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  async downloadCertificate(EvaluationName:string) {
    // console.log(this.userinfo)

    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    // console.log(...this.rows);
    // console.log(listInReport)
    // debugger





    const pdf = new PdfMakeWrapper();

    await this.getdataCertified();

    // INFORMATION IN TITLE NAV

    let content = 'Por haber completado exitosamente la actividad formativa';
    // let fecha =  this.momentjs().tz('America/Guayaquil').format('YYYY-MMM-DD H:mm:ss').;
    let fecha = this.momentjs().locale('es').format('DD/MMM/YYYY');




    pdf.info({

      title: "Certificado",
      // author: `${this.userInfo.name}`,
      author: 'DURAGAS',
      subject: 'Listado',
    });
    //OPTIONS

    var fontSize: 10


    pdf.defaultStyle({
      bold: false,
      fontSize: fontSize,
      alignment: 'justify'
    });
    pdf.pageMargins([40, 110, 40, 110]);
    const jsImg = await new Img(
      '../../../../assets/images/image_certified.png'
    ).width(841.89).height(595.28).build();

    pdf.background(jsImg);
    pdf.add(
      new Table(
        [
          [
            new Txt([new Txt(this.title_certified).bold().end]).fontSize(13).alignment('center').end,
          ],
        ]
      )
        .margin([0, 50, 30, 0])
        .alignment('center')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.add(
      new Table(
        [

          [
            new Txt([new Txt(this.userinfo.name).bold().end]).fontSize(22).alignment('center').end,
          ],


        ]
      )
        .margin([0, 20, 30, 0])
        .alignment('center')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.add(
      new Table(
        [

          [
            new Txt([new Txt(content).bold().end]).fontSize(13).alignment('center').end,
          ],


        ]
      )
        .margin([0, 10, 30, 0])
        .alignment('center')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.add(
      new Table(
        [

          [
            new Txt([new Txt(EvaluationName).bold().end]).fontSize(22).alignment('center').end,
          ],


        ]
      )
        .margin([0, 10, 30, 0])
        .alignment('center')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.add(
      new Table(
        [

          [
            new Txt([new Txt(fecha).bold().end]).fontSize(13).alignment('center').end,
          ],

        ]
      )
        .margin([0, 30, 30, 0])
        .alignment('center')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.add(
      new Table(
        [
          [
            new Cell(await new Img(this.imageSignature).width('120').build()).end,
          ],

        //  [
        //    new Txt([new Txt(this.user_signature_certified).bold().end]).fontSize(14).alignment('center').end,
        //  ],
          [
            new Txt([new Txt(this.user_profile_certified).bold().end]).fontSize(14).alignment('center').end,
          ],

        ]
      )
        .margin([0, 25, 0, 0])
        .alignment('center')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.pageOrientation('landscape');
    pdf.create().download("certificado_" +EvaluationName+"_" + this.userinfo.name);
    Swal.close();
  }
}

