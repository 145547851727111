<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">

                    <div class="col-12 col-md-4 col-lg-3">
                        <div class="form-control crud-link" style="text-align: left;">
                            <div class="title"><i class="icon-info m-r-5"></i> {{ 'language' | translate }}</div>
                            <div class="setting-item">
                                <select class="custom-select custom-select-md" [(ngModel)]="currlang" name="lang" (change)="setLang(currlang)">
                                    <option value="es">Espa&ntilde;ol</option>
                                    <option value="en">English</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-12 col-md-4 col-lg-3">
                        <div class="form-control crud-link" (click)="checkUpdates()" style="text-align: left;">
                            <div class="title"><i class="icon-info m-r-5"></i> {{ 'sw_version' | translate }}</div>
                            <div class="setting-item" style="text-align: center;">{{version}}</div>
                        </div>
                    </div> -->

                    <!-- <div class="col-12 col-md-4 col-lg-3">
                        <div class="form-control crud-link" (click)="getMasterData()" style="text-align: left;">
                            <div class="title"><i class="icon-refresh m-r-5"></i> {{ 'masterdata_lastupdate' | translate }}</div>
                            <div class="setting-item" style="text-align: center;">{{((last_sync != null) && (last_sync != undefined)) ? last_sync : 'N/A'}}</div>
                        </div>
                    </div> -->

                    <div class="col-12 col-md-4 col-lg-3">
                        <div class="form-control crud-link" (click)="logout()">
                            <div class="title">&nbsp;</div>
                            <div class="setting-item">
                                <i class="icon-logout m-r-5"></i> {{ 'logout' | translate }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>