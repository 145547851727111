import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSTIPOPREGUNTA = environment.Global.API_CONSTIPOPREGUNTA;
const API_REGTIPOPREGUNTA = environment.Global.API_REGTIPOPREGUNTA;
const API_UPDATEPREGUNTA = environment.Global.API_UPDATEPREGUNTA;
const API_DESACTIVEPREGUNTA = environment.Global.API_DESACTIVEPREGUNTA;
const API_ACTIVEPREGUNTA = environment.Global.API_ACTIVEPREGUNTA;

@Component({
  selector: 'app-paramtipopregunta',
  templateUrl: './paramtipopregunta.component.html',
  styleUrls: ['./paramtipopregunta.component.css']
})
export class ParamtipopreguntaComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;


  constructor(
    srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSTIPOPREGUNTA;
        this.create_url = API_REGTIPOPREGUNTA;
        this.update_url = API_UPDATEPREGUNTA;
        this.delete_url = API_DESACTIVEPREGUNTA;
        this.active_url = API_ACTIVEPREGUNTA;

        this.search_fields = ['NameType', 'Description'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg,'','success')
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg,'','success')
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
  });
}

public createItem(content:any, form:any){
  var validated = true;

  if( form == undefined ){
    form = {};
  }

  validated = this.validateCreateInfo(form);
  if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
  }

  var to:any;
 form.CreatorUser = this.userinfo['logon'];
 form.LastModifierUser = this.userinfo['logon'];
  to = form;


  var qst = this.create_url;
  this.isCreating = true;

  this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
          this.c_obj = {};
          this.confirmDialogWithModal(x.msg,'','success', content)
      }
      else {
        this.confirmDialogWithModal(x.msg,'','success', content)
      }
  });
}


validateCreateInfo(fields:any){
  let mygroup:any = {};
  mygroup = new FormGroup({
      'NameType': new FormControl(fields.NameType, [Validators.required]),
      'Descripcion': new FormControl(fields.Descripcion, [])
  });

  return mygroup.valid;
}

  public updateItem(content:any, form:any){
    this.isUpdating = true;
    var qst = this.update_url;

    if( form == undefined ){
      form = {};
    }

    var to:any;

    if( (form.NameType == undefined) || (form.NameType == '') ){
      this.isUpdating = false;
        Swal.fire(this.translations.msg_all_required, '', 'error');
        return;
    }

    to = {};
    to.NameType = form.NameType;
    to.Descripcion = form.Descripcion;
    to.CreatorUserId = form.CreatorUserId;
    to.LastModifierUserID = this.userinfo['userId'];
    to.TipoPreguntaID = form.TipoPreguntaID;
    

    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        this.confirmDialogWithModal(x.msg,'','success', content)
        this.u_obj = {};     
      } 
      }, err => {
      this.confirmDialogWithModal(err,'','error',content);
    });
  }

deleteItem(obj: any){
  var id:any;
  id = {};
  id.TipoPreguntaID = obj;
  id.DeleteUser = this.userinfo['userId'];
  var qst = this.delete_url;
  this.srv.postCall(qst, id).subscribe(x => {
    if(x.success){
      this.confirmDialog(x.msg,'','success');
    }
    else{
      this.confirmDialog(x.msg,'','error');
    }
  }, err => {
    this.confirmDialog(err,'','error');
  });
}


Activar(obj: any){
  var id:any;
  id = {};
  id.TipoPreguntaID = obj;
  id.LastModifierUser = this.userinfo['userId'];
  var qst = this.active_url;
  this.srv.postCall(qst, id).subscribe(x => {
    Swal.close();
    if(x.success){
      this.confirmDialog(x.msg,'','success');
    }
    else{
      this.confirmDialog(x.msg,'','error');
    }
  }, err => {
    this.confirmDialog(err,'','error');
  });
}

  toggleEditable(event, id) {
    if ( event.target.checked ) {
      this.Activar(id);
    }else {
      this.deleteItem(id);
    }
  }



}
