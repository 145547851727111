import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSPREGUNTADETALLE = environment.Global.API_CONSPREGUNTADETALLE;
const API_REGIPREGUNTADETALLE = environment.Global.API_REGIPREGUNTADETALLE;
const API_UPDATEPREGUNTADETALLE = environment.Global.API_UPDATEPREGUNTADETALLE;
const API_DESACTIVEPREGUNTADETALLE = environment.Global.API_DESACTIVEPREGUNTADETALLE;
const API_ACTIVEPREGUNTADETALLE = environment.Global.API_ACTIVEPREGUNTADETALLE;

@Component({
  selector: 'app-parampreguntadetalle',
  templateUrl: './parampreguntadetalle.component.html',
  styleUrls: ['./parampreguntadetalle.component.css']
})
export class ParampreguntadetalleComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  Opciontemp = false;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSPREGUNTADETALLE;
        this.create_url = API_REGIPREGUNTADETALLE;
        this.update_url = API_UPDATEPREGUNTADETALLE;
        this.delete_url = API_DESACTIVEPREGUNTADETALLE;
        this.active_url = API_ACTIVEPREGUNTADETALLE;

        this.search_fields = ['Respuestas'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
  
    var id:any;
    id = {};
    id.PreguntaID = this.id;
    this.messageShowLoading();
  this.srv.postCall(qst, id).subscribe(x => {
      this.loadingList = false;
          
          if(x.success){
            if(x.val != null){
              this.rawdata = x.val;
              var rs = x.val;
              this.rows = rs;
              this.page = 1;
              if( Array.isArray(this.rows) ){
                this.pageTotal = this.rows.length;
              }
              this.filterAll();
              this.closeLoadingMessage();
            }else {
              // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.closeLoadingMessage();
              this.rows = [];
              return;
                }
          }else {
            Swal.fire(x.msg, '', 'error');
            return;
              }
      });
  }

  public createItem(content:any, form:any){
    var validated = true;
  
    if( form == undefined ){
      form = {};
    }
  
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
  
    var to:any;
   form.CreatorUser = this.userinfo['logon'];
   form.LastModifierUser = this.userinfo['logon'];
   form.PreguntaID = this.id;
   form.Opcion = this.Opciontemp;
   to = form;
  
    var qst = this.create_url;
    this.isCreating = true;
  
    this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        if(x.success){
            this.getList();
            this.closeModal(content);
            Swal.fire('¡Creado!', x.msg, 'success');
        }//end success
        else{
            //Swal.fire(this.translations[x.msg], '', 'error');
            Swal.fire(x.msg, '', 'error');
        }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'Respuestas': new FormControl(fields.Respuestas, [Validators.required]),
    });
  
    return mygroup.valid;
  }

  changeupdate(event){
    if ( event.target.checked ) {
     this.Opciontemp = true;
    }else {
      this.Opciontemp = false;
    }
    }
  
    public updateItem(content:any, form:any){
      this.isCreating = true;
      var qst = this.update_url;
      
      if( form == undefined ){
        form = {};
      }
    
      var to:any;
    
      if( (form.Respuestas == undefined) || (form.Respuestas == '') ){
          Swal.fire('Ingrese todos los campos requeridos', '', 'warning');
          return;
      }
    
      to = {};
      to.Respuestas = form.Respuestas;
      to.PreguntaID =  this.id;
      to.LastModifierUser = this.userinfo['logon'];
      to.DetallePreguntaID = form.DetallePreguntaID;
      to.Opcion = this.Opciontemp;
      
    
      this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if (x.success) {
          this.reload.next(true);
          this.closeModal('update1');
          Swal.fire('¡Actualizado!', x.msg, 'success');
          this.getList();
          
        } 
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    
    }

    toggleEditable(event, id) {
    
      if ( event.target.checked ) {
         this.ActivarItem(id);
     }else {
       this.deleteItem(id);
    
    }
    }
    
    deleteItem(obj: any){
      var id:any;
      id = {};
      id.DetallePreguntaID = obj;
      id.DeleteUser = this.userinfo['logon'];
      id.LastModifierUser = this.userinfo['logon'];
      var qst = this.delete_url;
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('Desactivado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }
    
    ActivarItem(obj: any){
      var id:any;
      id = {};
      id.DetallePreguntaID = obj;
      id.LastModifierUser = this.userinfo['logon'];
      var qst = this.active_url;
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('¡Activado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }
    


}
