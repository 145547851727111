<div class="col">
    <div class="row">
        <div class="col-md-12">
            <!-- <input type="text" class="form-control" name="search" placeholder="{{'search_msg' | translate}}"> -->
            <ng-select [(ngModel)]="c_obj['userId']" placeholder="{{'select_user' | translate}}">
                <!--<ng-option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>-->
            </ng-select>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <p>{{'user' | translate}}: </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p>{{'name' | translate}}: </p>
        </div>
    </div>
</div>
