<div class="row">
    <div class="col-md-12 div-filter">
        <select class="custom-select custom-select-md form-control u-select" name="JobTypeID" [(ngModel)]="options_ruc"  (change)="rucSelected()" [disabled]=disabledRuc>
            <option [value] =null disabled>{{'select_ruc' | translate}}</option>
            <option [value] ="-1">{{'all' | translate}}</option>
            <option *ngFor="let ruc of ruc_list; let i = index" [value]="ruc.id">{{ruc.ruc |translate}}</option>
        </select>
        <select class="custom-select custom-select-md form-control u-select" name="JobTypeID" [(ngModel)]="options_company" (change)="companySelected()" [disabled]=disabledCompany>
            <option [value] =null disabled>{{'select_company' | translate}}</option>
            <option [value] ="-1">{{'all' | translate}}</option>
            <option *ngFor="let companie of companies_list; let i = index" [value]="companie.id">{{companie.name |translate}}</option>
        </select>
        <input type="date" class="form-control u-input-calendar" [(ngModel)]="date_init" title="{{'date_init' | translate}}">
        <input type="date" class="form-control u-input-calendar"  [(ngModel)]="date_end" title="{{'date_end' | translate}}" >
        <button  [ladda]="isFind" type="button" (click)="getList()"  class="btn button-modal u-button-find">{{'search_filters' | translate}}</button>
    </div>
</div>


<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div >
                            <button type="button" class="btn buttons-pages m-t-10" (click)="downloadReport()" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'download_report' | translate}}</button>
                            <!-- <button data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button> -->
                        </div>
                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <!-- <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white" #thead>
                    <tr>
                        <th> {{'code' | translate}}</th>
                        <th> {{'worker_name' | translate}}</th>
                        <th> {{'company' | translate}}</th>
                        <th> {{'level' | translate}} </th>
                        <th> {{'date_ing' | translate}} </th>
                        <th> {{'status' | translate}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                        <td>{{row.EnterOutWorkerId}}</td>
                        <td>{{row.Name}}</td>
                        <td>{{row.CompanyName}}</td>
                        <td>{{row.Planta[0]}}</td>
                        <td>{{row.DateRegister.split('T')[0]}}</td>
                        <td>{{row.Status}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="6">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div> 