<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-md-12" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('DocumentName')" class="sh">
                                    {{'menu_title_man_documento' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DocumentName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'desc' | translate}} </th>
                                <th (click)="setOrder('Sugerido')" class="sh">
                                    {{'Obligatorio' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Sugerido')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{ "emition_date" | translate }}</th>
                                <th>{{ "caducity_date" | translate }}</th>
                                <th>{{'carga' | translate}}</th>
                                <th colspan="2">{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>
                                    <div style="width: 100px;">
                                        {{ row.DocumentName }}
                                    </div>
                                </td>
                                <td>
                                    <div style="width: 200px;">
                                        {{ row.DocumentDescription }}
                                    </div>
                                </td>
                                <td class="align-text-top">
                                    <div class="form-check">
                                        <input class="form-check-input" [attr.checked]="(row.Is_required == true) ? 'checked' : null" id="{{row.ParamDocumentID}}" name="{{row.DocumentName}}" type="checkbox" disabled>
                                    </div>
                                </td>
                                <td class="align-text-top">
                                    <div > 
                                      <input type="date"  [ngModel]="row.date_doc | date:'yyyy-MM-dd'"  (ngModelChange)="row.date_doc = $event" name="dt" class="form-control form-control-rounded" #searchDate>
                                    </div>
                                </td>
                                <td class="align-text-top">
                                  <div > 
                                    <input type="date"  [ngModel]="row.date_doc_end | date:'yyyy-MM-dd'"  (ngModelChange)="row.date_doc_end = $event" name="dt" class="form-control form-control-rounded" #searchDate>
                                  </div>
                                </td>
                                <td>
                                    <label for="file"></label>
                                    <input type="file" id="file" (change)="handleInputChange($event)" [disabled]="(row.statusDocument == 'E' || row.statusDocument == 'R' || row.statusDocument == null) ? false : true" >
                                </td>
                                <td class="align-text-"> 
                                    <div class="form-check">
                                        <i (click)="onExpSubmit(row)" style="cursor:pointer;" class="fa fa-save text-info m-r-10" title="{{'save' | translate}}"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="7">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                      <!--Aqui vamos a aumentar el listado de los documentos subidos-->
                <div class="row py-4 config_header_fixes paddingtopheader" >
                    <div class="container">
                        <div  class="collapse show col-12 modal-body">
                            <div class="card-body col-12 container" *ngIf="rowdocumentupload != undefined">
                              <div class="table-responsive">
                                <table  class="table">
                                    <thead style="padding: 0">
                                        <tr>
                                            <th>{{ "document_upload" | translate }}</th>
                                            <th>{{ "name_archive" | translate }}</th>
                                            <th>{{ "emition_date" | translate }}</th>
                                            <th>{{ "expiration_date" | translate }}</th>
                                            <th>{{ "status" | translate }}</th>
                                            <th>{{ "comment" | translate }}</th>
                                            <th>{{ "action" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row1 of rowdocumentupload">
                                            <td>
                                              <div style="width: 150px;">
                                                {{ row1.NameDoc }}
                                              </div>
                                            </td>
                                            <td>
                                              <div style="width: 150px;">
                                                {{ row1.FileName }}
                                              </div>
                                            </td>
                                            <td>
                                              <div style="width: 150px;">
                                                {{ row1.DateDocument | date: "y-MM-dd" }}
                                              </div>
                                            </td>
                                            <td>
                                              <div style="width: 150px;">
                                                {{ row1.ExpirationTime | date: "y-MM-dd" }}
                                              </div>
                                            </td>
                                            <td>{{ row1.status_name }}</td>
                                            <td>
                                              <div style="width: 150px;">
                                                {{ (row1.VerificateComment == null || row1.VerificateComment == "") ? "Sin novedad" : row1.VerificateComment }}
                                              </div>
                                            </td>
                                            <td class="action-buttons">
                                              <i (click)="openViewModal(view, row1)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>
                                              <!-- <i (click)="downloadDocFile(row1)" style="cursor: pointer"
                                                class="fa fa-cloud-download-alt m-r-10" title="{{ 'export' | translate }}" >
                                              </i> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer" *ngIf="rowdocumentupload.length > 0" >
                            <div  *ngIf="cargados != true" style=" background-color: lavenderblush;">
                                <div *ngIf="cargavacia != true" > 
                                    <label class="col-sm-12 text-center control-label col-form-label">{{'mensaje_status' | translate}}</label>
                                </div>
                            </div>
                            <div  *ngIf="cargados == true">
                                <button [ladda]="isUpdating" (click)="UpdateStatus()" data-style="expand-" type="button" class="btn button-modal" style="color: #ffffff;">{{'verificate' | translate}}</button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #view let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
      <h4 class="modal-title">{{'preview' | translate}}</h4>
      <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
        <i (click)="downloadDocFile(this.row2)" style="cursor:pointer;" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}" title="{{'export' | translate}}"></i>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <form class="form-horizontal">
        <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
          <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                      style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
        </ng-container>
        <ng-template #elseTemplate>
          <div style="display: flex;">
            <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
                 alt="">
          </div>
        </ng-template>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
    </div>
</ng-template>