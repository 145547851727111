<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('TipoPreguntaID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='TipoPreguntaID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Pregunta')" class="sh">
                                    {{'pregunta' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Pregunta')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'group' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='TypeParameterName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'type' | translate}}&nbsp;{{'pregunta' | translate}} </th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.PreguntaID}}</td>
                                <td width="500pt">{{row.Pregunta}}</td>
                                <td>{{row.TypeParameterName}}</td>
                                <td>{{row.TypeName}}</td>
                                <td class="align-text-top"> <div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.PreguntaID}}" name="{{row.Name}}" (change)="toggleEditable($event,row.PreguntaID)" type="checkbox">
                                  </div></td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    
                                    <!--<i (click)="goTo('/params/'+row.IdParameter, {​​​​​}​​​​​)" style="cursor:pointer;" class="fa fa-settings text-dark m-r-10" title="{​​​​​{​​​​​'parameters' | translate}​​​​​}​​​​​"></i>-->
                                    <i (click)="goTo('/questiondetail/'+row.PreguntaID, {})" style="cursor:pointer;" class="ti-settings text-info m-r-10" title="{{'respuesta' | translate}}"></i>
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="DeleteItem(row.PreguntaID)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i> 
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'pregunta' | translate}} *</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['Pregunta']" name="Pregunta" required>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="input-group mb-3">
                                    <label for="lname" class="col-sm-3 text-right control-label col-form-label">{{'type' | translate}}&nbsp;{{'pregunta' | translate}} *</label>
                                    <div class="col-9">
                                        <select class="custom-select custom-select-md" name="TipoPreguntaID" [(ngModel)]="c_obj['TipoPreguntaID']" required>
                                            <option *ngFor="let list of listTypeQuestion; let i = index" [value]="list['TipoPreguntaID']">{{list['NameType']}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="input-group mb-3">
                                    <label for="lname" class="col-sm-3 text-right control-label col-form-label">{{'menu_type_question' | translate}} *</label>
                                    <div class="col-9">
                                        <select class="custom-select custom-select-md" name="TypeParameterID" [(ngModel)]="c_obj['TypeParameterID']" required>
                                            <option *ngFor="let listp of listTypeParameter; let i = index" [value]="listp['ID']">{{listp['value']}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.PreguntaID" name="`PreguntaID" maxlength="20" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'pregunta' | translate}} *</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.Pregunta" name="Pregunta" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'type' | translate}}&nbsp;{{'pregunta' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="TipoPreguntaID" [(ngModel)]="u_obj.TipoPreguntaID" required>
                                                <option *ngFor="let list of listTypeQuestion; let i = index" [value]="list['TipoPreguntaID']">{{list['NameType']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">{{'typegroup' | translate}}&nbsp;{{'pregunta' | translate}} *</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="TypeParameterID" [(ngModel)]="u_obj['TypeParameterID']" required>
                                                <option *ngFor="let listp of listTypeParameter; let i = index" [value]="listp['ID']">{{listp['value']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>