<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                         <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()"> 
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div  *ngIf="habilitar == true" >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <div *ngIf="habilitar == true" >
                        <table class="table">
                            <thead class="table-head text-white">
                                <tr>
                                    <th (click)="setOrder('RequestId')" class="sh">
                                        {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='RequestId')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                    </th>
                                    <th>{{'date_creation' | translate}}</th>
                                    <th>{{'worker_quantity' | translate}}</th>
                                    <th>{{'habidesa' | translate}}</th>
                                    <th  (click)="setOrder('status_eval')" class="sh">
                                        {{'status_assign_evaluation' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='status_eval')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                    </th>
                                    <th>{{'actions' | translate}}</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc "><!-- | orderBy:orderKey:orderDesc"> -->
                                    <!-- <td>{{row.CompanyID}}</td> -->
                                    <td>{{row.RequestId}}</td>
                                    <td>{{row.CreationTime.split('T')[0]}} &nbsp; {{row.CreationTime.split('T')[1].split('.')[0]}}</td>
                                    <td>{{row.listRequestdetail.length}}</td>
                                    <!-- <td>{{row.WhoRequest}}</td> -->
                                    <td class="align-text-top"> <div class="form-check">
                                        <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.RequestId}}" name="{{row.RequestId}}" (change)="toggleEditable($event,row.RequestId)" type="checkbox">
                                    </div>
                                    </td>
                                    <td> 
                                        <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="{{row.status_eval === 'ASIGNADO'? 'Los trabajadores deben iniciar las evaluaciones':'Debe ingresar la solicitud de ingreso'}}">
                                            {{row.status_eval}}
                                        </span>
                                    </td>
                                    <td class="action-buttons" *ngIf="row.Status == 'A'  ">
                                        <!-- <i class="fa fa-download text-success m-r-10" title="{{'download_prorequest' | translate}}"></i> -->
                                        <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="fa fa-eye text-info m-r-10" title="{{'see_detail' | translate}}"></i>
                                        <!-- <i (click)="ActivarItem(row.RequestId,3)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>  -->
                                       
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="6">
                                    <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                                </td>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <h4 style="margin-left: 10px;">{{'menu_mtrabajador' | translate}} <label *ngIf="row_emp.length== 0">  - {{'loading' | translate}} </label></h4>
                                <div class="table-responsive">
                                    <table class="table" style=" padding: auto;">
                                        <thead style="padding: 0">
                                            <tr>
                                                <th>{{'identification' | translate}}</th>
                                                <th>{{'name' | translate}}</th>
                                                <th>{{'employee_type' | translate}}</th>
                                                <th>{{'status_documents'| translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of row_emp  | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2">
                                                <td>{{row.NumberIdentification}}</td>
                                                <td>{{row.Name}}</td>
                                                <td>{{row.typeEmployeeName}}</td>
                                                <td>{{row.Status_doc}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <td colspan="4">
                                                <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2" [maxSize]="pageSize2" style="float: right;"></ngb-pagination>
                                            </td>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <hr>
                            <div class="col-12">
                                <h4>{{'data_head_request'| translate}}</h4>
                                <div class="row">
                                    <div class="col-md-6 ha-ibox">
                                        <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'company' | translate}} </label>
                                        <input type="text" class="form-control" value="{{userinfo['logon']}}" name="company" readonly>      
                                    </div>
                                    <div class="col-md-6 ha-ibox">
                                        <label for="fname" class="col-sm-3 text-right control-label col-form-label"> &nbsp;</label>
                                        <input type="text" class="form-control" value="{{userinfo['name']}}" name="company"  readonly>     
                                    </div>
                                    <div class="col-md-6 ha-ibox">
                                        <label>{{'applicant' | translate}} *</label>
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['WhoRequest']" name="WhoRequest"  required>     
                                    </div>
                                    <div class="col-md-12 ha-ibox">
                                        <h4>{{'data_details_request'| translate}}</h4>
                                    </div>
                                    <!-- <div class="col-md-12 ha-ibox">
                                        <h6>*{{'detail_employees'| translate}}</h6>
                                    </div> -->
                                    <div class="col-md-6 ha-ibox">
                                        <label>{{'identification' | translate}}</label>
                                        <input type="text" maxlength="13" (keypress)="onlyNumberKey($event)"  pattern="[0-9]+" class="form-control" name="identification" [(ngModel)]="c_obj['NumberIdentification']" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)"  >
                                    </div>
                                    <div class="col-md-6 ha-ibox">
                                        <label>{{'name' | translate}}</label>
                                        <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']"  title="{{'autocomplete'| translate}}" readonly>
                                    </div>
                                    <div class="col-md-6 ha-ibox">
                                        <label>{{'employee_type'|translate}}</label>
                                        <input type="text"  class="form-control" name="typeEmployeeName" [(ngModel)]="c_obj['typeEmployeeName']"  title="{{'autocomplete'| translate}}" readonly>
                                    </div>
                                    <div class="col-md-6 ha-ibox" *ngIf="userinfo.typeCompany != 2">
                                        <label>{{'jobtitle' | translate}}</label>
                                        <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}"  readonly>
                                    </div>
                                    <div class="col-md-5 ha-ibox" *ngIf="userinfo.typeCompany != 2">
                                        <label>{{'globalparam_typejob' | translate}}</label>
                                        <ng-select [(ngModel)]="curr_parameter" placeholder="{{'select' | translate}}" [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let p of row_typejob; let i = index" [value]="p['JobTypeID']">{{p['Name']}}</ng-option>
                                        </ng-select>  
                                    </div>
                                    <div class="col-md-3 ha-ibox">
                                        <label *ngIf="userinfo.typeCompany == 2">&nbsp;</label>
                                        <label *ngIf="userinfo.typeCompany == 1">&nbsp;</label>
                                        <br>
                                        <button type="button" class="btn button-modal" (click)="addParameter(c_obj)" style="color: #ffffff;" ngbTooltip="{{'add'|translate}}">
                                           {{'add' | translate}} <i class=" fas fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-12 ha-ibox">
                                        <h4>{{'employee' | translate}}</h4>
                                        <div class="table-responsive">
                                        <table class="table">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'identification' | translate}}</th>
                                                    <th>{{'name' | translate}}</th>
                                                    <th>{{'jobtitle' | translate}}</th>
                                                    <th>{{'globalparam_typejob'| translate}}</th>
                                                    <th>{{'action' | translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let parameter of c_obj['RequestDetails']; let i=index">
                                                    <td>{{parameter.NumberIdentification}}</td>
                                                    <td>{{parameter.NameEmployee}}</td>
                                                    <td>{{parameter.position}}</td>
                                                    <td>{{parameter.JobtypeName}}</td>
                                                    <td class="action-buttons">
                                                        <i (click)="delParameter(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['RequestId']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="col-12">
                                <h4>{{'data_head_request'| translate}}</h4>
                                <div class="row">
                                    <div class="col-md-6 ha-ibox">
                                        <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'company' | translate}} </label>
                                        <input type="text" class="form-control" value="{{u_obj['Ruc']}}" name="company" readonly>      
                                    </div>
                                    <div class="col-md-6 ha-ibox">
                                        <label for="fname" class="col-sm-3 text-right control-label col-form-label"> &nbsp;</label>
                                        <!-- <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'company' | translate}} </label> -->
                                        <input type="text" class="form-control" value="{{u_obj['namecompany']}}" name="company"  readonly>     
                                    </div>
                                    <div class="col-md-6 ha-ibox">
                                        <label>{{'job_applicant' | translate}} *</label>
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['WhoRequest']" name="WhoRequest"  required readonly>     
                                    </div>
                                    <div class="col-md-12 ha-ibox">
                                        <h4>{{'employee' | translate}}</h4>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead style="padding: 0">
                                                    <tr>
                                                        <th>{{'identification' | translate}}</th>
                                                        <th>{{'name' | translate}}</th>
                                                        <th>{{'jobtitle' | translate}}</th>
                                                        <th>{{'globalparam_typejob'| translate}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let parameter of u_obj['listRequestdetail']">
                                                        <td>{{parameter.NumberIdentification}}</td>
                                                        <td>{{parameter.NameEmployee}}</td>
                                                        <td>{{parameter.position}}</td>
                                                        <td>{{parameter.JobtypeName}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                    </div>
                </ng-template>

                
            </div>
        </div>
    </div>
</div>

