<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <!-- <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired" ><i class=" fas fa-plus"></i> {{'create' | translate}}</button> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th>
                                    ID
                                </th>
                                <th>
                                    {{'employee'| translate}}
                                </th>
                                <th class="text-nowrap">{{'identification' | translate}}</th>
                                <th class="text-nowrap">{{'job_to_do' | translate}}</th>
                                <th class="text-nowrap">{{'date_init' | translate}}</th>
                                <th class="text-nowrap">{{'date_end' | translate}}</th>
                                <th class="text-nowrap">{{'emergency' | translate}}</th>
                                <th class="text-nowrap">{{'status_docs' | translate}}</th>
                                <th class="text-nowrap">{{'status_eval' | translate}}</th>
                                <th class="text-nowrap">{{'status_sanct' | translate}}</th>
                                <th class="text-nowrap">{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ; index as i">
                                <td >{{i + 1}}</td>
                                <td >{{row.Name}}</td>
                                <td >{{row.NumberIdentification}}</td>
                                <td >{{row.WorkDescription}}</td>
                                <td>{{row.startDateRequest.split('T')[0]}}</td>
                                <td>{{row.endDateRequest.split('T')[0]}}</td>
                                <td>{{row.emergency ? ('emergency' | translate ): ('no_emergency' | translate)}}</td>
                                <td>{{row.Status_doc == "VALIDADO" ? "Aprobado" : "Rechazado/Pendiente"}}</td>
                                <td>{{row.Status_evaluation == "APROBADO" ? "Aprobado" : "Reprobado/Pendiente"}}</td>
                                <td>{{row.Status_faltas == "N" ? "Sin faltas" : "Con faltas"}}</td>
                                <td class="action-buttons">
                                    <i (click)="seeListTools(row, tools)" style="cursor:pointer;" class="fas fa-file-alt text-warning m-r-10" title="{{'menu_list_of_tools_and_equipment' | translate}}"></i> 
                                    <!-- <i (click)="seeDocumentsVehicle(row, cars)" style="cursor:pointer;" class="far fa-file-alt text-success m-r-10" title="{{'menu_vehiculos_document' | translate}}"></i> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="11">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #tools let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'see_tools' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white">
                    <tr>                        
                        <th class="text-nowrap">{{'code' | translate}}</th>
                        <th class="text-nowrap">{{'description' | translate}}</th>
                        <th class="text-nowrap">{{'brand_model' | translate}}</th>
                        <th class="text-nowrap">{{'quantity' | translate}}</th>
                        <th class="text-nowrap">{{'notice_in' | translate}}</th>
                        <th class="text-nowrap">{{'notice_out' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of listTools; index as i">
                        <td >{{i + 1}}</td>
                        <td >{{row.Description}}</td>
                        <td >{{row.ModelBrand}}</td>
                        <td >{{row.quantity}}</td>
                        <td >{{row.EntryNews}}</td>
                        <td >{{row.DeparturesNews}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
<ng-template #cars let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'see_vehicle' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white">
                    <tr>                        
                        <th class="text-nowrap">{{'code' | translate}}</th>
                        <th class="text-nowrap">{{'v_model' | translate}}</th>
                        <th class="text-nowrap">{{'v_registration' | translate}}</th>
                        <th class="text-nowrap">{{'v_color' | translate}}</th>
                        <th class="text-nowrap">{{'status' | translate}}</th>
                        <th class="text-nowrap">{{'action' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of listCars; index as i">
                        <td >{{i + 1}}</td>
                        <td >{{row.Model}}</td>
                        <td >{{row.CarRegistration}}</td>
                        <td >{{row.Color}}</td>
                        <td >{{row.estadoGeneral}}</td>
                        <td ><i style="cursor: pointer;" class="fa fa-info" title="{{'see_detail' | translate}}" (click)="seeDetailDocument(row._DocumentsVehicle)"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="havedata">
            <h4>{{'doc_vehicles' | translate}}</h4>
            <table class="table">
                <thead class="table-head text-white">
                    <tr>          
                        <th class="text-nowrap">{{'code' | translate}}</th>              
                        <th class="text-nowrap">{{'document' | translate}}</th>
                        <th class="text-nowrap">{{'description' | translate}}</th>
                        <th class="text-nowrap">{{'Obligatorio' | translate}}</th>
                        <th class="text-nowrap">{{'status' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of detalleDocumentos; index as i">
                        <td >{{i + 1}}</td>
                        <td >{{row.document}}</td>
                        <td >{{row.description}}</td>
                        <td >{{row.Obligatorio ? 'Obligatorio' : 'No obligatorio'}}</td>
                        <td >{{row.Status == "V" ? "Aprobado" : "Reprobado"}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="havedata == false">
            <h4>{{'dont_have_document' | translate}}</h4>
        </div>
    </div>
</ng-template>