import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-production-group',
  templateUrl: './production-group.component.html'
})
export class ProductionGroupComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;

    degrees:any;
    positions:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'ProductionGroup/All';
        this.create_url = '';
        this.update_url = 'ProductionGroup/Update';
        this.delete_url = '';
        this.search_fields = ['fgName'];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        Swal.fire(
            {
              title: this.translations['loading'],
              html: '',
              icon: 'info',
            }
          );
          Swal.showLoading();
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            if(x.success){
                let rs = x.val;
                this.rawdata = [];

                rs.forEach(y => {
                    if( y['fgIsClosed'] == false ){
                        this.rawdata.push(y);
                    }
                });

                //this.rawdata = x.val;
                //this.rows = x.val;
                this.rows = this.rawdata;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
                Swal.close()
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

}
