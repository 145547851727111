import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../main/api.service';
import { environment } from 'src/environments/environment';
import { resolve } from 'url';
import { Observable } from 'rxjs';


const API_GET_ALL_MENUS = environment.Global.API_GET_ALL_MENUS;
const API_GET_ALL_MODULES = environment.Global.API_GET_ALL_MODULES;
const API_GET_PAGES_BY_MENUID = environment.Global.API_GET_PAGES_BY_MENUID;

@Injectable({
  providedIn: 'root'
})

export class PermissionService {

  constructor(private srv: ApiService) { }

  public getMenus(): Promise<any[]> {
    return this.srv.getCall(API_GET_ALL_MENUS).toPromise().then(x => {
      if (x.val != null) {
        let newList = [];
        x.val.forEach(element => {
          newList.push(
            {
              path: element.Path,
              title: element.Title,
              icon: element.Icon,
              class: '',
              extralink: false,
              perm: element.Permissions,
              submenu: []
            },
          )
        })
        return x.val;
      } else {
        return [];
      }
    });
  }

  public getModules() {
    return this.srv.getCall(API_GET_ALL_MODULES).toPromise().then(data => {
      return data;
    });
  }

  public getModulesByMenuId(id) {
    return this.srv.getCall(API_GET_PAGES_BY_MENUID + id).toPromise().then(data => {
      let items = []
      if (data.success) {
        if (data.val != null || (Array.isArray(data.val) && data.val.length > 0)) {
          data.val.forEach(element => {
            items.push(
              {
                title: element.Title,
                url: element.Path,
                icon: element.Icon,
                perm: element.Permission,
                principal: element.Principal
              }
            )
          })
        }else {
          items = []
        }
      } else {
        items = []
      }
      return items;
    });
  }
}
