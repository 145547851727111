import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { isNumeric } from 'rxjs/internal-compatibility';
import { debug } from 'console';
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';

const API_GET_EMPLOYEES_BY_EVALUATION = environment.Global.API_GET_EMPLOYEES_BY_EVALUATION;
const API_GET_EVALS_BY_TYPE_JOB = environment.Global.API_GET_EVALS_BY_TYPE_JOB;
const API_GET_JOBS_BY_TYPE_EMPLOYEE = environment.Global.API_GET_JOBS_BY_TYPE_EMPLOYEE;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const API_GET_EVALUATION_BY_COMPANY = environment.Global.API_GET_EVALUATION_BY_COMPANY;



@Component({
  selector: 'app-evalassignbyuser',
  templateUrl: './evalassignbyuser.component.html',
  styleUrls: ['./evalassignbyuser.component.css']
})
export class EvalassignbyuserComponent extends BaseCrudComponent implements OnInit {

  userinfo: any = null;
  typesEmployee = [{ id: 100, name: "Trabajador" }, { id: 101, name: "Conductor" }];
  isVisible = false;
  evaluationsList = [];
  private workbook: Workbook;
  private lista_titulos;
  date_init = null;
  date_end = null;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  isClean = false;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);
    this.search_fields = ['CompanyName', 'TypeCompany',
      'NumberIdentification', 'EmployeeName', 'TypeEmployee', 'EvaluationName', 'StatusEval'];
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
    this.pageSize = 10;
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.getCompanies();
  }

  public async getListCertificate(url:string) {

    this.isFind = true;
    // console.log(url)
    let list_tmp = [];
    this.srv.getCall(url).subscribe(x => {
      // console.log(x.val)
      if (x.success) {
        if (x.val != null) {
          // console.log(x.val)
          this.rawdata = x.val;
          let new_list = [];
          list_tmp = x.val;
          if ((!(this.date_init===null) && !(this.date_end===null)) && (this.options_ruc == -1 || this.options_ruc == null) && (this.options_company == -1 || this.options_company == null) ) {
            // console.log('Primer filtro')
            list_tmp.forEach(tmp => {
              tmp.Date = this.formatOnlyDate(new Date(tmp.Date));
              if (tmp.Date >= this.date_init && tmp.Date <= this.date_end) {
                new_list.push(tmp);
              }
            })
            this.rawdata = new_list;
            this.rows = new_list;
            this.isFind=false
          } else if ((this.options_ruc != -1 || this.options_company != -1) && (!(this.date_init===null) && !(this.date_end===null))) {
            // console.log('Segundo filtro')

            list_tmp.forEach(tmp => {
              tmp.Date = this.formatOnlyDate(new Date(tmp.Date));
              if (tmp.Date >= this.date_init && tmp.Date <= this.date_end) {
                if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                  new_list.push(tmp);
                }
              }
            })

            this.rawdata = new_list;
            this.rows = new_list;
            this.isFind=false;
            this.filterAll();
            this.isFind = false;
            debugger
            if (this.rows.length === 0) {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
              this.isFind = false;
            }
          } else if ((this.options_ruc != null || this.options_company != null) && ((this.date_init===null) && (this.date_end===null))) {
            // console.log('SKRETTING')
            if (((this.options_ruc !== null && this.options_ruc != null) || (this.options_company != null && this.options_company != null))) {
              // console.log('Tercer filtro induccion')
              list_tmp.forEach(tmp => {
                new_list.push(tmp);
              })
              this.rawdata = new_list;
              this.rows = new_list;
              this.isFind=false;
            }
            this.page = 1;
            if (Array.isArray(this.rows)) {
              this.pageTotal = this.rows.length;
            }
            // console.log(this.rows)

            this.filterAll();
            this.isFind = false;
            debugger
            if (this.rows.length === 0) {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
              this.isFind = false;
            }
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false;
            return;
          }
        } else {
          this.isFind = false;
          Swal.fire(x.msg, '', 'error');
          return;
        }
        if (this.rows.length === 0) {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
        }
      }})
  }



  public validateCreateInfo(fields: any) {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'Name': new FormControl(fields.Name, [Validators.required]),
      'NumberIdentification': new FormControl(fields.NumberIdentification, [Validators.required])
    });

    return mygroup.valid;
  }

  public openCreateModal(content) {
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    this.c_obj = {};
    this.isCreating = false;

  }

  public onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public getEmployeesByType() {
    if (this.c_obj['typeEmployee'] != null && this.c_obj['typeEmployee'] != 101) {
      this.isVisible = true;
      let url = API_GET_JOBS_BY_TYPE_EMPLOYEE + this.c_obj['typeEmployee'];
      this.srv.getCall(url).subscribe(x => {
        if (x.val.length > 0) {
          this.c_obj['jobs'] = x.val;
        }
      });
    } else if (this.c_obj['typeEmployee'] == 101) {
      this.isVisible = false;
      this.c_obj['NumberIdentification'] = null;
      this.c_obj['Name'] = null;
      this.getEvaluationsByTypeJob(1);
    }
  }

  public GetEmployeeforIdentification(obj: any, val: any) {
    console.log(obj);
    if ((obj !== null) && (obj !== undefined) && (obj !== '')) {
      console.log('no vacío')
      var qst = "Employee/GetIdentification/" + obj;
      this.messageShowLoading();
      this.srv.getCall(qst).subscribe(x => {
        if (x.val == null) {
          this.messageDialog('Registro no existe. Verifique si se encuentra previamente registrado', '', 'warning');
        } else
        if (x.val.length > 0) {
          console.log(x.val)
          this.c_obj['CompanyID'] = x.val[0].CompanyID;
          this.c_obj['EmployeeId'] = x.val[0].EmployeeId;
          this.c_obj['Name'] = x.val[0].Name;
          this.c_obj['_JobTypeEmployee'] = x.val[0]._JobTypeEmployee;
          this.c_obj['RequestDetails'] = [
            {
              EmployeeID: x.val[0].EmployeeId,
              JobTypeID: x.val[0].typeEmployeeName == "Conductor" ? 6 : null
            }
          ];
          this.messageDialog(x.msg, '', 'success');
        } else {
          this.messageDialog(x.msg, '', 'error');
        }
      });
    }
    // else {
    //   console.log('vacío')
    // }
  }

  public getEvaluationsByTypeJob(type?) {
    this.c_obj['ParameEvaID'] = null;
    this.evaluationsList = [];
    if (type == undefined) {
      if (this.c_obj['JobTypeID'] != null && this.c_obj['JobTypeID'] != undefined) {
        let url = API_GET_EVALS_BY_TYPE_JOB + this.c_obj['JobTypeID'];
        this.c_obj['RequestDetails'][0].JobTypeID = this.c_obj['JobTypeID'];
        this.messageShowLoading();
        this.srv.getCall(url).subscribe(x => {
          if (x.success) {
            if (x.val != null) {
              x.val.forEach(element => {
                this.evaluationsList.push(
                  {
                    ParameEvaID: element[0].ParameEvaID,
                    Nombre: element[0].Nombre
                  }
                )
              })
              this.messageDialog(x.msg, '', 'success');
            }
          } else {
            this.messageDialog('Registro no existe. Verifique si se encuentra evaluaciones previamente registradas', '', 'warning');
          }
        })
      }
    } else {
      this.c_obj['JobTypeID'] = 6;
      let url = API_GET_EVALS_BY_TYPE_JOB + this.c_obj['JobTypeID'];
      this.messageShowLoading();
      this.srv.getCall(url).subscribe(x => {
        if (x.success) {
          if (x.val != null) {
            x.val.forEach(element => {
              this.evaluationsList.push(
                {
                  ParameEvaID: element[0].ParameEvaID,
                  Nombre: element[0].Nombre
                }
              )
            })
            this.messageDialog(x.msg, '', 'success');
          }
        }
      })
    }
  }

  public async createItem(content: any, form: any) {
    this.create_url = "Request/Create";
    let qst = "Request/ExistSpecificEval";
    this.isCreating = true;
    var validated = true;
    if (form == undefined) {
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if (!validated) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      this.isCreating = false;
      return;
    }

    if (form.ParameEvaID == undefined || form.ParameEvaID == null) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      this.isCreating = false;
      return;
    }

    if (form.JobTypeID == undefined || form.JobTypeID == null) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      this.isCreating = false;
      return;
    }

    let to = {
      CompanyID: form.CompanyID,
      WhoRequest: this.userinfo['logon'],
      CreatorUserID: this.userinfo['userId'],
      RequestDetails: form.RequestDetails,
      Status: "A",
      ParameEvaID: form.ParameEvaID
    }
    let to2 = {
      ParameEvaID: this.c_obj.ParameEvaID,
      employeeId: this.c_obj.EmployeeId
    };
    let exist = false;
    let msg = "";
    await this.srv.postCall(qst, to2).toPromise().then(x => {
      if (x.success) {
        exist = false;
      } else {
        exist = true;
        msg = x.msg;
      }
    });
    if (exist) {
      this.isCreating = false;
      Swal.fire(msg, '', 'error');
      return;
    }
    console.log(to)
    var url = this.create_url;
    await this.srv.postCall(url, to).toPromise().then(async data => {
      if (data.val != null) {
        this.confirmDialog(data.msg, '', 'success');
        await this.AssigneItem("", data.val["RequestId"]);
      }
    }, catchError => {
      catchError.message;
    });
  }

  public async AssigneItem(content: any, form: any) {
    var qst = "Request/AssigneSpecificEval";
    var to: any;
    to = {
      RequestId: form,
      AssignedUser: "Automatic",
      CompanyID: this.c_obj.CompanyID,
      ParameEvaID: this.c_obj.ParameEvaID,
      employeeId: this.c_obj.EmployeeId
    };
    await this.srv.postCall(qst, to).toPromise().then(async x => {
      if (x.success) {
        await this.SendMail(to, content);
      }
    });
  }

  public async SendMail(data: any, content) {
    var qst = "Request/SenMail";
    var to: any;
    to = {};
    to = data;
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      if (x.success) {
        this.confirmDialogWithModal(x.msg, '', 'success', content);
        this.c_obj = {};
      }
    }, err => {
      this.isCreating = false;
      this.confirmDialogWithModal(err, '', 'error', content);
    });
  }
  downloadExcel() {

    this.workbook = new Workbook();
    let nombreXLSX = "Reporte asignación de cursos virtuales.xlsx";
    this.lista_titulos = [


      this.translations.code,
      this.translations.Company,
      this.translations.type_company,
      this.translations.identification,
      this.translations.Employee,
      this.translations.employee_type,
      this.translations.evaluation,
      this.translations.status,
      this.translations.Qualification,
      this.translations.date_emision,
      this.translations.caducity_date,

    ]


    this.ContainsExcel();
    this.workbook.creator = "GESCON";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      fs.saveAs(blob, nombreXLSX);
    })
  }
  private ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 8;
    sheet.getColumn("B").width = 20;
    sheet.getColumn("C").width = 20;
    sheet.getColumn("D").width = 20;
    sheet.getColumn("E").width = 20;
    sheet.getColumn("F").width = 20;
    sheet.getColumn("G").width = 20;
    sheet.getColumn("H").width = 20;
    sheet.getColumn("I").width = 20;
    sheet.getColumn("J").width = 20;
    sheet.getColumn("K").width = 20;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });



    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    // console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12, color:{ argb: 'FFFFFF' } };
    headerRow.fill={
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'003d6a'},
      bgColor:{argb:'F38230'}
    }
    // agregar los datos a las filas
    const rowsToInsert = sheet.getRows(2, this.rows.length);

    for (let index = 0; index < rowsToInsert.length; index++) {
      const itemData = this.rows[index]; //obtenemos el item segun el index de la iteraciòn(recorrido)
      const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
      // los valores de itemData seran asignados a row (fila actual en la iteraciòn)

      row.values = [

        itemData.RequestDetailId,
        itemData.CompanyName,
        itemData.TypeCompany,
        itemData.NumberIdentification,
        itemData.EmployeeName,
        itemData.TypeEmployee,
        itemData.EvaluationName,
        itemData.StatusEval,
        itemData.Score,
        itemData.Date==null?"":itemData.Date.split('T')[0],
        itemData.DateExpired==null?"":itemData.DateExpired.split('T')[0],
      ];

    }
  }
  clearFilters() {

    this.options_company = null;
    this.options_ruc = null;
    this.date_end = null;
    this.date_init = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
  }

  rucSelected() {
    if (this.options_ruc == -1 || this.options_ruc == null) {
      this.disabledCompany = false;
    } else {
      this.disabledCompany = true;
    }
  }
  companySelected() {
    // console.log(this.options_company);
    if (this.options_company == -1 || this.options_company == null) {
      this.disabledRuc = false;
    } else {
      this.disabledRuc = true;
    }
  }

  getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => {
      if (data.success) {
        if (data.val !== null) {
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push({ id: element.CompanyID, name: element.Name });
            this.ruc_list.push({ id: element.CompanyID, ruc: element.Ruc });
          })
        }
      }
    })
  }
  dataToFind() {
    debugger
    let url = '';
    this.isFind = true;
    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    if (this.date_init !== null && this.date_end === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_end_select, '', 'error');
    }
    if (this.date_end !== null && this.date_init === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_init_select, '', 'error');
    }

    if (isNumeric(this.userinfo.logon)) {
      url = API_GET_EVALUATION_BY_COMPANY+ this.userinfo.selectedCompanyId;
    } else {
      let id = this.options_company;
      id ??= this.options_ruc;
      if(id!=-1 && id!=null){
        url=API_GET_EVALUATION_BY_COMPANY+id;

      }else{
        url =  API_GET_EMPLOYEES_BY_EVALUATION;
      }
    }

    this.isFind = false;
    this.getListCertificate(url);
  }
  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }
  async downloadReport() {
    // console.log(this.rows)

    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    let listInReport = this.rows;
    let mapaTitles = new Map();
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];
    // console.log(...this.rows);
    // debugger
    listInReport.forEach(element => {
      let Date=element.Date==null?"":element.Date.split('T')[0];
      let DateExpired=element.DateExpired==null?"":element.DateExpired.split('T')[0];
      let obj = [];
      obj = [
        element.RequestDetailId,
        element.CompanyName,
        element.TypeCompany,
        element.NumberIdentification,
        element.EmployeeName,
        element.TypeEmployee,
        element.EvaluationName,
        element.StatusEval,
        element.Score,
        Date,
        DateExpired
      ]
      lista_widths = [
        'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
      ]

      lista_titulos = [
        new Cell(new Txt(this.translations.code).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.Company).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.type_company).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.identification).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.Employee).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.employee_type).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.evaluation).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.status).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.Qualification).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.date_emision).margin([0, 5, 0, 5]).color('white').bold().end).end,
        new Cell(new Txt(this.translations.caducity_date).margin([0, 5, 0, 5]).color('white').bold().end).end
      ]

      nueva_lista_reportes.push(obj);

      if (!mapaTitles.has(element.NameDoc)) {
        mapaTitles.set(element.NameDoc, element.NameDoc);
      }

    });




    const pdf = new PdfMakeWrapper();
    let title = "Reporte asignación de cursos virtuales";

    pdf.info({

      title: `${title}`,
      author: `${this.userinfo.name}`,
      subject: 'Listado',
    });

    var fontSize: 0;
    fontSize: 10
    fontSize: 8

    pdf.defaultStyle({
      bold: false,
      fontSize: fontSize,
      alignment: 'justify'
    });
    pdf.pageMargins([40, 110, 40, 75]);
    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/duragas_logo.svg').width('160').build()).end,
          ]
        ]
      )
        .margin([0, 30, 30, 0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.pageOrientation('landscape');
    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0, 0, 0, 20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.requested_by + ': ').bold().end, this.userinfo.name]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.user + ': ').bold().end, this.userinfo.logon]).fontSize(10).alignment('left').margin([0, 0, 0, 20]).end,
        new Table(
          [
            lista_titulos,
            ...nueva_lista_reportes
          ]
        ).layout({
          fillColor: (rowIndex: number, node: any, columnIndex: number) => {
            return rowIndex === 0 ? '#003d6a' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(8)
          .widths(lista_widths)
          .alignment('center')
          .end
      ]
    );
    pdf.footer(function (currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Duragas Ecuador | Km 7.5 vía a la costa, sector El Salitral Guayaquil - Ecuador | Telf: 1700387242').fontSize(8).color('gray').alignment('center').margin([0, 10, 0, 0]).end).end,
          new Cell(new Txt('www.duragas.com.ec').link('https://www.duragas.com.ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40, 20, 0]).end).end
        ]
      ).end
    });



    pdf.create().download(title);
    Swal.close();
  }
}
