<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-12 col-xlg-12 col-12 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="bandera_Activa_mensaje else noData">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('EntryRequestId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='EntryRequestId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Requiredby')" class="sh">
                                    {{'requested_by'| translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Requiredby')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'job_order' | translate}}</th>
                                <th>{{'job_to_do' | translate}}</th>
                                <th>{{'date_init' | translate}}</th>
                                <th>{{'date_end' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.EntryRequestId}}</td>
                                <td >{{row.Requiredby}}</td>
                                <td >{{row.WorkOrder}}</td>
                                <td >{{row.WorkDescription}}</td>
                                <td>{{row.StartDate.split('T')[0]}}</td>
                                <td>{{row.EndDte.split('T')[0]}}</td>
                                <td class="align-text-top"> 
                                    <div class="form-check" >
                                        <input class="form-check-input text-center" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.RequestId}}" name="{{row.RequestId}}" disabled type="checkbox">
                                    </div>
                                </td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row, 'lg')" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
                <ng-template #noData>
                    <h1>{{'no_contain_request_emergency' | translate}}</h1>
                </ng-template>
                <ng-template #update1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" >
                        <div class="col-12">
                            <h4>{{'job_data'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_init_work" class="text-right control-label col-form-label">{{'date_init_work' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_init_work"  [(ngModel)]="c_obj['date_init_work']" disabled >
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_final_work" class="text-right control-label col-form-label">{{'date_final_work' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_final_work" id="fechaFinal" [(ngModel)]="c_obj['date_final_work']"  disabled>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_order" class="text-right control-label col-form-label"> {{'job_order' | translate}} *</label>
                                    <input type="text"  class="form-control" id="job_order" name="job_order" placeholder="Orden de trabajo..." [(ngModel)]="c_obj['job_order']" (keypress)="onlyNumberKey($event)" disabled>   
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                    <input type="text" class="form-control"  name="name" value="{{userinfo['name']}}" readonly>    
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_applicant" class="text-right control-label col-form-label"> {{'job_applicant' | translate}} *</label>
                                    <input type="text"  class="form-control" id="job_applicant" name="job_applicant" placeholder="Solicitante..." [(ngModel)]="c_obj['job_applicant']" disabled>   
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="email_applicant" class="text-right control-label col-form-label"> {{'email_applicant' | translate}} *</label>
                                    <input type="email"  class="form-control" id="email_applicant" name="email_applicant" placeholder="Solicitante..." [(ngModel)]="c_obj['email_applicant']" disabled>   
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}} *</label>
                                    <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="setLevelArea()" disabled>
                                        <option *ngFor="let p of row_plantas" [value]="p.PlantaID + '-' + p.Name">{{p.Name}}</option>
                                    </select> 
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="job_area" class="text-right control-label col-form-label"> {{'job_area' | translate}} *</label>
                                    <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['job_area']" disabled>
                                        <option *ngFor="let p of row_plantas_area" [value]="p.SeccionID + '-' + p.NameSeccion">{{p.NameSeccion}}</option>
                                    </select>   
                                </div>  
                                <div class="col-md-4 ha-ibox">
                                    <label for="request_area" class="text-right control-label col-form-label"> {{'request_area' | translate}} *</label>
                                    <input type="text"  class="form-control" id="request_area" name="request_area" placeholder="Area..." [(ngModel)]="c_obj['request_area']" disabled>   
                                </div>    
                            </div>
                            <div class="row">                                 
                                <div class="col-md-4 ha-ibox">
                                    <label for="work_description" class="text-right control-label col-form-label"> {{'job_to_do' | translate}} *</label>
                                    <input type="text"  class="form-control" id="work_description" name="work_description" placeholder="Solicitante..." [(ngModel)]="c_obj['work_description']" disabled>   
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="risk" class="text-right control-label col-form-label">{{'job_risk' | translate}} *</label>
                                    <input type="checkbox" class="form-control" [(ngModel)]="c_obj['risk']" value="c_obj['risk']" name="risk" disabled>
                                </div>                            
                                <div class="col-md-4 ha-ibox">
                                    <label for="emergency" class="text-right control-label col-form-label">{{'emergency' | translate}} *</label>
                                    <input type="checkbox" class="form-control" [(ngModel)]="c_obj['emergency']" value="c_obj['emergency']" name="emergency" disabled>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="comment_emergency" class="text-right control-label col-form-label">{{'comment_emergency' | translate}} *</label>
                                    <textarea style="width: 100%;" [(ngModel)]="c_obj['comment_emergency']" placeholder="Indique quien solicita el ingreso" disabled></textarea>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="who_responsable" class="text-right control-label col-form-label">{{'who_responsable' | translate}} *</label>
                                    <textarea style="width: 100%;" [(ngModel)]="c_obj['who_responsable']" placeholder="Debe ingresar al responsable de la persona de emergencia" disabled></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <h4>{{'nametype' | translate}}</h4>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'name' | translate}}</th>
                                                    <th>{{'ci' | translate}}</th>
                                                    <th>{{'jobtitle'| translate}}</th>
                                                    <th>{{'globalparam_typejob' | translate}}</th>
                                                    <!-- <th *ngIf="!isSkretting">{{'action' | translate}}</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let parameter of c_obj['worker']; let i=index">
                                                    <td>{{parameter.NameEmployee}}</td>
                                                    <td>{{parameter.NumberIdentification}}</td>
                                                    <td>{{parameter.jobtitle}}</td>
                                                    <td>{{parameter.globalparam_typejob}}</td>
                                                    <!-- <td class="action-buttons" *ngIf="!isSkretting">
                                                        <i (click)="delParameter(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}" ></i>
                                                    </td>  -->
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>             
                        </div> 
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isReject" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="rejectSol(update1, this.c_obj)">{{'reject' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="confirmSol(update1, this.c_obj)">{{'confirm' | translate}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
