<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages"  style="color: #ffffff;" [disabled]="loadingRequired"><i class="far fa-save"></i> {{'save' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('ParamDocumentID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='ParamDocumentID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('DocumentName')" class="sh">
                                    {{'menu_title_man_documento' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DocumentName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Sugerido')" class="sh">
                                    {{'Obligatorio' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Sugerido')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'carga' | translate}}</th>
                                <th>{{ 'Check' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.ParamDocumentID}}</td>
                                <td>{{row.DocumentName}}</td>
                                <td class="align-text-top"><div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Sugerido == true) ? 'checked' : null" id="{{row.ParamDocumentID}}" name="{{row.DocumentName}}" type="checkbox" disabled>
                                  </div></td>
                                <td>
                                    <input class="file_policiaco" type="file" name="Examinar"/>
                                </td>
                                <td class="align-text-"> 
                                    <div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.SubJobTypeID}}" name="{{row.Name}}" (change)="toggleEditable($event,row.SubJobTypeID)" type="checkbox">
                                  </div></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-sm-4 text-right control-label col-form-label">{{'globalparam_typejob' | translate}}</label>
                                        <div class="col-sm-8">
                                            <select name="miselect" [(ngModel)]="seleccionados" multiple="multiple" style="width: 15rem;">
                                                <option [ngValue]="item" *ngFor="let item of this.rowJob">{{item.Name}}</option>
                                            </select> 
                                            <ul>
                                                <li *ngFor="let items of seleccionados">{{items.Name}}</li>
                                              </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">{{'globalparam_typejob' | translate}}</label>
                                        <div class="col-sm-9">
                                            <select class="custom-select custom-select-md" name="selectjob" [(ngModel)]="u_obj.JobTypeID" required (change)="filterAnalysisGroup($event.target.value)">
                                                <option *ngFor="let p of this.rowJob" [ngValue]="p.JobTypeID">{{p.Name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>