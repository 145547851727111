
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('ProfileId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='ProfileId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Name')" class="sh">
                                    {{'name'| translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'description' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i ">
                                <td> {{ i + 1}}</td>
                                <td >{{row.Name}}</td>
                                <td >{{row.Description}}</td>
                                <td >{{row.Status == 'A' ? 'Activo' : 'Inactivo'}}</td>
                                <td class="align-text-top"> 
                                    <div class="form-check" >
                                        <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.RequestId}}" name="{{row.RequestId}}" (change)="activarItem($event,row)" type="checkbox">
                                    </div>
                                </td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}" *ngIf="(row['ProfileId'] !== 3 && row['ProfileId'] !== 2 && row['ProfileId'] !== 21 && row['ProfileId'] !== 1 && row['ProfileId'] !== 4)"></i> 
                                   
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <h4>{{'detail_profile' | translate}}</h4>
            </div>
        </div> 
        <hr>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="nameProfile">{{'profile' | translate}} *</label>
                <input type="text" class="form-control" name="nameProfile"  [(ngModel)]="c_obj['nameProfile']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="descriptionProfile">{{'description_profile' | translate}} *</label>
                <input type="text" class="form-control" name="descriptionProfile"  [(ngModel)]="c_obj['descriptionProfile']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <h4>{{'select_permission' | translate}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="all">{{'select_by_menu' | translate}}</label>
                <ng-select [(ngModel)]="menuOption" placeholder="{{'Select-level' | translate}}" (change)="selectPages()">
                    <ng-option [value]=-1>{{'any' | translate }}</ng-option>
                    <ng-option [value]=0>{{'all' | translate }}</ng-option>
                    <ng-option *ngFor="let menu of listMenu" [value]="menu.MenuId">{{menu.Name}}</ng-option>
                </ng-select>
            </div>
            <!-- <div class="col-md-6 ha-ibox">
                <label for="all">{{'select_crud' | translate}}</label>
                <ng-select [(ngModel)]="c_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="setLevelArea()">
                    <ng-option *ngFor="let p of row_plantas" [value]="p.PlantaID + '-' + p.Name">{{p.Name}}</ng-option>
                </ng-select>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table" >
                        <thead class="table-head text-white">
                            <th>{{'name' | translate}}</th>
                            <!-- <th>{{'description' | translate}}</th> -->
                            <!-- <th>{{'permission' | translate}}</th> -->
                            <th>{{'create' | translate}}</th>
                            <th>{{'read' | translate}}</th>
                            <th>{{'update' | translate}}</th>
                            <th>{{'delete' | translate}}</th>
                            <th>{{'all' | translate}}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of listPages">
                                <td>{{row.Name}}</td>
                                <!-- <td>{{row.Description}}</td> -->
                                <!-- <td>{{row.Permission}}</td> -->
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['create']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['read']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['update']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['delete']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['all']" (change)="selectAllByRow($event,row)" [disabled]=disabledGeneralChecked></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <h4>{{'detail_profile' | translate}}</h4>
            </div>
        </div> 
        <hr>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="nameProfile">{{'profile' | translate}} *</label>
                <input type="text" class="form-control" name="nameProfile"  [(ngModel)]="u_obj['nameProfile']" [disabled]="u_obj['ProfileId'] == 3 || u_obj['ProfileId'] == 2 || u_obj['ProfileId'] == 21">
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="descriptionProfile">{{'description_profile' | translate}} *</label>
                <input type="text" class="form-control" name="descriptionProfile"  [(ngModel)]="u_obj['descriptionProfile']" [disabled]="u_obj['ProfileId'] == 3 || u_obj['ProfileId'] == 2 || u_obj['ProfileId'] == 21">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ha-ibox">
                <h4>{{'select_permission' | translate}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="all">{{'select_by_menu' | translate}}</label>
                <ng-select [(ngModel)]="menuOption" placeholder="{{'Select-level' | translate}}" (change)="selectPages()">
                    <ng-option [value]=-1>{{'any' | translate }}</ng-option>
                    <ng-option [value]=0>{{'all' | translate }}</ng-option>
                    <ng-option *ngFor="let menu of listMenu" [value]="menu.MenuId">{{menu.Name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table" >
                        <thead class="table-head text-white">
                            <th>{{'name' | translate}}</th>
                            <!-- <th>{{'description' | translate}}</th> -->
                            <!-- <th>{{'permission' | translate}}</th> -->
                            <th>{{'create' | translate}}</th>
                            <th>{{'read' | translate}}</th>
                            <th>{{'update' | translate}}</th>
                            <th>{{'delete' | translate}}</th>
                            <th>{{'all' | translate}}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of listPages">
                                <td>{{row.Name}}</td>
                                <!-- <td>{{row.Description}}</td> -->
                                <!-- <td>{{row.Permission}}</td> -->
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['create']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['read']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['update']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['delete']"></td>
                                <td><input class="form-check-input" type="checkbox" [(ngModel)]="row['all']" (change)="selectAllByRow($event,row)" [disabled]=disabledGeneralChecked></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="editItem(update1, u_obj)">{{'update' | translate}}</button>
    </div>
</ng-template>