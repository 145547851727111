import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';

import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNumeric } from 'rxjs/util/isNumeric';
import {ConsoleLogger} from "@angular/compiler-cli/ngcc";

const API_CONSULINFOALL = environment.Global.API_CONSULINFOALL;
const API_GET_INFO_VIGENCIA_DOCUMENTOS_CONTRATISTAS = environment.Global.API_GET_INFO_VIGENCIA_DOCUMENTOS_CONTRATISTAS;

const API_GET_INFO_DOCUMENTOS_PENDIENTES_CONTRATISTAS = environment.Global.API_GET_DOCUMENTOS_PENDIENTES_CONTRATISTAS;
const API_GET_INFO_DOCUMENTOS_PENDIENTES_TRABAJADORES_CONTRATISTAS = environment.Global.API_GET_DOCUMENTOS_PENDIENTES_TRABAJADORES;
// const GET_INFO_TRABAJADORES_CONTRATISTAS = environment.Global.GET_INFO_TRABAJADORES_CONTRATISTAS;

const GET_INFO_TRABAJADORES_COURSES_PENDING = environment.Global.GET_INFO_TRABAJADORES_COURSES_PENDING;
const GET_DOCUMENTS_APPROVE=environment.Global.GET_DOCUMENTS_APPROVE;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseCrudComponent {
  date_actually = new Date();
  typeUser = 0;
  translations: any;
  available_languages: any;
  currlang: any;
  version: any;
  // items:any;
  datenow;
  permissions = [];
  datearrays = [];
  new_comunicates = [];
  VigenciaDocumentosContratistas = [];
  DocumentosPendienteContratistas = [];
  DocumentosPendientesTrabajadores = [];
  DocumentosApprove:any[]=[];
  documentApprove:boolean=false;
  CoursesInfo = [];
  userInfo;
  Title: string = ""
  constructor(
    private spinner: NgxSpinnerService,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {


    // this.spinner.show();
    // this.items = [
    //   {title: 'menu_forms', url: '/formshome', icon: 'icon icon-note', perm: 'SI_ADMREGISTRO'},
    //   {title: 'menu_processes', url: '/prochome', icon: 'icon icon-chemistry', perm: 'SI_ADMSOLICITUD'},
    //   {title: 'menu_operacional', url: '/cruds', icon: 'icon icon-globe', perm: 'SI_ADMOPERACIONAL'},
    //   {title: 'menu_crud', url: '/cruds', icon: 'icon icon-globe', perm: 'SI_ADMCONSULTA'},
    //   {title: 'menu_general', url: '/settings', icon: 'icon icon-settings', perm: 'sett'}
    // ];
    this.read_url = API_CONSULINFOALL;
    this.Title = ""
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        // // this.rows = [];
        // super.ngOnInit();
        this.getList();
      });




    this.search_fields = ['Title', 'SubTitle', 'Description'];

    this.c_obj['Info'] = [];
    this.VigenciaDocumentosContratistas = [];
    this.DocumentosPendienteContratistas = [];
    this.DocumentosPendientesTrabajadores = [];
    this.CoursesInfo = [];

    // this.reload.subscribe(val => {
    //   if( val ){
    //     super.clearData();
    //     this.getList();
    //   }
    // });

    moment.locale('es');
    // this.auth.getPermissions().then(x => {
    //   this.permissions = x;
    //   this.permissions.push('sett');
    // });

        this.storage.get('userinfo').then(x => {
          this.userInfo = x;
          if(x.DocumentApprove==true){
            this.getDocumentsApprove();
            this.documentApprove=true
          }else{
          // console.log(this.userInfo);
          if (this.userInfo.ProfileID === undefined) {
            this.typeUser = 0;
          } else if (this.userInfo.ProfileID === 1) {
            this.typeUser = 1;
          } else if (this.userInfo.ProfileID === 2) {
            this.typeUser = 2;
            this.Title = "Contratistas"
          } else if (this.userInfo.ProfileID === 3) {
            this.typeUser = 3; // trabajadores

          } else if (this.userInfo.ProfileID === 21) {
            this.typeUser = 21; //transportistas
            this.Title = "Transportistas"
          }

          // this.getList();
          this.getVigenciaDocumentosContratistas();
          this.getDocumentosPendientesContratistas();
          this.getDocumentosPendientesTrabajadoresContratistas();
          this.getInfoCoursesPending();
        };
      })
    }


  hasPermission(str: string) {
    if (this.permissions.indexOf(str) > -1) {
      return true;
    }
    else {
      return false;
    }
  }


  public getList() {
    var qst = this.read_url;

    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      this.rows = [];
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.rowsTemp = rs;

          this.rowsTemp.forEach(element => {
            var day = moment(element.CreationTime, "YYYYMMDD");
            var days = moment(element.CreationTime).format('MMMM Do YYYY, h:mm:ss a');
            var xs = moment(day).startOf('day').fromNow();
            element.dateago = xs;
            element.dateformat = days;
          });

          this.rowsTemp.forEach(elements => {
            if (elements.Status == 'A') {
              this.rows.push(elements);
            }
          });
          let date_comparation = this.formatOnlyDate(this.date_actually);
          // console.log(date_comparation)
          this.rows.sort(function (a, b) { return (b.InfoID - a.InfoID) })
          this.rows.forEach(element => {
            // console.log(element);
            if (element.DateEnd.split('T')[0] > date_comparation) {
              if ((this.typeUser == 0) && (element.NameType == "Globales" || element.NameType == "Duragas")) {
                this.new_comunicates.push(element);


              } else if ((this.typeUser == 1) && (element.NameType == "Globales" || element.NameType == "Contratistas")) {
                this.new_comunicates.push(element);
                // solo mostramos el asside por hacer de Contratistas
              } else if ((this.typeUser == 2) && (element.NameType == "Globales" || element.NameType == "Trabajadores")) {
                this.new_comunicates.push(element);
                // solo mostramos el asside por hacer de
              }
            }
          });
          // console.log(this.new_comunicates)
          // console.log(this.rows);
          // this.spinner.hide();
          Swal.close();
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }

    });

  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString())
      + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString()) + ' ' +
      (date.getHours() <= 9 ? `0${date.getHours().toString()}` : date.getHours().toString()) + ':' +
      (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}` : date.getMinutes().toString()) + ':' +
      (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}` : date.getSeconds().toString());
  }

  async getVigenciaDocumentosContratistas() {
    // let url = GET_INFO_CONTRATISTAS + this.userInfo.selectedCompanyId;
    let url = API_GET_INFO_VIGENCIA_DOCUMENTOS_CONTRATISTAS + this.userInfo.selectedCompanyId;
    // console.log(url);
    this.srv.getCall(url).subscribe(data => {
      if (data.val != null) {
        // console.log(data.val);
        this.VigenciaDocumentosContratistas = data.val;
      } else {
        this.VigenciaDocumentosContratistas = []
        return;
      }
    })
    // console.log(this.ContratistasInfo);
  }
  // documentos pendientes de subir del contratistas
  async getDocumentosPendientesContratistas() {
    // let url = GET_INFO_CONTRATISTAS + this.userInfo.selectedCompanyId;
    let url = API_GET_INFO_DOCUMENTOS_PENDIENTES_CONTRATISTAS;
    var to: any;
    to = {};
    to._IdComp = this.userInfo.selectedCompanyId;
    if (this.userInfo['typeCompany'] == 1) {
      to._Id = 25;
    } else {
      to._Id = 97;
    }


    await this.srv.postCall(url, to).subscribe(x => {
      // debugger

      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.DocumentosPendienteContratistas = rs;
          // console.log("getDocumentosPendientesContratistas", this.DocumentosPendienteContratistas);
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.DocumentosPendienteContratistas = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

    // console.log(this.ContratistasInfo);
  }
  async getDocumentosPendientesTrabajadoresContratistas() {

    // let url = API_GET_INFO_DOCUMENTOS_PENDIENTES_TRABAJADORES_CONTRATISTAS;
    let url = API_GET_INFO_DOCUMENTOS_PENDIENTES_TRABAJADORES_CONTRATISTAS;
    //  console.log(url);
    // debugger

    var to: any;
    to = {};
    to._IdComp = this.userInfo.selectedCompanyId;
    if (this.userInfo.ProfileID == 2) {
      to._Id = 27; // trabajador
    } else if (this.userInfo.ProfileID == 21) {
      to._Id = 98 // conductor
    }

    await this.srv.postCall(url, to).subscribe(x => {
      this.loadingList = false;
      // console.log(x);
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.DocumentosPendientesTrabajadores = rs;
          // console.log("TrabajadoresContratistas",this.DocumentosPendientesTrabajadores)
        }else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.DocumentosPendientesTrabajadores = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });


  }
  getInfoCoursesPending() {
    // let url = GET_INFO_CONTRATISTAS + this.userInfo.selectedCompanyId;
    let url = GET_INFO_TRABAJADORES_COURSES_PENDING + this.userInfo.selectedCompanyId;
    //  console.log(url);
    // debugger
    this.srv.getCall(url).subscribe(d => {
      // console.log(d);
      if (d.val != null) {
        // console.log(d.val);
        this.CoursesInfo = d.val;
      }
    })
  }
getDocumentsApprove(){
    let url =GET_DOCUMENTS_APPROVE;
  this.srv.getCall(url).subscribe(resp=>{
        this.DocumentosApprove=resp["val"]
    console.log((resp));
    console.log (resp["val"]);
    console.log(this.DocumentosApprove);
    console.log(this.DocumentosApprove.length>0)

  });
  console.log(this.DocumentosApprove);
}
}
