import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { LoginService } from '../../../services/authentication/login.service';
import {environment} from "../../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {

  isUpToDate: boolean = true;
  browserName: string = '';
  browserVersion: string = '';

  @Output() toggleSidebar = new EventEmitter<void>();
  version:any;
  public user: any;
  public company: any;
  public photo: any;

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  online: boolean = true;
  connectionSpeed: string = 'unknown';
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private storage: Storage,
    private srv: LoginService
  ) {
    this.version = environment.version;
    // informacion del usuario
    storage.get('userinfo').then(x => {
      this.user = x.name;
      this.company = x.selectedCompanyName;
      // console.log(this.user)
      this.photo = x.photo;
    });
  }

  ngAfterViewInit() {}

  public logout() {
      this.srv.clearData().then(() => {
          this._router.navigate(['/authentication/login']);
      });
  }
  ngOnInit(): void {
    this.srv.onlineStatus$.subscribe(status => this.online = status);
    this.srv.connectionSpeed$.subscribe(speed => this.connectionSpeed = speed);

    this.isUpToDate = this.srv.isBrowserUpToDate();
    this.browserName = this.srv.getBrowserName();
    this.browserVersion = this.srv.getBrowserVersion();
  }
}
