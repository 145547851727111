import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

const ALLCOMPANIES=Global.API_ALLCOMPANIESWITHDOCUMENT;

@Component({
  selector: 'app-proapprovedocuments',
  templateUrl: './proapprovedocuments.component.html',
  styleUrls: ['./proapprovedocuments.component.css']
})
export class ProapprovedocumentsComponent extends BaseCrudComponent {
  userinfo:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);

      this.read_url = ALLCOMPANIES;
      this.search_fields = ['CompanyID','Ruc','Name'];

      this.c_obj['Company'] = [];
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
    await this.getList();
  }

  public async getList()
  {
    var qst = this.read_url;
    this.loadingList = true;
    let to = {
      mail: this.userinfo['userId']
    }
    this.messageShowLoading();
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        this.messageDialog(x.msg, '' ,'error');
        return;
      }
    });
  }
}
