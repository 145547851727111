import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const API_CREATE_PLANACTION = environment.Global.API_CREATE_PLANACTION;
const API_UPDATE_PLANACTION= environment.Global.API_UPDATE_PLANACTION;
const API_ALL_PLANACTION = environment.Global.API_ALL_PLANACTION;
const API_ID_PLANACTION = environment.Global.API_ID_PLANACTION;
const API_UPDATESTATUS_PLANACTION = environment.Global.API_UPDATESTATUS_PLANACTION;
const API_GET_PLANACTION_BY_USER = environment.Global.API_GET_PLANACTION_BY_USER;

const API_GET_UNSAFEACTBYID = environment.Global.API_GET_UNSAFEACTBYID;
const API_ID_BETTER = environment.Global.API_ID_BETTER;
const API_ID_UNSAFECONDITION = environment.Global.API_ID_UNSAFECONDITION;


const API_UPDATE_UNSAFEACT_STATUS = environment.Global.API_UPDATE_UNSAFEACT_STATUS;
const API_UPDATESTATUS_UNSAFECONDITION= environment.Global.API_UPDATESTATUS_UNSAFECONDITION;
const API_UPDATESTATUS_BETTER = environment.Global.API_UPDATESTATUS_BETTER;
@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  modalCreate = false;
  row_plantas_area = [];
  row_plantas = [];
  id = null;
  typeForm;
  typePlan = null;
  typePlanEsp = null;
  inspectionObject = null;
  listaAcciones = [];
  pageTotal2 = 0;
  page2 = 0;
  flag = true;
  flagprog = true;
  close_object : any;
  contador = 0;
  cantidadFotos = 0;
  isClosing = false;
  existInspection = false;
  evidenceForActionImage: any;
  habilitarAct = true;
  creoPlan = false;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
      
        // this.getTypeFouls();
    this.search_fields = ['ActionPlanId','PlantaName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });
    this.pageSize2 = 3;
    this.page2 = 1;
    this.GetPlantas();
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.typeForm = params['type'];
    });
    
    this.getInformationByType();
  }

  public getList() {
    this.loadingList = true;
    this.srv.getCall(API_GET_PLANACTION_BY_USER + this.userinfo['logon']).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          if(this.flag) {
            Swal.close()
          }
          this.rows.forEach(element => {
            if (element.InspectionID == this.id) {
              this.existInspection = true;
              if(!this.creoPlan) {
                Swal.fire(this.translations.msg_have_plan, this.translations.msg_dont_create_plan, 'info');
              }
            }
          });
            
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public deletePlan(row) {
    this.ActivarItem(row.ActionPlanId,3);
  }

  public ActivarItem(id:any, accion:any): void
  {
    var to:any;
    to = {};
    to.ActionPlanId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['logon'];
    // console.log(to)
    this.srv.postCall(API_UPDATESTATUS_PLANACTION, to).subscribe(x => {
      Swal.close();
      if(x.success){
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.flag = false;
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  public openCreateModal(content: any) {
    this.modalCreate = true;
    this.c_obj = {};
    // this.modalCreate = true;
    // console.log(this.inspectionObject)
    this.c_obj['level'] = this.inspectionObject['PlantaName'];
    this.c_obj['levelcode'] = this.inspectionObject['PlantaID'];
    this.c_obj['areacode'] = this.inspectionObject['SeccionID'];
    this.c_obj['area'] = this.inspectionObject['SeccionName'];
    this.c_obj['Reportby'] = this.inspectionObject['Reportby'] == undefined ? this.inspectionObject['CreatorUser'] : this.inspectionObject['Reportby'];
    this.c_obj['date_ha'] = this.inspectionObject['Date'].split('T')[0];
    this.c_obj['justificative'] = this.typePlanEsp ;
    this.c_obj['invertion'] = 0;
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: 'lg' });
    // this.GetPlantas();
    // this.updateStatusForInspections();
    if (this.typeForm == 1 || this.typeForm == 2) {
      this.c_obj['paramDetailDescription'] = this.inspectionObject['paramDetailDescription'];
    } else if (this.typeForm ==3 ){
      this.c_obj['Observation'] = this.inspectionObject['Observation'];
    }
  }

  public getInformationByType() {
    // console.log(this.typeForm)
    switch (this.typeForm) {
      case '1':
        this.typePlan = 'menu_act_nosecure';
        this.typePlanEsp = 'Acto inseguro';
        this.getUnsafeAct();
        break;
      case '2':
        this.typePlan = 'menu_condi_nosecure';
        this.typePlanEsp = 'Condición insegura';
        this.getUnsafeCondition();
        break;
      case '3':
        this.typePlan = 'menu_oportunity_mejor';
        this.typePlanEsp = 'Mejora u Oportunidad';
        this.getOportunity();
        break;
    }
  }

  public getUnsafeAct() {
    this.srv.getCall(API_GET_UNSAFEACTBYID + this.id).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.inspectionObject = x.val[0];
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getUnsafeCondition() {
    this.srv.getCall(API_ID_UNSAFECONDITION + this.id).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.inspectionObject = x.val[0];
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getOportunity() {
    this.srv.getCall(API_ID_BETTER + this.id).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.inspectionObject = x.val[0];
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public addToTable(object) {
    // console.log(this.c_obj)
    const indice = this.listaAcciones.length + 1;
    let validated = this.validateActionDetail(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    if(this.listaAcciones.length > 0) {
      let cont = 0;
      this.listaAcciones.forEach(element => {
        if(element.Action === object['actionCon']) {
          cont = 1;
        }
      })
      if(cont == 0) {
        this.listaAcciones.push(
          {
            Nro: indice,
            Action: this.c_obj['actionCon'],
            Details: this.c_obj['detailEject'],
            Observation: this.c_obj['observation'],
            Responsable: this.c_obj['responsable'],
            InitDate: this.c_obj['date_planea_init'],
            PlanEndDate: this.c_obj['date_planea_end'],
            PlanProgDate: null,
            PlanProgDate2: null,
            EndDate: this.c_obj['date_planea_end']
          }
        );
      } else {
        return Swal.fire(this.translations['action_repeated'], '', 'error');
      }
    } else {
      this.listaAcciones.push(
        {
          Nro: indice,
          Action: this.c_obj['actionCon'],
          Details: this.c_obj['detailEject'],
          Observation: this.c_obj['observation'],
          Responsable: this.c_obj['responsable'],
          InitDate: this.c_obj['date_planea_init'],
          PlanEndDate: this.c_obj['date_planea_end'],
          PlanProgDate: null,
          PlanProgDate2: null,
          EndDate: this.c_obj['date_planea_end']
        }
      );
    }
    this.c_obj['actionCon'] = null;
    this.c_obj['detailEject'] = null;
    // this.c_obj['observation'] = null; La observacion no e borra porque puede ser general
    this.c_obj['date_planea_init'] = null;
    this.c_obj['date_planea_end'] = null;
    this.c_obj['date_fini'] = null;
    this.c_obj['responsable'] = null;

    if( Array.isArray(this.listaAcciones) ){
      this.pageTotal2 = this.listaAcciones.length;
    }
  }

  public deleteAction ( numero ) {
    this.listaAcciones.splice(numero - 1, 1)
    if( Array.isArray(this.listaAcciones) ){
      this.pageTotal2 = this.listaAcciones.length;
    }
  }

  public setEndDate () {
    this.c_obj['date_fini'] = this.c_obj['date_planea_end']
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
          //  console.log(this.row_plantas)
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public searchArea() {
    if (this.modalCreate) {
      var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'];
      this.srv.getCall(qst).subscribe(x => {
        if(x.success){
          if(x.val != null){
            var rs = x.val;
            this.row_plantas_area = rs;
            // console.log(this.row_plantas_area)
          }else {
            this.row_plantas_area = [];
            return;
          }
        }else {
          return;
            }
      });
    } else {
      var qst = GET_ALL_AREA_BY_ID + this.u_obj['level'];
        this.srv.getCall(qst).subscribe(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              this.row_plantas_area = rs;
              // console.log(this.row_plantas_area)
            }else {
              this.row_plantas_area = [];
              return;
            }
          }else {
            return;
              }
        });
    }
   
  }



  public validateCreateInfo(fields:any): boolean {
    let form:any = {};
    form = new FormGroup({
        'level': new FormControl(fields.level, [Validators.required]),
        'area': new FormControl(fields.area, [Validators.required]),
        'date_ha': new FormControl(fields.date_ha, [Validators.required]),
        'justificative': new FormControl(fields.justificative, [Validators.required]),
        'invertion': new FormControl(fields.invertion, [Validators.required]),
        // 'result_esp': new FormControl(fields.result_esp, [Validators.required]),
    });
    return form.valid;
  }

  public validateActionDetail(fields:any): boolean {
    let form:any = {};
    form = new FormGroup({
        'actionCon': new FormControl(fields.actionCon, [Validators.required]),
        'detailEject': new FormControl(fields.detailEject, [Validators.required]),
        'responsable': new FormControl(fields.responsable, [Validators.required]),
        // 'invertion': new FormControl(fields.invertion, [Validators.required]),
        // 'result_esp': new FormControl(fields.result_esp, [Validators.required]),
        'observation': new FormControl(fields.observation, [Validators.required]),
        'date_planea_init': new FormControl(fields.date_planea_init, [Validators.required]),
        'date_planea_end': new FormControl(fields.date_planea_end, [Validators.required]),
    });
    return form.valid;
  }

  public openUpdateModal (content, object) {
    // console.log(object)
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.u_obj['level'] = object['PlantaName'];
    this.u_obj['levelcode'] = object['PlantaID'];
    this.u_obj['area'] = object['SeccionName'];
    this.u_obj['areacode'] = object['SeccionID'];
    this.u_obj['Reportby'] = object['CreatorUser'];
    this.u_obj['date_ha'] = object['FindingDate'].split('T')[0];
    this.u_obj['justificative'] = object['Justification'];
    this.u_obj['ActionPlanDetails'] = object['ActionPlanDetails'];
    this.u_obj['invertion'] = object['Investment'];
    this.u_obj['ActionPlanId'] = object['ActionPlanId'];
    this.u_obj['status'] = object['Status'];
    this.u_obj['StatusPlan'] = object['StatusPlan'];

    let contadorfechas = 0;
    let contadorUpdateDates = this.u_obj['ActionPlanDetails'].length;
    this.u_obj['ActionPlanDetails'].forEach(element => {
        if (element.PlanProgDate !== null && element.PlanProg2Date !== null) {
          contadorfechas ++;
        }
    });
    // console.log(contadorfechas, contadorUpdateDates)
    if(contadorfechas == contadorUpdateDates) {
      this.habilitarAct = false;
    }

    // console.log(this.habilitarAct)
  }

  public createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    let new_obj = {
      PlantaID: object['levelcode'],
      SeccionID: object['areacode'],
      FindingDate: object['date_ha'],
      Justification: object['justificative'],
      Investment: object['invertion'],
      ActionPlanDetails: this.listaAcciones,
      CreatorUser: this.userinfo['logon'],
      InspectionID: this.id
    }
    // console.log(new_obj)
    // console.log(this.listaAcciones);
    this.srv.postCall(API_CREATE_PLANACTION, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        this.c_obj = {};
        this.listaAcciones = [];
        this.flag = false;
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.creoPlan = true;
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }

  public changeDate(date, ActionPlanDetails) {
    ActionPlanDetails.PlanEndDate = date
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    let new_obj = {
      ActionPlanId: object['ActionPlanId'],
      ActionPlanDetails: object['ActionPlanDetails'],
      LastModifierUser:  this.userinfo['logon']
    }
    this.srv.postCall(API_UPDATE_PLANACTION, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        this.u_obj = {};
        this.flag = false;
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }
  public openCloseModal (content, object) {
    // console.log(object)
    this.cantidadFotos = object.ActionPlanDetails.length;
    this.contador = 0;
    object.ActionPlanDetails.forEach(element => {
      if(element.Evidence !== null) {
        this.contador ++;
      }
    });
    console.log(this.contador, this.cantidadFotos)
    this.modalService.open(content, { backdrop: 'static', keyboard : false, size: 'lg' });
    this.close_object = {};
    this.close_object['level'] = object['PlantaName'];
    this.close_object['levelcode'] = object['PlantaID'];
    this.close_object['area'] = object['SeccionName'];
    this.close_object['areacode'] = object['SeccionID'];
    this.close_object['Reportby'] = object['CreatorUser'];
    this.close_object['date_ha'] = object['FindingDate'].split('T')[0];
    this.close_object['justificative'] = object['Justification'];
    this.close_object['ActionPlanDetails'] = object['ActionPlanDetails'];
    this.close_object['invertion'] = object['Investment'];
    this.close_object['ActionPlanId'] = object['ActionPlanId'];
    this.close_object['StatusPlan'] = object['StatusPlan'] == 'C'? 'is_closed' : 'is_open';
  }

  public insertImg(event, acc) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log(reader.result);
      acc['Evidence'] = reader.result;
    };
    // console.log(acc)
  }

  public updateCloseModalEvidence(content, object) {
    console.log(object)
    this.isUpdating = true;
    let contador = 0;
    this.contador = 0;
    object.ActionPlanDetails.forEach(element => {
      if(element.Evidence !== null) {
        contador ++;
      }
    });
    console.log(contador)
    if(contador == 0) {
      return Swal.fire(this.translations.should_upload_img,'','error')
    }

    let new_obj = {
      ActionPlanId: object['ActionPlanId'],
      ActionPlanDetails: object['ActionPlanDetails'],
      LastModifierUser:  this.userinfo['logon']
    }

    this.srv.postCall(API_UPDATE_PLANACTION, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        this.u_obj = {};
        this.flag = false;
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(content);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });

  }

  public closePlanStatus(content, object) {
    this.isClosing = true;
    var to:any;
    to = {};
    to.ActionPlanId = object['ActionPlanId'];
    to.Status = '4';
    to.LastModifierUser = this.userinfo['logon'];
    this.srv.postCall(API_UPDATESTATUS_PLANACTION, to).subscribe(x => {
      if(x.success){
        this.isClosing = false;
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.updateStatusForInspections ();
        this.closeModal(content);    
        this.flag = false;
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
          this.isClosing = false;
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  public updateStatusForInspections () {
    let url = '';
    var to:any;
    to = {};
    to.Status = '4';
    to.LastModifierUser = this.userinfo['logon'];
    switch (this.typeForm) {
      case '1':
        url = API_UPDATE_UNSAFEACT_STATUS;
        to.UnsafeActId = this.id;
        break;
      case '2':
        url = API_UPDATESTATUS_UNSAFECONDITION;
        to.UnsafeConditionId = this.id;
        break;
      case '3':
        url = API_UPDATESTATUS_BETTER;
        to.GetsBetterId = this.id;
        break;
    }
  
    this.srv.postCall(url, to).subscribe(x => {
      if(x.success){
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  public seeEvidence(modal, evidence) {
    this.evidenceForActionImage = evidence
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' })
  }

}