import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry, debounce } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import {saveAs as importedSaveAs  } from "file-saver";
import { thresholdFreedmanDiaconis } from 'd3-array';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { now } from 'moment';
import * as moment from 'moment-timezone';


const API_UPDATEOPORTUNITIES=Global.API_UPDATEOPORTUNITIES;

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})
export class OpportunitiesComponent extends BaseCrudComponent {
  userinfo:any;
  Opportunities: FormGroup;
  flag = true;
  curr_parameter:any;
  evaluacion:any;
  data_emp:any;
  veces:any;

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);

      this.update_url = API_UPDATEOPORTUNITIES;
      this.data_emp=[];
      //this.read_url="Questions/GetQuestionsByEvaluationId";
      //this.search_fields = ['CompDocumentId','NameDoc','plazo'];


      //this.c_obj['Respuesta'] = [];
      this.curr_parameter = 0;

      this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
      });
    }


    async ngOnInit() {
      await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
            this.translations = translations;
            super.ngOnInit();
            //this.getList();
      });
      this.userinfo = [];
      this.userinfo['name'] = '';
      this.userinfo['emailAddress'] = '';

      await this.storage.get('userinfo').then(x => {
          this.userinfo = x;

      });

      this.curr_parameter = 0;
      this.GetEvaluation().subscribe(x => {
              if(x != false){
            this.evaluacion = x;
        }
      });
    }

    onlyNumberKey(event) {
      return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
    }

    GetEmployeeforIdentification(obj:any)
    {


        //debugger;
      var qst = "Employee/GetIdentification/"+obj;
      //console.log("cedula:",this.c_obj['identification']);


      //console.log("usuario logoneado:",this.userinfo)
          this.srv.getCall(qst).subscribe(x => {
              this.loadingList = false;
              console.log("X:",x.val);debugger;
              if(x.success)
              {
                if(x.val != null){
                  //debugger;
                    //Swal.fire(this.translations.error, this.translations.msg_hability_request, 'error');

                    //Swal.fire('', 'Trabajador no esta habilitado', 'success');
                    this.c_obj['NumberIdentification']=x.val['NumberIdentification'];
                    this.c_obj['nameemployee']=x.val['Name'];
                    this.c_obj['NameCompany']=x.val['namecompany'];
                    this.c_obj['EmployeeId']=x.val['EmployeeId'];
                    this.curr_parameter = 0;
                  if(x.val instanceof Array){
                    this.c_obj['NumberIdentification']=x.val[0]['NumberIdentification'];
                    this.c_obj['nameemployee']=x.val[0]['Name'];
                    this.c_obj['NameCompany']=x.val[0]['namecompany'];
                    this.c_obj['EmployeeId']=x.val[0]['EmployeeId'];
                  }
                    this.ConsultaData(this.curr_parameter);
                    return;
                }
                else{
                  this.c_obj['NumberIdentification']="";
                  this.c_obj['nameemployee']="";
                  this.c_obj['NameCompany']=""
                  this.c_obj['EmployeeId']="";
                  this.flag=true;
                }
              }else {
                this.flag=true;
                return;
              }
            });


    }
    GetEvaluation()
    {
      var qst = "Evaluation/AllActive";

      return this.srv.getCall(qst).pipe(
        mergeMap(data => {
            let mydata:any = {success: false, msg:'', val:[]};
            mydata = data;
            //console.log("data:",data);
            //console.log("mydata:",mydata);

            if(mydata.success == true ){
              return of(mydata.val);
            }
            else{
              return of(false);
            }
          }),
          //timeout(MAX_TIME),
          catchError(data => of(false))
        );
    }

    ConsultaData(data:any)
    {
      var qst ="Myevaluation/EmployeeByEvaluation/"+this.c_obj['EmployeeId'] ;
      //this.loadingList = true;
      this.srv.getCall(qst).subscribe(x => {
        this.loadingList = false;
        if(x.success){
          if(x.val != null){
            console.log("data:",x.val.length);
            if (x.val.length>0){
            //this.rawdata = x.val;
              var rs = x.val;
              this.rows = rs;
              this.flag=false;
              console.log("rs:",rs);
            }
            else{
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            }
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
      });
    }

    openUpdateModal(content:any, obj:any){
      this.curr_parameter = 0;
      this.veces=0;
      this.data_emp=this.c_obj;
      this.modalService.open(content, { backdrop: 'static',  keyboard : false, size:'lg' });
      let newobj = JSON.parse(JSON.stringify(obj));
      this.u_obj = newobj;
    }

    AumentarVeces(content:any, form:any)
    {
      var validated = true;
      this.isUpdating = true;
      var qst = this.update_url;

      if( form == undefined ){
        form = {};
      }


      validated = this.validateCreateInfo(form);
      if( !validated ){
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
          return;
      }
      if (this.veces == undefined || this.veces == 0) {
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
          return;
      }

      var to:any;
      to = {};
      //debugger;
      //console.log("form1:",form);
      form.userModification = this.userinfo.logon;
      //form.max_repetition=(Number(form.vecesrepetidas)+Number(form.max_repetition)).toString();
      form.max_repetition=(Number(this.veces)+Number(form.max_repetition)).toString();


      to.MyEvaluationId=form.MyEvaluationId;
      to.max_repetition = form.max_repetition;
      to.userModification=form.userModification;
      to.requiredBy=form.requiredBy;
      to.requiredWhy=form.requiredWhy;
      //console.log("to:",to);

      this.srv.postCall(qst, to).subscribe(x => {
        this.isUpdating = false;
        if (x.success) {
          this.closeModal('update1');
          Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
          this.GetEmployeeforIdentification(this.data_emp.NumberIdentification)

        }
     }, err => {
       //console.log(err);
        Swal.close();
        Swal.fire('Error');
       });
    }

    validateCreateInfo(fields:any){
      // debugger;
      let mygroup:any = {};
      mygroup = new FormGroup({
          'requiredWhy': new FormControl(fields.requiredWhy, [Validators.required]),
          'requiredBy': new FormControl(fields.requiredBy, [Validators.required])
      });

      return mygroup.valid;
    }

}
