import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs as importedSaveAs } from "file-saver";

import { environment } from '../../../../environments/environment';

const API_CONSEVALUACION = environment.Global.API_CONSEVALUACION;
const API_REGEVALUACION = environment.Global.API_REGEVALUACION;
const API_UPDATEEVALUACION = environment.Global.API_UPDATEEVALUACION;
const API_DESACTEVALUACION = environment.Global.API_DESACTEVALUACION;
const API_ACTIVEEVALUACION = environment.Global.API_ACTIVEEVALUACION;
const API_CONSULTAJOBTYPE = environment.Global.API_CONSULTAJOBTYPE;
const API_GET_QUESTIONSBYGROUP = environment.Global.API_GET_QUESTIONSBYGROUP;
const API_UPLOAD_FILE = environment.Global.API_UPLOAD_FILE;
const API_CONSPREGUNTA = environment.Global.API_CONSPREGUNTA;
const API_GET_QUESTIONSBYEVAL = environment.Global.API_GET_QUESTIONSBYEVAL;
const API_ASSIGN_QUESTIONSBYEVAL = environment.Global.API_ASSIGN_QUESTIONSBYEVAL;
const API_GET_FILE_PARAM_EVAL = environment.Global.API_GET_FILE_PARAMEVAL;

@Component({
  selector: 'app-paramevaluacion',
  templateUrl: './paramevaluacion.component.html',
  styleUrls: ['./paramevaluacion.component.css']
})
export class ParamevaluacionComponent extends BaseCrudComponent {

  id: any;
  listParameter: any;
  curr_parameter: any = [];
  loadingRequired = false;
  listAgr: any;
  translations: any;
  userinfo: any;
  listQuestion: any;
  curr_question: any;
  lista = [];
  listaTrabajos = [];
  jobs: any = [];
  contadorgeneral = 0;
  archivos = [];
  namefile = '';
  tipoArchivo = [{ id: 1, name: 'Archivo' }, { id: 2, name: 'Video' }]
  viewFileInput = false;
  size_max = 8388608//3145728;//3mb
  questionList = [];
  page2 = 0;
  pageTotal2 = 0;
  questionsByEval = [];
  ParameEvaID: any;
  numberQuestions = 0;
  nameEvaluation = '';
  questionsInDB = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;
    this.read_url = API_CONSEVALUACION;
    this.create_url = API_REGEVALUACION;
    this.update_url = API_UPDATEEVALUACION;
    this.delete_url = API_DESACTEVALUACION;
    this.active_url = API_ACTIVEEVALUACION;


    this.search_fields = ['ParameEvaID', 'Nombre', 'NombreVideo'];

    //this.c_obj['ParamEvaluacion'] = [];

    this.c_obj['ParameterEvaluation'] = [];
    this.curr_parameter = 0;

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        this.getList();
      });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.pageSize = 10;
    this.pageSize2 = 5;
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
  }

  public getList() {
    var qst = this.read_url;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          // console.log(x.val);
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '', 'success');
          this.closeLoadingMessage();
        } else {
          // this.messageDialog(x.msg, '', 'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      } else {
        this.messageDialog(x.msg, '', 'error');
        return;
      }
    });
  }

  async openCreateModal(content: any) {
    this.isCreating = false;
    this.c_obj = {};
    this.c_obj['ParamEvalByJob'] = [];
    this.c_obj['NumeroPregunta'] = 1;
    this.c_obj['durationtime'] = 15;
    this.c_obj['PorcentajeCalificacion'] = 10;
    this.c_obj['PorcentajeCalificacionMinima'] = 6;
    this.c_obj['ValidateTime'] = 60;
    this.c_obj['Obligatorio'] = false;
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    this.jobs = [];
    await this.getTrabajo();
  }

  public async createItem(content: any, form: any) {

    this.isCreating = true;
    var validated = true;
    if (form == undefined) {
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if (!validated) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (this.c_obj['NumeroPregunta'] == 0) {
      this.isCreating = false;
      Swal.fire(this.translations['select_one_question'], '', 'error');
      return;
    }
    if (form.TipoArchivo == 1) {
      if (this.archivos.length == 0) {
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        this.archivos = [];
        return;
      } else {
        var size_document = this.archivos[0].size;
        if (size_document > this.size_max) {
          this.isCreating = false;
          Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
          this.archivos = [];
          return;
        }
      }
      form.UrlGeneral = "";
    } else if (form.TipoArchivo == 2) {
      if (form.UrlGeneral == null || form.UrlGeneral == undefined) {
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
    }

    if (form['ParamEvalByJob'].length == 0) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    var to: any;
    form.CreatorUser = this.userinfo['logon'];
    form.Status = "A";
    to = form;
    to.TipoArchivo = form['TipoArchivo'] == 1 ? 'Archivo' : 'Video';
    var qst = this.create_url;
    debugger
    await this.srv.postCall(qst, to).toPromise().then(async x => {
      if (x.success) {
        if (to['TipoArchivo'] == 'Archivo') {
          await this.uploadFile(x.val, content);
        } else {
          this.confirmDialogWithModal(x.msg, '', 'success', content);
          this.c_obj = {};
          this.c_obj['ParamEvalByJob'] = [];
        }
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  public async uploadFile(obj, content) {
    let formData = new FormData();
    formData.append('FileUpload', this.archivos[0]);
    formData.append('ParamEvaID', obj.ParameEvaID);
    await this.srv.AddFiles(API_UPLOAD_FILE, formData).subscribe(result => {
      this.isCreating = false;
      if (result.STATUS) {
        this.c_obj = {};
        this.c_obj['ParamEvalByJob'] = [];
        this.confirmDialogWithModal(result.MENSAJE, '', 'success', content);
      }
      else {
        this.c_obj = {};
        this.c_obj['ParamEvalByJob'] = [];
        this.confirmDialog(result.MENSAJE, '', 'error');
        this.archivos = [];
      }
    });
  }


  validateCreateInfo(fields: any) {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'Nombre': new FormControl(fields.Nombre, [Validators.required]),
      'TipoArchivo': new FormControl(fields.TipoArchivo, []),
      'UrlGeneral': new FormControl(fields.UrlGeneral, []),
      'NumeroPregunta': new FormControl(fields.NumeroPregunta, []),
      'RepeticionMaxima': new FormControl(fields.RepeticionMaxima, []),
      'PorcentajeCalificacion': new FormControl(fields.PorcentajeCalificacion, []),
      'PorcentajeCalificacionMinima': new FormControl(fields.PorcentajeCalificacionMinima, []),
      'durationtime': new FormControl(fields.durationtime, []),
      'ValidateTime': new FormControl(fields.ValidateTime, [])
    });

    return mygroup.valid;
  }

  async openUpdateModal(content: any, obj: any) {
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.isUpdating = false;
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    if (obj.TipoArchivo == "Archivo") {
      this.u_obj['TipoArchivo'] = 1;
      this.viewFileInput = true;
    } else {
      this.u_obj['TipoArchivo'] = 2;
      this.viewFileInput = false;
    }
    await this.getTrabajo();
    this.jobs = [];
    this.listaTrabajos.forEach(trabajo => {
      this.u_obj['ParamEvalByJob'].forEach(job => {
        if (job.JobTypeID == trabajo.JobTypeID) {
          this.jobs.push(job.JobTypeID)
        }
      });
    })
  }

  public async updateItem(content: any, form: any) {
    this.isUpdating = true;
    var qst = this.update_url;
    var validated = true;
    if (form == undefined) {
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if (!validated) {
      this.isUpdating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    if (form.TipoArchivo == 2) {
      if (form.UrlGeneral == null || form.UrlGeneral == undefined) {
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
    }
    if (this.u_obj['NumeroPregunta'] == 0) {
      this.isUpdating = false;
      Swal.fire(this.translations['select_one_question'], '', 'error');
      return;
    }
    if (this.archivos.length > 0) {
      var size_document = this.archivos[0].size;
      if (size_document > this.size_max) {
        this.isUpdating = false;
        Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
        this.archivos = [];
        return;
      }
    }
    if (form['ParamEvalByJob'].length == 0) {
      this.isUpdating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    let to = {};
    form.LastModifierUserID = this.userinfo['logon'];
    form.LastModifierUser = this.userinfo['logon'];
    to = form;
    to['TipoArchivo'] = form.TipoArchivo == 1 ? "Archivo" : "Video";
    await this.srv.postCall(qst, to).toPromise().then(async x => {
      if (x.success) {
        if (to['TipoArchivo'] == 'Archivo' && this.archivos.length > 0) {
          this.messageDialog(x.msg, '', 'success');
          let formData = new FormData();
          formData.append('FileUpload', this.archivos[0]);
          formData.append('ParamEvaID', to['ParameEvaID']);
          await this.srv.AddFiles(API_UPLOAD_FILE, formData).toPromise().then(result => {
            this.isUpdating = false;
            Swal.close();
            if (result.STATUS) {
              this.confirmDialogWithModal(result.MENSAJE, '', 'success', content);
            }
          }, err => {
            this.isUpdating = false;
            this.messageDialog(x.msg, '', 'error');
          });
        } else {
          this.confirmDialogWithModal(x.msg, '', 'success', content);
        }
        this.closeModal(content)
      } else {
        this.isUpdating = false;
        this.messageDialog(x.msg, '', 'error');
      }
    }, err => {
      this.isUpdating = false;
      this.messageDialog(err, '', 'error');
    });
  }


  deleteItem(obj: any) {
    var id: any;
    id = {};
    id.ParameEvaID = obj;
    id.Status = 2;
    id.LastModifierUser = this.userinfo['logon'];
    var qst = this.delete_url;
    this.srv.postCall(qst, id).subscribe(x => {
      if (x.success) {
        this.confirmDialog(x.msg, '', 'success');
      }
      else {
        this.confirmDialog(x.msg, '', 'error');
      }
    }, err => {
      this.confirmDialog(err, '', 'error');
    });
  }


  Activar(obj: any) {
    var id: any;
    id = {};
    id.ParameEvaID = obj;
    id.Status = 1;
    id.LastModifierUser = this.userinfo['logon'];
    var qst = this.active_url;
    this.srv.postCall(qst, id).subscribe(x => {
      if (x.success) {
        this.confirmDialog(x.msg, '', 'success');
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      this.confirmDialog(err, '', 'error');
    });
  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id);
    } else {
      this.deleteItem(id);

    }
  }

  DeletedItem(obj: any) {
    var id: any;
    id = {};
    id.ParameEvaID = obj;
    id.Status = 3;
    id.LastModifierUser = this.userinfo['userId'];
    var qst = "Evaluation/UpdateStatus";
    this.srv.postCall(qst, id).subscribe(x => {
      if (x.success) {
        this.confirmDialog(x.msg, '', 'success');
      }
      else {
        this.confirmDialog(x.msg, '', 'error');
      }
    }, err => {
      this.confirmDialog(err, '', 'success');
    });
  }



  public async getTrabajo() {
    await this.srv.getCall(API_CONSULTAJOBTYPE).toPromise().then(x => {
      if (x.success) {
        if (x.val != null) {
          // console.log(x.val)
          this.listaTrabajos = x.val;
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public setJobs(type: Number) {
    if (type == 1) {
      this.c_obj['ParamEvalByJob'] = [];
      this.jobs.forEach(job => {
        this.c_obj['ParamEvalByJob'].push(
          {
            JobTypeID: job
          }
        )
      });
    } else {
      this.u_obj['ParamEvalByJob'] = [];
      this.jobs.forEach(job => {
        this.u_obj['ParamEvalByJob'].push(
          {
            JobTypeID: job
          }
        )
      });
    }
  }


  public async verifyQuestions() {
    let preguntas = [];
    let to = {
      groups: this.curr_parameter
    }
    let url = API_GET_QUESTIONSBYGROUP;
    await this.srv.postCall(url, to).toPromise().then(x => {
      if (x.success) {
        if (x.val.length > 0) {
          preguntas = x.val;
        }
      }
    });
    this.contadorgeneral = 0;
    if (preguntas.length > 0) {
      preguntas.forEach(pregunta => {
        this.contadorgeneral = this.contadorgeneral + pregunta.cantidad;
      })
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public handleInputChange(e) {
    this.archivos = [];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const archivocapture = e.target.files[0];
    this.archivos.push(archivocapture);
    this.namefile = file.name;
  }

  public selectFile(type) {
    if (type == 1) {
      if (this.c_obj['TipoArchivo'] == 2) {
        this.viewFileInput = false;
      } else if (this.c_obj['TipoArchivo'] == 1) {
        this.viewFileInput = true;
      }
    } else {
      if (this.u_obj['TipoArchivo'] == 2) {
        this.viewFileInput = false;
      } else if (this.u_obj['TipoArchivo'] == 1) {
        this.viewFileInput = true;
      }
    }
  }

  public async assignQuestions(content, { ParameEvaID, NumeroPregunta, Nombre }) {
    this.ParameEvaID = ParameEvaID;
    this.numberQuestions = NumeroPregunta;
    this.nameEvaluation = Nombre;
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
    await this.getQuestionsByEval(ParameEvaID);
    await this.getQuestions();
    if (this.questionsInDB.length > 0) { // se deben asignar las preguntas ya almacenadas
      this.questionsInDB.forEach(element => {
        this.questionsByEval.push(
          {
            ParameEvaID: this.ParameEvaID,
            PreguntaID: element.PreguntaID
          }
        )
      })
    }
  }

  public async getQuestions() {
    let url = API_CONSPREGUNTA;
    let id: any;
    id = {};
    id.TipoPreguntaID = this.id;
    await this.srv.postCall(url, id).toPromise().then(x => {
      if (x.success) {
        if (x.val != null) {
          this.page2 = 1;
          this.questionList = x.val;
          if (Array.isArray(this.questionList)) {
            this.pageTotal2 = this.questionList.length;
          }
        }
      }
    })
  }

  public isChecked(event, PreguntaID) {
    let obj = {
      ParameEvaID: this.ParameEvaID,
      PreguntaID: PreguntaID
    }
    if (event.target.checked) {
      let exist = this.questionsByEval.indexOf(evaluation => {
        return evaluation['PreguntaID'] == obj.PreguntaID;
      })
      if (exist == -1) {
        this.questionsByEval.push(obj);
      }
    } else {
      this.questionsByEval = this.questionsByEval.filter(evaluation => {
        return evaluation['PreguntaID'] != obj.PreguntaID;
      })
    }
  }

  public isSelected(preguntaID) {
    if (this.questionsByEval.length > 0) {
      let flag = 0;
      this.questionsByEval.forEach(element => {
        if (element.PreguntaID == preguntaID) {
          flag = -1;
        }
      });
      if (flag == -1) {
        return 'checked';
      }
    }
  }

  public UpdateQuestions(content) {
    this.isUpdating = true;
    if (this.questionsByEval.length == 0) {
      Swal.fire(this.translations.select_any_questions, '', 'error');
      this.isUpdating = false;
      return;
    }
    if (this.numberQuestions > this.questionsByEval.length) {
      Swal.fire(this.translations.description_minusquestions, this.translations.minimun_questions + " " + this.numberQuestions, 'error');
      this.isUpdating = false;
      return;
    }
    let obj = {
      ParameEvaID: this.ParameEvaID,
      ParamEvaluationQuestion: this.questionsByEval
    }
    this.srv.postCall(API_ASSIGN_QUESTIONSBYEVAL, obj).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.confirmDialogWithModal(x.msg, '', 'success', content);
      } else {
        this.confirmDialogWithModal(x.msg, '', 'error', content);
      }
    }, err => {
      this.isUpdating = false;
      this.confirmDialogWithModal(err, '', 'error', content);
    })
  }

  public async getQuestionsByEval(ParameEvaID) {
    this.questionsByEval = [];
    await this.srv.getCall(API_GET_QUESTIONSBYEVAL + ParameEvaID).toPromise().then(x => {
      if (x.success && x.val != null) {
        this.questionsInDB = x.val;
      } else {
        this.questionsInDB = [];
      }
    })
  }
  downloadDocFile(data) {
    const name = data.Nombre;
    this.srv.downloadFileGeneral(API_GET_FILE_PARAM_EVAL + data.ParameEvaID).subscribe((data) => {
      importedSaveAs(data, name)
    });
  }
}
