<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-20" style="text-align: right;">
                    </div>
                    <div class="col-md-12 col-9 m-b-30" style="text-align: right;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-6 m-b-30" style="text-align: left;">
                        {{this.name_company}}
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-6 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <!-- <th (click)="setOrder('CompanyID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='CompanyID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th> -->
                                <th (click)="setOrder('VerificatesSendTime')" class="sh">
                                    {{'date_register' | translate}} <i *ngIf="(orderKey!==null)&&(orderKey=='VerificatesSendTime')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('plazo')" class="sh" style="padding-left: 0;">
                                    {{'time_term' | translate}} <i *ngIf="(orderKey!==null)&&(orderKey=='plazo')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('NameDoc')" class="sh">
                                    {{'document' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='NameDoc')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('DateDocument')" class="sh">
                                    {{'date_emision' | translate}} <i *ngIf="(orderKey!==null)&&(orderKey=='DateDocument')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{ "caducity_date" | translate }}</th>
                                <th>{{'approve' | translate}} </th>
                                <th>{{'reject' | translate}}</th>
                                <th>{{'comment' | translate}} </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                                <td>{{ i + 1}}</td>
                                <td >
                                    <div style="width: 100px;">
                                        {{row.VerificatesSendTime | date:'y-MM-dd'}}
                                    </div>
                                </td>
                                <td >
                                    <div style="width: 150px;">
                                      {{row.NameDoc}}
                                    </div>
                                </td>
                                <td >
                                  <input type="date" [ngModel]="
                                        row.DateDocument | date: 'yyyy-MM-dd'
                                      " (ngModelChange)="row.date_doc = $event" name="dt"
                                         class="form-control form-control-rounded" #searchDate />
                                  <!--<div style="width: 100px;">
                                      {{row.DateDocument | date:'y-MM-dd'}}
                                  </div>-->
                                </td>
                                <td >
                                  <input type="date" [ngModel]="
                                        row.ExpirationTime | date: 'yyyy-MM-dd'
                                      " (ngModelChange)="row.date_doc_end  = $event" name="dt"
                                         class="form-control form-control-rounded" #searchDate />
                                  <!--<div style="width: 150px;">
                                      {{row.ExpirationTime | date:'y-MM-dd'}}
                                  </div>-->
                                </td>
                                <td style="text-align: center;">
                                    <div>
                                        <input type="radio" name="{{row.VehicleDocumentID}}" id="{{row.VehicleDocumentID}}" [disabled]="!this.userinfo['DocumentApprove']" class="with-gap"  [value]="0" [(ngModel)]="row.valor" (change)="changeSelect(row)"><!--row.sel=!row.sel;-->

                                    </div>
                                </td>
                                <td style="text-align: center;">
                                    <div>
                                        <input type="radio" name="{{row.VehicleDocumentID}}" id="{{row.VehicleDocumentID}}" [disabled]="!this.userinfo['DocumentApprove']" class="with-gap"  [value]="1" [(ngModel)]="row.valor" (change)="changeSelect(row)"><!--(change)="row.sel=!row.sel;row.VerificateComment='';flag=1;-->

                                    </div>
                                </td>
                                <td>
                                    <input style="width: 200px;" type="text" class="form-control" name="obs" [(ngModel)]="row['VerificateComment']" [disabled]="row.valor == 0">
                                </td>
                                <td class="action-buttons" >
                                    <i (click)="openViewModal(view, row)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>
                                    <i *ngIf="this.userinfo['DocumentApprove'] == true" (click)="verificateprocesardoc(row)" style="cursor:pointer;" class="fa fa-save text-info m-r-10" title="{{'save' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="12">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #view let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header head-modal-bg">
      <h4 class="modal-title">{{'preview' | translate}}</h4>
      <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
        <i (click)="downloadDocFile(this.row2)" style="cursor:pointer;" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}" title="{{'export' | translate}}"></i>
        <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <form class="form-horizontal">
        <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
          <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                      style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
        </ng-container>
        <ng-template #elseTemplate>
          <div style="display: flex;">
            <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
                 alt="">
          </div>
        </ng-template>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
    </div>
</ng-template>
