<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('ParId')" class="sh">
                                    ID
                                </th>
                                <th (click)="setOrder('field_txt')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='field_txt')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i">
                                <td>{{i + 1}}</td>
                                <td>{{row.field_txt}}</td>
                                <td class="action-buttons">
                                    <i (click)="goTo('/paramgenedetail/'+row.field_txt, {})" style="cursor:pointer;" class="ti-settings text-info m-r-10" title="{{'menu_title_mant_params' | translate}}"></i>
                                    <i (click)="openUpdate(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <!-- <i (click)="deleteItem({anId:row.anId}, translations)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'name' | translate}} *</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['field_txt']" name="field_txt" required>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [(ngModel)]="c_obj['Descripction']" name="Descripction" rows="3"></textarea>
                                    </div>
                                </div> -->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 ha-ibox">
                                        <label for="fname">{{'name_old' | translate}}</label>
                                        <input class="form-control" [(ngModel)]="u_obj.nombreViejo" name="nombreViejo" readonly>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 ha-ibox">
                                        <label for="fname">{{'name' | translate}}</label>
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.nombreNuevo" name="nombreNuevo" required>
                                    </div>
                                </div>
                                 <!-- <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'name_old' | translate}}</label>
                                    <div class="col-sm-9">
                                        <input class="form-control" [(ngModel)]="u_obj.nombreViejo" name="nombreViejo" readonly>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'name' | translate}} *</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.nombreNuevo" name="nombreNuevo" required>
                                    </div>
                                </div> -->
                                <!-- <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [(ngModel)]="u_obj.Descripction" name="Descripction" rows="3"></textarea>
                                    </div>
                                </div> -->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>