import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Storage } from '@ionic/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { isNumeric } from 'rxjs/util/isNumeric';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {saveAs as importedSaveAs  } from "file-saver";

const GET_REQUEST_BY_ID = environment.Global.API_GET_REQUEST_BY_ID;
const CREATE_WORK_AUTH = environment.Global.API_CREATE_WORK_AUTH;
const GET_BY_ID_COMPANY_WORK_AUTH = environment.Global.API_GET_BY_ID_COMPANY_WORK_AUTH;
const UPDATE_STATUS_WORK_AUTH = environment.Global.API_UPDATE_STATUS_WORK_AUTH;
const UPDATE_WORK_AUTH = environment.Global.API_UPDATE_WORK_AUTH;
const GET_WORK_AUTH_BY_ID = environment.Global.API_GET_WORK_AUTH_BY_ID;
const GET_ALL_WORK_AUTHS_FOR_ADMIN = environment.Global.API_GET_ALL_WORK_AUTHS_FOR_ADMIN;

@Component({
  selector: 'app-repocreator',
  templateUrl: './repocreator.component.html',
  styleUrls: ['./repocreator.component.css']
})
export class RepocreatorComponent extends BaseCrudComponent implements OnInit {
  userinfo:any;
  loadingRequired = false;
  id;
  isSkretting = false;
  date_doc:any;
  habilitar = false;
  statusWork = false;
  date;
  company:any;
  mostarselectemp;
  entryRequestId;
  objectEntryRequest;
  archivos=[];
  namefile:any;
  tipotrabajo=[];
  mensaje="";
  size_max= 3145728;//3mb
  diffDate; // diferencia de dias en las fechas
  lista;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) { 
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        // this.create_url = API_CREATE_TOOLS;
        // this.read_url = API_GET_TOOLS;
        // this.update_url = API_UPDATE_TOOLS;
        this.date_doc = new Date();
        this.search_fields = ['WorkAuthId','Area'];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
        // this.crearFormEmp();
  }

  ngOnInit() {
    this.date = new Date();
    //console.log(this.date)
    this.company =[];
    //this.u_obj=[];
    this.route.queryParams.subscribe(params => {
      this.entryRequestId = params['id'];
    });
    //debugger;
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          // this.getList();
    });
    this.pageSize = 5;
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        this.c_obj['CompanyID'] = this.userinfo['selectedCompanyId'];
        if( isNumeric( this.userinfo.logon) ) {
          this.mostarselectemp = false;
          this.habilitar=true;
          this.isSkretting = false;
        } 
        this.getList();
    });

  }

  public getList() {
    let qst = '';
    // console.log(this.isSkretting)
    if (this.isSkretting) {
      qst = GET_BY_ID_COMPANY_WORK_AUTH + this.userinfo['selectedCompanyId'];
      this.c_obj['CompanyID'] = this.userinfo['selectedCompanyId'];
    } else {
      qst = GET_ALL_WORK_AUTHS_FOR_ADMIN;
    }
    // console.log(qst)
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.loadingList = true;
      this.rows = [];
      
    this.messageShowLoading();
      this.srv.getCall(qst).subscribe(x => { 
        if(x.success){
          if(x.val != null){
            // console.log(x.val)
            let contador = 0;
            this.rawdata = x.val;
            this.rows = x.val;
            this.rows.forEach(element => {
              if (element['CloseWorkerAuth'] === 'A') {
                contador += 1;
              }
            });
            // if (contador === 1) {
            //   Swal.fire(this.translations.information_message, this.translations.have + ' 1 ' + this.translations.request_Work_open, 'warning');
            // } else if (contador > 1) {
            //   Swal.fire(this.translations.information_message, this.translations.have + ` ${contador.toString()} ` + this.translations.request_Works_open, 'warning');
            // }
            this.rows = x.val;
            // console.log(this.rows)
            this.page = 1;
            if (Array.isArray(this.rows)) {
              this.pageTotal = this.rows.length;
            }
            this.filterAll();
            this.loadingList = false;
            this.closeLoadingMessage();
            // Swal.close()
          } else {
            // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.closeLoadingMessage();
            this.rows = [];
            return;
          } 
        } else {
            Swal.fire(x.msg, '', 'error');
            return;
        }
    });
    
  }
  public openCreateModal(content: NgbModal)  {


    let qst = GET_REQUEST_BY_ID + this.entryRequestId;
    this.srv.getCall(qst).subscribe(x => { 
      if(x.success){
        if(x.val != null){
          this.c_obj['date_init'] = x.val['StartDate'].split('T')[0];
          this.c_obj['date_end'] = x.val['EndDte'].split('T')[0];
          this.c_obj['work_area'] = x.val['Requiredbyarea'];
          this.c_obj['work_person_number'] = x.val['RequestDetails'].length;
          this.c_obj['work_description'] = x.val['WorkDescription'];
          this.c_obj['workers'] = x.val['RequestDetails'];
          this.c_obj['work_sub_description'] = '';
          this.c_obj['WorkOrder'] = x.val['WorkOrder'];
          // console.log(this.c_obj)
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.c_obj = {} ;
          return;
        } 
      } else {
          Swal.fire(x.msg, '', 'error');
          return;
      }
    });

    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
  }

  public verifyDateEnd() {
    if (this.c_obj['date_init'] === undefined ) {
      this.c_obj['date_end'] = null;
      return Swal.fire(this.translations.error,this.translations.msg_not_found_date_init, 'error');
    }
    const date_init = new Date(this.c_obj['date_init']);
    const date_end = new Date(this.c_obj['date_end']);
    this.diffDate = (date_end.getTime() - date_init.getTime()) / 86400000;
    // console.log(this.c_obj['date_init']);
    if (this.diffDate > 7 ){
      return Swal.fire(this.translations.error,this.translations.msg_max_ranges_dates, 'error');
    }

    if (date_init > date_end ){
      (<HTMLInputElement> document.getElementById("fechaFinal")).value = "";
      return Swal.fire(this.translations.error,this.translations.msg_date_end_max, 'error');
    }
    
    
  }

  public validateCreateInfo(fields:any): boolean {
    let mygroup:any = {};
    mygroup = new FormGroup({
        'date_init': new FormControl(fields.date_init, [Validators.required]),
        'date_end': new FormControl(fields.date_end, [Validators.required]),
        'date_hour': new FormControl(fields.date_hour, [Validators.required]),
        'work_area': new FormControl(fields.work_area, [Validators.required]),
        'work_person_number': new FormControl(fields.work_person_number, [Validators.required]),
        'work_description': new FormControl(fields.work_description, [Validators.required]),
        'work_sub_description':new FormControl(fields.work_sub_description, [Validators.required]),
    });
    return mygroup.valid;
  }


  public createItem(content:any, form:any): any{
    // console.log(form)
    // console.log(this.objectEntryRequest);
    var validated = true;
    this.isCreating = false;
    if( form == undefined ){
      form = {};
    }

    validated = this.validateCreateInfo(form);  
    // if (this.diffDate > 7 ){    
    //   this.isCreating = false;
    //   return Swal.fire(this.translations.error,this.translations.msg_max_ranges_dates, 'error');
    // }

    let new_workerAuth = {
      Wa_DateBegin: form['date_init'],
      Wa_DaTeend: form['date_end'],
      Area: form['work_area'],
      QuantityWorker: form['work_person_number'],
      DescriptionWorker: form['work_description'],
      Hour: form['date_hour'],
      CompanyID: form['CompanyID'],
      EntryRequestId: this.entryRequestId,
      CreatorUser:this.userinfo.logon,
      WorkSubDescription: form ['work_sub_description'],
      FileName: null,
      PathFile: null
    }

    // console.log(new_workerAuth);

    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    let url_create = CREATE_WORK_AUTH;
    this.srv.postCall(url_create, new_workerAuth).toPromise().then( data =>{
      if(data.success){
        this.closeModal(content);
        this.getList();
        Swal.fire(this.translations.msg_create, '', 'success');
        this.isCreating = false;
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

    

  }

  toggleEditable(event: any, id: Number): void {
    if ( event.target.checked ) {
      this.ActivarItem(id,1);
    }else {
      this.ActivarItem(id,2);
  
    }
  }

  public ActivarItem(id:any, accion:any): void
  {
    var to:any;
    to = {};
    to.WorkAuthId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['logon'];
    // console.log(to)
    this.srv.postCall(UPDATE_STATUS_WORK_AUTH, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  openCloseWork(content, row, size) {
    // console.log(row)
   
    this.c_obj['WorkAuthId'] = row.WorkAuthId;
    this.c_obj['date_init'] = row['Wa_DateBegin'].split('T')[0];
    this.c_obj['date_end'] = row['Wa_DaTeend'].split('T')[0];
    this.c_obj['work_area'] = row['Requiredbyarea'];
    this.c_obj['work_person_number'] = row['QuantityWorker'];
    this.c_obj['work_description'] = row['DescriptionWorker'];
    this.c_obj['CloseWorkerAuth'] = row['CloseWorkerAuth'] === 'C' ? true:false;
    this.c_obj['work_sub_description'] = row['WorkSubDescription'];
    this.c_obj['WorkOrder'] = row['WorkOrder'];
    this.c_obj['date_hour'] = row['Hour'] !== undefined ? row['Hour'] : null;
    let qst = '';
    if ( this.c_obj['CloseWorkerAuth'] === false) {
      this.statusWork = true;
    } else {
      this.statusWork = false;
    }
    if(this.isSkretting) {
      qst = GET_REQUEST_BY_ID + this.entryRequestId;
    } else {
      qst = GET_REQUEST_BY_ID + row['EntryRequestId'];
    }
    this.srv.getCall(qst).subscribe(x => { 
      if(x.success){
        if(x.val != null){
          this.c_obj['workers'] = x.val['RequestDetails'];
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.c_obj = {} ;
          return;
        } 
      } else {
          Swal.fire(x.msg, '', 'error');
          return;
      }
    });
    this.modalService.open(content, { backdrop: 'static', size: size });
  }


  handleInputChange(e) {
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const archivocapture=e.target.files[0];
    this.archivos.push(archivocapture);
    this.namefile=file.name;
  }

  closeItem(content, row) {
    // console.log(row);
    if (this.archivos.length === 0) {
      return Swal.fire(this.translations.error, this.translations.must_upload_document, 'error');
    } 

    if (row['CloseWorkerAuth'] === undefined) {
      return Swal.fire(this.translations.error, this.translations['msg_all_required'], 'error');
    }
    this.isUpdating = true;
    let formData = new FormData();
    formData.append('FileUpload',  this.archivos[0]);
    formData.append('CompanyID', this.userinfo.selectedCompanyId);
    formData.append('WorkDocumentID', row.WorkAuthId);
    formData.append('CreatorUser', this.userinfo.logon);
    this.srv.AddFileWorkerAuth(formData).subscribe(result => {
      if (result=="OK") {
        this.reload.next(true);
        this.closeModal(content);
        this.uploadDataBase(row);
        this.archivos=[];
        this.getList();
        this.isUpdating = false;
      }
      else{
        Swal.fire(this.translations.document, result, 'success');
        this.archivos=[];
        this.getList();
      }
    });
  }

  public uploadDataBase(row:any){

    var par_url = UPDATE_WORK_AUTH;

    var to:any;
    to={};
    to.LastModifierUser = this.userinfo.logon;
    to.Wa_DateBegin = row['date_init'] ;
    to.Wa_DaTeend = row['date_end'];
    to.CloseWorkerAuth = 'C',
    to.WorkAuthId = row['WorkAuthId'];
    to.CompanyID = row['CompanyID'];
    to.FileName = this.namefile;
    to.WorkSubDescription = row['work_sub_description'];
    console.log(to);

    this.srv.postCall(par_url, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        Swal.fire(this.translations.updated, x.msg, 'success');
        this.getList();

      }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }

  deleteItem(row) {
    this.ActivarItem(row.WorkAuthId,3);
  }

  downloadWork(idPermisoTrabajo){
    console.log(idPermisoTrabajo)
    const url = 'WorkAuth/GeneratePdfWork/' + idPermisoTrabajo;
    
    Swal.fire(
      {
        title: 'DESCARGANDO PDF',
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.downloadFiles(url).subscribe( x => {
      console.log('Descarga correcta', x);
      importedSaveAs(x, 'PERMISO DE TRABAJO' + idPermisoTrabajo);
      Swal.close();
    });

  }


}
