
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div  *ngIf="!isSkretting  && entryRequestId !== undefined" >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('ListsToolsId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='ListsToolsId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Lt_Supervisor')" class="sh">
                                    {{'supervisor'| translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Lt_Supervisor')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'job_to_do' | translate}}</th>
                                <th>{{'date_init' | translate}}</th>
                                <th>{{'date_end' | translate}}</th>
                                <th >
                                    {{'habidesa' | translate}}
                                </th>
                                <th (click)="setOrder('Status')" class="sh">
                                    {{'status_assign_evaluation' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Status')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td (click)="setOrder('id')" class="sh">{{row.ListsToolsId}}</td>
                                <td (click)="setOrder('supervisor')" class="sh">{{row.Lt_Supervisor}}</td>
                                <td>{{row.WorkDescription}}</td>
                                <td>{{row.StartDate.split('T')[0]}}</td>
                                <td>{{row.EndDte.split('T')[0]}}</td>
                                <td class="align-text-top"> 
                                    <div class="form-check">
                                        <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.ListsToolsId}}" name="{{row.ListsToolsId}}" (change)="toggleEditable($event,row.ListsToolsId)" type="checkbox">
                                    </div>
                                </td>
                                <td>{{row.Status === 'A'? 'ACTIVO':'INACTIVO'}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="printListTool(row)" style="cursor:pointer;" class="fas fa-print text-dark m-r-10" title="{{'print' | translate}}"></i>
                                    <i *ngIf="entryRequestId !== undefined" (click)="openUpdateModal(update1, row, 'lg')" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i *ngIf="entryRequestId !== undefined" (click)="deleteItem(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i> 
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
                
                <ng-template #create1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12">
                            <h4>{{'data'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                    <input type="text" class="form-control" value="{{userinfo['name']}}" name="name"  readonly>     
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="Supervisor" class="text-right control-label col-form-label"> {{'supervisor' | translate}} *</label>
                                    <input type="text"  class="form-control" id="Supervisor" name="Supervisor" placeholder="Supervisor..." [(ngModel)]="c_obj['Supervisor']">   
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="date_register" class="text-right control-label col-form-label">{{'date_register' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_register"  [(ngModel)]="c_obj['date_register']" disabled>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="date_out" class="text-right control-label col-form-label">{{'date_out' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_out" id="fechaFinal"  [(ngModel)]="c_obj['date_out']"  disabled>  <!--(ngModelChange)="verifyDateEnd()" -->
                                </div>
                            </div>
                            <hr>
                            <h4>{{'data_list_tools'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="lname" class="text-right control-label col-form-label">{{'tool_detail' | translate}} *</label>
                                    <input type="text" class="form-control" name="detailTool" id="detailTool" [(ngModel)]="c_obj['detailTool']">
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="mModel" class="text-right control-label col-form-label">{{'brand_model' | translate}} *</label>
                                    <input type="text" class="form-control" name="mModel" id="mModel" [(ngModel)]="c_obj['mModel']">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="noticeI" class="text-right control-label col-form-label">{{'notice_in' | translate}}</label>
                                    <textarea id="noticeI" name="noticeI" class="form-control" [(ngModel)]="c_obj['noticeI']"></textarea>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="noticeO" class="text-right control-label col-form-label">{{'notice_out' | translate}}</label>
                                    <textarea id="noticeO" name="noticeO" class="form-control"  [(ngModel)]="c_obj['noticeO']"></textarea>
                                </div>                      
                            </div>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="quantity" class="text-right control-label col-form-label">{{'quantity' | translate}} *</label>
                                    <input type="text" name="quantity" class="form-control" id="quantity" [(ngModel)]="c_obj['quantity']" (keypress)="onlyNumberKey($event)">
                                </div>      
                                <div class="col-md-6 ha-ibox" style="padding-top: 35px;">
                                    <button type="button" class="btn buttons-pages" (click)="addParameter(c_obj)" style="color: #ffffff;">
                                        <i class=" fas fa-plus"></i>
                                    </button>
                                </div>                            
                            </div>      
                            <hr>
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <h4>{{'tool' | translate}}</h4>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead style="padding: 0">
                                                <tr>
                                                    <th>{{'tool_detail' | translate}}</th>
                                                    <th>{{'brand_model' | translate}}</th>
                                                    <th>{{'quantity' | translate}}</th>
                                                    <th>{{'notice_in'| translate}}</th>
                                                    <th>{{'notice_out' | translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let parameter of c_obj['ToolsDetails']; let i=index">
                                                    <td>{{parameter.tool_detail}}</td>
                                                    <td>{{parameter.brand_model}}</td>
                                                    <td>{{parameter.quantity}}</td>
                                                    <td>{{parameter.notice_in}}</td>
                                                    <td>{{parameter.notice_out}}</td>
                                                    <td class="action-buttons">
                                                        <i (click)="delParameter(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                                    </td> 
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>         
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>
                <ng-template #update1 let-c="close" let-d="dismiss" let-modal> 
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{edit_obj['ListsToolsId']}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="form">
                            <div class="card-body">
                                <div class="col-12">
                                    <h4>{{'data'| translate}}</h4>
                                    <div class="row">
                                        <div class="col-md-6 ha-ibox">
                                            <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                            <input type="text" class="form-control" value="{{userinfo['name']}}" name="name"  readonly>     
                                        </div>
                                        <div class="col-md-6 ha-ibox">
                                            <label for="Supervisor" class="text-right control-label col-form-label"> {{'supervisor' | translate}} *</label>
                                            <input type="text"  class="form-control" id="Supervisor" name="Supervisor" placeholder="Supervisor..." [(ngModel)]="c_obj['Supervisor']" [disabled]="isSkretting">   
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 ha-ibox">
                                            <label for="date_register" class="text-right control-label col-form-label">{{'date_register' | translate}} *</label>
                                            <input type="date" class="form-control" name="date_register"  [(ngModel)]="c_obj['date_register']" disabled>
                                        </div>
                                        <div class="col-md-6 ha-ibox">
                                            <label for="date_out" class="text-right control-label col-form-label">{{'date_out' | translate}} *</label>
                                            <input type="date" class="form-control" name="date_out"  [(ngModel)]="c_obj['date_out']" disabled>
                                        </div>
                                    </div>
                                    <hr>
                                    <h4 *ngIf="!isSkretting">{{'data_list_tools'| translate}}</h4>
                                    <div class="row" *ngIf="!isSkretting">
                                        <div class="col-md-6 ha-ibox">
                                            <label for="lname" class="text-right control-label col-form-label">{{'tool_detail' | translate}} *</label>
                                            <input type="text" class="form-control" name="detailTool" id="detailTool" [(ngModel)]="c_obj['detailTool']">
                                        </div>
                                        <div class="col-md-6 ha-ibox">
                                            <label for="mModel" class="text-right control-label col-form-label">{{'brand_model' | translate}} *</label>
                                            <input type="text" class="form-control" name="mModel" id="mModel" [(ngModel)]="c_obj['mModel']">
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isSkretting">
                                        <div class="col-md-6 ha-ibox">
                                            <label for="noticeI" class="text-right control-label col-form-label">{{'notice_in' | translate}}</label>
                                            <textarea id="noticeI" name="noticeI" class="form-control" [(ngModel)]="c_obj['noticeI']"></textarea>
                                        </div>
                                        <div class="col-md-6 ha-ibox">
                                            <label for="noticeO" class="text-right control-label col-form-label">{{'notice_out' | translate}}</label>
                                            <textarea id="noticeO" name="noticeO" class="form-control"  [(ngModel)]="c_obj['noticeO']"></textarea>
                                        </div>                      
                                    </div>
                                    <div class="row" *ngIf="!isSkretting">
                                        <div class="col-md-6 ha-ibox">
                                            <label for="quantity" class="text-right control-label col-form-label">{{'quantity' | translate}} *</label>
                                            <input type="text" name="quantity" class="form-control" id="quantity" [(ngModel)]="c_obj['quantity']" (keypress)="onlyNumberKey($event)">
                                        </div>      
                                        <div class="col-md-6 ha-ibox" style="padding-top: 35px;">
                                            <button type="button" class="btn buttons-pages" (click)="addParameter(c_obj)" style="color: #ffffff;">
                                                <i class=" fas fa-plus"></i>
                                            </button>
                                        </div>                            
                                    </div>      
                                    <hr *ngIf="!isSkretting">
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <h4>{{'tool' | translate}}</h4>
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead style="padding: 0">
                                                        <tr>
                                                            <th>{{'tool_detail' | translate}}</th>
                                                            <th>{{'brand_model' | translate}}</th>
                                                            <th>{{'quantity' | translate}}</th>
                                                            <th>{{'notice_in'| translate}}</th>
                                                            <th>{{'notice_out' | translate}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let parameter of c_obj['ToolsDetails']; let i=index">
                                                            <td>{{parameter.tool_detail}}</td>
                                                            <td>{{parameter.brand_model}}</td>
                                                            <td>{{parameter.quantity}}</td>
                                                            <td>
                                                                <input type="text" [value]="parameter.notice_in" class="form-control" [(ngModel)]="parameter.notice_in" [ngModelOptions]="{standalone: true}">
                                                            </td>
                                                            <td>
                                                                <input type="text" [value]="parameter.notice_out" class="form-control" [(ngModel)]="parameter.notice_out" [ngModelOptions]="{standalone: true}">
                                                            </td>
                                                            <td class="action-buttons" *ngIf="!isSkretting">
                                                                <i (click)="delParameter(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                                            </td> 
                                                        </tr> 
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, c_obj)" *ngIf="!isSkretting">{{'update' | translate}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>