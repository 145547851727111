<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('AccIncId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='AccIncId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('Name')">{{'worker_name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'company' | translate}}</th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'requiredby' | translate}}</th>
                                <th>{{'severity_of_injury' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.AccIncId}}</td>
                                <td >{{row.Name}}</td>
                                <td >{{row.Company.Name}}</td>
                                <td >{{row.Date.split('T')[0]}}</td>
                                <td >{{row.Reportby}}</td>
                                <td >{{row.SeverityTypeName}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="edit(update1, row)" style="cursor:pointer;" class="fas fa-edit text-info text-warning m-r-10" title="{{'edit' | translate}}"></i> 
                                    <i (click)="deleteAccInc(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>










<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <label>{{'select'|translate}}</label>
                <select class="custom-select custom-select-md" name="worker_perm" [(ngModel)]="c_obj['typeinac']">
                    <option *ngFor="let li of list_incad" [value]="li.id">{{li.type}}</option>
                </select>
            </div>
            <div class="col-md-4">
                <label>{{'select_type_worker' | translate}}</label>
                <select class="custom-select custom-select-md" name="worker_perm" [(ngModel)]="workerId" (change)="setTypeWorker()">
                    <option *ngFor="let wks of list_workers" [value]="wks.id |json">{{wks.type}}</option>
                </select>
            </div>
            <div class="col-md-4">
                <label>{{'date' | translate}}</label>
                <input type="text"  class="form-control" name="date" [(ngModel)]="c_obj['date']" readonly>
            </div>
        </div>
        <br>
        <hr>
        <div>
            <h4 *ngIf="c_obj['typeinac'] == 1">{{'detail_incident'| translate}}</h4>
            <h4 *ngIf="c_obj['typeinac'] == 2">{{'detail_accident'| translate}}</h4>
        </div>
        <div class="row" *ngIf="workerId == 1">            
            <div class="col-md-6 ha-ibox">
                <label>{{'code' | translate}} *</label>
                <input type="text" maxlength="13"  class="form-control" name="code" [(ngModel)]="c_obj['code']" (keypress)="onlyNumberKey($event)">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'name' | translate}} *</label>
                <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']" readonly>
            </div> 
        </div>
        <div class="row" *ngIf="workerId == 2">
            <div class="col-md-6 ha-ibox">
                <label>{{'identification' | translate}} *</label>
                <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (keypress)="onlyNumberKey($event)" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'name' | translate}} *</label>
                <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']" readonly>
            </div>  
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="severity_of_injury"> {{'severity_of_injury' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['severity_of_injury']">
                    <option [value]="null" selected disabled>{{'select_injury' | translate}}</option>
                    <option *ngFor="let lt of list_injury" [value]="lt.id">{{lt.type}}</option>
                </select>  -->                
                <ng-select [(ngModel)]="c_obj['severity_of_injury']" placeholder="{{'select_injury' | translate}}">
                    <ng-option *ngFor="let lt of list_injury" [value]="lt.id">{{lt.type}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="level">{{'level' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="searchArea()">
                    <option [value]="null" selected disabled>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</option>
                </select> -->
                <ng-select [(ngModel)]="c_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="searchArea()">
                    <ng-option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="area" > {{'cmp_area' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="cmp_area" [(ngModel)]="c_obj['cmp_area']">
                    <option [value]="null" selected disabled>{{'Select_position' | translate}}</option>
                    <option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</option>
                </select> -->
                <ng-select [(ngModel)]="c_obj['cmp_area']" placeholder="{{'Select_position' | translate}}" >
                    <ng-option  *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'report_by' | translate}} *</label>
                <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['reportby']"  title="{{'autocomplete'| translate}}" readonly>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'boss_inme' | translate}} *</label>
                <input type="text"  class="form-control" name="boss_inme" [(ngModel)]="c_obj['boss_inme']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'mail_boss' | translate}} *</label>
                <input type="email"  class="form-control" name="mail_boss" [(ngModel)]="c_obj['mail_boss']" >
            </div>
        </div>
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'medt_take'|translate}} *</label>
                <textarea type="text"  class="form-control" name="medt_take" [(ngModel)]="c_obj['medt_take']" ></textarea>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'witness' | translate}} *</label>
                <textarea type="text"  class="form-control" name="witness" [(ngModel)]="c_obj['witness']"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button data-style="expand-left" [ladda]="isCreating" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>












<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <label>{{'date' | translate}}</label>
                <input type="text"  class="form-control" name="date" [(ngModel)]="u_obj['date']" readonly>
            </div>
        </div>
        <hr>
        <div>
            <h4 *ngIf="u_obj['typeinac'] == 1">{{'detail_incident'| translate}}</h4>
            <h4 *ngIf="u_obj['typeinac'] == 2">{{'detail_accident'| translate}}</h4>
        </div>
        <div class="row" *ngIf="workerId == 1">            
            <div class="col-md-6 ha-ibox">
                <label>{{'code' | translate}} *</label>
                <input type="text" maxlength="13"  class="form-control" name="code" [(ngModel)]="u_obj['code']" (keypress)="onlyNumberKey($event)">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'name' | translate}} *</label>
                <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="u_obj['NameEmployee']" readonly>
            </div> 
        </div>
        <div class="row" *ngIf="workerId == 2">
            <div class="col-md-6 ha-ibox">
                <label>{{'identification' | translate}} *</label>
                <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="u_obj['NumberIdentification']" (keypress)="onlyNumberKey($event)" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'name' | translate}} *</label>
                <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="u_obj['NameEmployee']" readonly>
            </div>  
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="severity_of_injury"> {{'severity_of_injury' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="u_obj['severity_of_injury']" >
                    <option [value]="null" selected disabled>{{'select_injury' | translate}}</option>
                    <option *ngFor="let lt of list_injury" [value]="lt.id">{{lt.type}}</option>
                </select>  -->
                <ng-select [(ngModel)]="u_obj['severity_of_injury']" placeholder="{{'select_injury-level' | translate}}"  >
                    <ng-option  *ngFor="let lt of list_injury" [value]="lt.id">{{lt.type}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="level">{{'level' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="u_obj['level']" (change)="searchArea()">
                    <option [value]="null" selected disabled>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</option>
                </select> -->
                <ng-select [(ngModel)]="u_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="searchArea()" >
                    <ng-option  *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="area" > {{'cmp_area' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="cmp_area" [(ngModel)]="u_obj['cmp_area']">
                    <option [value]="null" selected disabled>{{'Select_position' | translate}}</option>
                    <option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</option>
                </select> -->
                <ng-select [(ngModel)]="u_obj['cmp_area']" placeholder="{{'Select_position' | translate}}" >
                    <ng-option  *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'report_by' | translate}} *</label>
                <input type="text"  class="form-control" name="position" [(ngModel)]="u_obj['reportby']"  title="{{'autocomplete'| translate}}" readonly >
            </div>
        </div>        
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'boss_inme' | translate}} *</label>
                <input type="text"  class="form-control" name="boss_inme" [(ngModel)]="u_obj['boss_inme']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'mail_boss' | translate}} *</label>
                <input type="email"  class="form-control" name="mail_boss" [(ngModel)]="u_obj['mail_boss']" >
            </div>
        </div>
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'medt_take'|translate}} *</label>
                <textarea type="text"  class="form-control" name="medt_take" [(ngModel)]="u_obj['medt_take']" ></textarea>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'witness' | translate}} *</label>
                <textarea type="text"  class="form-control" name="witness" [(ngModel)]="u_obj['witness']"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button data-style="expand-left" [ladda]="isUpdating" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(create1, u_obj)">{{'edit' | translate}}</button>
    </div>
</ng-template>