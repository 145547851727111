import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';

const API_CONSULTATYPEFALTA = environment.Global.API_CONSULTATYPEFALTA;
const API_CONSULTAFALTADETAIL = environment.Global.API_CONSULTAFALTADETAIL;
const API_SANCION = environment.Global.API_SANCION;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID; 

const API_CREATE_UNSAFECONDITION = environment.Global.API_CREATE_UNSAFECONDITION;
const API_UPDATE_UNSAFECONDITION= environment.Global.API_UPDATE_UNSAFECONDITION;
const API_ALL_UNSAFECONDITION= environment.Global.API_ALL_UNSAFECONDITION;
const API_ID_UNSAFECONDITION= environment.Global.API_ID_UNSAFECONDITION;
const API_UPDATESTATUS_UNSAFECONDITION= environment.Global.API_UPDATESTATUS_UNSAFECONDITION;
const API_GET_UNSAFECONDITION_BY_USER= environment.Global.API_GET_UNSAFECONDITION_BY_USER;
const API_UPLOAD_FILE_UNSAFECONDITION= environment.Global.API_UPLOAD_FILE_UNSAFECONDITION;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const API_UNSAFECONDITION_MAIL = environment.Global.API_UNSAFECONDITION_MAIL;

@Component({
  selector: 'app-condinosecure',
  templateUrl: './condinosecure.component.html',
  styleUrls: ['./condinosecure.component.css']
})
export class CondinosecureComponent  extends BaseCrudComponent implements OnInit {
  list_type = [];
  fechaActual = new Date();
  list_workers = [{id: 1, type: 'Interno'},{id: 2, type:'Externo'}];
  workerId = 1;
  companyId = null;
  list_option_fault = [{id: 1, type: 'Sí'},{id: 2, type:'No'}];
  archivos=[];
  list_fouls = [];
  list_fouls_detail = [];
  list_sanction = [];
  row_plantas = [];
  row_plantas_area = [];
  userinfo: any;
  loadingList = false;
  isUpdating = false;
  iscreated = false;
  companies_list = [];
  getCompany = false;
  listfouls = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
    });
    this.pageSize = 5
    this.search_fields = ['UnsafeConditionId','CategoryTypeName'];
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.c_obj['fault_type'] = null;
    this.c_obj['fault_associated'] = null;
    this.c_obj['type_sanction'] = null;
    this.c_obj['close_fault'] = 2;
    this.c_obj['category'] = null;
    this.c_obj['date'] = this.globalService.formatOnlyDate(this.fechaActual);
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });

  }

  public setTypeWorker() {
    // console.log(this.workerId)
    if(this.workerId == 2) {
      // this.c_obj['worker_perm'] = null;    
      this.getCompanies();
    } else {
      this.companies_list = [];
      this.c_obj['companyID'] = null;
    }
  }

  public setCompany() {
    this.c_obj['companyID'] = this.companyId;
    
    this.getCompany = true;
  }
  
  public getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => { 
      if(data.success) {
        if(data.val !== null){
          // console.log(data.val)
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push(
              {
                id: element.CompanyID, 
                ruc: element.Ruc,
                name: element.Name,
              }
            );
          })
        }
      }
    })

  }

  public GetSeverityTypes() {
    var qst = API_GET_SEVERITY_TYPE_BY_ID + 'CATEGORY_TYPE';
    this.srv.getCall(qst).subscribe(x => {
      this.list_type = [];
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          x.val.forEach(element => {
            this.list_type.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
        }else {
          return;
        }
      }else {
        return;
          }
    });
  }

  public openCreateModal(content: any) {
    this.GetSeverityTypes();   
    this.getTypeFouls();
    this.iscreated = true;
    this.GetPlantas();
    this.c_obj['category'] = null;
    this.c_obj['level'] = null;
    this.c_obj['area'] = null;
    this.c_obj['reportby'] = this.userinfo['logon'];
    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
  }

  public handleInputChange(e){
    // console.log(e)
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    // console.log(file)
    let files = Object.values(file);
    // console.log(files)
    files.forEach(element => {
      if (this.archivos.length < 2) {
        this.archivos.push(element);
      } else {
        return Swal.fire(this.translations.only_two_imgs,'','error');
      }
    });
    
  }

  public getTypeFouls () {
    let url = API_CONSULTATYPEFALTA;
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.list_fouls = x.val
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getFouls(falta_id) {
    // console.log(falta_id)
    if (falta_id !== null) {
      let url = API_CONSULTAFALTADETAIL;
      let id = {FoulTypeID: falta_id};      
      this.c_obj['fault_associated'] = null;
      this.srv.postCall(url, id).subscribe(x => {
        if(x.success){
          if(x.val != null){
            // console.log(x.val)
            this.list_fouls_detail = x.val;
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
      });
      this.getSanction(falta_id);
    } else {
      this.c_obj['fault_associated'] = null;
      this.c_obj['type_sanction'] = null;
    }
  }

  public getSanction(falta_id) {
    let url = API_SANCION;
    let id = {FoulTypeID: falta_id};
    this.srv.postCall(url, id).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.list_sanction = x.val;
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
    });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
          // console.log(this.row_plantas)
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }
  public searchArea() {
    this.setLevelArea();
  }

  public setLevelArea() {
    if ( this.iscreated) {
      var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'];
      this.srv.getCall(qst).subscribe(x => {
        if(x.success){
          if(x.val != null){
            var rs = x.val;
            this.row_plantas_area = rs;
            // console.log(rs);
          }else {
            this.row_plantas_area = [];
            return;
          }
        }else {
          return;
            }
      });
    } else {
      var qst = GET_ALL_AREA_BY_ID + this.u_obj['level'];
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
          // console.log(rs);
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
    }
    
  }

  public changeValueFoult() {
    if(this.c_obj['close_fault'] == 2) {
      this.c_obj['samp_comment'] = null;
    }
  }

  public gotoPlan(UnsafeConditionId) {
    this.router.navigate(['plan/'],{ queryParams: {
      id: UnsafeConditionId,
      type: 2
    }});
  }

  public validateCreateInfo(fields:any): boolean {
    let internalWorker:any = {};
    internalWorker = new FormGroup({
        'category': new FormControl(fields.category, [Validators.required]),
        'date': new FormControl(fields.date, [Validators.required]),
        'reportby': new FormControl(fields.reportby, [Validators.required]),
        'level': new FormControl(fields.level, [Validators.required]),
        'area': new FormControl(fields.area, [Validators.required]),
        'fault_type': new FormControl(fields.fault_type, [Validators.required]),
        'fault_associated': new FormControl(fields.fault_associated, [Validators.required]),
        'type_sanction': new FormControl(fields.type_sanction, [Validators.required]),
        'observation_ha': new FormControl(fields.observation_ha, [Validators.required])

    });
    return internalWorker.valid;
  }
  

  public async createItem(modal, object) {
    // console.log(object)
    // console.log(this.c_obj['companyID'])
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);

    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');      
      return;
    }

    if(this.workerId == 2) {
      if (this.c_obj['companyID'] == null) {
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');   
        return;
      }
    }

    if (this.archivos.length == 0) {
      return Swal.fire(this.translations.should_upload_imgs, '','error');
    }

    if(object['close_fault'] == 1 && object['samp_comment'] == null) {
      return Swal.fire(this.translations.add_comment_act, '','error');
    }

    let new_obj = {
      CategoryTypeId: object['category'],
      Date: object['date'],
      PlantaID: object['level'],
      SeccionID: object['area'],
      FoulTypeID: object['fault_type'],
      ParaDetaFoulID: object['fault_associated'],
      SanctionID: object['type_sanction'],
      Photos: '',
      Commentary: object['samp_comment'] == null ? '' : object['samp_comment'],
      StatusInsp: object['close_fault'],
      Observation: object['observation_ha'],
      CreatorUser: this.userinfo['logon'],
      WorkerTypeId: this.workerId,
    }

    if (this.c_obj['companyID'] !== null) {
      new_obj['CompanyID'] = this.c_obj['companyID'];
    }

    console.log(new_obj)

    this.srv.postCall(API_CREATE_UNSAFECONDITION, new_obj).toPromise().then(  (data)=> {
      this.isCreating = true;
      if(data.success){
        this.c_obj = {};
        this.sendPhotos(data);
        if (new_obj.StatusInsp == 2) {
          this.gotoPlan(data.val['UnsafeConditionId']);
        }
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.sendMail(data.val);
        this.isCreating = false;
        this.closeModal(modal);
        this.workerId = 1;
        this.companyId = null;
        this.c_obj['fault_type'] = null;
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

    // this.createReportToSend();
  }

  public async sendPhotos(data?) {
    // console.log(data)
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('user',this.userinfo['logon']);
    formData.append('UnsafeConditionId', data.val.UnsafeConditionId);
    // console.log(formData);
    // await this.srv.AddFiles(API_UPLOAD_FILE_UNSAFECONDITION, formData).toPromise().then(result => {
    //   if (result=="OK") {
    //     this.archivos=[];
    //   }
    //   else{
    //     Swal.fire(this.translations.evidence, result, 'error');
    //     this.archivos=[];
    //     // this.getList();
    //   }
    // });
  }

  public getList() {
    this.loadingList = true; 
    Swal.fire(
      {
        title: this.translations.loading,
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(API_GET_UNSAFECONDITION_BY_USER + this.userinfo['logon']).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }


  public deleteInsp (row) {
    this.ActivarItem(row.UnsafeConditionId,3);
  }

  public ActivarItem(id:any, accion:any): void
  {
    var to:any;
    to = {};
    to.UnsafeConditionId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['logon'];
    // console.log(to)
    this.srv.postCall(API_UPDATESTATUS_UNSAFECONDITION, to).subscribe(x => {
      // Swal.close();
      if(x.success){
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  edit (modal, object) {
    this.u_obj['fault_type'] = null;
    this.iscreated = false;
    this.GetPlantas();
    this.GetSeverityTypes();
    this.getTypeFouls();
    // this.getTypeFouls();
    // let new_obj = {
    //   CategoryTypeId: object['category'],
    //   Date: object['date'],
    //   PlantaID: object['level'],
    //   SeccionID: object['area'],
    //   FoulTypeID: object['fault_type'],
    //   ParaDetaFoulID: object['fault_associated'],
    //   SanctionID: object['type_sanction'],
    //   Photos: '',
    //   Commentary: object['samp_comment'] == null ? '' : object['samp_comment'],
    //   StatusInsp: object['close_fault'],
    //   CreatorUser: this.userinfo['logon']
    // }
    console.log(object) ;
    this.u_obj['category'] = object['CategoryTypeId'];
    this.u_obj['level'] = object['PlantaID'];
    this.searchArea();
    this.u_obj['area'] = object['SeccionID'];
    this.u_obj['date'] = object['Date'].split('T')[0];
    this.u_obj['fault_type'] = object['FoulTypeID'];
    this.getFouls(this.u_obj['fault_type']);
    this.u_obj['fault_associated'] = object['ParaDetaFoulID'];
    // this.getSanction(this.u_obj['fault_associated']);
    this.u_obj['type_sanction'] = object['SanctionID'];
    this.u_obj['samp_comment'] = object['Commentary'];
    this.u_obj['close_fault'] = object['StatusInsp'];
    this.u_obj['observation_ha'] = object['Observation'];
    this.u_obj['reportby'] = object['CreatorUser'];
    this.u_obj['worker'] = object['WorkerTypeId'];
    this.u_obj['CompanyID'] = object['CompanyID'];
    this.u_obj['CodeEmployee'] = object['CodeEmployee'];
    this.u_obj['Name'] = object['Name'];
    // console.log(this.u_obj['fault_type'])
    // console.log(this.u_obj['fault_associated'])
    // console.log(this.u_obj['type_sanction'])
    this.modalService.open(modal, { backdrop: 'static', size: 'lg' });
  }

  public sendMail(to ) {
    let url = API_UNSAFECONDITION_MAIL;
    console.log(to)
    this.srv.postCall(url ,to).subscribe(x => {
      Swal.close();
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

}
