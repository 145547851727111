import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.css']
})
export class ModalFormComponent implements OnInit {
  
  @Input() labelTitle: string;
  @Input() styleTitle = {
    'color':'blue',
    'cursor':'pointer'
  };
  @Input() object = null;
  u_obj;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }


  openModal(content: any, obj: any, size?:any) {
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    // console.log(this.u_obj);
    if( size == undefined ){
        this.modalService.open(content, { centered: true });
    }
    else{
        this.modalService.open(content, { centered: true, size: size });
    }
    
  }

  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns {} esta función no retorna nada.
     * @desc función que cierra el modal. <br> Fecha de Creación: 17/06/2021
     * @param {content} Modal modal contenedor
     * @param {}
     * @type {void} void type.
  */
  closeModal(content: any): void {
    // content.close();
    this.modalService.dismissAll();
  }

}
