<div class="row">
    <div class="col-md-12 div-filter">
        <select class="custom-select custom-select-md form-control u-select" name="company"
            [(ngModel)]="options_company" (change)="companySelected()" *ngIf="isSkretting" [disabled]='disabledCompany'>
            <option [value]=null disabled>{{'select_company' | translate}}</option>
            <option [value]="-1">{{'all' | translate}}</option>
            <option *ngFor="let companie of companies_list; let i = index" [value]="companie.id">{{companie.name
                |translate}}</option>
        </select>
        <select class="custom-select custom-select-md form-control u-select" name="ruc" [(ngModel)]="options_ruc"
            (change)="rucSelected()" *ngIf="isSkretting" [disabled]='disabledRuc'>
            <option [value]=null disabled>{{'select_ruc' | translate}}</option>
            <option [value]="-1">{{'all' | translate}}</option>
            <option *ngFor="let ruc of ruc_list; let i = index" [value]="ruc.id">{{ruc.ruc |translate}}</option>
        </select>
        <button [ladda]="isFind" type="button" (click)="dataToFind()"
        class="btn button-modal u-button-find m-b-10">{{'search_filters' | translate}}</button>
        <button [ladda]="isClean" type="button" (click)="clearFilters()"
        class="btn button-modal u-button-find m-b-10">{{'clean' | translate}}</button>

    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn buttons-pages" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class=" fas fa-plus"></i>
                                {{'download_report' | translate}}</button>
                            <button type="button" class="btn buttons-pages" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class=" fas fa-plus"></i>
                                {{'download_to_excel' | translate}}</button>

                        </div>
                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive text-center">
            <table class="table">
                <thead class="table-head text-white" #thead>
                    <tr>
                        <th></th>
                        <th>{{'code' | translate}} </th>
                        <th>{{'identification' | translate}} </th>
                        <th>{{'worker_name' | translate}} </th>
                        <th>{{'birth_date' | translate}}</th>
                        <th *ngIf="isSkretting">{{'ruc' | translate}}</th>
                        <th *ngIf="isSkretting">{{'company' | translate}}</th>
                        <th *ngIf="isSkretting">{{'company_type' | translate}}</th>
                         <!--<th>{{'Email' | translate}}</th>   -->
                         <!--<th>{{'address' | translate}}</th> -->
                        <th>{{'position' | translate}}</th>
                         <!--<th>{{'phone' | translate}}</th> -->
                         <!--<th>{{'phone2' | translate}}</th>-->
                        <th>{{'nationality' | translate}}</th>
                        <th>{{'status' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let row of rows| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc; index as i ">
                    <tr [id]="'employee'+i"  >
                         <td><i class="fa " aria-hidden="true" (click)="mostrarAdicional(row.EmployeeId)" [ngClass]="{'fa-folder': i!=this.mostrar, 'fa-folder-open': i==this.mostrar}"></i></td>
                        <td>{{row.EmployeeId}}</td>
                        <td>{{row.NumberIdentification}}</td>
                        <td>{{row.Name}}</td>
                        <td>{{row.Birth_date.split('T')[0]}}</td>
                        <td *ngIf="isSkretting">{{row.ruccompany}}</td>
                        <td *ngIf="isSkretting">{{row.namecompany}}</td>
                        <td *ngIf="isSkretting">{{row.TypeCompany == 1 ? "Contratista":"Transportista" }}</td>
                         <!--<td>{{row.Email}}</td>        -->
                         <!--<td>{{row.address}}</td>      -->
                        <td>{{row.position}}</td>
                         <!--<td>{{row.telephone}}</td> -->
                         <!--<td>{{row.telephone2}}</td>-->
                        <td>{{row.nationality}} </td>
                        <td>{{row.Status=='A' ? "Activo":"Inactivo"}}</td>

                    </tr>
                  <tr *ngIf="row.EmployeeId==this.mostrar" id="trHija" style="borderTop: 10px solid transparent">
                    <td colspan="11" id="tablahija" class="text-center">
                      <table  class="table" width="100%">
                        <thead  class="table-head text-white" >
                        <tr>
                          <th>{{'Email' | translate}}</th>
                          <th>{{'address' | translate}}</th>
                          <th>{{'phone' | translate}}</th>
                          <th>{{'phone2' | translate}}</th>
                          <th>{{'course' | translate}}</th>
                          <th>{{'menu_docs' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody> 
                        <td>{{row.Email}}</td>
                        <td>{{row.address}}</td>
                        <td>{{row.telephone}}</td>
                        <td>{{row.telephone2}}</td>
                        <td><p *ngFor="let course of row._ListsCourses ">{{course}}</p>
                          <p *ngIf="row._ListsCourses.length==0">No cuenta con cursos realizados</p>
                        </td>                 
                        <td><p *ngFor="let document of row._DocumentsEmployees">{{document.NameDoc}} | {{document.Status}}</p>
                        </td>
                        </tbody>
                    </table></td>
                  </tr>
                </ng-container>
                </tbody>
                <tfoot>
                    <td colspan="9">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                            style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>
