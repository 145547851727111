import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { isNumeric } from 'rxjs/util/isNumeric';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
//import { isNull } from 'util';
import { Canvas, Cell, Columns, Ellipse, Img, Item, Line, PageReference, PdfMakeWrapper, Polyline, Rect, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake

PdfMakeWrapper.setFonts(pdfFonts);

const GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const GET_ALL_ENTRY_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const GET_ALL_REQUEST_INDUCTION = environment.Global.API_GET_ALL_REQUEST_INDUCTION;
const GET_ALL_REQUEST_INDUCTION_BY_ID = environment.Global.API_GET_ALL_REQUEST_INDUCTION_BY_ID;
const GET_BY_ID_COMPANY_WORK_AUTH = environment.Global.API_GET_BY_ID_COMPANY_WORK_AUTH;
const GET_ALL_WORK_AUTHS_FOR_ADMIN = environment.Global.API_GET_ALL_WORK_AUTHS_FOR_ADMIN;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const GET_DOCUMENTS_BY_ID = environment.Global.API_GET_DOCUMENTS_BY_ID;

const GET_REPORT_ENTRY_REQUEST = environment.Global.API_REPORT_ENTRY_REQUEST;
const GET_REPORT_REQUEST = environment.Global.API_REPORT_REQUEST;
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ConsoleLogger } from '@angular/compiler-cli/ngcc';
import { element } from 'protractor';

@Component({
  selector: 'app-rptevaluationrequest',
  templateUrl: './rptevaluationrequest.component.html',
  styleUrls: ['./rptevaluationrequest.component.css']
})

export class RptevaluationrequestComponent extends BaseCrudComponent implements OnInit {
  @ViewChild('thead', { static: true }) thead: ElementRef;
  userInfo;
  option_processes = 1;
  date_init = null;
  date_end = null;
  list_processes = [{ title: 'induction_soli', id: 1 }, { title: 'request_soli', id: 2 }, { title: 'work_sol', id: 3 }]
  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = false;
  isSkretting = false;
  isClean = false;
  pageSize = 10;
  flag = false;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  col_value = 0;
  url_report_ = '';
  l = [];
  cantidadDeEvaluaciones = 0;
  private workbook: Workbook;
  private lista_titulos;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Requiredby', 'CompanyName', 'WhoRequest', 'Name', 'WorkAuthId'];
  }


  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
      });
    this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(this.userInfo)
      if (isNumeric(this.userInfo.logon)) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });
    this.getCompanies();
    // console.log(this.request_selected);
    // if (this.request_selected) {
    this.disabledCompany = false;
    this.disabledRuc = false;
    // } else {
    //   this.disabledCompany = true;
    //   this.disabledRuc = true;
    // }
  }

  clearFilters() {
    this.option_processes = null;
    this.options_company = null;
    this.options_ruc = null;
    this.date_end = null;
    this.date_init = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.url_report_ = '';
    this.disabledCompany = true;
    this.disabledRuc = true;
    // console.log(this.options_company);
    // console.log(this.options_ruc);
  }

  dataToFind() {
    let url = '';
    this.isFind = true;

    // if (this.option_processes === null) {
    //   this.isFind = false;
    //   return Swal.fire(this.translations.msg_all_required, '', 'error');
    // }

    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    if (this.date_init !== null && this.date_end === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_end_select, '', 'error');
    }
    if (this.date_end !== null && this.date_init === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_init_select, '', 'error');
    }

    if (isNumeric(this.userInfo.logon)) {
      url = GET_ALL_REQUEST_INDUCTION_BY_ID + this.userInfo.selectedCompanyId;
      this.isSkretting = false;
    } else {
      url = GET_ALL_REQUEST_INDUCTION;
      this.isSkretting = true;
    }
    this.isFind = false;
    this.getListFromApi(url);
  }


  setUrlToFindData() {
    let url = '';
    // console.log(this.option_processes)
    switch (Number(this.option_processes)) {
      case 1:
        // console.log('entro aqui 1')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_ALL_REQUEST_INDUCTION_BY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          url = GET_ALL_REQUEST_INDUCTION;
          this.isSkretting = true;
        }
        break;
      case 2:
        // console.log('entro aqui 2')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          this.isSkretting = true;
          url = GET_ALL_ENTRY_REQUEST;
        }
        break;
      default:
        // console.log('entro aqui 3')
        if (isNumeric(this.userInfo.logon)) {
          url = GET_BY_ID_COMPANY_WORK_AUTH + this.userInfo.selectedCompanyId;
          this.isSkretting = false;
        } else {
          url = GET_ALL_WORK_AUTHS_FOR_ADMIN;
          this.isSkretting = true;
        }
        break;
    }
    // console.log(url);
    this.isFind = false;
    this.getListFromApi(url);
  }


  getListFromApi(url) {
    // console.log(this.option_processes)

    this.isFind = true;
    // console.log(url)
    let list_tmp = [];
    // console.log( this.date_init, this.date_end, this.option_processes,this.options_ruc, this.options_company);
    this.srv.getCall(url).subscribe(x => {
      // console.log(x.val)
      if (x.success) {
        if (x.val != null) {
          // console.log(x.val)
          this.rawdata = x.val;
          let new_list = [];
          list_tmp = x.val;
          if ((!(this.date_init===null) && !(this.date_end===null)) && (this.options_ruc == -1 || this.options_ruc == null) && (this.options_company == -1 || this.options_company == null) && this.option_processes !== null) {
            // console.log('Primer filtro')
            if (this.option_processes == 1) {
              list_tmp.forEach(tmp => {
                tmp.CreationTime = this.formatOnlyDate(new Date(tmp.CreationTime));
                if (tmp.CreationTime >= this.date_init && tmp.CreationTime <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 2) {
              list_tmp.forEach(tmp => {
                tmp.StartDate = this.formatOnlyDate(new Date(tmp.StartDate));
                if (tmp.StartDate >= this.date_init && tmp.StartDate <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            } else if (this.option_processes == 3) {
              list_tmp.forEach(tmp => {
                tmp.Wa_DateBegin = this.formatOnlyDate(new Date(tmp.Wa_DateBegin));
                if (tmp.Wa_DateBegin >= this.date_init && tmp.Wa_DateBegin <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            }
            this.rawdata = new_list;
            this.rows = new_list;
          } else if ((this.options_ruc != -1 || this.options_company != -1) && (!(this.date_init===null) && !(this.date_end===null))) {
            // console.log('Segundo filtro')
            if (this.option_processes == 1) {
              list_tmp.forEach(tmp => {
                tmp.CreationTime = this.formatOnlyDate(new Date(tmp.CreationTime));
                if (tmp.CreationTime >= this.date_init && tmp.CreationTime <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                }
              })
            } else if (this.option_processes == 2) {
              list_tmp.forEach(tmp => {
                tmp.StartDate = this.formatOnlyDate(new Date(tmp.StartDate));
                if (tmp.StartDate >= this.date_init && tmp.StartDate <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                }
              })
            } else if (this.option_processes == 3) {
              list_tmp.forEach(tmp => {
                tmp.Wa_DateBegin = this.formatOnlyDate(new Date(tmp.Wa_DateBegin));
                if (tmp.Wa_DateBegin >= this.date_init && tmp.Wa_DateBegin <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                }
              })
            }
            this.rawdata = new_list;
            this.rows = new_list;
          } else if ((this.options_ruc != -1 || this.options_company != -1) && ((this.date_init===null) && (this.date_end===null))) {
            if (this.isSkretting) {
              // console.log('SKRETTING')
              // console.log(this.options_company, this.options_ruc, this.option_processes);
              if (this.option_processes == 1 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1))) {
                // console.log('Tercer filtro induccion')
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                })
                this.rawdata = new_list;
                this.rows = new_list;
              } else if (this.option_processes == 2 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1))) {
                // console.log('Tercer filtro ingreso')
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                })
                this.rawdata = new_list;
                this.rows = new_list;
              } else if (this.option_processes == 3 && ((this.options_ruc !== null && this.options_ruc != -1) || (this.options_company != null && this.options_company != -1))) {
                // console.log('Tercer filtro trabajo')
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                })
                this.rawdata = new_list;
                this.rows = new_list;
              } else if ((this.option_processes == 1 || this.option_processes == 2 || this.option_processes == 3) && ((this.options_ruc == null || this.options_ruc == -1) || (this.options_company == null || this.options_company == -1))) {
                // console.log('Tercer filtro cualquiera sin ruc o compañia skretting')
                this.rawdata = list_tmp;
                this.rows = list_tmp;
              }
            } else {
              if ((this.option_processes == 1 || this.option_processes == 2 || this.option_processes == 3) && ((this.options_ruc == null || this.options_ruc == -1) || (this.options_company == null || this.options_company == -1))) {
                // console.log('Tercer filtro cualquiera sin ruc o compañia contratista')
                this.rawdata = list_tmp;
                this.rows = list_tmp;
              }
            }
          }
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          // console.log(this.rows)

          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      } else {
        this.isFind = false;
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

  }


  rucSelected() {
    if (this.options_ruc == -1 || this.options_ruc == null) {
      this.disabledCompany = false;
    } else {
      this.disabledCompany = true;
    }
  }

  companySelected() {
    // console.log(this.options_company);
    if (this.options_company == -1 || this.options_company == null) {
      this.disabledRuc = false;
    } else {
      this.disabledRuc = true;
    }
  }

  async downloadReport() {
    // console.log(this.rows)

    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    console.log(this.option_processes)
    let listInReport = this.rows;
    const pdf = new PdfMakeWrapper();
    let nameDoc = '';
    let title = '';
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];

    //if (this.option_processes == 1) {
      nameDoc = 'Solicitud de inducción';
      title = this.translations.msg_rep_induction;
    //} else if (this.option_processes == 2) {
    //  nameDoc = 'Solicitud de ingreso';
    //  title = this.translations.msg_rep_entry_request;
    //} else if (this.option_processes == 3) {
    //  nameDoc = 'Permiso de trabajo';
    //  title = this.translations.msg_rep_worker_auth;
    //}
    listInReport.forEach(element=>{
      let obj=[];
      if(this.isSkretting){
        obj=[

             element.RequestId,
             element.WhoRequest,
             element.namecompany,
             //element.plantaname==null?"No cuenta con Planta":element.plantaname,
             element.CreationTime.split('T')[0],
             element.listRequestdetail.length,
             element.contadorEvaluations,
        ]
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 
        ]
      }else{
        obj=[
          element.RequestId,
          element.WhoRequest,
          //element.plantaname==null?"No cuenta con Planta":element.plantaname,
          element.CreationTime.split('T')[0],
          element.listRequestdetail.length,
          element.contadorEvaluations,
        ]
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 
        ]
      }
      if (this.isSkretting) {
          lista_titulos = [
            new Cell(new Txt(this.translations.code).margin([0, 5, 0, 5]).color('white').bold().end).end,
            new Cell(new Txt(this.translations.job_applicant).margin([0, 5, 0, 5]).color('white').bold().end).end,
            new Cell(new Txt(this.translations.company).margin([0, 5, 0, 5]).color('white').bold().end).end,
            //new Cell(new Txt(this.translations.level).margin([0, 5, 0, 5]).color('white').bold().end).end,
            new Cell(new Txt(this.translations.date_creation).margin([0, 5, 0, 5]).color('white').bold().end).end,
            new Cell(new Txt(this.translations.worker_quantity).margin([0, 5, 0, 5]).color('white').bold().end).end,
            new Cell(new Txt(this.translations.request_induction_quantity).margin([0, 5, 0, 5]).color('white').bold().end).end
          ];

      }else{
        lista_titulos = [
          new Cell(new Txt(this.translations.code).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.job_applicant).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.level).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.date_creation).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.worker_quantity).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.request_induction_quantity).margin([0, 5, 0, 5]).color('white').bold().end).end
        ];

      }
      nueva_lista_reportes.push(obj);
    });
//    if (this.isSkretting) {
//      if (this.option_processes == 1) {
//        lista_titulos = [
//          new Cell(new Txt(this.translations.name_last_name).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.ci).margin([0, 5, 0, 5]).color('white').bold().end).end,   
//          new Cell(new Txt(this.translations.menu_contract).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.ruc).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.theme_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.date_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.duration_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.calification_inducton_evaluation).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.status_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.quantity_repeat_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//        ];
//        lista_widths = [
//          '*', 'auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'
//        ]
//      } else if (this.option_processes == 2) {
//        lista_titulos = [
//          new Cell(new Txt(this.translations.name_last_name).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.ci).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.menu_contract).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.ruc).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.sol_number).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.date_init).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.date_cerr).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.status_docs).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.status_eval).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.status_sanct).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.habilitation).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.colors).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.asist).margin([0, 5, 0, 5]).color('white').bold().end).end,
//        ];
//        lista_widths = [
//          '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
//        ]
//      } else if (this.option_processes == 3) {
//        lista_titulos = [
//          new Cell(new Txt(this.translations.menu_contract).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.ruc).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.quantity_w_risk).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.quantity_w_auths).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.quantity_w_open).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.quantity_w_closed).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.worker_quantity).margin([0, 5, 0, 5]).color('white').bold().end).end,
//        ];
//        lista_widths = [
//          '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
//        ]
//      }
//    } else {
     //if (this.option_processes == 1) {
//        lista_titulos = [
//          new Cell(new Txt(this.translations.name_last_name).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.ci).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.theme_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.date_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.duration_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.calification_inducton_evaluation).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.status_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//          new Cell(new Txt(this.translations.quantity_repeat_induction).margin([0, 5, 0, 5]).color('white').bold().end).end,
//        ];
//        lista_widths = [
//          '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'
//        ]
      //} else if (this.option_processes == 2) {
      //  lista_titulos = [
      //    new Cell(new Txt(this.translations.name_last_name).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.ci).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.sol_number).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.date_init).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.date_cerr).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.status_docs).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.status_eval).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.status_sanct).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.habilitation).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.colors).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.asist).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //  ];
      //  lista_widths = [
      //    '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'
      //  ]
      //} else if (this.option_processes == 3) {
      //  lista_titulos = [
      //    new Cell(new Txt(this.translations.sol_number).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.date_init).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.date_cerr).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.level).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.quantity_w_risk).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.worker_quantity).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.quantity_w_open).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //    new Cell(new Txt(this.translations.quantity_w_closed).margin([0, 5, 0, 5]).color('white').bold().end).end,
      //  ];
      //  lista_widths = [
      //    '*', '*', '*', '*', '*', '*', '*', '*'
      //  ]
      //}

  //  }
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${nameDoc}`,
      author: `${this.userInfo.name}`,
      subject: 'Listado',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment: 'justify'
    });

    pdf.pageMargins([40, 110, 40, 110]);
    pdf.pageOrientation("landscape");
    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/duragas_logo.svg').width('160').build()).end,
          ]
        ]
      )
        .margin([0, 30, 30, 0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    // pdf.background(
    //   await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 515).width(900).build() //505
    // )
    // console.log(lista_titulos)
    // console.log(await this.converDataToRow(listInReport))
    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0, 0, 0, 20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.requested_by + ': ').bold().end, this.userInfo.name]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.user + ': ').bold().end, this.userInfo.logon]).fontSize(10).alignment('left').margin([0, 0, 0, 20]).end,
        new Table(
          [
            lista_titulos,
            ...nueva_lista_reportes
          ]
        ).layout({
          fillColor: (rowIndex: number, node: any, columnIndex: number) => {
            return rowIndex === 0 ? '#003d6a' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(8)
          .widths(lista_widths)
          // .widths('*')
          .alignment('center')
          .end,
      ]
    )

    pdf.footer(function (currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Duragas Ecuador | Km 7.5 vía a la costa, sector El Salitral Guayaquil - Ecuador | Telf: 1700387242').fontSize(8).color('gray').alignment('center').margin([0, 10, 0, 0]).end).end,
          new Cell(new Txt('www.duragas.com.ec').link('https://www.duragas.com.ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40, 20, 0]).end).end
        ]
      ).end
    });

    let nombre = '';
    //if (this.option_processes == 1) {
      nombre = 'Reporte de Solicitudes de Inducción';
    //} else if (this.option_processes == 2) {
    //  nombre = 'Reporte de Solicitudes de Ingreso';
    //} else if (this.option_processes == 3) {
    //  nombre = 'Reporte de Permisos de Trabajo';
    //}
    pdf.create().download(nombre);
    Swal.close();
  }

//  async converDataToRow(list) {
//    let nueva_lista = [];
//    this.l = [];
//    debugger
//    console.log(this.url_report_)
//    if (this.url_report_ !== '' && this.url_report_ !== ' ') {
//      await this.srv.getCall(this.url_report_).toPromise().then(x => {
//        if (x.success) {
//          if (x.val != null) {
//            x.val.forEach(element => {
//              if (this.option_processes == 1) {
//                if (element.RequestDetails.length > 0) {
//                  element.RequestDetails.forEach(emp => {
//                    if (emp.evaluations.length > 0) {
//                      emp.evaluations.forEach(evals => {
//                        let obj = ['', '', '', '', '', '', 0, '', 0, 0, '']
//                        obj[0] = emp.Name;
//                        obj[1] = emp.NumberIdentification;
//                        obj[2] = element.Name;
//                        obj[3] = element.Ruc;
//                        obj[4] = evals.nameEval;
//                        obj[5] = evals.lastregistereval.creationtime.split('T')[0];
//                        obj[7] = evals.lastregistereval.score;
//                        if (evals.status_end == 'AP') {
//                          obj[8] = 'APROBADO';
//                        } else if (evals.status_end == 'RP') {
//                          obj[8] = 'REPROBADO';
//                        }
//                        obj[6] = Number((evals.timeEval / 60).toFixed(2));
//                        obj[9] = evals.repeticiones;
//                        obj[10] = element.RequestId;
//                        this.l.push(obj)
//                      });
//                    } else {
//                      let obj = ['', '', '', '', '', '', 0, '', 0, 0, '']
//                      obj[0] = emp.Name;
//                      obj[1] = emp.NumberIdentification;
//                      obj[2] = element.Name;
//                      obj[3] = element.Ruc;
//                      obj[10] = element.RequestId;
//                      this.l.push(obj);
//                    }
//                  });
//                } else {
//                  let obj = ['', '', '', '', '', '', '', 0, '', 0, 0, '']
//                  obj[2] = element.Name;
//                  obj[3] = element.Ruc;
//                  obj[11] = element.RequestId;
//                  this.l.push(obj);
//                }
//              } else if (this.option_processes == 2) {
//                if (element._employee_status.length > 0) {
//                  element._employee_status.forEach(emp => {
//                    let obj = ['', '', '', '', '', '', '', '', '', '', '', {}, ''];
//                    obj[0] = emp.Name;
//                    obj[1] = emp.NumberIdentification;
//                    obj[2] = element.NameCompany;
//                    obj[3] = element.RucCompany;
//                    obj[4] = element.EntryRequestId;
//                    obj[5] = element.StartDate.split('T')[0];
//                    obj[6] = element.EndDte.split('T')[0];
//                    obj[7] = emp.Status_Document;
//                    obj[8] = emp.Status_Evaluation;
//                    obj[9] = (emp.Status_Faltas == 'SI' ? 'Sancionado' : 'No sancionado')
//                    obj[10] =
//                      (emp.Status_Document == 'Validado' && emp.Status_Evaluation == 'Aprobado' && emp.Status_Faltas == 'NO')
//                        ? 'Habilitado' : 'No Habilitado';
//                    obj[11] = obj[10] == 'Habilitado' ? new Cell(new Txt('').end).fillColor('green').end : new Cell(new Txt('').end).fillColor('red').end;
//                    obj[12] = obj[10] == 'Habilitado' ? 'Si' : 'No';
//                    this.l.push(obj);
//                  });
//                }
//              }
//            })
//            list.forEach(element => {
//              this.l.forEach(worker => {
//                if (this.option_processes == 1) {
//                  if (element.RequestId == worker[10]) {
//                    if (this.isSkretting) {
//                      nueva_lista.push(
//                        [worker[0], worker[1], worker[2], worker[3], worker[4], worker[5], worker[6], worker[7], worker[8], worker[9]]
//                      )
//                    } else {
//                      nueva_lista.push(
//                        [worker[0], worker[1], worker[4], worker[5], worker[6], worker[7], worker[8], worker[9]]
//                      )
//                    }
//                  }
//                } else if (this.option_processes == 2) {
//                  if (element.EntryRequestId == worker[4]) {
//                    if (this.isSkretting) {
//                      nueva_lista.push(
//                        [worker[0], worker[1], worker[2], worker[3], worker[4], worker[5], worker[6], worker[7], worker[8], worker[9], worker[10], worker[11], worker[12]]
//                      )
//                    } else {
//                      nueva_lista.push(
//                        [worker[0], worker[1], worker[4], worker[5], worker[6], worker[7], worker[8], worker[9], worker[10], worker[11], worker[12]]
//                      )
//                    }
//                  }
//                }
//              });
//            });
//          } else {
//            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
//            this.isFind = false;
//            return;
//          }
//        } else {
//          this.isFind = false;
//          Swal.fire(x.msg, '', 'error');
//          return;
//        }
//      });
//      return nueva_lista;
//    } else {
//      if (this.option_processes == 3) {
//        let map = new Map();
//        list.forEach(element => {
//          if (this.isSkretting) {
//            if (!map.has(element.CompanyID)) {
//              map.set(element.CompanyID, [
//                element.Name,
//                element.Ruc,
//                element.Risk ? 1 : 0,
//                1,
//                element.CloseWorkerAuth == 'A' ? 1 : 0,
//                element.CloseWorkerAuth == 'C' ? 1 : 0,
//                element.QuantityWorker
//              ])
//            } else {
//              let tmp = map.get(element.CompanyID);
//              tmp[2] = element.Risk ? tmp[2] + 1 : tmp[2];
//              tmp[3] = tmp[3] + 1;
//              tmp[4] = element.CloseWorkerAuth == 'A' ? tmp[4] + 1 : tmp[4];
//              tmp[5] = element.CloseWorkerAuth == 'C' ? tmp[5] + 1 : tmp[5];
//              tmp[6] = tmp[6] + element.QuantityWorker;
//            }
//          } else {
//            if (!map.has(element.CompanyID)) {
//              map.set(element.CompanyID, [
//                element.WorkAuthId,
//                element.Wa_DateBegin.split('T')[0],
//                element.Wa_DaTeend.split('T')[0],
//                element.PlantaName,
//                element.Risk ? 1 : 0,
//                element.QuantityWorker,
//                element.CloseWorkerAuth == 'C' ? 1 : 0,
//                element.CloseWorkerAuth == 'A' ? 1 : 0,
//              ])
//            } else {
//              let tmp = map.get(element.CompanyID);
//              tmp[4] = element.Risk ? tmp[4] + 1 : tmp[4];
//              tmp[5] = tmp[5] + element.QuantityWorker;
//              tmp[6] = element.CloseWorkerAuth == 'C' ? tmp[6] + 1 : tmp[6];
//              tmp[7] = element.CloseWorkerAuth == 'A' ? tmp[7] + 1 : tmp[7];
//            }
//          }
//        });
//        for (let [key, value] of map) {
//          nueva_lista.push(value)
//        }
//        return nueva_lista
//      }
//    }
//  }

  getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => {
      if (data.success) {
        if (data.val !== null) {
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push({ id: element.CompanyID, name: element.Name });
            this.ruc_list.push({ id: element.CompanyID, ruc: element.Ruc });
          })
        }
      }
    })
  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString())
      + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString()) + ' ' +
      (date.getHours() <= 9 ? `0${date.getHours().toString()}` : date.getHours().toString()) + ':' +
      (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}` : date.getMinutes().toString()) + ':' +
      (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}` : date.getSeconds().toString());
  }
  downloadExcel() {

    this.workbook = new Workbook();
    //let nombreXLSX = "Reporte de Ordenes de compra.xlsx";
   // if (this.option_processes == 1) {
      let nombreXLSX = "Reporte de Solicitudes de Inducción.xlsx";

      if (this.isSkretting == true) {
        this.lista_titulos = [
          this.translations.code,
          this.translations.job_applicant,
          this.translations.company,
          //this.translations.level,
          this.translations.date_creation,
          this.translations.worker_quantity,
          this.translations.request_induction_quantity,

        ];

      } else {
        this.lista_titulos = [
          this.translations.code,
          this.translations.job_applicant,
          //this.translations.level,
          this.translations.date_creation,
          this.translations.worker_quantity,
          this.translations.request_induction_quantity,

        ];

      }
    //} else if (this.option_processes == 2) {
    //  nombreXLSX = "Trabajadores de los contratistas registrados.xlsx";
    //  if (this.isSkretting == true) {
    //    this.lista_titulos = [
    //      this.translations.code,
    //      this.translations.requested_by,
    //      this.translations.company,
    //      this.translations.level,
    //      this.translations.date_init,
    //      this.translations.date_end,
    //      this.translations.worker_quantity,
    //      this.translations.emergency
//
    //    ];
//
    //  } else {
    //    this.lista_titulos = [
    //      this.translations.code,
    //      this.translations.requested_by,
    //      this.translations.level,
    //      this.translations.date_init,
    //      this.translations.date_end,
    //      this.translations.worker_quantity,
    //      this.translations.emergency
//
    //    ];
//
    //  }
//
    //} else if (this.option_processes == 3) {
    //  nombreXLSX = "Trabajadores de los contratistas registrados.xlsx";
    //  if (this.isSkretting == true) {
    //    this.lista_titulos = [
    //      this.translations.code,
    //      this.translations.requested_by,
    //      this.translations.company,
    //      this.translations.level,
    //      this.translations.date_init,
    //      this.translations.date_end,
    //      this.translations.worker_quantity,
    //      this.translations.work_status,
//
    //    ];
//
    //  } else {
    //    this.lista_titulos = [
    //      this.translations.code,
    //      this.translations.requested_by,
    //      this.translations.level,
    //      this.translations.date_init,
    //      this.translations.date_end,
    //      this.translations.worker_quantity,
    //      this.translations.work_status,
//
    //    ];
//
    //  }
//
    //}
    this.ContainsExcel();
    this.workbook.creator = "Comex";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      fs.saveAs(blob, nombreXLSX);
    })
  }


  private ContainsExcel() {
    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 8;
    sheet.getColumn("B").width = 20;
    sheet.getColumn("C").width = 20;
    sheet.getColumn("D").width = 20;
    sheet.getColumn("E").width = 20;
    sheet.getColumn("F").width = 20;
    sheet.getColumn("G").width = 20;
    sheet.getColumn("H").width = 20;
    sheet.getColumn("I").width = 20;
    sheet.getColumn("J").width = 20;
    sheet.getColumn("K").width = 20;
    sheet.getColumn("L").width = 20;
    sheet.getColumn("M").width = 20;
    sheet.getColumn("N").width = 20;
    sheet.getColumn("O").width = 20;
    sheet.getColumn("P").width = 20;
    sheet.getColumn("Q").width = 20;
    sheet.getColumn("R").width = 20;
    sheet.getColumn("S").width = 20;
    sheet.getColumn("T").width = 20;
    sheet.getColumn("U").width = 20;
    sheet.getColumn("V").width = 20;
    sheet.getColumn("W").width = 20;
    sheet.getColumn("X").width = 20;
    sheet.getColumn("Y").width = 20;
    sheet.getColumn("Z").width = 20;
    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
      column.alignment = { vertical: 'middle', wrapText: true }
    });



    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    // console.log("Lista de titulos:", this.lista_titulos);
    headerRow.values = this.lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12, color:{ argb: 'FFFFFF' }};
    //HeaderRow.Fill=BackgroundColor
    headerRow.fill={
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'003d6a'},
      bgColor:{argb:'F38230'}
  }
    // agregar los datos a las filas
    const rowsToInsert = sheet.getRows(2, this.rows.length);

    for (let index = 0; index < rowsToInsert.length; index++) {
      const itemData = this.rows[index]; //obtenemos el item segun el index de la iteraciòn(recorrido)
      const row = rowsToInsert[index]; //obtenemos la primera fila segun el index de la iteraciòn(recorrido)
      // los valores de itemData seran asignados a row (fila actual en la iteraciòn)

      //if (this.option_processes == 1) {

        if (this.isSkretting == true) {
          row.values = [

            itemData.RequestId,
            itemData.WhoRequest,
            itemData.namecompany,
            //itemData.plantaname==null?"No cuenta con Planta":itemData.plantaname,
            itemData.CreationTime == null ? "" : itemData.CreationTime.split('T')[0].split('T')[0],
            itemData.listRequestdetail.length,
            itemData.contadorEvaluations,
            //itemData.EmployeeId,
            //itemData.NumberIdentification,
            //itemData.EmployeeName,
            //itemData.Name,
            //itemData.NameDoc,
            //itemData.status_name

          ];

        } else {
          row.values = [
            itemData.RequestId,
            itemData.WhoRequest,
           // itemData.plantaname==null?"No cuenta con Planta":itemData.plantaname,
            itemData.CreationTime == null ? "" : itemData.CreationTime.split('T')[0].split('T')[0],
            itemData.listRequestdetail.length,
            itemData.contadorEvaluations,
            //itemData.EmployeeId,
            //itemData.NumberIdentification,
            //itemData.EmployeeName,
            //itemData.Name,
            //itemData.NameDoc,
            //itemData.status_name
          ];

        }
      //} else if (this.option_processes == 2) {
//
      //  if (this.isSkretting == true) {
      //    row.values = [
//
//
      //      itemData.EntryRequestId,
      //      itemData.Requiredby,
      //      itemData.CompanyName,
      //      itemData.PlantaName,
      //      itemData.StartDate == null ? "" : itemData.StartDate.split('T')[0],
      //      itemData.EndDte.split('T')[0],
      //      itemData.RequestDetails.length,
      //      itemData.Emergency
//
//
      //    ];
//
      //  } else {
      //    row.values = [
      //      itemData.EntryRequestId,
      //      itemData.Requiredby,
      //      itemData.PlantaName,
      //      itemData.StartDate == null ? "" : itemData.StartDate.split('T')[0],
      //      itemData.EndDte.split('T')[0],
      //      itemData.RequestDetails.length,
      //      itemData.Emergency
//
      //    ];
//
      //  }
//
      //} else if (this.option_processes == 3) {
//
      //  if (this.isSkretting == true) {
      //    row.values = [
//
//
      //      itemData.WorkAuthId,
      //      itemData.Requiredby,
      //      itemData.Name,
      //      itemData.PlantaName,
      //      itemData.Wa_DateBegin == null ? "" : itemData.Wa_DateBegin.split('T')[0],
      //      itemData.Wa_DaTeend == null ? "" : itemData.Wa_DaTeend.split('T')[0],
      //      itemData.QuantityWorker,
      //      itemData.CloseWorkerAuth == 'C' ? 'Cerrado' : 'Abierto',
//
//
      //    ];
//
      //  } else {
      //    row.values = [
      //      itemData.WorkAuthId,
      //      itemData.Requiredby,
      //      itemData.PlantaName,
      //      itemData.Wa_DateBegin == null ? "" : itemData.Wa_DateBegin.split('T')[0],
      //      itemData.Wa_DaTeend == null ? "" : itemData.Wa_DaTeend.split('T')[0],
      //      itemData.QuantityWorker,
      //      itemData.CloseWorkerAuth == 'C' ? 'Cerrado' : 'Abierto',
//
      //    ];
//
      //  }
//
      //}
    }
  }
}
