import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSULTAJOBTYPE = environment.Global.API_CONSULTAJOBTYPE;
const API_REGISTROJOBTYPE = environment.Global.API_REGISTROJOBTYPE;
const API_UPDATEJOBTYPE = environment.Global.API_UPDATEJOBTYPE;
const API_DELETEJOBTYPE = environment.Global.API_DELETEJOBTYPE;
const API_ACTIVARJOBTYPE = environment.Global.API_ACTIVARJOBTYPE;
const API_DROPJOBTYPE = environment.Global.API_DROPJOBTYPE;

@Component({
  selector: 'app-paramjobtype',
  templateUrl: './paramjobtype.component.html',
  styleUrls: ['./paramjobtype.component.css']
})
export class ParamjobtypeComponent extends BaseCrudComponent  {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  documentJobTypes = [
    // { id: 1, name: 'Tipo 1' },
    // { id: 2, name: 'Tipo 2' },
    // más tipos...
  ];
  selectedDocumentJobTypes = [];
  constructor(
    srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSULTAJOBTYPE;
        this.create_url = API_REGISTROJOBTYPE;
        this.update_url = API_UPDATEJOBTYPE;
        this.delete_url = API_DELETEJOBTYPE;
        this.active_url = API_ACTIVARJOBTYPE;
        this.search_fields = ['NameJob', 'Description'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      //debugger;
      this.loadingList = false;

      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.closeLoadingMessage();
        }else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
  });
  }

  public openCreateModal(modal) {
    this.c_obj = {};
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
  }

  public createItem(content:any, form:any){
    var validated = true;

    if( form == undefined ){
      form = {};
    }

    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }

    var to:any;
    form.CreatorUser = this.userinfo['logon'];
    form.LastModifierUser = this.userinfo['logon'];
    to = form;

    var qst = this.create_url;
    this.isCreating = true;
    this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        if(x.success){
            this.getList();
            this.closeModal(content);
            Swal.fire(this.translations.msg_create, x.msg, 'success');
        }//end success
        else{
            Swal.fire(x.msg, '', 'error');
        }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'NameJob': new FormControl(fields.NameJob, [Validators.required]),
        'Description': new FormControl(fields.Description, [])
    });

    return mygroup.valid;
  }

  public updateItem(content:any, form:any){

    this.isCreating = true;
    var qst = this.update_url;
    if( form == undefined ){
      form = {};
    }

    var to:any;

    if( (form.Name == undefined) || (form.Name == '') ){
        Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
        return;
    }

    to = {};
    to.NameJob = form.Name;
    to.Description = form.Description;
    to.LastModifierUser = this.userinfo['logon'];
    to.JobTypeID = form.JobTypeID;
    to.DocumentJobType = this.selectedDocumentJobTypes;debugger;

    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update1');
        Swal.fire('¡Actualizado!', x.msg, 'success');
        this.getList();

      }
  }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }

  toggleEditable(event, id) {
    if ( event.target.checked ) {
      this.Activar(id);
  }else {
    this.deleteItem(id);

  }
  }

  deleteItem(obj: any){
    var id:any;
    id = {};
    id.JobTypeID = obj;
    var qst = this.delete_url;
    this.isCreating = true;

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire('Desactivado!', x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
  }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  Activar(obj: any){
    var id:any;
    id = {};
    id.JobTypeID = obj;
    var qst = this.active_url;
    this.isCreating = true;

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire('¡Activado!', x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
  }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }
  public DropItem(id:any){
    var id:any;
    id = {};
    id.JobTypeID = id;
    var qst = API_DROPJOBTYPE;
    this.isCreating = true;

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire('Desactivado!', x.msg, 'success');
        this.getList();
      }
      else{
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }
  public openUpdateModal(content: any, obj: any, size?:any) {
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.documentJobTypes = newobj.DocumentJobTypes;
    this.selectedDocumentJobTypes = newobj.DocumentJobTypes.filter(d => d.IsSelected).map(d => d.docuTypeID);
    if( size == undefined ){
      this.modalService.open(content, { centered: true });
    }
    else{
      this.modalService.open(content, { centered: true, size: size });
    }
  }
}
