import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-permissions-by-user',
  templateUrl: './permissions-by-user.component.html',
  styleUrls: ['./permissions-by-user.component.css']
})
export class PermissionsByUserComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }
  

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;  
      this.getList();
    });
  }
}
