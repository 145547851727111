
<div class="row">
    <div class="col-md-4">
        <div class="form-group idUser">
            <label for="idUser">C&eacute;dula del Usuario</label>
            <input type="text" maxlength="10" minlength="1" class="form-control" id="idUser" 
            [(ngModel)]="idUser"
            placeholder="Ingrese la cédula" (keyup.enter)="setUser()" (keypress)="onlyNumberKey($event)">
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group nameUser">
            <label for="user">Usuario</label>
            <input type="user" class="form-control" id="exampleInputEmail1" disabled  [(ngModel)]="user">
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group evals">
            <label for="user">Evaluaciones</label>
            <select class="custom-select selectc" [(ngModel)]="evaluation"  (change)="evalSelected()">
                <option [ngValue]="null" disabled selected> Elija las evaluaciones </option>
                <option [value]="usuario.user" *ngFor="let usuario of usuarios">{{usuario.user}}</option>
            </select>
        </div>
    </div>
</div>

