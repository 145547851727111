import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { environment } from 'src/environments/environment';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2';
import { isNumeric } from 'rxjs/util/isNumeric';
//import { isNull } from 'util';

import { Cell, Columns, Img, Item, Line, PageReference, PdfMakeWrapper, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import { List } from 'pdfmake-wrapper/lib/definitions/list/list';
import { element } from 'protractor';
import {blobToB64} from "../../docs/approvedocumentsbase/approvedocumentsbase.component";

PdfMakeWrapper.setFonts(pdfFonts);
const GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const GET_ALL_ENTRY_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const GET_ALL_REQUEST_INDUCTION = environment.Global.API_GET_ALL_REQUEST_INDUCTION;
const GET_ALL_REQUEST_INDUCTION_BY_ID = environment.Global.API_GET_ALL_REQUEST_INDUCTION_BY_ID;
const GET_WORK_AUTH_BY_ID = environment.Global.API_GET_WORK_AUTH_BY_ID;
const GET_ALL_WORK_AUTHS_FOR_ADMIN = environment.Global.API_GET_ALL_WORK_AUTHS_FOR_ADMIN;
const GET_DOCUMENTS_BY_ID = environment.Global.API_GET_DOCUMENTS_BY_ID;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const GET_DOCUMENTS_ALL_PARAM = environment.Global.API_GET_DOCUMENTS_ALL;
const GET_DOCUMENTS_ALL_BY_COMPANYID = environment.Global.API_GET_DOCUMENTS_ALL_BY_COMPANYID;
const API_GET_REPORTS_EMPLOYEES_BY_COMPANY = environment.Global.API_GET_REPORTS_EMPLOYEES_BY_COMPANY;
const API_GET_REPORTS_VEHICLES_BY_COMPANY = environment.Global.API_REPORTS_VEHICLE_COMPANY;
const API_GET_DOCUMENTS_BY_ID_RPT= environment.Global.API_GET_DOCUMENTS_BY_ID_RPT;
const API_GET_FILE_COMPANY_ID = environment.Global.API_GET_FILE_COMPANY_ID;

@Component({
  selector: 'app-rptcompany',
  templateUrl: './rptcompany.component.html',
  styleUrls: ['./rptcompany.component.css']
})
export class RptcompanyComponent extends BaseCrudComponent implements OnInit {
  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = true;
  isSkretting = false;
  isClean = false;
  pageSize = 10;
  userInfo;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  private workbook: Workbook;
  mostrar:number=-1;
  isPDF = true;
  isImage = false;
  pdfSrc = "";
  row2: any = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);
    this.search_fields = ['Name', 'NumberIdentification', 'EmployeeName'];
  }

  async ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
      });
    await this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(this.userInfo)
      if (isNumeric(this.userInfo.logon)) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });
    this.getCompanies();
  }


  dataToFind() {
    let url = '';
    this.isFind = true;
    console.log(this.options_company)
    console.log(this.options_ruc)

    if (!this.validateSelectedCompany()) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }

    if (isNumeric(this.userInfo.logon)) {
      this.isSkretting = false;
      url = API_GET_DOCUMENTS_BY_ID_RPT + this.userInfo.selectedCompanyId;
    } else {
      this.isSkretting = true;
      let id = this.options_company;
      id ??= this.options_ruc;
      url = API_GET_DOCUMENTS_BY_ID_RPT + id;
    }
    this.isFind = false;
    this.getListFromApi(url);
  }

  validateSelectedCompany(): boolean {
    if (this.options_company === null && this.options_ruc === null) {
      return false;
    }
    return true;
  }

  getListFromApi(url) {
    this.isFind = true;
    let list_tmp = [];
    // console.log(url);
    this.srv.getCall(url).subscribe(x => {
      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          let new_list = [];
          list_tmp = x.val;
          if ((this.options_ruc !== -1 || this.options_company !== -1)) {

          } else if ((this.options_ruc !== -1 || this.options_company !== -1)) {
            if (this.isSkretting) {
              if ((this.options_ruc !== null || this.options_company !== null)) {
                list_tmp.forEach(tmp => {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID == this.options_company) {
                    new_list.push(tmp);
                  }
                })
              }
              if (new_list.length === 0) {
                this.rawdata = list_tmp;
                this.rows = list_tmp;
              } else {
                this.rawdata = new_list;
                this.rows = new_list;
              }
            } else {
              this.rawdata = list_tmp;
              this.rows = list_tmp;
            }
          }
         // this.rows=list_tmp;
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      } else {
        this.isFind = false;
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  async downloadReport() {
    // console.log(this.rows)

    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    let listInReport = this.rows;
    let mapaTitles = new Map();
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];
    // console.log(...this.rows);
    console.log(listInReport)
    // debugger
    listInReport.forEach(element => {
      var listaDocumentos="";
      element._DocumentsEmployees.forEach(documentos => {
        let documento=documentos.NameDoc;
        let status=documentos.Status
        listaDocumentos+=documento+"("+status+"); "
      });
      var cursos="";
      element._ListsCourses.forEach(estado=>
      {
        cursos+=estado+"; "
      }
      );
      if(cursos==""){
        cursos="No cuenta con cursos realizados"
      }
       //console.log(element)
      let obj = [];
      if (this.isSkretting) {
        obj = [
          element.EmployeeId,
          element.NumberIdentification,
          element.Name,
          // element.Birth_date != null ? element.Birth_date.split('T')[0] : "",
          element.ruccompany,
          element.namecompany,
          element.TypeCompany == 1 ? "Contratista" : "Transportista",
          element.Email,
          element.address,
          element.position,
          element.telephone,
          // element.telephone2,
          // element.nationality,
          element.Status == 'A' ? "Activo" : "Inactivo",
          cursos,
          listaDocumentos
        ]
        // console.log(obj)
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', "auto"
        ]
      } else {
        obj = [
          element.EmployeeId,
          element.NumberIdentification,
          element.Name,
         // element.Birth_date != null ? element.Birth_date.split('T')[0] : "",
          element.Email,
          element.address,
          element.position,
          element.telephone,
          // element.telephone2,
          // element.nationality,
          element.Status == 'A' ? "Activo" : "Inactivo",
          cursos,
          listaDocumentos
        ]
        lista_widths = [
          'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto',  "auto"
        ]

      }

      if (this.isSkretting) {
        lista_titulos = [
          new Cell(new Txt(this.translations.code).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.identification).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.worker_name).margin([0, 5, 0, 5]).color('white').bold().end).end,
          // new Cell(new Txt(this.translations.birth_date).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ruc).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.company).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.company_type).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.email).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.address).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.position).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.phone).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.phone2).margin([0, 5, 0, 5]).color('white').bold().end).end,
           new Cell(new Txt(this.translations.nationality).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.status).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.course).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.menu_docs).margin([0, 5, 0, 5]).color('white').bold().end).end
        ]
      } else {
        lista_titulos = [
          new Cell(new Txt(this.translations.code).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.identification).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.worker_name).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.birth_date).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.ruc).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.company).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.company_type).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.email).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.address).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.position).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.phone).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.phone2).margin([0, 5, 0, 5]).color('white').bold().end).end,
          //new Cell(new Txt(this.translations.nationality).margin([0, 5, 0, 5]).color('white').bold().end).end,
          new Cell(new Txt(this.translations.status).margin([0, 5, 0, 5]).color('white').bold().end).end
        ]
      }

      nueva_lista_reportes.push(obj);

      if (!mapaTitles.has(element.NameDoc)) {
        mapaTitles.set(element.NameDoc, element.NameDoc);
      }

    });




    const pdf = new PdfMakeWrapper();
    // INFORMATION IN TITLE NAV
    let title = 'Reporte de trabajadores de los contratistas registrados'

    pdf.info({
      // title: `${this.translations.msg_doc_Ext}`,
      // title: `${title}`,
      title: `${title}`,
      author: `${this.userInfo.name}`,
      subject: 'Listado',
    });
    //OPTIONS

    var fontSize: 0;
    fontSize: 10
    fontSize: 8

    pdf.defaultStyle({
      bold: false,
      fontSize: fontSize,
      alignment: 'justify'
    });
    pdf.pageMargins([40, 110, 40, 75]);
    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/duragas_logo.svg').width('160').build()).end,
          ]
        ]
      )
        .margin([0, 30, 30, 0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );
    pdf.pageOrientation('landscape');
    pdf.add(
      [
        new Txt(title).fontSize(14).bold().margin([0, 0, 0, 20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.requested_by + ': ').bold().end, this.userInfo.name]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.user + ': ').bold().end, this.userInfo.logon]).fontSize(10).alignment('left').margin([0, 0, 0, 20]).end,
        new Table(
          [
            lista_titulos,
            ...nueva_lista_reportes
          ]
        ).layout({
          fillColor: (rowIndex: number, node: any, columnIndex: number) => {
            return rowIndex === 0 ? '#003d6a' : '';
          },
        })
          .headerRows(1)
          .dontBreakRows(true)
          .fontSize(8)
          .widths(lista_widths)
          .alignment('center')
          .end
      ]
    );
    pdf.footer(function (currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Duragas Ecuador | Km 7.5 vía a la costa, sector El Salitral Guayaquil - Ecuador | Telf: 1700387242').fontSize(8).color('gray').alignment('center').margin([0, 10, 0, 0]).end).end,
          new Cell(new Txt('www.duragas.com.ec').link('https://www.duragas.com.ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40, 20, 0]).end).end
        ]
      ).end
    });



    pdf.create().download(title);
    Swal.close();
  }


  clearFilters() {
    this.options_company = null;
    this.options_ruc = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.pageTotal = 0;
  }

  rucSelected() {
    if (this.options_ruc == -1 || this.options_ruc == null) {
      this.disabledCompany = false;
    } else {
      this.disabledCompany = true;
    }
  }

  companySelected() {
    if (this.options_company == -1) {
      this.disabledRuc = false;
    } else {
      this.disabledRuc = true;
    }
  }

  selectRequest() {
    this.request_selected = true;
    this.rows = [];
    this.page = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
  }

  getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => {
      if (data.success) {
        if (data.val !== null) {
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push({ id: element.CompanyID, name: element.Name });
            this.ruc_list.push({ id: element.CompanyID, ruc: element.Ruc });
          })
        }
      }
    })


  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}` : (date.getMonth() + 1).toString())
      + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}` : date.getDate().toString()) + ' ' +
      (date.getHours() <= 9 ? `0${date.getHours().toString()}` : date.getHours().toString()) + ':' +
      (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}` : date.getMinutes().toString()) + ':' +
      (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}` : date.getSeconds().toString());
  }
  openViewModal(modal, object){
    const DocFileName = object.ParamDocumentID;
    this.row2 = object;

    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_COMPANY_ID, object.CompanyID, DocFileName)
      .subscribe({
        next: async (blob) => {
          if (blob) {
            var extension = blob.type;
            if (extension == 'application/pdf') {
              this.isPDF = true;
              this.isImage = false;
            } else if (
              extension == 'image/jpeg' ||
              extension == 'image/jpg' ||
              extension == 'image/png'
            ) {
              this.isPDF = false;
              this.isImage = true;
            } else {
              this.messageDialog('Formato de archivo incorrecto!', '', 'error');
              return;
            }
            this.pdfSrc = await blobToB64(blob);
            this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
          } else {
            Swal.fire(
              'No se pudo obtener documento',
              'Contacte con el administrador',
              'error'
            );
          }
        },
        error: (error) => {
          Swal.fire(
            'No se pudo obtener documento',
            'Contacte con el administrador',
            'error'
          );
        }
      });
  }
}
