<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <!-- <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('ActionPlanId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='ActionPlanId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('PlantaName')">{{'level' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PlantaName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'cmp_area' | translate}}</th>
                                <th>{{'date_ha' | translate}}</th>
                                <th>{{'justification' | translate}}</th>
                                <th>{{'no_actions' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.ActionPlanId}}</td>
                                <td >{{row.PlantaName}}</td>
                                <td >{{row.SeccionName}}</td>
                                <td >{{row.FindingDate.split('T')[0]}}</td>
                                <td >{{row.Justification}}</td>
                                <td >{{row.ActionPlanDetails.length}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="fas fa-edit text-info  m-r-10" title="{{'edit' | translate}}"></i> 
                                    <i (click)="openCloseModal(close1, row)" style="cursor:pointer;" class="fas fa-check text-success m-r-10" title="{{'close_plan' | translate}}"></i> 
                                    <i (click)="deletePlan(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #create1 let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4><strong>{{'HSE'}}</strong></h4>
        <hr>
        <h4>{{'header' | translate}}</h4>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label for="cmp_area"> {{'cmp_area' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="c_obj['cmp_area']">
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="cmp_area"> {{'date' | translate}}</label>
                <input type="date"  class="form-control" name="area" [(ngModel)]="c_obj['date']">
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="cmp_area"> {{'number_person' | translate}}</label>
                <input type="number"  class="form-control" name="area" [(ngModel)]="c_obj['number_person']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label for="cmp_area"> {{'auditor' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="c_obj['auditor']">
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="cmp_area"> {{'responsable_area' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="c_obj['responsable_area']">
            </div>
        </div>          
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>