import { Component } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';

const API_CONSULTAVEHICLE = environment.Global.API_CONSULTAVEHICLE;
const API_DESACTIVEVEHICLE = environment.Global.API_DESACTIVEVEHICLE;
const API_ACTIVEVEHICLE = environment.Global.API_ACTIVEVEHICLE;
const API_CREATEVEHICLE = environment.Global.API_CREATEVEHICLE;
const API_CONSULTALL  = environment.Global.API_CONSULTALL;
const API_UPDATEVEHICLE = environment.Global.API_UPDATEVEHICLE;
const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID;
const API_UPDATE_STATUS_VEHICLE = environment.Global.API_UPDATE_STATUS_VEHICLE;

environment.ConfigVehicle.tipo

@Component({
  selector: 'app-reg-vehicle-contra',
  templateUrl: './reg-vehicle-contra.component.html',
  styleUrls: ['./reg-vehicle-contra.component.css']
})
export class RegVehicleContraComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  jobtypeID:any;
  mostarselectemp = true;
  date;
  CompanyID;
  seleccionados=[];
  SubContratista = "SubContratista";
  curr_cur:any;
  listcomp:any;
  ruta:any;
  brands = null;
  models = null;
  type = null;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSULTAVEHICLE;
        this.delete_url = API_DESACTIVEVEHICLE;
        this.active_url = API_ACTIVEVEHICLE;
        this.create_url = API_CREATEVEHICLE;
        this.update_url = API_UPDATEVEHICLE;

        this.search_fields = ['VehicleID','CarRegistration'];

        this.c_obj['SubCompany'] = [];
   }

  async ngOnInit() {
    this.date = new Date();
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
       if(this.userinfo.ProfileID == 1) {
        this.ruta = API_CONSULTALL;
      }else if(this.userinfo.ProfileID == 2 || this.userinfo.ProfileID == 21){ //contratista o transportista
        this.ruta = this.read_url + this.userinfo['selectedCompanyId'];
      }
    });
    this.getList();
  }


  public getList() {
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(this.ruta).subscribe(x => {
      console.log(x);
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg,'','success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg,'','success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.getBrands();
    this.getModels();
    this.getType();
  }

  public getBrands() {
    this.srv.getCall(API_GET_SEVERITY_TYPE_BY_ID + "BRAND_VEHICLES").subscribe(x => {
      if (x.success) {
        if (x.val != null) {
          this.brands = x.val;
        }
      }
    })
  }
  public getModels() {
    this.srv.getCall(API_GET_SEVERITY_TYPE_BY_ID + "MODEL_VEHICLES").subscribe(x => {
      if (x.success) {
        if (x.val != null) {
          this.models = x.val;
        }
      }
    })
  }

  public getType() {
    this.srv.getCall(API_GET_SEVERITY_TYPE_BY_ID + "TYPE_VEHICLES").subscribe(x => {
      if (x.success) {
        if (x.val != null) {
          this.type = x.val;
        }
      }
    })
  }

  public createItem(content:any, form:any){
    var validated = true;
    if( form == undefined ){
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
    var to:any;
    form.CompanyID = this.userinfo.selectedCompanyId;
    form.CreatorUser = this.userinfo['logon'];
    to = form;
    var qst = this.create_url;
    this.isCreating = true;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
        this.confirmDialogWithModal(x.msg, '' , 'success',content);
      }
      else{
        this.confirmDialogWithModal(x.msg, '' , 'warning',content);
      }
    });
  }


  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
      'RegistrationNumber': new FormControl(fields.RegistrationNumber, [Validators.required]),
      'MarcaId': new FormControl(fields.MarcaId, [Validators.required]),
      'Model': new FormControl(fields.Model, [Validators.required]),
      'VehicleTypeId': new FormControl(fields.VehicleTypeId, [Validators.required]),
      'Color': new FormControl(fields.Color, [Validators.required]),
    });
    return mygroup.valid;
  }

  public openUpdateModal(content: any, obj: any) {
    this.isUpdating = false;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.modalService.open(content, { backdrop: 'static', keyboard: false ,windowClass:"myCustomModalClass"});
  }


  public updateItem(content:any, form:any){
    this.isUpdating = true;
    var qst = this.update_url;
    if( form == undefined ){
      form = {};
    }
    var to:any;
    var validated = true;
    validated = this.validateCreateInfo(form);
    if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    to = {};
    to.CompanyID = this.userinfo.selectedCompanyId;
    to.VehicleID = form.VehicleID;
    to.RegistrationNumber = form.RegistrationNumber;
    to.MarcaId = form.MarcaId;
    to.Color = form.Color;
    to.VehicleTypeId = form.VehicleTypeId;
    to.LastModifierUser = this.userinfo['logon'];
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        this.confirmDialogWithModal(x.msg, '' , 'success',content);

      } else {
        this.confirmDialogWithModal(x.msg, '' , 'warning',content);
      }
    }, err => {
      this.confirmDialogWithModal(err, '' , 'error',content);
    });
  }


  public updateStatus(id, status) {
    let to = {
      VehicleID : id,
      Status: status
    }
    this.srv.postCall(API_UPDATE_STATUS_VEHICLE, to).subscribe(x => {
      if ( x.success ) {
        this.confirmDialog(x.msg,'','success');
      } else {
        this.confirmDialog(x.msg,'','error');
      }
    })
  }


  public toggleEditable(event, id) {
    if ( event.target.checked ) {
      this.updateStatus(id, 1);
    }else {
      this.updateStatus(id, 2);
    }
  }

  public onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  public openCreateModal(content: any) {
    this.isCreating = false;
    this.c_obj = {};
    this.modalService.open(content, { backdrop: 'static', keyboard: false ,windowClass:"myCustomModalClass"});
  }



}

