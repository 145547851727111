import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import { PermissionService } from 'src/app/services/authentication/permission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prochome',
  templateUrl: './prochome.component.html',
  styleUrls: ['./prochome.component.css']
})
export class ProchomeComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any = [];
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService,
      public permission: PermissionService
  	) { }

  	ngOnInit() { 
      let id = environment.Global.Menus.Procesos// menu procesos
      this.permission.getModulesByMenuId(id).then(x => {
        x.forEach(element => {
          if (element.principal == 1) {
            this.items.push(element)
          }
        });
      })
      
      this.auth.getPermissions().then(x => {
          this.permissions = x;
      });
  	}

    hasPermission(str:string){
      let flag = false;
      this.permissions.forEach(permission => {
        if(permission.Permission == str) {
          flag = true;
        }
      })
      return flag;
    }

}
