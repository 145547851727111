<!-- <ng-select [(ngModel)]="selectedCar" (change)="imprimiro()" placeholder="seleccione">
    <ng-option *ngFor="let car of cars" [value]="car.id">{{car.name}}</ng-option>
 </ng-select> -->
 
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('UnsafeActId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='UnsafeActId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('Name')">{{'worker_name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'company' | translate}}</th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'requiredby' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.UnsafeActId}}</td>
                                <td >{{row.Name}}</td>
                                <td >{{row.CompanyName}}</td>
                                <td >{{row.Date.split('T')[0]}}</td>
                                <td >{{row.Requiredby}}</td>
                                <td >{{row.StatusInsp == '1' ? 'Cerrada' : 'Abierta'}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i *ngIf="row.StatusInsp == 2" (click)="gotoPlan(row.UnsafeActId)" style="cursor:pointer;" class="fas fa-file text-warning m-r-10" title="{{'go_to_action_plan' | translate}}"></i> 
                                    <i (click)="edit(update1, row)" style="cursor:pointer;" class="fas fa-eye text-info m-r-10" title="{{'View' | translate}}"></i> 
                                    <i (click)="deleteInsp(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>                                   
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <h4>{{'select_type_worker' | translate}}</h4>
            </div>
            <div class="col-md-6 ha-ibox" *ngIf="workerId == 2">
                <h4>{{'select_work_perm' | translate}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <select class="custom-select custom-select-md" name="worker_perm" [(ngModel)]="workerId" (change)="setTypeWorker()">
                    <option *ngFor="let wks of list_workers" [value]="wks.id |json">{{wks.type}}</option>
                </select>
            </div>
            <div class="col-md-6"  *ngIf="workerId == 2">
                <!-- <select class="custom-select custom-select-md" name="worker_perm" [(ngModel)]="c_obj['worker_perm']" (change)="setDataInForm()">
                    <option [value]="null" selected disabled>{{'select' | translate}}</option>
                    <option [value]="0" >{{'any' | translate}}</option>
                    <option *ngFor="let pw of list_work_perm" [value]="pw |json">{{pw.WorkAuthId}}</option>
                </select> -->
                <ng-select [(ngModel)]="c_obj['worker_perm']" (change)="setDataInForm()" placeholder="{{'select' | translate}}">
                    <ng-option [value]="'0'" >{{'any' | translate}}</ng-option>
                    <ng-option *ngFor="let pw of list_work_perm" [value]="pw |json">{{pw.WorkAuthId}}</ng-option>
                 </ng-select>
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="list_employees_worker.length > 0">
            <div class="col-md-12">
                <h4>{{'worker_names' | translate}}</h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white text-center" #thead>
                            <tr>
                                <th> {{'ci' | translate}}</th>
                                <th> {{'worker_name' | translate}}</th>
                                <th> {{'position' | translate}}</th>
                                <th> {{'job_to_do' | translate}} </th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr *ngFor="let emp of list_employees_worker | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td>{{emp.cod}}</td>
                                <td>{{emp.name}}</td>
                                <td>{{emp.position}}</td>
                                <td>{{emp.typeJob}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>     
        </div> 
        <h4>{{'data_details_request'| translate}}</h4>
        <div class="col-md-12 ha-ibox">
            <h6>**{{'detail_employees'| translate}}</h6>
        </div>
        <div *ngIf="workerId == 1">
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'identification' | translate}} *</label>
                    <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (keypress)="onlyNumberKey($event)" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)">
                </div>                
                <div class="col-md-6 ha-ibox">
                    <label>{{'name' | translate}}</label>
                    <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']" readonly>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'date'|translate}}</label>
                    <input type="text"  class="form-control" name="dateActInsecure" [(ngModel)]="c_obj['dateActInsecure']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'code' | translate}} *</label>
                    <input type="text" maxlength="13"  class="form-control" name="code" [(ngModel)]="c_obj['code']" (keypress)="onlyNumberKey($event)" readonly>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'jobtitle' | translate}}</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="level" > {{'level' | translate}}</label>
                    <input type="text"  class="form-control" name="level" [(ngModel)]="c_obj['level']" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'cmp_area'|translate}}</label>
                    <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']"  title="{{'autocomplete'| translate}}" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'year_old' | translate}}</label>
                    <input type="text" maxlength="3"  class="form-control" name="years" [(ngModel)]="c_obj['years']" (keypress)="onlyNumberKey($event)" readonly >
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'boss_inme'|translate}}</label>
                    <input type="text"  class="form-control" name="boss_inme" [(ngModel)]="c_obj['boss_inme']">
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'mail_boss' | translate}}</label>
                    <input type="email"  class="form-control" name="mail_boss" [(ngModel)]="c_obj['mail_boss']" >
                </div>
            </div>
            <hr>
            <h4>{{'should_register'| translate}}</h4>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'report_by' | translate}}*</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['report_by']"  title="{{'autocomplete'| translate}}" readonly >
                    
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="fault_type">{{'fault_type' | translate}} *</label>
                    <!-- <select class="custom-select custom-select-md" name="fault_type" [(ngModel)]="c_obj['fault_type']"  (change)="getFouls(c_obj['fault_type'])">
                        <option [value]="null">{{'Select_fault' | translate}}</option>
                        <option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</option>
                    </select>  -->
                    <ng-select [(ngModel)]="c_obj['fault_type']" (change)="getFouls(c_obj['fault_type'])" placeholder="{{'Select_fault' | translate}}">
                        <ng-option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label for="job_area"> {{'fault_associated' | translate}} *</label>
                    <!-- <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['fault_associated']">
                        <option [value]="null">{{'Select_fault_detail' | translate}}</option>
                        <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</option>
                    </select> -->
                    <ng-select [(ngModel)]="c_obj['fault_associated']" placeholder="{{'Select_fault_detail' | translate}}">
                        <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</option>
                    </ng-select>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="type_sanction"> {{'sanction' | translate}} *</label>
                    <!-- <select class="custom-select custom-select-md" name="type_sanction" [(ngModel)]="c_obj['type_sanction']">
                        <option [value]="null">{{'Select_type_sanction' | translate}}</option>
                        <option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</option>
                    </select> -->
                    <ng-select [(ngModel)]="c_obj['type_sanction']" placeholder="{{'Select_type_sanction' | translate}}">
                        <ng-option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</ng-option>
                    </ng-select> 
                </div>
            </div>
        </div>
        <div *ngIf="workerId == 2">
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'identification' | translate}} *</label>
                    <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (keypress)="onlyNumberKey($event)" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)">
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'name' | translate}}</label>
                    <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']" [disabled]="enabled">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'date'|translate}}</label>
                    <input type="text"  class="form-control" name="dateActInsecure" [(ngModel)]="c_obj['dateActInsecure']" [disabled]="enabled">
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'jobtitle' | translate}}</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']" [disabled]="enabled">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'menu_mycompanies' | translate}}</label>
                    <input type="text"  class="form-control" name="company" [(ngModel)]="c_obj['company']" [disabled]="enabled">
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'subcompany'|translate}}</label>
                    <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']" [disabled]="enabled">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox" *ngIf="hasWork">
                    <label for="level"> {{'level' | translate}}</label>
                    <input type="text"  class="form-control" name="level" [(ngModel)]="c_obj['level']" [disabled]="enabled">
                </div>
                <div class="col-md-6 ha-ibox" *ngIf="!hasWork">
                    <label for="level" > {{'level' | translate}}</label>
                    <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="searchArea()">
                        <option [value]="null">{{'Select-level' | translate}}</option>
                        <option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</option>
                    </select> -->
                    <ng-select [(ngModel)]="c_obj['level']" (change)="searchArea()" placeholder="{{'Select-level' | translate}}">
                        <ng-option *ngFor="let rw of row_plantas" [value]="rw.PlantaID">{{rw.Name}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'perm_number' | translate}}</label>
                    <input type="text"  class="form-control" name="perm_number" [(ngModel)]="c_obj['perm_number']" [disabled]="enabled">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'user_sol'|translate}}</label>
                    <input type="text"  class="form-control" name="user_sol" [(ngModel)]="c_obj['user_sol']">
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'email_sol' | translate}}</label>
                    <input type="text"  class="form-control" name="email_sol" [(ngModel)]="c_obj['email_sol']" >
                </div>
            </div>
            <hr>
            <h4>{{'should_register'| translate}}</h4>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'cmp_area'|translate}}*</label>
                    <!-- <select class="custom-select custom-select-md" name="cmp_area" [(ngModel)]="c_obj['cmp_area']">
                        <option [value]="null">{{'Select_position' | translate}}</option>
                        <option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</option>
                    </select>  -->
                    <ng-select [(ngModel)]="c_obj['cmp_area']" placeholder="{{'Select_position' | translate}}">
                        <ng-option *ngFor="let rw of row_plantas_area" [value]="rw.SeccionID">{{rw.NameSeccion}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'report_by' | translate}}*</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['report_by']"  title="{{'autocomplete'| translate}}" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12 ha-ibox">
                            <label for="fault_type" > {{'fault_type' | translate}} *</label>
                            <!-- <select class="custom-select custom-select-md" name="fault_type" [(ngModel)]="c_obj['fault_type']"  (change)="getFouls(c_obj['fault_type'])">
                                <option [value]="null">{{'Select_fault' | translate}}</option>
                                <option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</option>
                            </select>  -->
                            <ng-select [(ngModel)]="c_obj['fault_type']" (change)="getFouls(c_obj['fault_type'])" placeholder="{{'Select_fault' | translate}}">
                                <ng-option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ha-ibox">
                            <label for="job_area"> {{'fault_associated' | translate}} *</label>
                            <!-- <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['fault_associated']" (change)="setdescriptionFoul()">
                                <option [value]="null">{{'Select_fault_detail' | translate}}</option>
                                <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Descripcion}}</option>
                            </select> -->
                            <ng-select [(ngModel)]="c_obj['fault_associated']" placeholder="{{'Select_fault_detail' | translate}}" (change)="setdescriptionFoul()">
                                <ng-option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</ng-option>
                            </ng-select>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-md-12 ha-ibox">
                            <label for="type_sanction" > {{'sanction' | translate}} *</label>
                            <!-- <select class="custom-select custom-select-md" name="type_sanction" [(ngModel)]="c_obj['type_sanction']">
                                <option [value]="null">{{'Select_type_sanction' | translate}}</option>
                                <option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</option>
                            </select> -->
                            <ng-select [(ngModel)]="c_obj['type_sanction']" placeholder="{{'Select_type_sanction' | translate}}">
                                <ng-option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</ng-option>
                            </ng-select> 
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12 ha-ibox" style="text-align: center;" *ngIf="getEmployee">
                            <h4 style="color: #003d6a; text-decoration: underline; font-size: bold;"> {{'info_foul' | translate}}</h4>
                            <div class="table-responsive" *ngIf="listfouls.length > 0, else mensaje">
                                <table class="table table-bordered">
                                    <thead  style="font-size: 0.75rem;" class="thead-light">
                                        <tr>
                                            <th style=" color: #003d6a; padding: 10px;"><strong>{{'fault_type' | translate}}</strong></th>
                                            <th style=" color: #003d6a; padding: 10px;"><strong>{{'quantity' | translate}}</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-size: 0.75rem;">
                                        <tr *ngFor="let foul of listfouls">
                                            <td style="padding: 14px;">{{foul.namefouls}}</td>
                                            <td style="padding: 14px;">{{foul.quantity}}</td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>
                            <ng-template #mensaje>
                                <p style="text-align: justify;">{{'user_has_no_faults' | translate}}</p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div> 
        </div>         
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile" class="text-right control-label col-form-label">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile" class="text-right control-label col-form-label">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <hr>
        <h4>{{'finish'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="close_fault" class="text-right control-label col-form-label"> {{'close_fault' | translate}}</label>
                <select class="custom-select custom-select-md" name="close_fault" [(ngModel)]="c_obj['close_fault']" (change)="changeValueFoult()">
                    <option *ngFor="let op of list_option_fault" [value]="op.id">{{op.type}}</option>
                </select> 
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'samp_comment' | translate}}</label>
                <textarea rows="4" type="text"  class="form-control" name="position" [(ngModel)]="c_obj['samp_comment']"  [disabled]="c_obj['close_fault']==2"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>

<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>{{'data_details_request'| translate}}</h4>
        <!-- *ngIf="this.u_obj.WorkerTypeId == 1" -->
        <div *ngIf="this.u_obj.WorkerTypeId == 1"> 
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'identification' | translate}} *</label>
                    <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="u_obj['NumberIdentification']" readonly>
                </div>                
                <div class="col-md-6 ha-ibox">
                    <label>{{'name' | translate}}</label>
                    <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="u_obj['NameEmployee']" readonly>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'date'|translate}}</label>
                    <input type="text"  class="form-control" name="dateActInsecure" [(ngModel)]="u_obj['dateActInsecure']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'code' | translate}} *</label>
                    <input type="text" maxlength="13"  class="form-control" name="code" [(ngModel)]="u_obj['code']" readonly>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'jobtitle' | translate}}</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="u_obj['position']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="level" > {{'level' | translate}}</label>
                    <input type="text"  class="form-control" name="level" [(ngModel)]="u_obj['level']" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'cmp_area'|translate}}</label>
                    <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="u_obj['namesubcompany']"  title="{{'autocomplete'| translate}}" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'year_old' | translate}}</label>
                    <input type="text" maxlength="3"  class="form-control" name="years" [(ngModel)]="u_obj['years']" (keypress)="onlyNumberKey($event)" readonly >
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'boss_inme'|translate}}</label>
                    <input type="text"  class="form-control" name="boss_inme" [(ngModel)]="u_obj['boss_inme']">
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'mail_boss' | translate}}</label>
                    <input type="email"  class="form-control" name="mail_boss" [(ngModel)]="u_obj['mail_boss']" >
                </div>
            </div>
            <hr>
            <h4>{{'should_register'| translate}}</h4>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'report_by' | translate}}*</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="u_obj['report_by']"  title="{{'autocomplete'| translate}}" readonly >
                    
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="fault_type">{{'fault_type' | translate}} *</label>
                    <select class="custom-select custom-select-md" name="fault_type" [(ngModel)]="u_obj['fault_type']">
                        <option [value]="null">{{'Select_fault' | translate}}</option>
                        <option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</option>
                    </select> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label for="job_area"> {{'fault_associated' | translate}} *</label>
                    <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="u_obj['fault_associated']">
                        <option [value]="null">{{'Select_fault_detail' | translate}}</option>
                        <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</option>
                    </select>   
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="type_sanction"> {{'sanction' | translate}} *</label>
                    <select class="custom-select custom-select-md" name="type_sanction" [(ngModel)]="u_obj['type_sanction']">
                        <option [value]="null">{{'Select_type_sanction' | translate}}</option>
                        <option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</option>
                    </select> 
                </div>
            </div>
        </div>
        <div *ngIf="this.u_obj.WorkerTypeId == 2">
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'identification' | translate}} *</label>
                    <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="u_obj['NumberIdentification']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'name' | translate}}</label>
                    <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="u_obj['Name']" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'date'|translate}}</label>
                    <input type="text"  class="form-control" name="dateActInsecure" [(ngModel)]="u_obj['Date'].split('T')[0]" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'jobtitle' | translate}}</label>
                    <input type="text"  class="form-control" name="position" [(ngModel)]="u_obj['position']" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'menu_mycompanies' | translate}}</label>
                    <input type="text"  class="form-control" name="company" [(ngModel)]="u_obj['CompanyName']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'subcompany'|translate}}</label>
                    <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="u_obj['SubCompany']" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}}</label>
                    <input type="text"  class="form-control" name="level" [(ngModel)]="u_obj['PlantaName']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'perm_number' | translate}}</label>
                    <input type="text"  class="form-control" name="perm_number" [(ngModel)]="u_obj['WorkAuthId']" readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'user_sol'|translate}}</label>
                    <input type="text"  class="form-control" name="user_sol" [(ngModel)]="u_obj['Requiredby']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'email_sol' | translate}}</label>
                    <input type="text"  class="form-control" name="email_sol" [(ngModel)]="u_obj['RequiredbyMail']" readonly>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label>{{'cmp_area'|translate}}*</label>
                    <input type="text"  class="form-control" name="email_sol" [(ngModel)]="u_obj['SeccionName']" readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label>{{'report_by' | translate}}*</label>
                    <input type="text"  class="form-control" name="Reportby" [(ngModel)]="u_obj['Reportby']"  readonly>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 ha-ibox">
                    <label for="fault_type" > {{'fault_type' | translate}} *</label>
                    <input type="text"  class="form-control" name="NameFoul" [(ngModel)]="u_obj['NameFoul']"  readonly>
                </div>
                <div class="col-md-6 ha-ibox">
                    <label for="job_area"> {{'fault_associated' | translate}} *</label>
                    <input type="text"  class="form-control" name="paramDetailName" [(ngModel)]="u_obj['paramDetailName']"  readonly>  
                </div>  
            </div>
            <div class="row">
                <div class="col-md-12 ha-ibox">
                    <label for="type_sanction" > {{'sanction' | translate}} *</label>
                    <input type="text"  class="form-control" name="NameSan" [(ngModel)]="u_obj['NameSan']"  readonly>
                </div>
            </div>
        </div>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6" *ngFor="let item of u_obj['_Evidences']">
                <img src="{{item.imagen}}" alt="{{'evidence' | translate}}" width="50%" height="auto">
            </div>
        </div>
        <hr>
        <h4>{{'finish'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="close_fault" class="text-right control-label col-form-label"> {{'close_fault' | translate}}</label>
                <input type="text"  class="form-control" name="position" [value]="u_obj['StatusInsp'] == 2? 'No': 'Si'" readonly>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'samp_comment' | translate}}</label>
                <textarea rows="4" type="text"  class="form-control" name="position" [(ngModel)]="u_obj['Commentary']"  title="{{'autocomplete'| translate}}" readonly></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
        <!-- <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'view' | translate}}</button> -->
    </div>
</ng-template>