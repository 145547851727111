import { Component, OnInit} from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import {saveAs as importedSaveAs  } from "file-saver";
import { environment } from 'src/environments/environment';
import { blobToB64 } from '../approvedocumentsbase/approvedocumentsbase.component';

const API_GET_FILE_EMPLOYEE_ID = environment.Global.API_GET_FILE_EMPLOYEE_ID;
const API_GET_ALL_DOCUMENTS_BY_EMPLOYEE_COMPANY = environment.Global.API_GET_ALL_DOCUMENTS_BY_EMPLOYEE_COMPANY;
const API_POST_UPDATE_STATUS_REVERSE_DOCUMENT_EMPLOYEED = environment.Global.API_POST_UPDATE_STATUS_REVERSE_DOCUMENT_EMPLOYEED;

@Component({
  selector: 'app-consdochistoryemployees',
  templateUrl: './consdochistoryemployees.component.html',
  styleUrls: ['./consdochistoryemployees.component.css']
})
export class ConsdochistoryemployeesComponent extends BaseCrudComponent{
  id:any;
  userinfo:any;
  name_company: any;

  isPDF = true;
  isImage = false;
  pdfSrc = "";
  row: any = [];

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.id = route.snapshot.params.id;
      this.route.queryParams.subscribe(params => {
        this.name_company = params.state;
      });
      this.search_fields = ['NameDoc','identification','EmployeeName','status_name','VerificateUser'];
      this.c_obj['EmployeeDocument'] = [];
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
        });
        this.userinfo = [];
        this.userinfo['name'] = '';
        this.userinfo['emailAddress'] = '';
        this.userinfo['DocumentStatusReverse'] = '';
        await this.storage.get('userinfo').then(x => {
          this.userinfo = x;
        });
    this.getList();
  }

  public getList()
  {
    var qst = API_GET_ALL_DOCUMENTS_BY_EMPLOYEE_COMPANY + this.id;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg,'','success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg,'','success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
     });
  }

  openViewModal(modal, object){
    const DocFileName = object.ParamDocumentID;
    const name = object.FileName;
    this.row = object;
    
    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_EMPLOYEE_ID, object.EmployeeId, DocFileName)
      .subscribe({
        next: async (blob) => {
          if (blob) {
            var extension = blob.type;
            if (extension == 'application/pdf') {
              this.isPDF = true;
              this.isImage = false;
            } else if (
              extension == 'image/jpeg' ||
              extension == 'image/jpg' ||
              extension == 'image/png'
            ) {
              this.isPDF = false;
              this.isImage = true;
            } else {
              this.messageDialog('Formato de archivo incorrecto!', '', 'error');
              return;
            }
            this.pdfSrc = await blobToB64(blob);
            this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });
          } else {
            Swal.fire(
              'No se pudo obtener documento',
              'Contacte con el administrador',
              'error'
            );
          }
        },
        error: (error) => {
          Swal.fire(
            'No se pudo obtener documento',
            'Contacte con el administrador',
            'error'
          );
        }
      });
  }

  downloadDocFile(data) {
    const DocFileName = data.ParamDocumentID;
    const name = data.FileName;
    this.srv.donwloadAnyFileByUserExt(API_GET_FILE_EMPLOYEE_ID, data.EmployeeId,DocFileName).subscribe((data) => {
      importedSaveAs(data,name )
    });
  }
  StatusReverse(data) {
    this.srv.postCall(API_POST_UPDATE_STATUS_REVERSE_DOCUMENT_EMPLOYEED, data).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
       Swal.fire(this.translations.msg_update, x.msg, 'success');
      }
      this.getList();
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }



}
