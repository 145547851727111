import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { isNumeric } from 'rxjs/util/isNumeric';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

const API_GET_ENTRY_REQUEST_EMPLOYEES = environment.Global.API_GET_ENTRY_REQUEST_EMPLOYEES;

@Component({
  selector: 'app-entryemployee',
  templateUrl: './entryemployee.component.html',
  styleUrls: ['./entryemployee.component.css']
})
export class EntryemployeeComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  listTools = [];
  listCars = [];
  havedata = null;
  detalleDocumentos = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.pageSize = 5;
    this.pageSize2 = 5;
    this.search_fields = ['Name','NumberIdentification'];
    this.userinfo = [];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.getList();
  }

  public getList() {
    this.loadingList = true;
    let url = API_GET_ENTRY_REQUEST_EMPLOYEES;
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if(x.val != null) {
          // console.log(x.val)
          this.rawdata = x.val;
          this.rows = x.val;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      }
    })
  }

  public seeListTools (row, modal) {
    this.listTools = row['_ListsToolsDetails'];
    if(this.listTools.length == 0) {
      Swal.fire(this.translations.dont_have_tools, '','info');
    } else {
      this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
    }
  }

  public seeDocumentsVehicle (row, modal) {
    this.listCars = row['_VehicleByEmployee'];    
    this.havedata = null;
    if(this.listCars.length == 0) {
      Swal.fire(this.translations.dont_have_vehicle, '','info');
    } else {
      this.listCars.forEach( car => {
        let cont = 0;
        let contador = 0;
        if (car._DocumentsVehicle.length > 0) {
          car._DocumentsVehicle.forEach(documento => {
            if (documento.Obligatorio == true) {
              contador ++;
            }
            if (documento.Status == "V" && documento.Obligatorio == true) {
              cont ++;
            }
          })
        } else {
          cont --;
        }
        if (contador == cont) {
          car.estadoGeneral = "Aprobado";
        } else {
          car.estadoGeneral = "Rechazado";
        }
      });
      this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
    }
    
  }

  public seeDetailDocument(document) {
    this.detalleDocumentos = document;
    if (this.detalleDocumentos.length > 0) {
      this.havedata = true;
    } else {
      this.havedata = false;
    }
  }
}
