<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('PlantaID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='PlantaID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Name')" class="sh">
                                    {{'nameevaluacion' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'numeropregunta' | translate}}</th>
                                <th>{{'preguntasassi' | translate}}</th>
                                <th (click)="setOrder('TipoArchivo')" class="sh">
                                    {{'file_type' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='TipoArchivo')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Direction')" class="sh">
                                    {{'calificacion' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Direction')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.ParameEvaID}}</td>
                                <td>{{row.Nombre}}</td>
                                <td>{{row.NumeroPregunta}}</td>
                                <td>{{row.Questions}}</td>
                                <td>{{row.TipoArchivo}}</td>
                                <td>{{row.PorcentajeCalificacion}}</td>
                                <td class="align-text-top"> <div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.ParameEvaID}}" name="{{row.Name}}" (change)="toggleEditable($event,row.ParameEvaID)" type="checkbox">
                                  </div></td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="assignQuestions(question1, row)" style="cursor:pointer;" class="far fa-file text-dark m-r-10" title="{{'assign_questions' | translate}}"></i>
                                    <i (click)="DeletedItem(row.ParameEvaID)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'nameevaluacion' | translate}} *</label>
                                <input type="text" class="form-control" [(ngModel)]="c_obj['Nombre']" name="Nombre" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'file_type' | translate}} *</label>
                                <ng-select name="ID" [(ngModel)]="c_obj['TipoArchivo']" [multiple]="false" (change)="selectFile(1)">
                                    <ng-option *ngFor="let archivo of tipoArchivo; let i = index" [value]="archivo['id']">{{archivo['name']}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'number_of_questions_randomly_assigned' | translate}} *</label>
                                <input type="text" class="form-control"  [(ngModel)]="c_obj['NumeroPregunta']" name="NumeroPregunta" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'number_of_opportunities' | translate}} *</label>
                                <input type="number" class="form-control"  [(ngModel)]="c_obj['max_repetition']" name="RepeticionMaxima" required>
                            </div>
                            <div class="col-md-6 ha-ibox"  *ngIf="(c_obj['TipoArchivo'] !== null && c_obj['TipoArchivo'] !== undefined)">
                                <label *ngIf="!viewFileInput" for="urlvideo">{{'urlvideo' | translate}} *</label>
                                <label *ngIf="viewFileInput" for="select_file">{{'select_file' | translate}} *</label>
                                <input *ngIf="!viewFileInput" type="text" class="form-control" [(ngModel)]="c_obj['UrlGeneral']" name="UrlGeneral" required>
                                <div *ngIf="viewFileInput" style="background-color: lavenderblush">
                                    {{ "comp_max_doc_size" | translate }}
                                    {{ "max_doc_size2" | translate }} MB
                                </div>
                                <input *ngIf="viewFileInput" type="file" class="form-control" id="file" (change)="handleInputChange($event)"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'calificacion' | translate}} *</label>
                                <input type="number" (keypress)="onlyNumberKey($event)" class="form-control" min="0" max="10" [(ngModel)]="c_obj['PorcentajeCalificacion']" name="PorcentajeCalificacion" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'durationtime' | translate}} *</label>
                                <input type="number" (keypress)="onlyNumberKey($event)" class="form-control" [(ngModel)]="c_obj['durationtime']" name="durationtime" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'calificacion_minimo' | translate}} *</label>
                                <input type="number"  (keypress)="onlyNumberKey($event)" class="form-control" min="0" max="10" [(ngModel)]="c_obj['PorcentajeCalificacionMinima']" name="PorcentajeCalificacionMinima" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'validate_time' | translate}} *</label>
                                <input type="number"  (keypress)="onlyNumberKey($event)" class="form-control" [(ngModel)]="c_obj['ValidateTime']" name="ValidateTime" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="lname2">{{'globalparam_typejob' | translate}} *</label>
                                <ng-select  name="ID" [(ngModel)]="jobs" [multiple]="true" (change)="setJobs(1)" >
                                    <ng-option *ngFor="let list of listaTrabajos; let i = index" [value]="list['JobTypeID']">{{list['Name']}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-6 ha-ibox" style="text-align: left;">
                                <label for="date_init_work">{{'Obligatorio' | translate}} *</label>
                                <input type="checkbox" name="Sugerido" class="form-control" [(ngModel)]="c_obj['Obligatorio']">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="lname2">{{'observation' | translate}}</label>
                                <ul>
                                    <li>{{'num_question_min' | translate}} : 1</li>
                                    <!-- <li>{{'num_question_max' | translate}} : {{contadorgeneral}}</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>



                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj.Nombre}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'nameevaluacion' | translate}} *</label>
                                <input type="text" class="form-control" [(ngModel)]="u_obj['Nombre']" name="Nombre" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'file_type' | translate}} *</label>
                                <ng-select name="ID" [(ngModel)]="u_obj['TipoArchivo']" [multiple]="false" (change)="selectFile(2)">
                                    <ng-option *ngFor="let archivo of tipoArchivo; let i = index" [value]="archivo['id']">{{archivo['name']}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'number_of_questions_randomly_assigned' | translate}} *</label>
                                <input type="text" class="form-control"  [(ngModel)]="u_obj['NumeroPregunta']" name="NumeroPregunta" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'number_of_opportunities' | translate}} *</label>
                                <input type="number" class="form-control"  [(ngModel)]="u_obj['max_repetition']" name="RepeticionMaxima" required>
                            </div>
                            <div class="col-md-6 ha-ibox"  *ngIf="(u_obj['TipoArchivo'] !== null && u_obj['TipoArchivo'] !== undefined)">
                                <label *ngIf="!viewFileInput" for="urlvideo">{{'urlvideo' | translate}} *</label>
                                <label *ngIf="viewFileInput" for="select_file">{{'select_file' | translate}} *</label>
                                <input *ngIf="!viewFileInput" type="text" class="form-control" [(ngModel)]="u_obj['UrlGeneral']" name="UrlGeneral" required>
                                <div *ngIf="viewFileInput" style="background-color: lavenderblush">
                                    {{ "comp_max_doc_size" | translate }}
                                    {{ "max_doc_size2" | translate }} MB
                                </div>
                                <input *ngIf="viewFileInput" type="file" class="form-control" id="file" (change)="handleInputChange($event)"/>
                            </div>
                            <div class="col-md-6 ha-ibox"  *ngIf="(u_obj['TipoArchivo'] !== null && u_obj['TipoArchivo'] !== undefined)">

                                <div style="margin-top: 40px;" >
                                    <button type="button" class="btn buttons-pages" [disabled]="u_obj['UrlGeneral'] == ''" (click)="downloadDocFile(u_obj)" style="color: #ffffff;"><i class="fas fa-plus"></i> {{'download_file' | translate}}</button>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'calificacion' | translate}} *</label>
                                <input type="number" (keypress)="onlyNumberKey($event)" class="form-control" min="0" max="10" [(ngModel)]="u_obj['PorcentajeCalificacion']" name="PorcentajeCalificacion" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'durationtime' | translate}} *</label>
                                <input type="text" (keypress)="onlyNumberKey($event)" class="form-control" [(ngModel)]="u_obj['durationtime']" name="durationtime" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'calificacion_minimo' | translate}} *</label>
                                <input type="number"  (keypress)="onlyNumberKey($event)" class="form-control" min="0" max="10" [(ngModel)]="u_obj['PorcentajeCalificacionMinima']" name="PorcentajeCalificacionMinima" required>
                            </div>
                            <div class="col-md-6 ha-ibox">
                                <label for="date_init_work">{{'validate_time' | translate}} *</label>
                                <input type="text"  (keypress)="onlyNumberKey($event)" class="form-control" [(ngModel)]="u_obj['ValidateTime']" name="ValidateTime" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ha-ibox">
                                <label for="lname2">{{'globalparam_typejob' | translate}} *</label>
                                <ng-select  name="ID" [(ngModel)]="jobs" [multiple]="true" (change)="setJobs(2)" >
                                    <ng-option *ngFor="let list of listaTrabajos; let i = index" [value]="list['JobTypeID']">{{list['Name']}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-6 ha-ibox" style="text-align: left;">
                                <label for="date_init_work">{{'Obligatorio' | translate}} *</label>
                                <input type="checkbox" name="Sugerido" class="form-control" [(ngModel)]="u_obj['Obligatorio']">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ha-ibox">
                                <label for="lname2">{{'observation' | translate}}</label>
                                <ul>
                                    <li>{{'num_question_min' | translate}} : 1</li>
                                    <!-- <li>{{'num_question_max' | translate}} : {{contadorgeneral}}</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #question1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'assign_questions' | translate}} - {{'nameevaluacion' | translate}}: {{nameEvaluation}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="table-head text-white">
                                    <tr>
                                        <th>ID</th>
                                        <th>{{'pregunta' | translate}}</th>
                                        <th>{{'group' | translate}}</th>
                                        <th>{{'assign' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let question of questionList | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; index as i">
                                        <td>{{i + 1}}</td>
                                        <td>
                                            <div style="width: 400px;">
                                                {{question.Pregunta}}
                                            </div>
                                        </td>
                                        <td>{{question.TypeParameterName}}</td>
                                        <td class="align-text-top">
                                            <div class="form-check">
                                                <input class="form-check-input" [attr.checked]="isSelected(question.PreguntaID)" (change)="isChecked($event,question.PreguntaID)" type="checkbox">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="4">
                                        <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2" [maxSize]="5" style="float: right;"></ngb-pagination>
                                    </td>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="UpdateQuestions(question1)">{{'update' | translate}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
