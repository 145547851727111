import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankComponent } from './ic2/layouts/blank/blank.component';
import { QrcodeValidationComponent } from './ic2/qrcode-validation/qrcode-validation.component';
import { LoginService } from './services/authentication/login.service';
import { LoginGuard } from './services/login.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      {
        path: 'authentication',
        canActivate: [LoginGuard],
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ],
  },
  {
		path: 'qrcodeValidationComponent',
		component: QrcodeValidationComponent,
	},
];
