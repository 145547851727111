import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class GlobalService {
 
    constructor() {}

    formatOnlyDate(date: Date) {
        return date.getFullYear().toString() + '-' + (date.getMonth() <= 8 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
    }
    
    formatDate(date: Date) {
        return date.getFullYear().toString() + '-' + (date.getMonth() <= 8 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) 
        + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' + 
        (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
        (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
        (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
    }
  
        
  }