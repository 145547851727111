import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { LoginService } from 'src/app/services/authentication/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sapproval',
  templateUrl: './sapproval.component.html',
  styleUrls: ['./sapproval.component.css']
})
export class SapprovalComponent implements OnInit {
  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any;
  permissions = [];
  userinfo = [];
  isSkretting = false;

  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: LoginService,
    private storage: Storage,
    private srv: ApiService,
  ) { }
  
  ngOnInit() {
    this.items = [
      {title: 'menu_act_nosecure', url: '/actnosecure', icon: 'mdi mdi-format-list-bulleted'},
      {title: 'menu_condi_nosecure', url: '/condinosecure', icon: 'mdi mdi-adjust'},
      {title: 'menu_incident_accident', url: '/incAc', icon: 'mdi mdi-adjust'},
      {title: 'menu_oportunity_mejor', url: '/oportunitymejor', icon: 'mdi mdi-adjust'},
    ];
    
    this.auth.getPermissions().then(x => {
      this.permissions = x;
    });
    
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
  }
  
  hasPermission(str:string){
    if( this.permissions.indexOf(str) > -1 ){
      return true;
    }
    else{
      return false;
    }
  }
}