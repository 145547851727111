<div class="row">
    <div class="col-md-12 div-filter">
        <select class="custom-select custom-select-md form-control u-select" name="company"
            [(ngModel)]="options_company" (change)="companySelected()" *ngIf="isSkretting" [disabled]='disabledCompany'>
            <option [value]=null disabled>{{'select_company' | translate}}</option>
            <option [value]="-1">{{'all' | translate}}</option>
            <option *ngFor="let companie of companies_list; let i = index" [value]="companie.id">{{companie.name
                |translate}}</option>
        </select>
        <select class="custom-select custom-select-md form-control u-select" name="ruc" [(ngModel)]="options_ruc"
            (change)="rucSelected()" *ngIf="isSkretting" [disabled]='disabledRuc'>
            <option [value]=null disabled>{{'select_ruc' | translate}}</option>
            <option [value]="-1">{{'all' | translate}}</option>
            <option *ngFor="let ruc of ruc_list; let i = index" [value]="ruc.id">{{ruc.ruc |translate}}</option>
        </select>
        <button [ladda]="isFind" type="button" (click)="dataToFind()"
            class="btn button-modal u-button-find m-b-10">{{'search_filters' | translate}}</button>
        <button [ladda]="isClean" type="button" (click)="clearFilters()"
            class="btn button-modal u-button-find m-b-10">{{'clean' | translate}}</button>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 col-12  text-right">
                        <div>
                            <button type="button" class="btn buttons-pages" (click)="downloadReport()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class=" fas fa-plus"></i>
                                {{'download_report' | translate}}</button>
                            <button type="button" class="btn buttons-pages" (click)="downloadExcel()"
                                style="color: #ffffff;" *ngIf="rows.length > 0"><i class=" fas fa-plus"></i>
                                {{'download_to_excel' | translate}}</button>

                        </div>
                    </div>
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive text-center">
            <table class="table">
                <thead class="table-head text-white" #thead>
                    <tr>
                        <th>{{'code' | translate}} </th>
                        <th>{{'v_registration' | translate}} </th>
                        <th>{{'v_brand' | translate}} </th>
                        <th>{{'v_model' | translate}}</th>
                        <th>{{'v_type' | translate}}</th>
                        <th *ngIf="isSkretting">{{'ruc' | translate}}</th>
                        <th *ngIf="isSkretting">{{'company' | translate}}</th>
                        <th *ngIf="isSkretting">{{'company_type' | translate}}</th>
                        <th>{{'status' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                        <td>{{row.VehicleID}}</td>
                        <td>{{row.RegistrationNumber}}</td>
                        <td>{{row.Brand}}</td>
                        <td>{{row.ModelName[0].value}}</td>
                        <td>{{row.VehicleType}}</td>
                        <td *ngIf="isSkretting">{{row.RucCompany}}</td>
                        <td *ngIf="isSkretting">{{row.NameCompany}}</td>
                        <td *ngIf="isSkretting">{{row.TypeCompany == 1 ? "Contratista":"Transportista" }}</td>
                        <td>{{row.Status=='A' ? "Activo":"Inactivo"}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="9">
                        <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                            style="float: right;"></ngb-pagination>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</div>