import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { SettingsComponent } from './settings/settings.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { CrudComponent } from './maint/crudhome/crud.component';
import { CompanyComponent } from './maint/company/company.component';
import { CountryComponent } from './maint/country/country.component';
import { ProductionGroupComponent } from './maint/production-group/production-group.component';
import { UserComponent } from './maint/user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { LoginService } from '../services/authentication/login.service';
import { ParamjobtypeComponent } from './maint/paramjobtype/paramjobtype.component';
import { ParamplantaComponent } from './maint/paramplanta/paramplanta.component';
import { ParamareaComponent } from './maint/paramarea/paramarea.component';
import { ParamgeneralesComponent } from './maint/paramgenerales/paramgenerales.component';
import { ParamgenedetailComponent } from './maint/paramgenedetail/paramgenedetail.component';
import { ParamdocujobtypeComponent } from './maint/paramdocujobtype/paramdocujobtype.component';
import { ParamdocuComponent } from './maint/paramdocu/paramdocu.component';
import { ParamtypefoulComponent } from './maint/paramtypefoul/paramtypefoul.component';
import { ParamfoulsComponent } from './maint/paramfouls/paramfouls.component';
import { ParamsancionComponent } from './maint/paramsancion/paramsancion.component';
import { RegcompaniesComponent } from './docs/regcompanies/regcompanies.component';
import { RegcompaniesdocuComponent } from './docs/regcompaniesdocu/regcompaniesdocu.component';
import { ExcelenciahomeComponent } from './excelencia/excelenciahome/excelenciahome.component';
import { ParamcomunicadoComponent } from './maint/paramcomunicado/paramcomunicado.component';
import { ComunicadosComponent } from './maint/comunicados/comunicados.component';
import { ParamtipopreguntaComponent } from './maint/paramtipopregunta/paramtipopregunta.component';
import { ParampreguntaComponent } from './maint/parampregunta/parampregunta.component';
import { ParampreguntadetalleComponent } from './maint/parampreguntadetalle/parampreguntadetalle.component';
import { ParamevaluacionComponent } from './maint/paramevaluacion/paramevaluacion.component';
import { RegcompaniesempComponent } from './docs/regcompaniesemp/regcompaniesemp.component';
import { QuestionsComponent } from './maint/questions/questions.component';
import { QuestiondetailComponent } from './maint/questiondetail/questiondetail.component';
import { RegemployeeComponent } from './docs/regemployee/regemployee.component';
import { RegemployeedocComponent} from './docs/regemployeedoc/regemployeedoc.component';
import { ConsdochistoryComponent } from './docs/consdochistory/consdochistory.component';
import { ConsdochistorycompaniesComponent } from './docs/consdochistorycompanies/consdochistorycompanies.component';
import { ConsdochistoryemployeesComponent } from './docs/consdochistoryemployees/consdochistoryemployees.component';
import { ProapprovedocumentsComponent } from './docs/proapprovedocuments/proapprovedocuments.component';
import { ProapprovedocumentscompanyComponent } from './docs/proapprovedocumentscompany/proapprovedocumentscompany.component';
import { ProapprovedocumentsemployeesComponent } from './docs/proapprovedocumentsemployees/proapprovedocumentsemployees.component';
import { ProrequestComponent } from './processes/prorequest/prorequest.component';
import { MyevaluationComponent } from './excelencia/myevaluation/myevaluation.component';
import { MyevaluationepecificComponent } from './excelencia/myevaluationepecific/myevaluationepecific.component';
import { OpportunitiesComponent } from './maint/opportunities/opportunities.component';

import { EvaluationinternalComponent } from './processes/evaluationinternal/evaluationinternal.component';
import { RegtoolsequipmentComponent } from './forms/regtoolsequipment/regtoolsequipment.component';
import { ProadmissionappComponent } from './processes/proadmissionapp/proadmissionapp.component';
import { RepocreatorComponent } from './processes/repocreator/repocreator.component';
import { ReportingComponent } from './processes/reporting/reporting.component';
import { ProadmissemergappComponent } from './processes/proadmissemergapp/proadmissemergapp.component';
import { WorkersPlantComponent } from './excelencia/workers-plant/workers-plant.component';
import { ReportformsComponent } from './forms/reportforms/reportforms.component';
import { SapprovalComponent } from './excelencia/sapproval/sapproval.component';
import { ActnosecureComponent } from './excelencia/sapproval/actnosecure/actnosecure.component';
import { CondinosecureComponent } from './excelencia/sapproval/condinosecure/condinosecure.component';
import { IncidentComponent } from './excelencia/sapproval/incident/incident.component';
import { OportunitymejorComponent } from './excelencia/sapproval/oportunitymejor/oportunitymejor.component';
import { ActionplanComponent } from './excelencia/actionplan/actionplan.component';
import { PlanComponent } from './excelencia/actionplan/plan/plan.component';
import { HseComponent } from './excelencia/actionplan/hse/hse.component';
import { InternalUserComponent } from './maint/internal-user/internal-user.component';
import { UserProfileComponent } from './maint/user-profile/user-profile.component';
import { RegVehicleContraComponent } from './docs/reg-vehicle-contra/reg-vehicle-contra.component';
import { RegvehicluedocuComponent } from './docs/regvehicluedocu/regvehicluedocu.component';
import { ProapprovedocumentsvehicleComponent } from './docs/proapprovedocumentsvehicle/proapprovedocumentsvehicle.component';
import { QremployeeComponent } from './excelencia/qremployee/qremployee.component';
import { EntryemployeeComponent } from './excelencia/entryemployee/entryemployee.component';
import { RegdriverComponent } from './docs/regdriver/regdriver.component';
import { CondochistoryvehiclesComponent } from './docs/condochistoryvehicles/condochistoryvehicles.component';
import { EvalassignbyuserComponent } from './processes/evalassignbyuser/evalassignbyuser.component';
import { DocshomeComponent } from './docs/docshome/docshome.component';
//import { title } from 'process';
import { ApprovedocumentsbaseComponent } from './docs/approvedocumentsbase/approvedocumentsbase.component';
import { ApprovedocumentscompaniesComponent } from './docs/approvedocumentscompanies/approvedocumentscompanies.component';
import { ApprovedocumentsemployeesComponent } from './docs/approvedocumentsemployees/approvedocumentsemployees.component';
import { ApprovedocumentsvehiclesComponent } from './docs/approvedocumentsvehicles/approvedocumentsvehicles.component';
import { ReportshomeComponent } from './reports/reportshome/reportshome.component';
import { RptdocumentexternalworkerComponent } from './reports/rptdocumentexternalworker/rptdocumentexternalworker.component';
import { RptworkersbycompanyComponent } from './reports/rptworkersbycompany/rptworkersbycompany.component';
import { RptvehiclesbycompanyComponent } from './reports/rptvehiclesbycompany/rptvehiclesbycompany.component';
import { RptevaluationrequestComponent } from './reports/rptevaluationrequest/rptevaluationrequest.component';
import { RptentryrequestComponent } from './reports/rptentryrequest/rptentryrequest.component';
import { RptworkpermissionComponent } from './reports/rptworkpermission/rptworkpermission.component';
import {RptcompanyComponent} from "./reports/rptcompy/rptcompany.component";



export const Ic2routes: Routes = [
  {
    path: '',
    component: FullComponent,
    //canActivate: [LoginService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'documentshome',
        component: DocshomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docs',
          urls: []
        }
      },
      {
        path: 'reportshome',
        component: ReportshomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_reports',
          urls: []
        }
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_home',
          urls: []
        }
      },
      {
        path: 'formshome',
        component: FormshomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_forms',
          urls: []
        }
      },
      {
        path: 'prochome',
        component: ProchomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_processes',
          urls: []
        }
      },

      {
        path: 'excelenciahome',
        component: ExcelenciahomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_operacional',
          urls: []
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_general',
          urls: []
        }
      },
      // ================================================================
      // ================================================================
      // MENU DOCUMENTOS
      // {
      //   path: 'proapprovedocuments',
      //   component: ProapprovedocumentsComponent,
      //   canActivate: [LoginService],
      //   data: {
      //     title: 'menu_docapproval',
      //     urls: [
      //       { title: 'menu_docs', url: '/documentshome' },
      //       { title: 'menu_docapproval'}
      //     ]
      //   }
      // },
      // {
      //   path: 'proapprovedocumentsemployees/:id',
      //   component: ProapprovedocumentsemployeesComponent,
      //   canActivate: [LoginService],
      //   data: {
      //     title: 'menu_docapproval_employees',
      //     urls: [
      //       { title: 'menu_docs', url: '/documentshome' },
      //       { title: 'menu_docapproval', url: '/approvedocuments/emp' },
      //       { title: 'menu_docapproval_employees'}
      //     ]
      //   }
      // },
      // {
      //   path: 'proapprovedocumentscompany/:id',
      //   component: ProapprovedocumentscompanyComponent,
      //   canActivate: [LoginService],
      //   data: {
      //     title: 'menu_docapproval_company',
      //     urls: [
      //       { title: 'menu_docs', url: '/documentshome' },
      //       { title: 'menu_docapproval', url: '/approvedocuments/comp' },
      //       { title: 'menu_docapproval_company'}
      //     ]
      //   }
      // },
      // {
      //   path: 'proapprovedocumentsvehicles/:id',
      //   component: ProapprovedocumentsvehicleComponent,
      //   canActivate: [LoginService],
      //   data: {
      //     title: 'menu_docapproval_vehicles',
      //     urls: [
      //       { title: 'menu_docs', url: '/documentshome' },
      //       { title: 'menu_docapproval', url: '/approvedocuments/vhc' },
      //       { title: 'menu_docapproval_vehicles'}
      //     ]
      //   }
      // },
      {
        path: 'regcompanie',
        component: RegcompaniesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_register_company_admin',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_register_company_admin'}
          ]
        }
      },
      {
        path: 'regcompanieemp',
        component: RegcompaniesempComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_contract',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_contract'}
          ]
        }
      },
      {
        path: 'regcontracdocu/:id',
        component: RegcompaniesdocuComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_contractdocu',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_contract', url: '/regcompanie' },
            { title: 'menu_contractdocu' }
          ]
        }
      },
      {
        path: 'regemployee',
        component: RegemployeeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_trabajadores',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_trabajadores'}
          ]
        }
      },
      {
        path: 'regdriver',
        component: RegdriverComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_conductores',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_conductores'}
          ]
        }
      },
      {
        path: 'regemployeedoc/:id',
        component: RegemployeedocComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_trabajadoresdoc',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_trabajadores', url: '/regemployee'},
            { title: 'menu_trabajadoresdoc' }
          ]
        }
      },
      {
        path: 'reportforms',
        component: ReportformsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_reporte',
          urls: [
            { title: 'menu_forms', url: '/formshome'},
            { title: 'menu_reporte'}
          ]
        }
      },
      {
        path: 'consdochistory',
        component: ConsdochistoryComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_form_documenthistory',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_title_form_documenthistory'}
          ]
        }
      },
      {
        path: 'consdochistorycompanies/:id',
        component: ConsdochistorycompaniesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_historydocument_company',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_title_form_documenthistory', url: '/consdochistory' },
            { title: 'menu_historydocument_company'}
          ]
        }
      },
      {
        path: 'consdochistoryemployees/:id',
        component: ConsdochistoryemployeesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_historydocument_employees',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_title_form_documenthistory', url: '/consdochistory' },
            { title: 'menu_historydocument_employees'}
          ]
        }
      },
      {
        path: 'consdochistoryvehicles/:id',
        component: CondochistoryvehiclesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_historydocument_vehicles',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_title_form_documenthistory', url: '/consdochistory' },
            { title: 'menu_historydocument_vehicles'}
          ]
        }
      },
      {
        path: 'regVContra',
        component: RegVehicleContraComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_vehiculos',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_vehiculos'}
          ]
        }
      },
      {
        path: 'regVDocu/:id',
        component: RegvehicluedocuComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_vehiculos_document',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_vehiculos', url: '/regVContra'},
            { title: 'menu_vehiculos_document' }
          ]
        }
      },
      {
        path: 'approvedocumentscompanies',
        component: ApprovedocumentscompaniesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_company',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_docapproval_company'}
          ]
        }
      },
      {
        path: 'approvedocumentsemployees',
        component: ApprovedocumentsemployeesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_employees',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_docapproval_employees'}
          ]
        }
      },
      {
        path: 'approvedocumentsvehicles',
        component: ApprovedocumentsvehiclesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_vehicles',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_docapproval_vehicles'}
          ]
        }
      },
      {
        path: 'approvedocumentscompanies/:id',
        component: ProapprovedocumentscompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_company',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_docapproval_company', url: '/approvedocumentscompanies' },
            { title: 'menu_docapproval_company'}
          ]
        }
      },
      {
        path: 'approvedocumentsemployees/:id',
        component: ProapprovedocumentsemployeesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_employees',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_docapproval_employees', url: '/approvedocumentsemployees' },
            { title: 'menu_docapproval_employees'}
          ]
        }
      },
      {
        path: 'approvedocumentsvehicles/:id',
        component: ProapprovedocumentsvehicleComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_vehicles',
          urls: [
            { title: 'menu_docs', url: '/documentshome' },
            { title: 'menu_docapproval_vehicles', url: '/approvedocumentsvehicles' },
            { title: 'menu_docapproval_vehicles'}
          ]
        }
      },
      // FIN DE MENU
      // ================================================================
      // MENU REPORTES
      {
        path: 'reporting',
        component: ReportingComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_reporting',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_reporting'}
          ]
        }
      },
      {
        path: 'documenteexternalworker',
        component: RptdocumentexternalworkerComponent,
        canActivate: [LoginService],
        data: {
          title: 'docs_workers_Ext',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'docs_workers_Ext'}
          ]
        }
      },
      {
        path: 'workersbycompany',
        component: RptworkersbycompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'workers_regis_Contract',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'workers_regis_Contract'}
          ]
        }
      },
      {
        path: 'vehiclesbycompany',
        component: RptvehiclesbycompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'registeredVehiclesByCompany',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'registeredVehiclesByCompany'}
          ]
        }
      },
      {
        path: 'evaluationrequest',
        component: RptevaluationrequestComponent,
        canActivate: [LoginService],
        data: {
          title: 'induction_soli',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'induction_soli'}
          ]
        }
      },
      {
        path: 'entryrequest',
        component: RptentryrequestComponent,
        canActivate: [LoginService],
        data: {
          title: 'request_soli',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'request_soli'}
          ]
        }
      },
      {
        path: 'workpermission',
        component: RptworkpermissionComponent,
        canActivate: [LoginService],
        data: {
          title: 'work_sol',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'work_sol'}
          ]
        }
      },

      {
        path: 'rptcompanies',
        component: RptcompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'compny_sol',
          urls: [
            { title: 'menu_reports', url: '/reportshome' },
            { title: 'compny_sol'}
          ]
        }
      },
      // ================================================================
      // MENU PROCESOS

      {
        path: 'prorequest',
        component: ProrequestComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_soinduccion',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_soinduccion'}
          ]
        }
      },
      {
        path: 'evaluationinternal',
        component: EvaluationinternalComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_assignment_evaluation_internal',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_assignment_evaluation_internal'}
          ]
        }
      },
      {
        path: 'repocreator',
        component: RepocreatorComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_permiso',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_permiso'}
          ]
        }
      },
      {
        path: 'regtoolsequipment',
        component: RegtoolsequipmentComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_list_of_tools_and_equipment',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_list_of_tools_and_equipment'}
          ]
        }
      },
      {
        path: 'proadmissionapp',
        component: ProadmissionappComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_soingreso',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_soingreso'}
          ]
        }
      },
      {
        path: 'proadmissemergapp',
        component: ProadmissemergappComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_soingreso_emergencia',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_soingreso_emergencia'}
          ]
        }
      },
      {
        path: 'opportunities',
        component: OpportunitiesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_opportunities',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_opportunities'}
          ]
        }
      },
      {
        path: 'evaluation-assign',
        component: EvalassignbyuserComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_evalAssignUser',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_evalAssignUser'}
          ]
        }
      },
      // FIN DE MENU

      // ================================================================
      // ================================================================
      // MENU EXCELENCIA OPERACIONAL
      {
        path: 'workersPlant',
        component: WorkersPlantComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_workers_plant',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_workers_plant'}
          ]
        }
      },
      {
        path: 'actionplan',
        component: ActionplanComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_planaccion',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_planaccion'}
          ]
        }
      },
      {
        path: 'sapproval',
        component: SapprovalComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_inspeccion',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_inspeccion'}
          ]
        }
      },
      {
        path: 'myevaluation',
        component: MyevaluationComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_myevaluation',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_myevaluation'}
          ]
        }
      },
      {
        path: 'myevaluationepecific/:id',
        component: MyevaluationepecificComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_myevaluationespecific',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_myevaluation', url: '/myevaluation' },
            { title: 'menu_myevaluationespecific'}
          ]
        }
      },
      {
        path: 'qrRequest',
        component: QremployeeComponent,
        canActivate: [LoginService],
        data: {
            title: 'my_qrcode',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_inspeccion'}
          ]
        }
      },
      {
        path: 'entryEmployeeExt',
        component: EntryemployeeComponent,
        canActivate: [LoginService],
        data: {
            title: 'menu_entry_employee',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_inspeccion'}
          ]
        }
      },
      // FIN DE MENU


      {
        path: 'plan',
        component: PlanComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_plan',
          urls: [
            { title: 'menu_planaccion', url: '/actionplan' },
            { title: 'menu_plan'}
          ]
        }
      },
      {
        path: 'hse',
        component: HseComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_hse',
          urls: [
            { title: 'menu_planaccion', url: '/actionplan' },
            { title: 'menu_hse'}
          ]
        }
      },
      {
        path: 'actnosecure',
        component: ActnosecureComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_act_nosecure',
          urls: [
            { title: 'menu_inspeccion', url: '/sapproval' },
            { title: 'menu_act_nosecure'}
          ]
        }
      },
      {
        path: 'condinosecure',
        component: CondinosecureComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_condi_nosecure',
          urls: [
            { title: 'menu_inspeccion', url: '/sapproval' },
            { title: 'menu_condi_nosecure'}
          ]
        }
      },
      {
        path: 'incAc',
        component: IncidentComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_incident_accident',
          urls: [
            { title: 'menu_inspeccion', url: '/sapproval' },
            { title: 'menu_incident_accident'}
          ]
        }
      },
      {
        path: 'oportunitymejor',
        component: OportunitymejorComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_oportunity_mejor',
          urls: [
            { title: 'menu_inspeccion', url: '/sapproval' },
            { title: 'menu_oportunity_mejor'}
          ]
        }
      },
      {
        path: 'companies',
        component: CompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_company',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_company'}
          ]
        }
      },
      {
        path: 'countries',
        component: CountryComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_countries',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_countries'}
          ]
        }
      },
      {
        path: 'prodgroups',
        component: ProductionGroupComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_prodgroups',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_prodgroups'}
          ]
        }
      },
      {
        path: 'params/:id',
        component: ParameterComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_params',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis', url: '/analysis' },
            { title: 'menu_title_mant_params' }
          ]
        }
      },
      {
        path: 'paramjobtype',
        component: ParamjobtypeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_tipotrabajo',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_tipotrabajo'}
          ]
        }
      },
      {
        path: 'paramdocujobtype/:id',
        component: ParamdocujobtypeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_docujobtype',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_tipotrabajo', url: '/paramjobtype' },
            { title: 'menu_title_man_docujobtype'}
          ]
        }
      },
      {
        path: 'paramplanta',
        component: ParamplantaComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_planta',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_planta'}
          ]
        }
      },
      {
        path: 'paramarea/:id',
        component: ParamareaComponent,
        canActivate: [LoginService],
        data: {
          title: 'areas',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_planta', url: '/paramplanta' },
            { title: 'areas' }
          ]
        }
      },
      {
        path: 'paramgenerales',
        component: ParamgeneralesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_generales',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_generales'}
          ]
        }
      },
      {
        path: 'paramgenedetail/:id',
        component: ParamgenedetailComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_generadetail',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_generales', url: '/paramgenerales' },
            { title: 'menu_title_man_generadetail' }
          ]
        }
      },
      {
        //path: 'paramdocudetail/:id',
        path: 'paramdocu',
        component: ParamdocuComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_docu',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            //{ title: 'menu_title_man_docujobtype', url: '/paramdocujobtype' },
            { title: 'menu_title_man_docu' }
          ]
        }
      },
      {
        path: 'paramtypefouls',
        component: ParamtypefoulComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_foultype',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_foultype'}
          ]
        }
      },
      {
        path: 'paramfouls/:id',
        component: ParamfoulsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_foul',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_foultype', url: '/paramtypefouls' },
            { title: 'menu_title_man_foul' }
          ]
        }
      },
      {
        path: 'paramsancion/:id',
        component: ParamsancionComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_sancion',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_foultype', url: '/paramtypefouls' },
            { title: 'menu_title_man_sancion' }
          ]
        }
      },
      {
        path: 'paramcomunicados',
        component: ParamcomunicadoComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_comunicados',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_comunicados'}
          ]
        }
      },
      {
        path: 'comunicados/:id',
        component: ComunicadosComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_comunicadosg',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_comunicados', url: '/paramcomunicados' },
            { title: 'menu_title_man_comunicadosg' }
          ]
        }
      },
      {
        path: 'paramtipopregunta',
        component: ParamtipopreguntaComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_tipo_preguntas',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_tipo_preguntas'}
          ]
        }
      },
      {
        path: 'questions',
        component: QuestionsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_pregunta',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_pregunta' }
          ]
        }
      },
      {
        path: 'questiondetail/:id',
        component: QuestiondetailComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_preguntasdetalle',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_pregunta', url: '/questions' },
            { title: 'menu_title_man_preguntasdetalle' }
          ]
        }
      },
      {
        path: 'paramevaluacion',
        component: ParamevaluacionComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_evaluacion',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_evaluacion'}
          ]
        }
      },
      {
        path: 'internaluser',
        component: InternalUserComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_title_internal_user',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_internal_user'}
          ]
        }
      },
      {
        path: 'userprofile',
        component: UserProfileComponent,
        // canActivate: [LoginService],
        data: {
          title: 'menu_title_user_profile',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_user_profile'}
          ]
        }
      },
      {
        path: 'cruds',
        component: CrudComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_crud',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'users',
        component: UserComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_users',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_users'}
          ]
        }
      },

      //end
    ]
  }
];
