import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/main/api.service';
import {saveAs as importedSaveAs  } from "file-saver";
import { isNumeric } from 'rxjs/internal-compatibility';
import { PermissionService } from 'src/app/services/authentication/permission.service';

const URL_TO_DOWNLOAD_QRCODE = environment.Global.API_DOWNLOAD_QR;

@Component({
  selector: 'app-excelenciahome',
  templateUrl: './excelenciahome.component.html',
  styleUrls: ['./excelenciahome.component.css']
})
export class ExcelenciahomeComponent implements OnInit {

  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any = [];
  permissions = [];
  userinfo = [];
  isWorkerExt = false;

  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: LoginService,
    private storage: Storage,
    private srv: ApiService,
    public permission: PermissionService
  ) { }

  ngOnInit() {
    let id = environment.Global.Menus.Excelencia// menu formularios
    this.permission.getModulesByMenuId(id).then(x => {
      // console.log(x)
      x.forEach(element => {
        if (element.principal == 1) {
          this.items.push(element)
        }
      });
    })

    this.auth.getPermissions().then(x => {
        this.permissions = x;
    });


  }

  hasPermission(str:string){
    let flag = false;
    this.permissions.forEach(permission => {
      if(permission.Permission == str) {
        flag = true;
      }
    })
    return flag;
  }

  // downloadQR () {
  //   let qst = URL_TO_DOWNLOAD_QRCODE + `${this.userinfo['userCode']}`;
  //   this.srv.downloadFileQR(qst).subscribe(data => {
  //     if (data) {
  //       importedSaveAs(data, 'QR - INGRESO PLANTA DURAGAS');
  //     }
  //   }, error => {
  //     console.log(error.message)
  //   });
  // }

}
