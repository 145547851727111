import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluationinternal',
  templateUrl: './evaluationinternal.component.html',
  styleUrls: ['./evaluationinternal.component.css']
})
export class EvaluationinternalComponent implements OnInit {


  user = '';
  idUser = '';
  evaluation = null;

  usuarios = [
    {
      id:'0955744347',
      user:'Danny Rios'
    },
    {
      id:'0955744346',
      user:'Evelyn Duarte'
    },
    {
      id:'0955744345',
      user:'Ricardo Mujica'
    }
  ]

  constructor() { }

  ngOnInit() {
  }


  evalSelected() {
    this.user = '';
    this.idUser = '';
  }

  setUser() {
    const id = this.idUser;
    let obj = this.usuarios.find(u=> u.id === id);
    this.user = obj !== undefined ? obj.user: '';
    // this.usuarios.forEach(user => {
    //   if (id === user.id) {
    //     this.user = user.user
    //   } else {
    //     this.user = ''
    //   }
    // })
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
