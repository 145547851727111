<div class="row">
    <div class="col-md-12 div-filter">
        <select class="custom-select custom-select-md form-control u-select" name="company"
            [(ngModel)]="options_company" (change)="companySelected()" *ngIf="isSkretting" [disabled]='disabledCompany'>
            <option [value]=null disabled>{{'select_company' | translate}}</option>
            <!--<option [value]="-1">{{'all' | translate}}</option>-->
            <option *ngFor="let companie of companies_list; let i = index" [value]="companie.id">{{companie.name
                |translate}}</option>
        </select>
        <select class="custom-select custom-select-md form-control u-select" name="ruc" [(ngModel)]="options_ruc"
            (change)="rucSelected()" *ngIf="isSkretting" [disabled]='disabledRuc'>
            <option [value]=null disabled>{{'select_ruc' | translate}}</option>
         <!--   <option [value]="-1">{{'all' | translate}}</option>-->
            <option *ngFor="let ruc of ruc_list; let i = index" [value]="ruc.id">{{ruc.ruc |translate}}</option>
        </select>
        <button [ladda]="isFind" type="button" (click)="dataToFind()"
        class="btn button-modal u-button-find m-b-10">{{'search_filters' | translate}}</button>
        <button [ladda]="isClean" type="button" (click)="clearFilters()"
        class="btn button-modal u-button-find m-b-10">{{'clean' | translate}}</button>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                      <a href=""></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive text-center">
           <table class="table" >
                    <thead style="padding: 0" class="table-head text-white" #thead>
                    <tr>
                      <th>{{ "document_upload" | translate }}</th>
                      <th>{{ "name_archive" | translate }}</th>
                      <th>{{ "emition_date" | translate }}</th>
                      <th>{{ "expiration_date" | translate }}</th>
                      <th>{{ "status" | translate }}</th>
                      <th>{{ "action" | translate }}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="rows != null">
                    <tr *ngFor="let row1 of rows">
                      <td>
                          {{ row1.NameDoc }}
                      </td>
                      <td>
                          {{ row1.ImageFileName }}
                      </td>
                      <td>
                          {{ row1.DateDocument | date: "y-MM-dd" }}
                      </td>
                      <td>
                          {{ row1.ExpirationTime | date: "y-MM-dd" }}
                      </td>
                      <td>{{ row1.status_name }}</td>
                      <td class="action-buttons">
                        <i (click)="openViewModal(view, row1)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>
                        <i *ngIf="row1.status_name == 'ACTIVO'"
                           (click)="deleteFile(row1, modaldelete, row1.NameDoc, row1.CompDocumentId)"
                           style="cursor: pointer" class="fa fa-trash-alt m-r-10"
                           title="{{ 'delete' | translate }}">
                        </i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>
<ng-template #view let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'preview' | translate}}</h4>
    <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
      <i (click)="downloadDocFile(this.row2)" style="cursor:pointer;" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}" title="{{'export' | translate}}"></i>
      <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                    style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
      </ng-container>
      <ng-template #elseTemplate>
        <div style="display: flex;">
          <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
               alt="">
        </div>
      </ng-template>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
  </div>
</ng-template>
