import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';

const API_CONSDOCUMENEMP = environment.Global.API_CONSDOCUMENEMP;


@Component({
  selector: 'app-regcompaniesdocu',
  templateUrl: './regcompaniesdocu.component.html',
  styleUrls: ['./regcompaniesdocu.component.css']
})
export class RegcompaniesdocuComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  RegSubJobCo: FormGroup;
  jobtypeID:any;
  seleccionados=[];

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    
        this.read_url = API_CONSDOCUMENEMP;
        // this.create_url = API_REGISTERJOBSUBCOM;
        // this.update_url = API_UPDATESUBJOBCOM;
        // this.active_url = API_ACTIVESUBJOBCOM;
        // this.delete_url = API_DESACTIVESUBJOBCOM;
        // this.urlJob = API_CONSULTAJOBTYPEACTIVE;
        

    this.search_fields = ['ParamDocumentID', 'DocumentName'];

    this.c_obj['AnalysisFeature'] = [];

    this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
    });
   }

   ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
  
    var qst = this.read_url;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    var id:any;
    id = {};
    id.NameJobType = this.id;
      
    this.srv.postCall(qst, id).subscribe(x => {
          this.loadingList = false;
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              this.rows = rs;
              Swal.close()
            }else {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
              this.rows = [];
              return;
                }
          }else {
            Swal.fire(x.msg, '', 'error');
            return;
              }
      });
    }

}
