<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                         <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                      <button *ngIf="this.showButtonSendMail" [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="sendMails()" style="color: #ffffff;"><i class="fas fa-paper-plane"></i> {{'send_mail_to_processed_docs' | translate}}</button>
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('CompanyID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='CompanyID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Ruc')" class="sh">
                                    {{'Ruc' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Ruc')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Name')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'company_type' | translate}}</th>
                                <th (click)="setOrder('DatetimeDocumentstoupdate')" class="sh">
                                    {{'date_register' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='DatetimeDocumentstoupdate')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.CompanyID}}</td>
                                <td>{{row.Ruc}}</td>
                                <td>{{row.Name}}</td>
                                <td>{{row.typeCompany == 1 ? 'Contratista' : 'Transportista'}}</td>
                                <td>{{row.DatetimeDocumentsToUpdate | date:'y-MM-dd hh:mm:s'}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i
                                        style="cursor:pointer;"
                                        (click)="goTo('approvedocumentscompanies/'+row.CompanyID, row.Name)"
                                        class="far fa-file text-info m-r-10"
                                        title="{{'doc_company' | translate}}">
                                    </i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
                </div>
        </div>
    </div>
</div>

