export const ConfigVehicle = {
    tipo: [
        {id: 1, name:'Liviano'},
        {id: 2, name:'Turbo'},
        {id: 3, name:'Sencillo'},
        {id: 4, name:'Doble Troque'},
        {id: 5, name:'Camion'},
        {id: 6, name:'Remolque'},
        {id: 7, name:'Remolque Articulado'},
        {id: 8, name:'Tanque'},
        {id: 9, name:'Estacas'},
        {id: 10, name:'Refrigerado'},
        {id: 11, name:'Volqueta'},
        {id: 12, name:'Cama Baja'},
        {id: 13, name:'Plataforma'}
    ],
    marca: [
        {id: 1, name:'Mercedes'},
        {id: 2, name:'Hino'},
        {id: 3, name:'Hyundai'},
        {id: 4, name:'Chevrolet'},
        {id: 5, name:'Peugot'},
        {id: 6, name:'Jac'},
        {id: 7, name:'Foton'},
        {id: 8, name:'Shacman'},
        {id: 9, name:'Dongfeng'},
        {id: 10, name:'Camc'},
    ]

}