<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search"
                            placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)"
                            style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create'
                            | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" type="button" class="btn buttons-pages" (click)="getList()"
                            style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('CompanyID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='CompanyID')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Name')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Name')"
                                        [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Ruc')" class="sh">
                                    {{'ruc' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Ruc')"
                                        [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'company_type' | translate}}</th>
                                <th>{{'service_type' | translate}}</th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.CompanyID}}</td>
                                <td>{{row.Name}}</td>
                                <td>{{row.Ruc}}</td>
                                <td>{{row.typeCompany == 1 ? 'Contratista' : 'Transportista'}}</td>
                                <td>{{row.IsRecurrent == 1 ? 'Recurrente' : 'No recurrente'}}</td>
                                <td class="align-text-top">
                                    <div class="form-check">
                                        <input class="form-check-input"
                                            [attr.checked]="(row.Status == 'A') ? 'checked' : null"
                                            id="{{row.CompanyID}}" name="{{row.Name}}"
                                            (change)="toggleEditable($event,row.CompanyID)" type="checkbox">
                                    </div>
                                </td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <!-- <i (click)="goTo('/regcontracdocu/'+Empresas, {})" style="cursor:pointer;" class="ti-view-list-alt text-info m-r-10" title="{{'menu_title_man_documento' | translate}}"></i> -->
                                    <i (click)="openUpdateModalValidateCompany(update1, row)" style="cursor:pointer;"
                                        class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="DeletedItem(row.CompanyID)" style="cursor:pointer;"
                                        class="far fa-trash-alt text-danger m-r-10"
                                        title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize"
                                    [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="RegCompanie" class="form-horizontal">
                            <div class="container">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">{{'date' | translate}}</label>
                                    <div class="col-sm-8">
                                        <input type="text" readonly class="form-control-plaintext text-left"
                                            id="staticEmail" value="{{date | date:'dd-MM-yyyy'}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">{{'ruc' | translate}} *</label>
                                    <div class="col-sm-8">
                                        <input type="text" maxlength="13" minlength="13" class="form-control"
                                            formControlName="ruc" [(ngModel)]="c_obj['Ruc']"
                                            (keypress)="onlyNumberKey($event)" (blur)="consultaEmp()">
                                        <label *ngIf="RegCompanie.get('ruc').hasError('pattern')" class="error"
                                            style="font-size: 11px !important;">Solo números, Ruc: 13 digitos</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">{{'menu_mycompanies' | translate}} *</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" formControlName="empresa"
                                            [(ngModel)]="c_obj['Name']" (blur)="generapass()" [readonly]="tieneruc">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputPassword" class="col-sm-4 col-form-label">{{'email' | translate}}
                                        *</label>
                                    <div class="col-sm-8">
                                        <input type="email" class="form-control" formControlName="email"
                                            [(ngModel)]="c_obj['Email']" id="exampleFormControlInput1">
                                        <div *ngIf="RegCompanie.get('email').hasError('pattern')" class="error">Formato
                                            del correo inválido</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-4 col-form-label">{{'company_type' | translate}}
                                        *</label>
                                    <div class="col-sm-8">
                                        <select class="form-control input-top-create" formControlName="typecompany"
                                            [(ngModel)]="c_obj['typeCompany']" required>
                                            <option *ngFor="let e of tipo_empresa; let i = index" [value]="e.id">
                                                {{e['name']}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <label for="service_type" class="col-sm-4 col-form-label">{{'service_type' | translate}}
                                    *</label>
                                  <div class="col-sm-8">
                                    <select class="form-control input-top-create" formControlName="typeservice" id="service_type"
                                            [(ngModel)]="c_obj['IsRecurrent']" required>
                                      <option *ngFor="let e of service_type; let i = index" [value]="e.id">
                                        {{e['name']}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputPassword" class="col-sm-4 col-form-label">{{'pass' |
                                        translate}}</label>
                                    <div class="col-sm-8 form-group mb-2">
                                        <input type="text" readonly class="form-control" formControlName="pass"
                                            id="inputPassword2" value="{{this.passnew}}">
                                        <div *ngIf="RegCompanie.get('ruc').hasError('pattern')" class="error"
                                            style="font-size: 11px !important;">Formato del RUC inválido</div>
                                        <div class="error">{{'msg_company' | translate}}</div>
                                        <!--  *Esta sera la contraseña que se le envie al proveedor</div> -->
                                        <div *ngIf="RegCompanie.get('empresa').hasError('required') || RegCompanie.get('ruc').hasError('required')"
                                            class="error">{{'msg_company1' | translate}}</div>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;"
                            (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal"
                            style="color: #ffffff;" (click)="onSubmit()">{{'create' | translate}}</button>
                    </div>
                </ng-template>


                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['Name']}}
                        </h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">{{'ruc' | translate}} *</label>
                                    <div class="col-sm-8">
                                        <input type="text" maxlength="13" class="form-control" [(ngModel)]="u_obj.Ruc"
                                            (keypress)="onlyNumberKey($event)" name="Ruc" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">{{'menu_mycompanies' | translate}} *</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.Name" name="Name"
                                            required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-4 col-form-label">{{'company_type' | translate}}
                                        *</label>
                                    <div class="col-sm-8">
                                        <select
                                            class="form-control input-top-create"
                                            [(ngModel)]="u_obj.typeCompany"
                                            name="typeCompany"
                                            [disabled]="disableEditCompType"
                                            required>
                                            <option *ngFor="let e of tipo_empresa; let i = index" [value]="e.id">
                                                {{e['name']}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputPassword" class="col-sm-4 col-form-label">{{'email' | translate}}
                                        *</label>
                                    <div class="col-sm-8">
                                        <input type="email" class="form-control" [(ngModel)]="u_obj.Email" name="Email"
                                            required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <label for="service_typeu" class="col-sm-4 col-form-label">{{'service_type' | translate}}
                                    *</label>
                                  <div class="col-sm-8">
                                    <select
                                      class="form-control input-top-create"
                                      [(ngModel)]="u_obj.IsRecurrent"
                                      name="typeservice" id="service_typeu"
                                      [disabled]="disableEditCompType"
                                      required>
                                      <option *ngFor="let e of service_type; let i = index" [value]="e.id">
                                        {{e['name']}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-sm-4 col-form-label">{{'reset' |
                                        translate}}&nbsp;{{'pass' | translate}}</label>
                                    <div class="col-sm-8" style="text-align: left;">
                                        <input type="checkbox" id="ResetPass"
                                            (change)="ValidaCheck($event);"><!--name="ResetPass"  (change)="ValidaCheck();">-->
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;"
                            (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal"
                            style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
