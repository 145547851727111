<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div *ngIf="id !== undefined && !existInspection">
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('ActionPlanId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='ActionPlanId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('PlantaName')">{{'level' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PlantaName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'cmp_area' | translate}}</th>
                                <th>{{'date_ha' | translate}}</th>
                                <th>{{'justificative' | translate}}</th>
                                <th>{{'N_inspeccion' | translate}}</th>
                                <th>{{'no_actions' | translate}}</th>
                                <th>{{'StatusPlan' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.ActionPlanId}}</td>
                                <td >{{row.PlantaName}}</td>
                                <td >{{row.SeccionName}}</td>
                                <td >{{row.FindingDate.split('T')[0]}}</td>
                                <td >{{row.Justification}}</td>
                                <td >{{row.InspectionID}}</td>
                                <td >{{row.ActionPlanDetails.length}}</td>
                                <td >{{row.StatusPlan == 'A' ? 'Abierto': 'Cerrado'}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="fas fa-edit text-info  m-r-10" title="{{'edit' | translate}}"></i> 
                                    <i (click)="openCloseModal(close1, row)" style="cursor:pointer;" class="fas fa-check text-success m-r-10" title="{{'close_plan' | translate}}"></i> 
                                    <i (click)="deletePlan(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4><strong>{{'actionPlan' | translate}} {{typePlan |translate}}</strong></h4>
        <hr>     
        <h4>{{'header' | translate}}</h4>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'level' | translate}}</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="searchArea()">
                    <option [value]="null" selected disabled>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rp of row_plantas" [value]="rp.PlantaID">{{rp.Name}}</option>
                </select>  -->
                <input type="text"  class="form-control" name="level" [(ngModel)]="c_obj['level']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'cmp_area' | translate}}</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['area']" >
                    <option [value]="null" selected disabled>{{'Select_position' | translate}}</option>
                    <option *ngFor="let rp of row_plantas_area" [value]="rp.SeccionID">{{rp.NameSeccion}}</option>
                </select>  -->
                <input type="text"  class="form-control" name="area" [(ngModel)]="c_obj['area']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'report_by' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="c_obj['Reportby']" readonly>
            </div>
            <!-- <div class="col-md-4 ha-ibox">
                <label>{{'localization'|translate}}*</label>
                <textarea type="text"  class="form-control" name="localization" [(ngModel)]="c_obj['localization']" rows="1"></textarea>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label>{{'date_ha'|translate}}</label>
                <input type="text"  class="form-control" name="date_ha" [(ngModel)]="c_obj['date_ha']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label>{{'justificative'|translate}}</label>
                <input type="text"  class="form-control" name="justificative" [(ngModel)]="c_obj['justificative']" readonly>
            </div>        
            <div class="col-md-4 ha-ibox" *ngIf="typeForm == 1 || typeForm == 2">
                <label>{{'fault_associated'|translate}}</label>
                <input type="text"  class="form-control" name="paramDetailDescription" [(ngModel)]="c_obj['paramDetailDescription']" readonly>
            </div>
            <div class="col-md-4 ha-ibox" *ngIf="typeForm == 3">
                <label>{{'observation'|translate}}</label>
                <input type="text"  class="form-control" name="Observation" [(ngModel)]="c_obj['Observation']" readonly>
            </div> 
        </div>
        <div class="row">
            <div class="col-md-8 ha-ibox">
                <label>{{'detail_plan'|translate}}*</label>
                <input type="text"  class="form-control" name="observation" [(ngModel)]="c_obj['observation']">
            </div>    
            <div class="col-md-4 ha-ibox">
                <label>{{'invertion'|translate}}*</label>
                <input type="text"  class="form-control" name="invertion" [(ngModel)]="c_obj['invertion']">
            </div>
        </div>
        <hr>
        <h4>{{'register' | translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'actionCon'|translate}}*</label>
                <input type="text"  class="form-control" name="actionCon" [(ngModel)]="c_obj['actionCon']">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'detailEject'|translate}}*</label>
                <input type="text"  class="form-control" name="detailEject" [(ngModel)]="c_obj['detailEject']">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'responsable'|translate}}*</label>
                <input type="text"  class="form-control" name="responsable" [(ngModel)]="c_obj['responsable']" >
            </div>
            <!-- <div class="col-md-6 ha-ibox">
                <label>{{'result_esp'|translate}}*</label>
                <input type="text"  class="form-control" name="result_esp" [(ngModel)]="c_obj['result_esp']">
            </div> -->
            <div class="col-md-6 ha-ibox">
                <label>{{'date_planea_init'|translate}}*</label>
                <input type="date"  class="form-control" name="date_planea_init" [(ngModel)]="c_obj['date_planea_init']" >
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'date_planea_end'|translate}}*</label>
                <input type="date"  class="form-control" name="date_planea_end" [(ngModel)]="c_obj['date_planea_end']" (change)="setEndDate()">
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'date_fini'|translate}}*</label>
                <input type="date"  class="form-control" name="date_fini" [(ngModel)]="c_obj['date_fini']" readonly >
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'date_planea_end_repro'|translate}}*</label>
                <input type="date"  class="form-control" name="date_planea_end_repro" [(ngModel)]="c_obj['date_planea_end_repro']" readonly >
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'date_planea_end_repro_2'|translate}}*</label>
                <input type="date"  class="form-control" name="date_planea_end_repro_2" [(ngModel)]="c_obj['date_planea_end_repro_2']" readonly >
            </div>
        </div>-->
        <br>
        <div class="row">
            <div class="col-md-2 ha-ibox">
                <button type="button" class="form-control btn buttons-pages" style="color: #ffffff;" (click)="addToTable(c_obj)"><i class=" fas fa-plus"></i> {{'add' | translate}}</button>
            </div>
        </div>
        <hr>
        <div *ngIf="listaAcciones.length > 0">
            <h4>{{'preguntadetalle' | translate}}</h4>
            <div class="table-responsive">
                <table class="table">
                    <thead class="table-head text-white">
                        <tr>
                            <th>Nro</th>
                            <th>{{'action_table' | translate}}</th>
                            <th>{{'preguntadetalle' | translate}}</th>
                            <th>{{'init' | translate}}</th>
                            <th>{{'end' | translate}}</th>
                            <th>{{'action' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let acc of listaAcciones | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2">
                            <td >{{acc.Nro}}</td>
                            <td >{{acc.Action}}</td>
                            <td >{{acc.Details}}</td>
                            <td >{{acc.InitDate}}</td>
                            <td >{{acc.PlanEndDate}}</td>
                            <td>
                                <i (click)="deleteAction(acc.Nro)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <td colspan="6">
                            <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2" [maxSize]="pageSize2" style="float: right;"></ngb-pagination>
                        </td>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>
<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4><strong>{{'actionPlan' | translate}} {{typePlan |translate}}</strong></h4>
        <hr>     
        <h4>{{'header' | translate}}</h4>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'level' | translate}}</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="searchArea()">
                    <option [value]="null" selected disabled>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rp of row_plantas" [value]="rp.PlantaID">{{rp.Name}}</option>
                </select>  -->
                <input type="text"  class="form-control" name="level" [(ngModel)]="u_obj['level']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'cmp_area' | translate}}</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['area']" >
                    <option [value]="null" selected disabled>{{'Select_position' | translate}}</option>
                    <option *ngFor="let rp of row_plantas_area" [value]="rp.SeccionID">{{rp.NameSeccion}}</option>
                </select>  -->
                <input type="text"  class="form-control" name="area" [(ngModel)]="u_obj['area']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'report_by' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="u_obj['Reportby']" readonly>
            </div>
            <!-- <div class="col-md-4 ha-ibox">
                <label>{{'localization'|translate}}*</label>
                <textarea type="text"  class="form-control" name="localization" [(ngModel)]="c_obj['localization']" rows="1"></textarea>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label>{{'date_ha'|translate}}</label>
                <input type="text"  class="form-control" name="date_ha" [(ngModel)]="u_obj['date_ha']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label>{{'justificative'|translate}}</label>
                <input type="text"  class="form-control" name="justificative" [(ngModel)]="u_obj['justificative']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label>{{'invertion'|translate}}</label>
                <input type="text"  class="form-control" name="invertion" [(ngModel)]="u_obj['invertion']" readonly>
            </div>
        </div>
        <hr>
        <h4>{{'actions' | translate}}</h4>
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white">
                    <tr>
                        <th>{{'code' | translate}}</th>
                        <th>{{'action' | translate}}</th>
                        <th>{{'observation' | translate}}</th>
                        <th>{{'preguntadetalle' | translate}}</th>
                        <th>{{'responsible' | translate}}</th>
                        <th>{{'init' | translate}}</th>
                        <th>{{'end' | translate}}</th>
                        <th>{{'end_repro' | translate}}</th>
                        <th>{{'end_repro2' | translate}}</th>
                        <th>{{'end_finit' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let acc of this.u_obj['ActionPlanDetails']; let indice=index">
                        <td >{{acc.ActionPlanDetailsId}}</td>
                        <td >{{acc.Action}}</td>
                        <td >{{acc.Observation}}</td>
                        <td >{{acc.Details}}</td>
                        <td >{{acc.Responsable}}</td>
                        <td >{{acc.InitDate !== null ? acc.InitDate.split('T')[0] : ''}}</td>
                        <td >{{acc.EndDate !== null ? acc.EndDate.split('T')[0] : ''}}</td>
                        <td *ngIf="acc.prog1status == 1">{{acc.PlanProgDate.split('T')[0]}}</td>
                        <td *ngIf="acc.prog1status == 0"><input type="date" [(ngModel)]="acc.PlanProgDate" style="border:none;" (change)="changeDate(acc.PlanProgDate, acc)"></td>
                        <td *ngIf="acc.prog2status == 1">{{acc.PlanProgDate.split('T')[0]}}</td>
                        <td *ngIf="acc.prog2status == 0" ><input type="date" [(ngModel)]="acc.PlanProg2Date" style="border:none;" (change)="changeDate(acc.PlanProg2Date, acc)" [disabled]="acc.prog1status == 0"></td>
                        <td >{{acc.PlanEndDate !== null ? acc.PlanEndDate.split('T')[0] : ''}}</td>
                        <!-- <td>
                             [(ngModel)]="u_obj[ acc.ActionPlanDetailsId + '-date_planea_end_repro-']"
                            u_obj[ acc.ActionPlanDetailsId + '-date_planea_end_repro2-']" style="border:none;" [disabled]="acc.PlanProgDate == null
                            <i (click)="deleteAction(acc.Nro)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i> 
                        </td> -->
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <td colspan="6">
                        <ngb-pagination [collectionSize]="pageTotal2" [(page)]="page2" [pageSize]="pageSize2" [maxSize]="pageSize2" style="float: right;"></ngb-pagination>
                    </td>
                </tfoot> -->
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button *ngIf="u_obj['StatusPlan'] == 'A' && habilitarAct" [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)"
        >{{'edit' | translate}}</button>
    </div>
</ng-template>
<ng-template #close1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4><strong>{{'actionPlan' | translate}} {{typePlan |translate}}</strong></h4>
        <small>{{'status' |translate}}&nbsp;<strong>{{this.close_object['StatusPlan'] | translate}}</strong></small>
        <hr>     
        <h4>{{'header' | translate}}</h4>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'level' | translate}}</label>
                <input type="text"  class="form-control" name="level" [(ngModel)]="close_object['level']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'cmp_area' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="close_object['area']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label for="level"> {{'report_by' | translate}}</label>
                <input type="text"  class="form-control" name="area" [(ngModel)]="close_object['Reportby']" readonly>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 ha-ibox">
                <label>{{'date_ha'|translate}}</label>
                <input type="text"  class="form-control" name="date_ha" [(ngModel)]="close_object['date_ha']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label>{{'justificative'|translate}}</label>
                <input type="text"  class="form-control" name="justificative" [(ngModel)]="close_object['justificative']" readonly>
            </div>
            <div class="col-md-4 ha-ibox">
                <label>{{'invertion'|translate}}</label>
                <input type="text"  class="form-control" name="invertion" [(ngModel)]="close_object['invertion']" readonly>
            </div>
        </div>
        <hr>
        <h4>{{'actions' | translate}}</h4>
        <div class="table-responsive">
            <table class="table">
                <thead class="table-head text-white">
                    <tr>
                        <!-- <th>{{'code' | translate}}</th> -->
                        <th>{{'action' | translate}}</th>
                        <th>{{'observation' | translate}}</th>
                        <th>{{'preguntadetalle' | translate}}</th>
                        <th>{{'responsable' | translate}}</th>
                        <th>{{'upload_evidence' | translate}}</th>
                        <th>{{'picture' | translate}}</th>
                </thead>
                <tbody>
                    <tr *ngFor="let acc of this.close_object['ActionPlanDetails']; let indice=index">
                        <!-- <td >{{acc.ActionPlanDetailsId}}</td> -->
                        <td >{{acc.Action}}</td>
                        <td >{{acc.Observation}}</td>
                        <td >{{acc.Details}}</td>
                        <td >{{acc.Responsable}}</td>
                        <td >
                            <input type="file" multiple="false" accept=".jpg, .jpeg, .png" (change)="insertImg($event, acc)" 
                                [disabled]="close_object.StatusPlan == 'is_closed'">
                            <p>
                                <small *ngIf="acc.Evidence !== null" style="color: #005c3c;">{{'action_contain_evidence' | translate}}</small>
                                <small *ngIf="acc.Evidence == null" style="color: #C8102E;">{{'action_contain_evidence_no' | translate}}</small>
                            </p>
                        </td>
                        <td>
                            <button [disabled]="acc.Evidence == null" style="background: none; border: none;" (click)="seeEvidence(evidenceImage, acc.Evidence)">
                                <i  class="fas fa-camera m-r-10" [style.color]="acc.Evidence == null? '#C8102E' : '#003d6a'"  style="cursor: pointer;" 
                                    [title]="acc.Evidence == null ? ('no_evidene' | translate): ('see_evidence' | translate)"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <small *ngIf="close_object.StatusPlan == 'is_open'" style="color: #C8102E;"> <strong>{{'msg_evidence_to_close' | translate}}</strong></small>
            <small *ngIf="close_object.StatusPlan == 'is_closed'" style="color: #005c3c;"> <strong>{{'msg_status_plan_closed' | translate}}</strong></small>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(close1)">{{'cancel' | translate}}</button>
        <button *ngIf="close_object.StatusPlan !== 'is_closed'" [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateCloseModalEvidence(close1, close_object)">{{'update' | translate}}</button>
        <button *ngIf="contador == cantidadFotos && close_object.StatusPlan == 'is_open'" [ladda]="isClosing" type="button" class="btn button-modal" style="color: #ffffff;" (click)="closePlanStatus(close1, close_object)" [disabled]="close_object.StatusPlan == 'is_closed'">{{'close' | translate}}</button>
    </div>
</ng-template>

<ng-template #evidenceImage  let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'evidence' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>{{'evidence' | translate}}</h4>
        <hr>
        <div class="row">
            <div class="col-md-12" style="text-align: center;">
                <img [src]="evidenceForActionImage" alt="{{'evidence' | translate}}" style="max-width:50%;  height:auto;">
            </div>
        </div>
    </div>
</ng-template>