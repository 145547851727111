import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';

const ALLCOMPANIES = environment.Global.API_ALLCOMPANIES;
const API_GET_DOCUMENTS_GENERAL_COMPANY = environment.Global.API_GET_DOCUMENTS_GENERAL_COMPANY;
const API_SEND_MAIL = environment.Global.API_COMPANY_APPROVE_MULTI_DOCUMENT;

@Component({
  selector: 'app-consdochistory',
  templateUrl: './consdochistory.component.html',
  styleUrls: ['./consdochistory.component.css']
})
export class ConsdochistoryComponent extends BaseCrudComponent {
  userinfo:any;
  showButtonSendMail:boolean;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.read_url = ALLCOMPANIES;
      this.search_fields = ['Name','Ruc'];
      this.c_obj['Company'] = [];
  }

  async ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        if (this.userinfo.ProfileID == 1) { //ADMINISTRADOR
          this.read_url = ALLCOMPANIES;
          this.showButtonSendMail = true;
        } else if (this.userinfo.ProfileID == 2 || this.userinfo.ProfileID == 21) { //CONTRATISTA O TRANSPORTISTA
          this.read_url = API_GET_DOCUMENTS_GENERAL_COMPANY + this.userinfo.selectedCompanyId;
          this.showButtonSendMail = false;
        }
        this.getList();
    });
  }

  public getList()
  {
    var qst = this.read_url;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg,'','success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg,'','success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        this.messageDialog(x.msg,'','error');
        return;
      } 
    });
    
  }

  public sendMails(){
    var qst = API_SEND_MAIL + null;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      console.log(x);
      if(x.success){
        this.closeLoadingMessage();
        return Swal.fire(x.msg,'', 'success');
      } else {
        this.messageDialog(x.msg,'','error');
        return;
      } 
    });
  }
}
