<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('usrId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='usrId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('name')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='name')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'email' | translate}}</th>
                                <th (click)="setOrder('UsrDegreeName')" class="sh">
                                    {{'degree' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='UsrDegreeName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('UsrPositionName')" class="sh">
                                    {{'position' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='UsrPositionName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'disabled-row': !row.usrActive}"> -->
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.usrId}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.usrEmail}}</td>
                                <td>{{row.UsrDegreeName}}</td>
                                <td>{{row.UsrPositionName}}</td>
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['name']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['name']" name="name" disabled="true">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'username' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['usrLogon']" name="usrLogon" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_active' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="usrActive" [(ngModel)]="u_obj['usrActive']" disabled="true">
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'degree' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="UsrDegreeId" [(ngModel)]="u_obj['UsrDegreeId']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of degrees; let i = index" [value]="list['Id']">{{list['Name']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'position' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="UsrPositionId" [(ngModel)]="u_obj['UsrPositionId']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of positions; let i = index" [value]="list['Id']">{{list['Name']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'sign' | translate}}</label>
                                        <div class="col-9">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" name="imgfile" accept="image/*" (change)="handleInputChange($event, u_obj)">
                                                <label class="custom-file-label">Choose file</label>
                                            </div>
                                            <button type="button" class="btn btn-danger" (click)="clearImgPrev(u_obj)" *ngIf="(u_obj['usrSign'] != null) && (u_obj['usrSign'] != '')" style="margin-top: 10px">Borrar</button>
                                            <div style="text-align: center;" *ngIf="(u_obj['usrSign'] != null) && (u_obj['usrSign'] != '')">
                                                <img style="width: 100%" [src]="u_obj['usrSign']" (click)="openPreview(u_obj['usrSign'])" class="pdi-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">HA user</label>
                                        <div class="col-md-9 ha-ibox" style="padding-top: 5px">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" [attr.checked]="(u_obj['usrBelongHA']) ? 'checked' : null" id="requestedby" name="requestedby" (change)="u_obj['usrBelongHA']=!u_obj['usrBelongHA'];">
                                                <label class="custom-control-label" for="requestedby">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
