<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('GetsBetterId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='GetsBetterId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('IncidentTypeName')">{{'menu_incident' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='IncidentName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'level' | translate}}</th>
                                <th>{{'cmp_area' | translate}}</th>
                                <th>{{'observation' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.GetsBetterId}}</td>
                                <td >{{row.IncidentTypeName}}</td>
                                <td >{{row.Date.split('T')[0]}}</td>
                                <td >{{row.PlantaName}}</td>
                                <td >{{row.SeccionName}}</td>
                                <td >{{row.Observation}}</td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="gotoPlan(row.GetsBetterId)" style="cursor:pointer;" class="fas fa-file text-warning m-r-10" title="{{'go_to_action_plan' | translate}}"></i> 
                                    <i (click)="edit(update1, row)" style="cursor:pointer;" class="fas fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i> 
                                    <i (click)="deleteBetter(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>{{'detail_mejor'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'date'|translate}}</label>
                <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['date']" readonly>
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="level"> {{'level' | translate}}*</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" (change)="searchArea()" >
                    <option [value]="null" selected disabled>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rp of row_plantas" [value]="rp.PlantaID">{{rp.Name}}</option>
                </select>  -->
                <ng-select [(ngModel)]="c_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="searchArea()" >
                    <ng-option *ngFor="let rp of row_plantas" [value]="rp.PlantaID">{{rp.Name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="area" > {{'cmp_area' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="area" [(ngModel)]="c_obj['area']">
                    <option [value]="null" selected disabled>{{'Select_position' | translate}}</option>
                    <option *ngFor="let ra of row_plantas_area" [value]="ra.SeccionID">{{ra.NameSeccion}}</option>
                </select>  -->
                <ng-select [(ngModel)]="c_obj['area']" placeholder="{{'Select_position' | translate}}" >
                    <ng-option *ngFor="let ra of row_plantas_area" [value]="ra.SeccionID">{{ra.NameSeccion}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'report_by' | translate}} *</label>
                <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['reportby']" readonly>
            </div>
        </div>
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'incidence'|translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['incidence']" >
                    <option [value]="null" selected disabled>{{'select_incidence' | translate}}</option>
                    <option *ngFor="let lt of list_incidence" [value]="lt.id">{{lt.type}}</option>
                </select> -->
                <ng-select [(ngModel)]="c_obj['incidence']" placeholder="{{'select_incidence' | translate}}" >
                    <ng-option *ngFor="let lt of list_incidence" [value]="lt.id">{{lt.type}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'observation' | translate}} *</label>
                <textarea type="text"  class="form-control" name="incidence" [(ngModel)]="c_obj['observation']" ></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template>



<ng-template #update1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>{{'detail_mejor'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'date'|translate}}</label>
                <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="u_obj['date']" readonly>
            </div>
            <div class="col-md-6 ha-ibox">
                <label for="level"> {{'level' | translate}}*</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="u_obj['level']" (change)="searchArea()" >
                    <option [value]="null" selected disabled>{{'Select-level' | translate}}</option>
                    <option *ngFor="let rp of row_plantas" [value]="rp.PlantaID">{{rp.Name}}</option>
                </select>  -->
                <ng-select [(ngModel)]="u_obj['level']" placeholder="{{'Select-level' | translate}}" (change)="searchArea()" >
                    <ng-option *ngFor="let rp of row_plantas" [value]="rp.PlantaID">{{rp.Name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="area" > {{'cmp_area' | translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="area" [(ngModel)]="u_obj['area']">
                    <option [value]="null" selected disabled>{{'Select_position' | translate}}</option>
                    <option *ngFor="let ra of row_plantas_area" [value]="ra.SeccionID">{{ra.NameSeccion}}</option>
                </select>  -->
                <ng-select [(ngModel)]="u_obj['area']" placeholder="{{'Select_position' | translate}}" >
                    <ng-option *ngFor="let ra of row_plantas_area" [value]="ra.SeccionID">{{ra.NameSeccion}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'report_by' | translate}} *</label>
                <input type="text"  class="form-control" name="position" [(ngModel)]="u_obj['reportby']" readonly>
            </div>
        </div>
        <hr>
        <h4>{{'evidence'| translate}}</h4>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label for="uploadFile">{{'upload_img' | translate}} *</label>
                <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                <small>{{'only_2_pic' | translate}}</small>
            </div>
            <div class="col-md-6 ha-ibox">
                <div *ngIf="archivos.length > 0">
                    <label for="uploadFile">{{'images' | translate}}</label>
                    <ol>
                        <li  *ngFor="let a of archivos">
                            {{a.name}}
                        </li>
                    </ol>
                </div>          
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <label>{{'incidence'|translate}} *</label>
                <!-- <select class="custom-select custom-select-md" name="level" [(ngModel)]="u_obj['incidence']" >
                    <option [value]="null" selected disabled>{{'select_incidence' | translate}}</option>
                    <option *ngFor="let lt of list_incidence" [value]="lt.id">{{lt.type}}</option>
                </select> -->
                <ng-select [(ngModel)]="u_obj['incidence']" placeholder="{{'select_incidence' | translate}}" >
                    <ng-option *ngFor="let lt of list_incidence" [value]="lt.id">{{lt.type}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 ha-ibox">
                <label>{{'observation' | translate}} *</label>
                <textarea type="text"  class="form-control" name="incidence" [(ngModel)]="u_obj['observation']" ></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'edit' | translate}}</button>
    </div>
</ng-template>