import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';


import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Companieadmin } from '../../../interfaces/companieadmin';
import * as CryptoJS from 'crypto-js';
import { format } from 'd3-format';
//mport { Console } from 'console';

const KEY = environment.KEY;
const API_CONSULTACOMPANIES = environment.Global.API_CONSULTACOMPANIES;
const API_REGISTEREMP = environment.Global.API_REGISTEREMP;
const API_CONSULTAEMP = environment.Global.API_CONSULTAEMP;
const API_UPDATEEMP = environment.Global.API_UPDATEEMP;
const API_DESATEEMP = environment.Global.API_DESATEEMP;
const API_ACTIVETEEMP = environment.Global.API_ACTIVETEEMP;
const API_CONSDOCUMENEMP = environment.Global.API_CONSDOCUMENEMP;

@Component({
  selector: 'app-regcompanies',
  templateUrl: './regcompanies.component.html',
  styleUrls: ['./regcompanies.component.css']
})
export class RegcompaniesComponent extends BaseCrudComponent {
  tipo_empresa = [{id:1, name:"Contratista"},{id:2, name:"Transportista"}];
  service_type = [{id:true, name:"Recurrente"},{id:false, name:"No recurrente"}];
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  jobtypeID:any;
  mostarselectemp = true;
  date;
  seleccionados=[];
  Empresas = "Empresas";
  available_languages:any;
  currlang:any;
  version:any;
  permissions = [];
  RegCompanie: FormGroup;
  passuno: string;
  passdos: string;
  passnew: string;
  conversionEncryptOutput: string;
  conversionDecryptOutput:string;
  cedulaexiste;
  resetclave:any;
  tieneruc = true;
  disableEditCompType: boolean = false;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSULTACOMPANIES;
        this.create_url = API_REGISTEREMP;
        this.select_url = API_CONSULTAEMP;
        this.update_url = API_UPDATEEMP;
        this.delete_url = API_DESATEEMP;
        this.active_url = API_ACTIVETEEMP;
        this.documentemp_url = API_CONSDOCUMENEMP;

        this.search_fields = ['CompanyID','Name', 'Ruc'];

        this.c_obj['Company'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
       this.crearFormEmp();

  }

  async ngOnInit() {
    this.date = new Date();
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      if( isNumeric( this.userinfo.logon) ) {
        this.mostarselectemp = false;
      } else {
        this.getList();
        this.mostarselectemp = true;
      }
    });
  }

  public getList() {
    var qst = this.read_url;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
        }else {
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
  });
}

public openCreateModal(content: any) {
  this.c_obj = {};
   this.passnew="";
  //this.modalService.open(content, { size: 'lg' });
  this.modalService.open(content, { backdrop: 'static', keyboard: false });
}

public openUpdateModalValidateCompany(content: any, obj: any, size?:any) {
  let newobj = JSON.parse(JSON.stringify(obj));

  // obtiene los documentos aprobados/valdados
  var qst = environment.Global.API_CAN_CHANGE_COMPANY_TYPE + obj.CompanyID;
  this.srv.getCall(qst).subscribe(x=>{
    // this.loadingList = false;
    if(x.success){
      if(x.val != null){
        console.log(x.val);
        // si hay algun documento se deshabilita el combo para editar el tipo de compañía
        this.disableEditCompType = x.val.length > 0;
      }
    }
  });

  this.u_obj = newobj;
  console.log(this.u_obj);
  if( size == undefined ){
      this.modalService.open(content, { centered: true });
  }
  else{
      this.modalService.open(content, { centered: true, size: size });
  }

}

onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}

crearFormEmp() {
  this.RegCompanie = new FormGroup({
    ruc: new FormControl('', Validators.compose([Validators.required, Validators.pattern(`^[0-9]{10}([0-9]{3})?$`)])),
    empresa: new FormControl('', [Validators.required]),
    email: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])),
    pass: new FormControl('', []),
    typecompany: new FormControl('', [Validators.required]),
    typeservice: new FormControl('', [Validators.required]),
    });
}

onSubmit() {
  this.isCreating = true;
  var qst = this.create_url;

  if (this.RegCompanie.valid) {
    const companieNew: Companieadmin = new Companieadmin();
    companieNew.Username = this.RegCompanie.value.ruc;
    companieNew.Name = this.RegCompanie.value.empresa;
    companieNew.Email = this.RegCompanie.value.email;
    companieNew.typeCompany = this.RegCompanie.value.typecompany;
    companieNew.IsRecurrent = this.RegCompanie.value.IsRecurrent;
    companieNew.PassWord = this.conversionEncryptOutput ;
    companieNew.CreatorUserID = this.userinfo['userId'];
    this.srv.postCall(qst, companieNew).subscribe(x => {
      this.isCreating = false;
      if (x.success) {
        Swal.fire(
          {
            title: this.translations['msg_create'],
            text: x.msg,
            icon:'success',
            allowOutsideClick: false
          }
        ).then((result) => {
          if (result.value) {
            this.RegCompanie.reset();
            this.closeModal('create1');
            this.getList();
          }
        });
      } else{
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }else {
    Swal.fire(this.translations.msg_all_required, '', 'warning');
    return;
  }
}

generapass(){
  if (this.RegCompanie.controls.ruc.status == 'VALID' && this.RegCompanie.controls.empresa.status == 'VALID') {
    this.passnew = this.GeneratePw(this.RegCompanie.value.empresa, this.RegCompanie.value.ruc);
    this.conversionEncryptOutput = this.passnew;
  }
}
  private GeneratePw(empresa, ruc) {
    if (empresa.length > 3) {
      this.passuno = empresa.substr(0, 4);
    } else {
      this.passuno = empresa;
    }
    this.passdos = ruc.substr(3, 4);
    return this.passuno.concat(this.passdos);
  }
consultaEmp (){
  if (this.RegCompanie.controls.ruc.status == 'VALID') {

    var qst = this.select_url;
    const companieNew: Companieadmin = new Companieadmin();
    companieNew.Ruc = this.RegCompanie.value.ruc;

    this.srv.postCall(qst, companieNew).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
         this.RegCompanie.reset();
         this.reload.next(true);
         this.closeModal('create1');
         this.getList();
         Swal.fire(this.translations.ruc_exist,x.msg , 'success');
      } else{
        this.tieneruc = false;
        return;
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });

    }
 }

 public ValidaCheck(val:any){
  this.resetclave="";
  if (val.target.checked==true){
      this.resetclave=true;
  }
  else{
    this.resetclave=false;
  }
 }

 public updateItem(content:any, form:any){
  this.isUpdating = true;
  var qst = this.update_url;

  if( form == undefined ){
    form = {};
  }

  var to:any;

  if( (form.Name == undefined) || (form.Name == '') ){
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
  }
  to = {};
  to.Ruc = form.Ruc;
  to.Name = form.Name;
  to.Email = form.Email;
  to.LastModifierUserID = this.userinfo['userId'];
  to.CompanyID = form.CompanyID;
  to.typeCompany = form.typeCompany;
  to.IsRecurrent = form.IsRecurrent;
debugger;
  console.log(to);

  if(this.resetclave==true)
  {
    to.ResetPass=1;
    //to.PassWord = CryptoJS.AES.encrypt(this.GeneratePw(to.Name, to.Ruc), KEY.trim()).toString();
    to.PassWord = this.GeneratePw(to.Name, to.Ruc);
  }
  else{
    to.ResetPass=2;
  }
  this.srv.postCall(qst, to).subscribe(x => {
    this.isUpdating = false;
    if (x.success) {
      this.getList();
      this.closeModal(content);
      Swal.fire(this.translations['updated'], '', 'success');
    }
  }, err => {
    Swal.close();
    Swal.fire('Error');
   });


}

deleteItem(obj: any){
  var id:any;
  id = {};
  id.CompanyID = obj;
  var qst = this.delete_url;
  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if(x.success){
      this.reload.next(true);
      Swal.fire('Desactivado!', x.msg, 'success');
      this.getList();
  }
  else{
      Swal.fire(x.msg, '', 'error');
  }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}


Activar(obj: any){
  var id:any;
  id = {};
  id.CompanyID = obj;
  var qst = this.active_url;
  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if(x.success){
      this.reload.next(true);
      Swal.fire('¡Activado!', x.msg, 'success');
      this.getList();
  }
  else{
      Swal.fire(x.msg, '', 'error');
  }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}

toggleEditable(event, id) {
  if ( event.target.checked ) {
     this.Activar(id);
 }else {
   this.deleteItem(id);

}


}

  DeletedItem(obj: any){
      var id:any;
      id = {};
      id.CompanyID = obj;
      id.DeleteUserID = this.userinfo['userId'];
      var qst = "RegisterCompany/delete";
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
      });
  }


}
