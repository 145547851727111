<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th style="cursor: pointer;" (click)="setOrder('EntryRequestId')">{{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='ActionPlanId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th style="cursor: pointer;" (click)="setOrder('Requiredby')">{{'requiredby' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PlantaName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'job_area' | translate}}</th>
                                <th>{{'date_init' | translate}}</th>
                                <th>{{'date_end' | translate}}</th>
                                <th>{{'job_to_do' | translate}}</th>
                                <th>{{'job_risk' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc ">
                                <td >{{row.EntryRequestId}}</td>
                                <td >{{row.Requiredby}}</td>
                                <td >{{row.Requiredbyarea}}</td>
                                <td >{{row.StartDate.split('T')[0]}}</td>
                                <td >{{row.EndDte.split('T')[0]}}</td>
                                <td >{{row.WorkDescription}}</td>
                                <td >{{row.Risk ? 'Si': 'No'}}</td>
                                <td class="action-buttons" >
                                    <i (click)="downloadQr(row)" style="cursor:pointer;" class="fas fa-download text-info  m-r-10" title="{{'see_qr' | translate}}"></i> 
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
