import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/main/api.service';
import { environment } from 'src/environments/environment';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import Swal from 'sweetalert2';
import { isNumeric } from 'rxjs/util/isNumeric';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';


const GET_EMPLOYEES_BY_COMPANY = environment.Global.API_GET_EMPLOYEES_BY_COMPANY;
const GET_EMPLOYEES_ALL_COMPANY = environment.Global.API_GET_EMPLOYEES_ALL_COMPANY;
const GET_JOBS_TYPE = environment.Global.API_GET_JOB_TYPE;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const GET_ALL_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const CREATE_QR_AND_SEND_EMAIL = environment.Global.API_CREATE_QR_AND_SEND_EMAIL;
const SEND_EMAIL_TO_REJECT = environment.Global.API_SEND_MAIL_TO_REJECT;
const UPDATE_STATUS__EMERGENCY = environment.Global.API_UPDATE_STATUS__EMERGENCY;

@Component({
  selector: 'app-proadmissemergapp',
  templateUrl: './proadmissemergapp.component.html',
  styleUrls: ['./proadmissemergapp.component.css']
})
export class ProadmissemergappComponent  extends BaseCrudComponent implements OnInit {
  userinfo;
  bandera_Activa_mensaje = true;
  isUpdating = false;
  isReject = false;
  nueva_lista = [];
  row_plantas:any;
  row_plantas_area:any;
  row_typejob:any;
  loadingRequired;
  row_emp:any; // lista que obtiene los trabajadores
  workerList = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.pageSize = 5;
    this.page = 1;
    this.search_fields = ['EntryRequestId','Requiredby'];
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    await this.getList();
  }


  public async  getList() {
    this.nueva_lista = [];
    var qst = GET_ALL_REQUEST ;
    this.loadingList = true;
    this.messageShowLoading();
    await this.srv.getCall(qst).toPromise().then(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          x.val.forEach(element => {
            if (element.Emergency && element.StatusConfirm === null) {
              this.nueva_lista.push(element)
            }
          });
          this.rawdata = this.nueva_lista
          this.rows = this.nueva_lista
          this.bandera_Activa_mensaje = true;
          // Swal.close();

          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
        }else {
          this.bandera_Activa_mensaje = false;
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        this.closeLoadingMessage();
        return;
      }
    });
  }

  public openUpdateModal(content, obj, size?) {
    this.GetTrabajadores();
    this.GetPlantas();
    this.c_obj = {};

    this.c_obj['date_init_work'] = obj.StartDate.split('T')[0];
    this.c_obj['date_final_work'] = obj.EndDte.split('T')[0];
    this.c_obj['job_order'] = obj.WorkOrder;
    this.c_obj['job_applicant'] = obj.Requiredby;
    this.c_obj['level'] = obj.PlantaID + '-' + obj.PlantaName;
    this.c_obj['job_area'] = obj.SeccionID + '-' + obj.SeccionName;
    this.c_obj['request_area'] = obj.Requiredbyarea;
    this.c_obj['work_description'] = obj.WorkDescription;
    this.c_obj['emergency'] = obj.Emergency || null;
    this.c_obj['comment_emergency'] = obj.Commentary || null;
    this.c_obj['who_responsable'] = obj.Responsable || '';
    this.c_obj['email_applicant'] = obj.MailRequiredby || '';
    this.c_obj['risk'] = obj.Risk;
    this.c_obj['worker'] = [];
    this.c_obj['EntryRequestId'] = obj.EntryRequestId;
    this.setLevelArea();
    console.log(obj);

    for (let w of obj.RequestDetails){
      this.c_obj['worker'].push({
        NameEmployee: w['EmployeeName'],
        NumberIdentification: w['NumberIdentification'],
        jobtitle: w['position'],
        globalparam_typejob: w['JobtypeName'],
        id_typejob: w['JobTypeID'],
        employeeId: w['EmployeeId']
      })
    }

    this.modalService.open(content, { backdrop: 'static', size: size });
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if(x != false){
          this.row_typejob = x;
      }
    });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public GetTrabajadores()
  {
    // var qst = this.read_url;
    if(isNumeric(this.userinfo.logon) )
    {
      this.read_url = GET_EMPLOYEES_BY_COMPANY + this.userinfo.selectedCompanyId;
    }
    else{
      this.read_url = GET_EMPLOYEES_ALL_COMPANY;
    }
    var qst = this.read_url;
    this.srv.getCall(qst).subscribe(x => {
      this.workerList = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_emp = rs;
          for (let row of rs) {
            if ((row.Status_doc === 'VALIDADO' && row.Status_evaluation === 'APROBADO')) {
              row['status_Doc_Eval'] = 'HABILITADO';
            } else {
              row['status_Doc_Eval'] = 'NO HABILITADO';
            }
            this.workerList.push(row);
          }
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
        }else {
          //Swal.fire('¡Vacio!', 'La consulta no devolvio registros', 'success');
          this.row_emp = [];
          return;
        }
      }else {
        //Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }
  public GetTypeJob()
  {
    var qst = GET_JOBS_TYPE;

    return this.srv.getCall(qst).pipe(
      mergeMap(data => {
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        //timeout(MAX_TIME),
        catchError(data => of(false))
      );
  }

  public setLevelArea() {
    var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'].split('-')[0];
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
  }
  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  confirmSol(content, obj) {
    this.isUpdating = true;

    // console.log(content)
    // console.log(obj)

    let to = {
        "EntryRequestId": obj.EntryRequestId,
        "StatusConfirm": 'A',
        "LastModifierUserSK": this.userinfo.logon,
        "Status": 'A'
    }
    console.log(to)
    this.messageShowLoading();
    this.srv.postCall(UPDATE_STATUS__EMERGENCY, to).toPromise().then( data =>{
      if(data.success){
        this.sendMailEmergencyRequest(data.val, content);
        this.isUpdating = false;
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    }
    );
  }


  rejectSol(content, obj) {
    this.isReject = true;
    let to = {
      "EntryRequestId": obj.EntryRequestId,
      "StatusConfirm": 'R',
      "LastModifierUserSK": this.userinfo.logon,
      "Status": 'E'
    }
    this.messageShowLoading();
    this.srv.postCall(UPDATE_STATUS__EMERGENCY, to).subscribe( data =>{
      if(data.success){
        this.sendMailToReject(data.val, content);
        this.confirmDialogWithModal(this.translations.sol_emergency_create_Qr,'','success',content);
        Swal.fire(this.translations.sol_emergency_reject_Qr, '','success');
        this.isReject = false;
      } else {
        this.isReject = false;
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isReject = false;
    });
  }

  sendMailEmergencyRequest(data:any, content){
    debugger
    var to:any;
    to = {};
    to = data;
    let qst = CREATE_QR_AND_SEND_EMAIL + to.EntryRequestId.toString();
    this.srv.postCall(qst ,to).subscribe(x => {
      this.c_obj = {};
      this.confirmDialogWithModal(this.translations.sol_emergency_create_Qr,'','success',content);
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }


  sendMailToReject (data: any, content) {
    let to: any = {};
    to = data;
    let qst = SEND_EMAIL_TO_REJECT;
    this.srv.postCall(qst ,to).subscribe(x => {
      this.c_obj = {};
      this.confirmDialogWithModal(this.translations.sol_emergency_create_Qr,'','success',content);
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

}
