<!--
<div class="flip-card" *ngFor="let rows of new_comunicates">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <img [src]="rows.UrlPicture || 'undefined'" [alt]="rows.Title"  class="img">
      <h3 style="color: #3e5569; margin-top: 5px;"> {{rows.Title}} </h3>
    </div>
    <div class="flip-card-back" style="overflow-y: scroll;">
      <br>
      <h3>{{rows.Title}}</h3>
      <p style="text-align: left; margin: 10px;">{{rows.SubTitle}}</p>
      <p style="text-align: justify; margin: 10px;">
        {{rows.Description}}
      </p>
      <a href="{{ rows.UrlLinks }}"  *ngIf="rows.UrlLinks != ''" target="_blank" style="text-align: left; font:bold;text-decoration: underline; color: white;">{{'notice_link' | translate}}</a>
    </div>
  </div>

</div> -->



<div class="row">
  <div class="col-md-9 sk-ibox">
    <div class="flip-card" *ngFor="let rows of new_comunicates">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img [src]="rows.UrlPicture || 'undefined'" [alt]="rows.Title" class="img">
          <h3 style="color: #3e5569; margin-top: 5px;"> {{rows.Title}} </h3>
        </div>
        <div class="flip-card-back" style="overflow-y: scroll;">
          <br>
          <h3>{{rows.Title}}</h3>
          <p style="text-align: left; margin: 10px;">{{rows.SubTitle}}</p>
          <p style="text-align: justify; margin: 10px;">
            {{rows.Description}}
          </p>
          <a href="{{ rows.UrlLinks }}" *ngIf="rows.UrlLinks != ''" target="_blank"
            style="text-align: left; font:bold;text-decoration: underline; color: white;">{{'notice_link' |
            translate}}</a>
        </div>
      </div>

    </div>

  </div>
  <aside class="col-md-3 sk-ibox" style="font-size: 1.2rem ;width: 100vh;">
    <div style="margin-bottom: 30px;">
      <h3 class="dividerLine" style="color: #3e5569; margin-bottom: 5px;"> Por Hacer </h3>
    </div>
    <!-- contratistas y Transportistas -->

    <div style="margin-bottom: 15px;" *ngIf="this.typeUser == 2 || this.typeUser == 21 || this.typeUser == 3 || this.documentApprove">
      <!-- <div > -->

      <ng-container
        *ngIf="this.VigenciaDocumentosContratistas.length == 0 &&  this.DocumentosPendienteContratistas.length == 0 && this.DocumentosPendientesTrabajadores.length == 0 && this.CoursesInfo.length == 0 && this.DocumentosApprove.length==0 ; else elseTemplate">
        nada por ahora.
      </ng-container>
      <ng-template #elseTemplate>

        <span *ngIf="this.typeUser == 2 || this.typeUser == 21 ">
          <h4 style="color: #3e5569; margin-bottom: 5px;"> {{Title}} </h4>
          <ul>
            <!-- VIGENCIA DE DOCUMENTOS -->
            <div *ngIf="this.VigenciaDocumentosContratistas.length > 0">
              <span style="font-weight: bold;" class="dividerLine">Vigencia de documentos </span>
              <div *ngFor="let item of this.VigenciaDocumentosContratistas">
                <div *ngFor="let cd of item.CompanyDocument">
                  <div *ngIf="cd.Status == 'V'">
                    <li style="margin-left: 20px;font-size: 0.90rem;" >
                      Documento: {{cd.Documentos[0].DocumentName}} Expira: {{cd.ExpirationTime | date:'y-MM-dd'}}
                    </li>
                  </div>
                </div>

                <span *ngFor="let a of item.Documentos">

                </span>
              </div>
              <br>
            </div>
            <!-- DOCUMENTOS PENDIENTES A SUBIR -->
            <div *ngIf="this.DocumentosPendienteContratistas.length > 0">
              <span style="font-weight: bold;" class="dividerLine">Documentos pendientes </span>
              <div *ngFor="let item of this.DocumentosPendienteContratistas">
                <li style="margin-left: 20px;font-size: 0.90rem;" *ngIf="item.statusDocument == null">
                  {{item.DocumentName}}
                </li>
              </div>
              <br>
            </div>

            <!-- DOCUMENTOS PENDIENTES DE TRABAJADORES -->
            <div *ngIf="this.DocumentosPendientesTrabajadores.length> 0">
              <span style="font-weight: bold;" class="dividerLine">Documentos pendientes de trabajadores</span>
              <div *ngFor="let item of this.DocumentosPendientesTrabajadores">
                <!-- {{item.DocumentName}} -->

                <li style="margin-left: 20px;font-size: 0.90rem;" *ngIf="item.fileUpload == null">
                  {{item.nameEmployee}} - {{item.DocumentName}}
                </li>

              </div>
              <br>
            </div>


          </ul>
        </span>
        <br>

        <span *ngIf="this.CoursesInfo.length > 0">
          <h4 style="color: #3e5569; margin-bottom: 5px;"> Trabajadores/Conductores </h4>
          <br>
          <ul>

            <span style="font-weight: bold" class="dividerLine">Cursos Pendientes</span>
            <section *ngIf="this.typeUser == 2 || this.typeUser == 21">
              <div *ngFor="let item of this.CoursesInfo">
                <span *ngFor="let a of item.Employees">
                  <li style="margin-left: 20px;font-size: 0.90rem;">
                    Nombre: {{a.Name}}
                    <br>
                    Evaluaciones
                    <br>
                    <div *ngFor="let b of a.Evaluations_toDo">
                      <div *ngIf="b.Status_eval == null">
                        - {{b.Evaluation}}
                      </div>
                    </div>

                  </li>
                </span>
              </div>
            </section>
            <section *ngIf="this.typeUser == 3">
              <div *ngFor="let item of this.CoursesInfo">
                <span *ngFor="let a of item.Employees">
                  <li style="margin-left: 20px;font-size: 0.90rem;" *ngIf="this.userInfo.name == a.Name">
                    Nombre: {{a.Name}}
                    <br>
                    Evaluaciones
                    <br>
                    <div *ngFor="let b of a.Evaluations_toDo">
                      <div *ngIf="b.Status_eval == null">
                        - {{b.Evaluation}}
                      </div>
                    </div>

                  </li>
                </span>
              </div>
            </section>
          </ul>
        </span>
        <span *ngIf="this.DocumentosApprove.length > 0">
          <h4 style="color: #3e5569; margin-bottom: 5px;"> Documentos Pendientes </h4>
          <br>
          <ul>
            <span style="font-weight: bold" class="dividerLine">Documentos Por Aprobar</span>
                <span *ngFor="let a of this.DocumentosApprove">
                  <li style="margin-left: 20px;font-size: 0.90rem;">
                    Compa&ntilde;ia: {{a.Company}}
                    <br>
                    Tiene: {{a.Count}} doumentos por aprobar
                  </li>

                </span>
          </ul>
        </span>
      </ng-template>
    </div>
  </aside>
</div>
