export class Newinfo {
  TypeID: number;
  Title: string;
  SubTitle: string;
  Description: string;
  UrlPicture: string;
  UrlLinks: string;
  CreatorUser: string;
  DateEnd: any;
  constructor(){}
}
