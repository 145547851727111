import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
//import { isNull } from 'util';
import Swal from 'sweetalert2';
import { Cell, Columns, Img, Item, Line, PageReference, PdfMakeWrapper, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
PdfMakeWrapper.setFonts(pdfFonts);
const GET_ALL_EMPLOYEE = environment.Global.API_ALLEMPLOYEE;
const GET_ALL_ENTER_OUT_WORK = environment.Global.API_GET_ALL_ENTER_OUT_WORK;
const GET_ALL_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const CREATE_ENTER_OUT_WORK = environment.Global.API_CREATE_ENTER_OUT_WORK;
const UPDATE_ENTER_OUT_WORK = environment.Global.API_UPDATE_ENTER_OUT_WORK;
const VALIDATE_QR_EMPLOYEE = environment.Global.API_VALIDATE_QR_EMPLOYEE;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;

@Component({
  selector: 'app-workers-plant',
  templateUrl: './workers-plant.component.html',
  styleUrls: ['./workers-plant.component.css']
})
export class WorkersPlantComponent extends BaseCrudComponent implements OnInit {
  date_init = null;
  date_end = null;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  isFind = false;
  userInfo;
  options_company = null;
  options_ruc = null;
  companies_list = [];
  ruc_list = [];
  disabledCompany = false;
  disabledRuc = false;



  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.search_fields = ['Name'];
    }

  ngOnInit() {

    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
    });
    this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(x)
    });
    this.search_fields = ['Name'];
    this.getList();
    this.getCompanies();

  }

  getList() {
    // console.log(this.options_ruc, '-' , this.options_company);
    this.isFind = true;
    let url = GET_ALL_ENTER_OUT_WORK;
    if (this.date_init!== null && this.date_end === null) {
      this.date_init = null;
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_end_select,'','error');
    }
    if (this.date_end!== null && this.date_init === null) {
      this.date_end = null;
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_init_select,'','error');
    }
    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    // FILTRO SELECCION TODO
    if (((this.date_init===null) && (this.date_end===null)) && (this.options_ruc == -1 || this.options_ruc == null) && (this.options_company == -1 || this.options_company == null)) {
      // console.log('1')
      this.srv.getCall(url).subscribe(data =>{
        if (data.success) {
          if (data.val !== null) {
            this.rawdata = data.val;
            this.rows = data.val;
            this.page = 1;
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.isFind = false
            this.filterAll();
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false
            return;
          }
        } else {
          Swal.fire(data.msg, '', 'error');
          this.isFind = false
          return;
        }
      })

    //FILTRO SOLO POR FECHAS
    } else if ((!(this.date_init===null) && !(this.date_end===null)) && (this.options_ruc == -1 || this.options_ruc == null) && (this.options_company == -1 || this.options_company == null)) {
      // console.log('2')
      let list_tmp = [];
      this.srv.getCall(url).subscribe(data =>{
        if (data.success) {
          if (data.val !== null) {
            this.rawdata = data.val;
            let new_list = [];
            list_tmp = data.val;
            list_tmp.forEach(tmp => {
              tmp.DateRegister = this.formatOnlyDate (new Date(tmp.DateRegister));
              if (tmp.DateRegister >= this.date_init && tmp.DateRegister <= this.date_end) {
                new_list.push(tmp);
              }
            })
            this.rawdata = new_list;
            this.rows = new_list;
            this.page = 1;
            if (this.rows.length === 0) {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            }
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.isFind = false
            this.filterAll();
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false
            return;
          }

        } else {
          Swal.fire(data.msg, '', 'error');
          this.isFind = false
          return;
        }
      })
      // FILTRO POR EMPRESA
    } else if (((this.date_init===null) && (this.date_end===null)) && (this.options_ruc !== -1 || this.options_company !== -1)) {
      // console.log('3')
      // console.log(this.options_ruc, ' ---------------------- ', this.options_company)
      let list_tmp = [];
      this.srv.getCall(url).subscribe(data =>{
        if (data.success) {
          if (data.val !== null) {
            this.rawdata = data.val;
            let new_list = [];
            list_tmp = data.val;
            list_tmp.forEach(tmp => {
              tmp.DateRegister = this.formatOnlyDate (new Date(tmp.DateRegister));
              // console.log(tmp.CompanyID)
              if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                // console.log(tmp)
                new_list.push(tmp);
              }
            })
            this.rawdata = new_list;
            this.rows = new_list;
            this.page = 1;
            if (this.rows.length === 0) {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            }
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.isFind = false
            this.filterAll();
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false
            return;
          }

        } else {
          Swal.fire(data.msg, '', 'error');
          this.isFind = false
          return;
        }
      })
      // FILTRO POR TODO
    } else if ((!(this.date_init===null) && !(this.date_end===null)) && (this.options_ruc !== -1 || this.options_company !== -1)) {
      // console.log('4')
      let list_tmp = [];
      this.srv.getCall(url).subscribe(data =>{
        if (data.success) {
          if (data.val !== null) {
            this.rawdata = data.val;
            let new_list = [];
            list_tmp = data.val;
            list_tmp.forEach(tmp => {
              tmp.DateRegister = this.formatOnlyDate (new Date(tmp.DateRegister));
              if (tmp.DateRegister >= this.date_init && tmp.DateRegister <= this.date_end) {
                // console.log('esta en la fecha buscada')
                // console.log(tmp.CompanyID);
                // console.log(this.options_ruc);
                // console.log(this.options_company);
                if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                  new_list.push(tmp);
                }
              }
            })
            this.rawdata = new_list;
            this.rows = new_list;
            this.page = 1;
            if (this.rows.length === 0) {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            }
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.isFind = false
            this.filterAll();
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.isFind = false
            return;
          }

        } else {
          Swal.fire(data.msg, '', 'error');
          this.isFind = false
          return;
        }
      })
    }



  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString())
    + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' +
    (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
    (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
    (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
  }

  // filterAllWorkers(){
  //   var fields = this.search_fields;
  //   var resp:boolean;
  //   var dummy:any;


  //   if( !Array.isArray(fields) ){
  //       return;
  //   }

  //   if( this.search == '' ){
  //       this.rows = this.rawdata;
  //   }
  //   else{
  //     if (this.rows.length == 0) {
  //       this.rows = this.rawdata;
  //     }
  //     let test = this.rows.filter(s => {
  //         resp = false;
  //         fields.forEach(f => {
  //           if( f.indexOf('array.') > -1 ){
  //             let parts = f.split('.');
  //             let f1 = parts[0];
  //             let f2 = parts[1];
  //             if( (s[f2] !== null) && (s[f2] !== undefined) ){
  //                 resp = resp || this.inArray(this.search, s[f2]);
  //             }
  //           }
  //           else if( (s[f] !== null) && (s[f] !== undefined) ){
  //             resp = resp || String(s[f]).toLowerCase().includes(this.search.toLowerCase());
  //           }

  //         });

  //         return resp;
  //     });

  //     this.rows = test;
  //   }

  //   if( this.f1_val != '' ){
  //       dummy = this.rows.filter(s => {
  //           return s[this.f1_key] == this.f1_val;
  //       });

  //       this.rows = dummy;
  //   }

  //   if( this.f2_val != '' ){
  //       dummy = this.rows.filter(s => {
  //           return s[this.f2_key] == this.f2_val;
  //       });

  //       this.rows = dummy;
  //   }

  //   if( this.f3_val != '' ){
  //       dummy = this.rows.filter(s => {
  //           return s[this.f3_key] == this.f3_val;
  //       });

  //       this.rows = dummy;
  //   }

  //   this.pageTotal = this.rows.length;
  // }

  // inArray(str:any, arr:any){
  //   let resp = false;

  //   arr.forEach(x => {
  //       x = x + '';

  //       if( x.indexOf(str) > -1 ){
  //           resp = true;
  //       }
  //   });

  //   return resp;
  // }


  async downloadReport() {
    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    const pdf = new PdfMakeWrapper();
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${this.translations.worker_ext_report}`,
      author: `${this.userInfo.name}`,
      subject: 'Listado',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });
    pdf.pageMargins([ 40, 110, 40, 110]);
    // pdf.header(await
    //   new Img('../../../../assets/images/logo-icon1.png')
    //   .width('75')
    //   //.alignment("right")
    //   .margin(20)
    //   .build()
    // )
    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/duragas_logo.svg').width('160').build()).end,
          ]
        ]
      )
        .margin([0,30,30,0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    // pdf.background(
    //   await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 770).width(600).build()
    // )
    pdf.add(
      // TITLE
      [
        new Txt('Listado de trabajadores externos en planta').fontSize(14).bold().margin([0,0,0,20]).alignment('center').end,
        new Txt([new Txt('Fecha: ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        // new Txt([new Txt('Planta: ').bold().end, isNull(this.date_init) ? this.fecha_sin_formato: this.date_init ]).fontSize(10).alignment('left').end,
        new Txt([new Txt('Solicitado por: ').bold().end,this.userInfo.name]).fontSize(10).alignment('left').margin([0,0,0,20]).end,
        new Table(
          [
            [
              new Cell( new Txt('Id').margin([0,5,0,5]).color('white').bold().end).end,
              new Cell( new Txt('Trabajador').margin([0,5,0,5]).color('white').bold().end).end,
              new Cell( new Txt('Contratista').margin([0,5,0,5]).color('white').bold().end).end,
              new Cell( new Txt('Planta').margin([0,5,0,5]).end,).color('white').bold().end,
              new Cell( new Txt('Fecha de ingreso').margin([0,5,0,5]).color('white').bold().end).end,
              new Cell( new Txt('# Ingresos').margin([0,5,0,5]).color('white').bold().end).end,
              new Cell( new Txt('# Salidas').margin([0,5,0,5]).color('white').bold().end).end
            ],
            ...this.convertDataToRow(this.rows)
          ]
        )
        .layout({
          fillColor: (rowIndex: number, node:any, columnIndex: number) => {
            return rowIndex === 0 ? '#003d6a' : '';
          },
        })
        .fontSize(8)
        .widths(['auto','*','*','*','auto','auto','auto'])
        .alignment('center')
        .end
      ]
    );

    pdf.footer( function(currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Duragas Ecuador | Km 7.5 vía a la costa, sector El Salitral Guayaquil - Ecuador | Telf: 1700387242').fontSize(8).color('gray').alignment('center').margin([0,10,0,0]).end).end,
          new Cell(new Txt('www.duragas.com.ec').link('https://www.duragas.com.ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40,20, 0]).end).end
        ]
      ).end
    });
    let title = 'Reporte de Trabajadores externos en planta';
    pdf.create().download(title);
    Swal.close()
  }


  convertDataToRow (data) {
    // console.log(data)
    return data.map((row) =>
      [
        row.EnterOutWorkerId,
        row.Name,
        row.CompanyName,
        row.Planta[0],
        row.DateRegister.split('T')[0],
        row.DateEnter !== null ? row.DateEnter.split(',').length : 0,
        row.DateOut !== null ? row.DateOut.split(',').length : 0
      ]
    );
  }



  getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => {
      if(data.success) {
        if(data.val !== null){
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push({id:element.CompanyID ,name: element.Name});
            this.ruc_list.push({id: element.CompanyID, ruc: element.Ruc});
          })
        }
      }
    })


  }


  rucSelected() {
    if (this.options_ruc == -1) {
      this.disabledCompany = false;
    } else {
      this.disabledCompany = true;
    }
  }

  companySelected() {
    if (this.options_company == -1 ) {
      this.disabledRuc = false;
    } else {
      this.disabledRuc= true;
    }
  }





















}
