
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div  *ngIf="habilitar == true && entryRequestId !== undefined" >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('WorkAuthId')">ID<i *ngIf="(orderKey!==null)&&(orderKey=='WorkAuthId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th (click)="setOrder('Area')">{{'job_area' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Area')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i></th>
                                <th>{{'date_init' | translate}}</th>
                                <th>{{'date_end' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th >
                                    {{'habidesa' | translate}}
                                </th>
                                <!-- <th >
                                    {{'risk' | translate}}
                                </th> -->
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td (click)="setOrder('WorkAuthId')" class="sh">{{row.WorkAuthId}}</td>
                                <td (click)="setOrder('Area')" class="sh">{{row.Area}}</td>
                                <td>{{row.Wa_DateBegin.split('T')[0]}}</td>
                                <td>{{row.Wa_DaTeend.split('T')[0]}}</td>
                                <td>{{row.CloseWorkerAuth === 'A'? 'ABIERTO':'CERRADO'}}</td>
                                <td class="align-text-top">
                                    <div class="form-check" class="text-center">
                                        <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.WorkAuthId}}" name="{{row.WorkAuthId}}" (change)="toggleEditable($event,row.WorkAuthId)" type="checkbox">
                                    </div>
                                </td>
                                <!-- <td class="align-text-top">
                                    <div class="form-check">
                                        <input class="form-check-input" [attr.checked]="(row.Risk) ? 'checked' : null" id="{{row.Risk}}" name="{{row.Risk}}"  type="checkbox" disabled>
                                    </div>
                                </td> -->
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <!-- <i class="fa fa-download text-dark m-r-10" style="cursor:pointer;" (click)="downloadWork(row.WorkAuthId)" title="{{'download_worker_auth' | translate}}"></i> -->
                                    <i (click)="openCloseWork(update1, row, 'lg')" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'close' | translate}}"></i>
                                    <i *ngIf="row.CloseWorkerAuth === 'A'" (click)="deleteItem(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12">
                            <h4>{{'head_work_permit'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                    <input type="text" class="form-control" value="{{userinfo['name']}}" name="name"  readonly>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="entryRequestId" class="text-right control-label col-form-label"> {{'menu_soingreso' | translate}}</label>
                                    <input type="text"  class="form-control" id="entryRequestId" name="entryRequestId" [(ngModel)]="entryRequestId" readonly>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="WorkOrder" class="text-right control-label col-form-label">{{'job_order' | translate}}</label>
                                    <input type="text" class="form-control" name="WorkOrder"  [(ngModel)]="c_obj['WorkOrder']" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_init" class="text-right control-label col-form-label">{{'date_init' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_init" id="fechaInicio" [(ngModel)]="c_obj['date_init']" (ngModelChange)="verifyDateEnd()" readonly>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_end" class="text-right control-label col-form-label">{{'date_end' | translate}} *</label>
                                    <input type="date" class="form-control" name="date_end" id="fechaFinal" [(ngModel)]="c_obj['date_end']" (ngModelChange)="verifyDateEnd()" readonly>
                                </div>
                                <div class="col-md-4 ha-ibox">
                                    <label for="date_hour" class="text-right control-label col-form-label">{{'hour_Work_perm' | translate}} *</label>
                                    <input type="time" name="date_hour" class="form-control" [(ngModel)]="c_obj['date_hour']">
                                </div>
                            </div>
                            <hr>
                            <h4>{{'detail_work_permit'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-6 ha-ibox">
                                    <label for="work_area" class="text-right control-label col-form-label">{{'cmp_area' | translate}} *</label>
                                    <input type="text" class="form-control" name="work_area" id="work_area" [(ngModel)]="c_obj['work_area']" readonly>
                                </div>
                                <div class="col-md-6 ha-ibox">
                                    <label for="work_person_number" class="text-right control-label col-form-label">{{'persons_number_work' | translate}} *</label>
                                    <input type="number" min="0" class="form-control" name="work_person_number" id="work_person_number" [(ngModel)]="c_obj['work_person_number']" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <label for="work_description" class="text-right control-label col-form-label">{{'job_to_do' | translate}} *</label>
                                    <textarea id="work_description" name="work_description" class="form-control" [(ngModel)]="c_obj['work_description']" readonly></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <label for="work_sub_description" class="text-right control-label col-form-label">{{'work_description' | translate}} *</label>
                                    <textarea id="work_sub_description" name="work_sub_description" class="form-control" [(ngModel)]="c_obj['work_sub_description']"></textarea>
                                </div>
                            </div>
                            <h4>{{'data_details_request'| translate}}</h4>
                            <div class="row">
                                <div class="col-md-12 ha-ibox">
                                    <table class="table" style="font-size: 9pt; padding: auto;">
                                        <thead style="padding: 0">
                                            <tr>
                                                <th><strong>{{'identification' | translate}}</strong></th>
                                                <th><strong>{{'worker' | translate}}</strong></th>
                                                <th><strong>{{'jobtitle'| translate}}</strong></th>
                                                <th><strong>{{'globalparam_typejob' | translate}}</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of c_obj['workers'] ">
                                                <td>{{row.NumberIdentification}}</td>
                                                <td>{{row.EmployeeName}}</td>
                                                <td>{{row.position}}</td>
                                                <td>{{row.JobtypeName}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss" let-modal>
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{c_obj['WorkAuthId']}}</h4>
                        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="form">
                            <div class="card-body">
                                <div class="col-12">
                                    <h4>{{'head_work_permit'| translate}}</h4>
                                    <div class="row">
                                        <div class="col-md-4 ha-ibox">
                                            <label for="name" class="text-right control-label col-form-label">{{'menu_mcompanie' | translate}} </label>
                                            <input type="text" class="form-control" value="{{userinfo['name']}}" name="name"  readonly>
                                        </div>
                                        <div class="col-md-4 ha-ibox">
                                            <label for="entryRequestId" class="text-right control-label col-form-label"> {{'menu_soingreso' | translate}}</label>
                                            <input type="text"  class="form-control" id="entryRequestId" name="entryRequestId" [(ngModel)]="entryRequestId" readonly>
                                        </div>
                                        <div class="col-md-4 ha-ibox">
                                            <label for="WorkOrder" class="text-right control-label col-form-label">{{'job_order' | translate}}</label>
                                            <input type="text" class="form-control" name="WorkOrder"  [(ngModel)]="c_obj['WorkOrder']" readonly>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 ha-ibox">
                                            <label for="date_init" class="text-right control-label col-form-label">{{'date_init' | translate}} *</label>
                                            <input type="date" class="form-control" name="date_init"  [(ngModel)]="c_obj['date_init']" readonly>
                                        </div>
                                        <div class="col-md-4 ha-ibox">
                                            <label for="date_end" class="text-right control-label col-form-label">{{'date_end' | translate}} *</label>
                                            <input type="date" class="form-control" name="date_end" id="fechaFinal" [(ngModel)]="c_obj['date_end']" (ngModelChange)="verifyDateEnd()" readonly>
                                        </div>
                                        <div class="col-md-4 ha-ibox">
                                            <label for="date_hour" class="text-right control-label col-form-label">{{'hour_Work_perm' | translate}} *</label>
                                            <input type="time" name="date_hour" class="form-control" [(ngModel)]="c_obj['date_hour']" readonly>
                                        </div>
                                    </div>
                                    <hr>
                                    <h4>{{'detail_work_permit'| translate}}</h4>
                                    <div class="row">
                                        <div class="col-md-6 ha-ibox">
                                            <label for="work_area" class="text-right control-label col-form-label">{{'cmp_area' | translate}} *</label>
                                            <input type="text" class="form-control" name="work_area" id="work_area" [(ngModel)]="c_obj['work_area']" readonly>
                                        </div>
                                        <div class="col-md-6 ha-ibox">
                                            <label for="work_person_number" class="text-right control-label col-form-label">{{'persons_number_work' | translate}} *</label>
                                            <input type="number" min="0" class="form-control" name="work_person_number" id="work_person_number" [(ngModel)]="c_obj['work_person_number']" readonly>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <label for="work_description" class="text-right control-label col-form-label">{{'work_description' | translate}} *</label>
                                            <textarea id="work_description" name="work_description" class="form-control" [(ngModel)]="c_obj['work_description']" readonly></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <label for="work_sub_description" class="text-right control-label col-form-label">{{'work_description' | translate}} *</label>
                                            <textarea id="work_sub_description" name="work_sub_description" class="form-control" [(ngModel)]="c_obj['work_sub_description']" readonly></textarea>
                                        </div>
                                    </div>
                                    <hr>
                                    <h4>{{'data_details_request'| translate}}</h4>
                                    <div class="row">
                                        <div class="col-md-12 ha-ibox">
                                            <table class="table" style="font-size: 9pt; padding: auto;">
                                                <thead style="padding: 0">
                                                    <tr>
                                                        <th><strong>{{'identification' | translate}}</strong></th>
                                                        <th><strong>{{'worker' | translate}}</strong></th>
                                                        <th><strong>{{'jobtitle'| translate}}</strong></th>
                                                        <th><strong>{{'globalparam_typejob' | translate}}</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let row of c_obj['workers'] ">
                                                        <td>{{row.NumberIdentification}}</td>
                                                        <td>{{row.EmployeeName}}</td>
                                                        <td>{{row.position}}</td>
                                                        <td>{{row.JobtypeName}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 ha-ibox">
                                            <label for="risk" class="text-right control-label col-form-label">{{'close_work' | translate}} *</label>
                                            <input type="checkbox" class="form-control" [(ngModel)]="c_obj['CloseWorkerAuth']" value="c_obj['CloseWorkerAuth']" name="risk" [disabled]="!statusWork">
                                        </div>
                                        <div class="col-md-8 ha-ibox">
                                            <label for="uploadFile" class="text-right control-label col-form-label">{{'upload_file' | translate}} *</label>
                                            <input type="file" class="form-control" accept="image/png, image/jpeg" (change)="handleInputChange($event)"  [disabled]="!statusWork">
                                            <small><strong>{{'must_enter_signed_permiss' | translate}} - {{'format_file_png_img' | translate}}</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeItem(update1, c_obj)"  [disabled]="!statusWork">{{'update' | translate}}</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
