import { RouteInfo } from './sidebar.metadata';



export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'menu_home',
    icon: 'far fa-comments',
    class: '',
    extralink: false,
    perm: 'DG_COMUNICADOS',
    submenu: []
  },
  {
    path: '/documentshome',
    title: 'menu_docs',
    icon: 'far fa-file',
    class: '',
    extralink: false,
    perm: 'DG_DOCSHOME',
    submenu: []
  },
  {
    path: '/reportshome',
    title: 'menu_reports',
    icon: 'fas fa-chart-line',
    class: '',
    extralink: false,
    perm: 'DG_REPORTES',
    submenu: []
  },
  {
    path: '/formshome',
    title: 'menu_forms',
    icon: 'icon icon-note',
    class: '',
    extralink: false,
    perm: 'DG_FORMULARIOS',
    submenu: []
  },
  {
    path: '/prochome',
    title: 'menu_processes',
    icon: 'fa fa-cogs',
    class: '',
    extralink: false,
    perm: 'DG_PROCESOS',
    submenu: []
  },
  {
    path: '/excelenciahome',
    title: 'menu_operacional',
    icon: 'icon icon-globe',
    class: '',
    extralink: false,
    perm: 'DG_EXCELENCIA_OPERACIONAL',
    submenu: []
  },
  {
    path: '/cruds',
    title: 'menu_maintenance',
    icon: 'fa fa-wrench',
    class: '',
    extralink: false,
    perm: 'DG_MANTENIMIENTO',
    submenu: []
  },
  {
    path: '/settings',
    title: 'menu_general',
    icon: 'icon icon-settings',
    class: '',
    extralink: false,
    perm: 'DG_CONFIGURACIONES',
    submenu: []
  }
];
