<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24" style="color: #F38230;"></i>
        </a>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <li class="hellow-msg">{{ browserName }}(v. {{ browserVersion }} ) <span *ngIf="!isUpToDate" class="text-danger">Desactualizado </span> </li>
    <li class="hellow-msg2">
      <div>
        <ng-container *ngIf="connectionSpeed === 'good'">
          <i class="fas fa-signal good-signal text-success"></i> <!-- Icono de buena señal -->
        </ng-container>
        <ng-container *ngIf="connectionSpeed === 'average'">
          <i class="fas fa-exclamation-triangle poor-signal text-warning"></i> <!-- Icono de señal baja -->
        </ng-container>
        <ng-container *ngIf="connectionSpeed === 'poor'">
          <i class="fas fa-times-circle poor-signal text-danger"></i> <!-- Icono sin conexión -->
        </ng-container>
      </div>
    </li>
    <li class="hellow-msg2">v. {{this.version}} </li>

    <li class="hellow-msg">¡{{'hi' | translate}} {{user}}! </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img [src]="photo || 'assets/images/users/1.jpg'" alt="user" class="rounded-circle" width="35">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 text-white m-b-10" style="background: #F38230;">
                <div class="">
                    <img [src]="photo || 'assets/images/users/1.jpg'" alt="user" class="img-circle" width="50">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">{{this.user}}</h4>
                </div>
            </div>
            <a class="dropdown-item" (click)="logout()" style="cursor: pointer;">
                <i class="fa fa-power-off m-r-5 m-l-5"></i>{{'logout' | translate }}</a>
            <div class="dropdown-divider"></div>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
