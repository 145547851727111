import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/authentication/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

const API_CONSULTATYPEFALTA = environment.Global.API_CONSULTATYPEFALTA;
const API_CONSULTAFALTADETAIL = environment.Global.API_CONSULTAFALTADETAIL;
const API_SANCION = environment.Global.API_SANCION;
const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const API_UPLOAD_FILE_BETTER = environment.Global.API_UPLOAD_FILE_BETTER;
const API_CREATE_BETTER = environment.Global.API_CREATE_BETTER;
const API_UPDATE_BETTER = environment.Global.API_UPDATE_BETTER;
const API_ALL_BETTER = environment.Global.API_ALL_BETTER;
const API_ID_BETTER = environment.Global.API_ID_BETTER;
const API_UPDATESTATUS_BETTER = environment.Global.API_UPDATESTATUS_BETTER;
const API_GET_BETTER_BY_USER = environment.Global.API_GET_BETTER_BY_USER;

@Component({
  selector: 'app-oportunitymejor',
  templateUrl: './oportunitymejor.component.html',
  styleUrls: ['./oportunitymejor.component.css']
})
export class OportunitymejorComponent extends BaseCrudComponent implements OnInit {
  fechaActual = new Date();
  archivos=[];
  list_incidence = [];
  userinfo;
  row_plantas = [];
  row_plantas_area = [];
  modalCreate = false;
  loadingList = false;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
      
        // this.getTypeFouls();
    this.search_fields = ['GetsBetterId','IncidentTypeName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });
    
  }

  public validateCreateInfo(fields:any): boolean {
    let oport:any = {};
    oport = new FormGroup({
        'level': new FormControl(fields.level, [Validators.required]),
        'area': new FormControl(fields.area, [Validators.required]),
        'incidence': new FormControl(fields.incidence, [Validators.required]),
        'observation': new FormControl(fields.observation, [Validators.required])
    });
    return oport.valid;
  }


  public handleInputChange(e){
    // console.log(e)
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    console.log(file)
    let files = Object.values(file);
    console.log(files)
    files.forEach(element => {
      if (this.archivos.length < 2) {
        this.archivos.push(element);
      } else {
        return Swal.fire(this.translations.only_two_imgs,'','error');
      }
    });
    
  }


  public getList() {
    this.loadingList = true; 
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(API_GET_BETTER_BY_USER + this.userinfo['logon']).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

    this.GetSeverityTypes()
  }

  public openCreateModal(content: any) {
    
    this.c_obj['date'] = this.globalService.formatOnlyDate(this.fechaActual);
    this.c_obj['reportby'] = this.userinfo['logon'];
    this.c_obj['level'] = null;
    this.c_obj['area'] = null;
    this.c_obj['incidence'] = null;
    this.modalCreate = true;
    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
    this.GetPlantas();
  }

  public GetSeverityTypes() {
    var qst = API_GET_SEVERITY_TYPE_BY_ID + 'INCIDENT_TYPE';
    this.srv.getCall(qst).subscribe(x => {
      this.list_incidence = [];
      if(x.success){
        if(x.val != null){
          console.log(x.val)
          x.val.forEach(element => {
            this.list_incidence.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
        }else {
          return;
        }
      }else {
        return;
          }
    });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
           console.log(this.row_plantas)
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public searchArea() {

    if (this.modalCreate) {
      if (this.c_obj['level'] !== null) {
        var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'];
        this.srv.getCall(qst).subscribe(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              this.row_plantas_area = rs;
              console.log(this.row_plantas_area)
            }else {
              this.row_plantas_area = [];
              return;
            }
          }else {
            return;
              }
        });

      } else {
        this.c_obj['area'] = null;
      }
    } else {
      if (this.u_obj['level'] !== null) {

        var qst = GET_ALL_AREA_BY_ID + this.u_obj['level'];
        this.srv.getCall(qst).subscribe(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              this.row_plantas_area = rs;
              console.log(this.row_plantas_area)
            }else {
              this.row_plantas_area = [];
              return;
            }
          }else {
            return;
              }
        });
      }else {
        this.u_obj['area'] = null;
      }
    }
   
  }

  public createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (this.archivos.length == 0) {
      this.isCreating = false;
      return Swal.fire(this.translations.should_upload_imgs, '','error');
    }

    let new_obj = {
      IncidentId: this.c_obj['incidence'],
      PlantaID: this.c_obj['level'],
      SeccionID: this.c_obj['area'],
      Date: this.c_obj['date'],
      Photos: '',
      Observation: this.c_obj['observation'],
      CreatorUser: this.userinfo['logon']
    }
    this.srv.postCall(API_CREATE_BETTER, new_obj).toPromise().then(  (data)=> {
      
      if(data.success){
        this.c_obj = {};
        this.sendPhotos(data);
        this.gotoPlan(data.val['GetsBetterId']);
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }

  public async sendPhotos(data?) {
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('user', this.userinfo['logon']);
    formData.append('GetsBetterId', data.val.GetsBetterId);
    // await this.srv.AddFiles(API_UPLOAD_FILE_BETTER, formData).toPromise().then(result => {
    //   if (result=="OK") {
    //     this.archivos=[];
    //   }
    //   else{
    //     Swal.fire(this.translations.evidence, result, 'error');
    //     this.archivos=[];
    //   }
    // });
  }

  public deleteBetter(row) {
    this.ActivarItem(row.GetsBetterId,3);
  }

  public ActivarItem(id:any, accion:any): void
  {
    var to:any;
    to = {};
    to.GetsBetterId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['logon'];
    // console.log(to)
    this.srv.postCall(API_UPDATESTATUS_BETTER, to).subscribe(x => {
      Swal.close();
      if(x.success){
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  public edit(modal, object) {
    this.GetPlantas();
    this.modalCreate = false
    this.modalService.open(modal, { backdrop: 'static', size: 'lg' });
    this.u_obj['GetsBetterId'] = object['GetsBetterId'];
    this.u_obj['incidence'] = object['IncidentId'];
    this.u_obj['date'] = object['Date'].split('T')[0];
    this.u_obj['level'] = object['PlantaID'];
    this.searchArea();
    this.u_obj['area'] = object['SeccionID'];
    this.u_obj['observation'] = object['Observation'];
    this.u_obj['reportby'] = this.userinfo['logon'];

  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    let x = Object.values(object);
    if (x.includes(null)) {
      this.isUpdating = false;
      return Swal.fire(this.translations['msg_all_required'], '', 'error');
    }

    let new_obj = {
      GetsBetterId: object['GetsBetterId'],
      IncidentId:  object['incidence'],
      PlantaID:  object['level'],
      SeccionID:  object['area'],
      Observation:  object['observation'],
      LastModifierUser:  this.userinfo['logon']
    }
    
    this.srv.postCall(API_UPDATE_BETTER, new_obj).toPromise().then(  (data)=> {

      if(data.success){
        this.u_obj = {};
        if (this.archivos.length > 0) {
          this.sendPhotos(data);
        }
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }
  
  public gotoPlan(GetsBetterId) {
    this.router.navigate(['plan/'],{ queryParams: {
      id: GetsBetterId,
      type: 3
    }});
  }

}
