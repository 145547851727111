<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <h3>{{'considerations' | translate}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul>
                    <li>
                        {{'msg_info_evaluation_consideration1' | translate}} {{this.duracion_evaluacion}} {{'minutes_evaluation' | translate}}
                    </li>
                    <li>
                        {{'msg_info_evaluation_repetitions' | translate}} {{this.max_repeticiones}} {{'msg_info_evaluation_repetitions_1' | translate}}
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="band == false">
            <div class="col-md-12">
                <h3>{{'time_elapse'| translate}} {{hora}}:{{minutos}}:{{time}}</h3>
            </div>
        </div>
        <div class="row" *ngIf="band == false">
            <div class="col-md-12">
                <h4>{{'question_'| translate}} {{numeroPregunta}} / {{total_preguntas}}</h4>
            </div>
        </div>
        <div class="row" *ngIf="band == true">
            <div class="col-md-12 text-center" >
                <button type="button" class="btn buttons-pages" style="color: #ffffff; width: 400px; height: 50px; font-size: 1.5rem;" (click)="iniciaevaluacion()">{{'menu_myevaluationespecific' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- <button (click)="downloadReport()">Descargar</button> -->

<div class="row" *ngIf="band == false">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <label for="rows.id_question">{{preguntaAMostrar['Pregunta']}}</label>
            </div>
        </div>
        <div class="row" *ngIf="preguntaAMostrar['TipoPreguntaID'] == 1">
            <div class="col-md-12 ha-ibox" *ngFor="let respuesta of preguntaAMostrar['RespuestasList']">
                <div>
                    <input type="checkbox" [value]="respuesta['respuesta']"
                        [(ngModel)]="respuesta['opcionEscogida']"
                        name="respuesta['descripcion']">
                    <label>&nbsp;{{respuesta['descripcion']}} </label>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="preguntaAMostrar['TipoPreguntaID'] == 2">
            <div class="col-md-12 ha-ibox" *ngFor="let respuesta of preguntaAMostrar['RespuestasList']">
                <div>
                    <input
                        type="radio"
                        [value]="respuesta['respuesta']"
                        [(ngModel)]="preguntaAMostrar['opcionEscogida']"
                        [name]="respuesta['descripcion']">
                    <label>&nbsp;{{respuesta['descripcion']}} </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ha-ibox" *ngIf="finalizar==false">
                <button type="button" class="btn buttons-pages" style="color: #ffffff;" (click)="GuardarRespuesta(preguntaAMostrar)">{{'next' | translate}}</button>
            </div>
            <div class="col-md-12 ha-ibox" *ngIf="finalizar==true">
                <button type="button" class="btn buttons-pages" style="color: #ffffff;" (click)="GuardarRespuesta(preguntaAMostrar)">{{'finish' | translate}}</button>
            </div>
        </div>
    </div>
</div>
