<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('InfoID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='InfoID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Title')" class="sh">
                                    {{'menu_title' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Title')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Description')" class="sh">
                                    {{'picture' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Description')" class="sh">
                                    {{'url' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Description')" class="sh">
                                    {{'date_creation' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('Description')" class="sh">
                                    {{'caducity_date' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.InfoID}}</td>
                                <td>{{row.Title}}</td>
                                <td style="width: 40px;"><img id="blah"  [src]="row.UrlPicture || 'undefined'" alt="your image" style="width: 100%;" /></td>
                                <td><a href="{{ row.UrlLinks }}" target="_blank" class="card-link">{{'rute' | translate}}</a></td>
                                <td> {{ row.CreationTime}}</td>
                                <td> {{ row.DateEnd }}</td>
                                <td class="align-text-top"><div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.InfoID}}" name="{{row.DetailName}}" (change)="toggleEditable($event,row.InfoID)" type="checkbox">
                                  </div></td> 
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <!-- <i (click)="deleteItem({anId:row.anId}, translations)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form [formGroup]="RegNewInfo" class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="fname" >{{'menu_title' | translate}} *</label>
                                    <input type="text" class="form-control" formControlName="Title" name="Title" required>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'menu_Subtitle' | translate}}</label>
                                    <input type="text" class="form-control" formControlName="SubTitle" name="SubTitle" required>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'desc' | translate}}</label>
                                    <textarea class="form-control" name="Description" id="Description" formControlName="Description" rows="4"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'link' | translate}}</label>
                                    <input type="text" class="form-control" formControlName="UrlLinks" name="UrlLinks">
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'date_caducity_publication' | translate}}</label>
                                    <input type="date" class="form-control" formControlName="DateEnd" name="DateEnd">
                                </div>
                                <div class="form-group">
                                    <input class="file_policiaco" type="file" name="file" (change)="onFileChanged($event)" accept="image/*" multiple="true" id="file" required/>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'picture' | translate}}</label>
                                    <div >
                                        <img id="blah" *ngIf="imageSrc" [src]="imageSrc || 'undefined'" alt="your image" width="100%"  height="100%"   style="border: 1px solid black;"/>
                                    </div>                      
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['InfoID']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="fname" >{{'menu_title' | translate}} *</label>
                                    <input type="text" class="form-control" [(ngModel)]="u_obj.Title" name="Title" required>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'menu_Subtitle' | translate}} *</label>
                                    <input type="text" class="form-control" [(ngModel)]="u_obj.SubTitle" name="SubTitle" required>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'descripcion' | translate}} *</label>
                                    <textarea class="form-control" name="Description" id="Description" [(ngModel)]="u_obj.Description" rows="3"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'link' | translate}} *</label>
                                    <input type="text" class="form-control" [(ngModel)]="u_obj.UrlLinks" name="UrlLinks" required>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'date_caducity_publication' | translate}} *</label>
                                    <input type="date" class="form-control" [(ngModel)]="u_obj.DateEnd" name="DateEnd" required>
                                </div>
                                <div class="form-group">
                                    <input class="file_policiaco" type="file" name="file" (change)="onFileChanged($event)" accept="image/*" multiple="true" id="file" required/>
                                </div>
                                <div class="form-group">
                                    <label for="fname" >{{'picture' | translate}}</label>
                                    <div >
                                        <img id="blah" *ngIf="imageSrc" [src]="imageSrc == undefined ? u_obj['UrlPciture']: imageSrc" alt="your image" width="100%"  height="100%"   style="border: 1px solid black;"/>
                                    </div>      
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
<!-- 
<input type="file" accept=".jpg,.png,.jpeg" id="image-input" (change)="selectFile($event)">
<br><br> Original: Size: {{sizeOfOriginalImage | number : '1.2-2'}}MB
<br><img [src]="localUrl" height="200px">
<br><br><br> Compressed: Size: {{sizeOFCompressedImage | number : '1.2-2'}}MB<br>
<img [src]="localCompressedURl" height="150px"> -->