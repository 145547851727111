import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { setTheme } from 'ngx-bootstrap/utils';

setTheme('bs4');

import { Ic2routes } from './ic2-routing';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Ic2Component } from '../ic2/ic2.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { QuillModule } from 'ngx-quill';
import {NgxImageCompressService} from 'ngx-image-compress';

import { BaseCrudComponent } from './base-crud/base-crud.component';
import { CompaniesComponent } from './companies/companies.component';
import { LaddaModule } from 'angular2-ladda';
import { LightboxModule } from 'ngx-lightbox';
import { OrderModule } from 'ngx-order-pipe';

import { SettingsComponent } from './settings/settings.component';
//CRUDS
import { CrudComponent } from './maint/crudhome/crud.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { CompanyComponent } from './maint/company/company.component';
import { CountryComponent } from './maint/country/country.component';
import { ProductionGroupComponent } from './maint/production-group/production-group.component';
import { UserComponent } from './maint/user/user.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParamjobtypeComponent } from './maint/paramjobtype/paramjobtype.component';
import { ParamplantaComponent } from './maint/paramplanta/paramplanta.component';
import { ParamareaComponent } from './maint/paramarea/paramarea.component';
import { ParamgeneralesComponent } from './maint/paramgenerales/paramgenerales.component';
import { ParamgenedetailComponent } from './maint/paramgenedetail/paramgenedetail.component';
import { ParamdocujobtypeComponent } from './maint/paramdocujobtype/paramdocujobtype.component';
import { ParamdocuComponent } from './maint/paramdocu/paramdocu.component';
import { ParamtypefoulComponent } from './maint/paramtypefoul/paramtypefoul.component';
import { ParamfoulsComponent } from './maint/paramfouls/paramfouls.component';
import { ParamsancionComponent } from './maint/paramsancion/paramsancion.component';
import { RegcompaniesComponent } from './docs/regcompanies/regcompanies.component';
import { RegcompaniesdocuComponent } from './docs/regcompaniesdocu/regcompaniesdocu.component';
import { ExcelenciahomeComponent } from './excelencia/excelenciahome/excelenciahome.component';
import { ParamcomunicadoComponent } from './maint/paramcomunicado/paramcomunicado.component';
import { ComunicadosComponent } from './maint/comunicados/comunicados.component';
import { ParamtipopreguntaComponent } from './maint/paramtipopregunta/paramtipopregunta.component';
//import { ParampreguntaComponent } from './maint/parampregunta/parampregunta.component';
import { ParampreguntadetalleComponent } from './maint/parampreguntadetalle/parampreguntadetalle.component';
import { ParamevaluacionComponent } from './maint/paramevaluacion/paramevaluacion.component';
import { RegcompaniesempComponent } from './docs/regcompaniesemp/regcompaniesemp.component';
import { QuestionsComponent } from './maint/questions/questions.component';
import { QuestiondetailComponent } from './maint/questiondetail/questiondetail.component';
import { RegemployeeComponent } from './docs/regemployee/regemployee.component';
import { RegemployeedocComponent } from './docs/regemployeedoc/regemployeedoc.component';
import { ConsdochistoryComponent } from './docs/consdochistory/consdochistory.component';
import { ConsdochistorycompaniesComponent } from './docs/consdochistorycompanies/consdochistorycompanies.component';
import { ConsdochistoryemployeesComponent } from './docs/consdochistoryemployees/consdochistoryemployees.component';
import { ProapprovedocumentsComponent } from './docs/proapprovedocuments/proapprovedocuments.component';
import { ProapprovedocumentscompanyComponent } from './docs/proapprovedocumentscompany/proapprovedocumentscompany.component';
import { ProapprovedocumentsemployeesComponent } from './docs/proapprovedocumentsemployees/proapprovedocumentsemployees.component';
import { ProrequestComponent } from './processes/prorequest/prorequest.component';
import { MyevaluationComponent } from './excelencia/myevaluation/myevaluation.component';
import { MyevaluationepecificComponent } from './excelencia/myevaluationepecific/myevaluationepecific.component';
import { OpportunitiesComponent } from './maint/opportunities/opportunities.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalFormComponent } from '../components/modal-form/modal-form.component';

import { EvaluationinternalComponent } from './processes/evaluationinternal/evaluationinternal.component';
import { RegtoolsequipmentComponent } from './forms/regtoolsequipment/regtoolsequipment.component';
import { ProadmissionappComponent } from './processes/proadmissionapp/proadmissionapp.component';
import { RepocreatorComponent } from './processes/repocreator/repocreator.component';

import { SafePipeModule } from 'safe-pipe';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { ReportingComponent } from './processes/reporting/reporting.component';
import { ProadmissemergappComponent } from './processes/proadmissemergapp/proadmissemergapp.component';
import { WorkersPlantComponent } from './excelencia/workers-plant/workers-plant.component';
import { ReportformsComponent } from './forms/reportforms/reportforms.component';
import { SapprovalComponent } from './excelencia/sapproval/sapproval.component';
import { ActnosecureComponent } from './excelencia/sapproval/actnosecure/actnosecure.component';
import { CondinosecureComponent } from './excelencia/sapproval/condinosecure/condinosecure.component';
import { IncidentComponent } from './excelencia/sapproval/incident/incident.component';
import { OportunitymejorComponent } from './excelencia/sapproval/oportunitymejor/oportunitymejor.component';
import { ActionplanComponent } from './excelencia/actionplan/actionplan.component';
import { PlanComponent } from './excelencia/actionplan/plan/plan.component';
import { HseComponent } from './excelencia/actionplan/hse/hse.component';
import { FivesComponent } from './excelencia/actionplan/fives/fives.component';
import { ScheduleComponent } from './excelencia/actionplan/schedule/schedule.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { InternalUserComponent } from './maint/internal-user/internal-user.component';
import { AvatarModule } from 'ngx-avatar';
import { PermissionsByUserComponent } from './maint/permissions-by-user/permissions-by-user.component';
import { UserProfileComponent } from './maint/user-profile/user-profile.component';
import { RegVehicleContraComponent } from './docs/reg-vehicle-contra/reg-vehicle-contra.component';
import { RegvehicluedocuComponent } from './docs/regvehicluedocu/regvehicluedocu.component';
import { ProapprovedocumentsvehicleComponent } from './docs/proapprovedocumentsvehicle/proapprovedocumentsvehicle.component';
import { QremployeeComponent } from './excelencia/qremployee/qremployee.component';
import { EntryemployeeComponent } from './excelencia/entryemployee/entryemployee.component';
import { RegdriverComponent } from './docs/regdriver/regdriver.component';
import { CondochistoryvehiclesComponent } from './docs/condochistoryvehicles/condochistoryvehicles.component';
import { EvalassignbyuserComponent } from './processes/evalassignbyuser/evalassignbyuser.component';
import { DocshomeComponent } from './docs/docshome/docshome.component';
import { ApprovedocumentsbaseComponent } from './docs/approvedocumentsbase/approvedocumentsbase.component';
import {ParampreguntaComponent} from "./maint/parampregunta/parampregunta.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApprovedocumentsvehiclesComponent } from './docs/approvedocumentsvehicles/approvedocumentsvehicles.component';
import { ApprovedocumentscompaniesComponent } from './docs/approvedocumentscompanies/approvedocumentscompanies.component';
import { ApprovedocumentsemployeesComponent } from './docs/approvedocumentsemployees/approvedocumentsemployees.component';
import { ReportshomeComponent } from './reports/reportshome/reportshome.component';
import { RptdocumentexternalworkerComponent } from './reports/rptdocumentexternalworker/rptdocumentexternalworker.component';
import { RptworkersbycompanyComponent } from './reports/rptworkersbycompany/rptworkersbycompany.component';
import { RptvehiclesbycompanyComponent } from './reports/rptvehiclesbycompany/rptvehiclesbycompany.component';
import { RptevaluationrequestComponent } from './reports/rptevaluationrequest/rptevaluationrequest.component';
import { RptentryrequestComponent } from './reports/rptentryrequest/rptentryrequest.component';
import { RptworkpermissionComponent } from './reports/rptworkpermission/rptworkpermission.component';
import {TooltipModule} from "primeng/tooltip";
import {MatTableModule} from "@angular/material/table";
import {MatTreeModule} from "@angular/material/tree";
import {MatExpansionModule} from "@angular/material/expansion";
import {RptcompanyComponent} from "./reports/rptcompy/rptcompany.component";


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    FullComponent,
    ModalFormComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    Ic2Component,
    BaseCrudComponent,
    CrudComponent,
    CompaniesComponent,
    SettingsComponent,
    ParameterComponent,
    CompanyComponent,
    CountryComponent,
    ProductionGroupComponent,
    UserComponent,
    FormshomeComponent,
    ProchomeComponent,
    DashboardComponent,
    ParamjobtypeComponent,
    ParamplantaComponent,
    ParampreguntaComponent,
    ParamareaComponent,
    ParamgeneralesComponent,
    ParamgenedetailComponent,
    ParamdocujobtypeComponent,
    ParamdocuComponent,
    ParamtypefoulComponent,
    ParamfoulsComponent,
    ParamsancionComponent,
    RegcompaniesComponent,
    RegcompaniesdocuComponent,
    ExcelenciahomeComponent,
    ParamcomunicadoComponent,
    ComunicadosComponent,
    ParamtipopreguntaComponent,
    ////ParampreguntaComponent,
    ParampreguntadetalleComponent,
    ParamevaluacionComponent,
    RegcompaniesempComponent,
    QuestionsComponent,
    QuestiondetailComponent,
    RegemployeeComponent,
    RegemployeedocComponent,
    ConsdochistoryComponent,
    ConsdochistorycompaniesComponent,
    ConsdochistoryemployeesComponent,
    ProapprovedocumentsComponent,
    ProapprovedocumentscompanyComponent,
    ProapprovedocumentsemployeesComponent,
    ProrequestComponent,
    MyevaluationComponent,
    MyevaluationepecificComponent,
    OpportunitiesComponent,
    EvaluationinternalComponent,
    RegtoolsequipmentComponent,
    ProadmissionappComponent,
    RepocreatorComponent,
    ReportingComponent,
    ProadmissemergappComponent,
    WorkersPlantComponent,
    ReportformsComponent,
    SapprovalComponent,
    ActnosecureComponent,
    CondinosecureComponent,
    IncidentComponent,
    OportunitymejorComponent,
    ActionplanComponent,
    PlanComponent,
    HseComponent,
    FivesComponent,
    ScheduleComponent,
    InternalUserComponent,
    PermissionsByUserComponent,
    UserProfileComponent,
    RegVehicleContraComponent,
    RegvehicluedocuComponent,
    ProapprovedocumentsvehicleComponent,
    QremployeeComponent,
    EntryemployeeComponent,
    RegdriverComponent,
    CondochistoryvehiclesComponent,
    EvalassignbyuserComponent,
    DocshomeComponent,
    ApprovedocumentsbaseComponent,
    ApprovedocumentsvehiclesComponent,
    ApprovedocumentscompaniesComponent,
    ApprovedocumentsemployeesComponent,
    ReportshomeComponent,
    RptdocumentexternalworkerComponent,
    RptworkersbycompanyComponent,
    RptvehiclesbycompanyComponent,
    RptevaluationrequestComponent,
    RptentryrequestComponent,
    RptworkpermissionComponent,
    RptcompanyComponent
      ],
  imports: [
    AvatarModule,
    YouTubePlayerModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    NgSelectModule,
    PdfViewerModule,
    //RouterModule.forRoot(Ic2routes, {onSameUrlNavigation: 'reload'}),
    //RouterModule.forRoot(Ic2routes, {onSameUrlNavigation: 'reload'}),
    RouterModule.forRoot(Ic2routes, {relativeLinkResolution: 'legacy'}),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LaddaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LightboxModule,
    OrderModule,
    SafePipeModule,
    QuillModule.forRoot(),
    TooltipModule,
    MatTreeModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    NgSelectConfig,
    ɵs,
    DatePipe,
    NgxImageCompressService,
    MatTableModule,
    MatTreeModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
  /*,
  bootstrap: [AppComponent]*/
})
export class Ic2Module {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
    //return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
