<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class=" white innercontainer menuconfig">
                    <form class="col-12 modal-body" name="Opportunities">
                        <h4 class="modal-title pb-2 pt-2 mb-2 pl-3" style="color: #ffffff; background-color: #F38230;">{{'opportunities_update' | translate}}</h4>
                        <div class="row">
                            <div class="col-md-3 ha-inbox">
                                <label class="text-left control-label col-form-label ">{{'identification' | translate}}</label>
                                <input type="text" class="form-control" maxlength="13" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (keypress)="onlyNumberKey($event)" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'])">
                            </div>
                            <div class="col-md-3 ha-inbox">
                                <label class="text-left control-label col-form-label">{{'name' | translate}}</label>
                                <input type="text" class="form-control" name="nameemployee"  [(ngModel)]="c_obj.nameemployee" disabled >
                            </div>
                            <div class="col-md-3 ha-inbox">
                                <label class="text-left control-label col-form-label">{{'company' | translate}}</label>
                                <input type="text" class="form-control" name="NameCompany" [(ngModel)]="c_obj.NameCompany" disabled>
                            </div>
                            <div class="col-md-3 ha-inbox">
                                <label class="text-left control-label col-form-label">{{'subcompany' | translate}}</label>
                                <input type="text" class="form-control" name="namesubcompany" [(ngModel)]="c_obj.namesubcompany" disabled>
                            </div>
                        </div>
                         <div class="row">
                          <div class="col-md-12 ha-ibox" style="text-align: left;">
                                <!--  <label class="col-sm-6 text-left control-label col-form-label">{{'evaluation' | translate}}</label>
                                <select class="custom-select custom-select-md" name="ParameEvaID" [(ngModel)]="curr_parameter" (change)="ConsultaData(curr_parameter)">
                                    <option *ngFor="let p of evaluacion; let i = index"  [value]="p['ParameEvaID']" >{{p['EvaluationName']}}</option>
                                </select>-->
                            </div>
                        </div> 
                        <div class="row"  *ngIf="flag == false">
                            <div class="col-md-12 ha-ibox">
                                <h5>{{'evaluation_request' | translate}}</h5>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead  style="padding: 0">
                                            <tr>
                                                <!-- <th>{{'code' | translate}}</th> -->
                                                <th>{{'evaluation'| translate}}</th>
                                                <th>{{'repeat' | translate}}</th>
                                                <th>{{'max_repeat' | translate}}</th>
                                                <!-- <th>{{'last_calification' | translate}}</th> -->
                                                <th>{{'reason' | translate}}</th>
                                                <th>{{'requiredby' | translate}}</th>
                                                <th></th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let parameter of rows; let i=index">
                                                <!-- <td>{{parameter.MyEvaluationId}}</td> -->
                                                <td>{{parameter.NameEvaluation}}</td>
                                                <td>{{parameter.vecesrepetidas}}</td>
                                                <td>{{parameter.max_repetition}}</td>
                                                <!-- <td>{{parameter.score_evaluation}}</td> -->
                                                <td>{{parameter.requiredWhy}}</td>
                                                <td>{{parameter.requiredBy}}</td>
                                                <td class="action-buttons">
                                                    <i (click)="openUpdateModal(update1, parameter)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-12 ha-ibox" style="text-align: center;">
                                <!-- <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="siguiente()">{{'save' | translate}}</button> -->
                            </div>

                        </div>
                    </form>
                </div>
                <!--modal para aumentar las veces-->
                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['NameEvaluation']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-12 ha-ibox">
                                        <label for="fname">{{'repeat' | translate}} </label>
                                        <input type="text" class="form-control" value="{{u_obj['vecesrepetidas']}}" name="vecesrepetidas" readonly>      
                                    </div>
                                </div>
                                <div class="row">                                    
                                    <div class="col-md-12 ha-ibox">
                                        <label for="fname" >{{'plass_repeat' | translate}} ({{'val_add_exam' | translate}}) </label> 
                                        <input type="text" class="form-control" [(ngModel)]="veces" name="max_repetition" (keypress)="onlyNumberKey($event)" required>     
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 ha-ibox">
                                        <label>{{'reason' | translate}}* </label>
                                        <textarea  rows="3"  class="form-control" name="requiredWhy" [(ngModel)]="u_obj['requiredWhy']" required style="resize: none;"></textarea>   
                                    </div>
                                </div>
                                <div class="row">                                    
                                    <div class="col-md-12 ha-ibox">
                                        <label>{{'requested_by' | translate}} *</label>
                                        <textarea  rows="3"  class="form-control" name="requiredBy" [(ngModel)]="u_obj['requiredBy']" required style="resize: none;"></textarea>        
                                    </div>
                                </div>
                            </div>
                            
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="AumentarVeces(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
