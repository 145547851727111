import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import { PermissionService } from 'src/app/services/authentication/permission.service';
import { environment } from 'src/environments/environment';
import {isNumeric} from "rxjs/util/isNumeric";
import {Storage} from "@ionic/storage";

@Component({
  selector: 'app-formshome',
  templateUrl: './formshome.component.html',
  styleUrls: ['./formshome.component.css']
})
export class FormshomeComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items = [];
    permissions = [];
    userinfo: any;
  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService,
      public permission: PermissionService,
      private storage: Storage,
  	) { }

  	ngOnInit() {
      let id = environment.Global.Menus.Formularios // menu formularios
      this.permission.getModulesByMenuId(id).then(x => {
        x.forEach(element => {
          if (element.principal == 1) {
            this.items.push(element)
          }
        });
      })
      this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        // console.log(this.userinfo)
      });
      this.auth.getPermissions().then(x => {
        this.permissions = x;
      });
  	}

    hasPermission(str:string){
      let flag = false;
      this.permissions.forEach(permission => {
        if(permission.Permission == str) {
          flag = true;
        }
      })
      return flag;
    }

}


















// this.items = [
      //   {title: 'menu_register_company_admin', url: '/regcompanie', icon: 'fas fa-industry', perm: 'SI_REGCOMPANIES'},
      //   {title: 'menu_regisEmpresa', url: '/regcompanieemp', icon: 'fas fa-industry', perm: 'SI_REGCOMPANIESEMP'},
      //   {title: 'menu_subcontratista', url: '/regsubcontract', icon: 'fas fa-building', perm: 'SI_REGSUBCONTRATISTA'},
      //   {title: 'menu_trabajadores', url: '/regemployee', icon: 'fa fa-users', perm: 'SI_REGEMPLOYE'},
      //   {title: 'menu_reporte', url: '/reportforms', icon: 'fas fa-chart-bar', perm: 'SI_REPORT_RESULT'},
      //   {title: 'menu_title_form_documenthistory', url: '/consdochistory', icon: 'fas fa-history', perm: 'SI_DOCUMENTS'},

      // ];
