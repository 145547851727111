<!-- <div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body nopadding">
        <div class="container menuconfig">
          <div class="row config_header_fixes paddingtopheader">

          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <form class="col-12 modal-body" name="NewCompanie">
        <!--Sección de Formulario-->
        <h4 class="modal-title pb-2 pt-2 mb-2 pl-3 modal-color" style="color: #ffffff">
          {{ "menu_title_newcompanies" | translate }}
        </h4>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group pl-3 pr-3">
              <label for="fname">{{ "date" | translate }}</label>
              <input type="text" readonly class="form-control" id="staticEmail"
                     value="{{this.u_obj.CreationTime | date:'yyyy-MM-dd'}}" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group pl-3 pr-3">
              <label for="fname">{{ "service_type" | translate }}</label>
              <input type="text" readonly class="form-control" id="staticService"
                     value="{{(this.u_obj.IsRecurrent==1)? 'Recurrente': 'No recurrente'}}" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group pl-3 pr-3">
              <label for="rname">{{ "ruc" | translate }}</label>
              <input type="text" readonly class="form-control" id="staticEmail" value="{{ userinfo?.logon }}" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group pl-3 pr-3">
              <label for="fname">{{ "empresa" | translate }}</label>
              <input type="text" readonly class="form-control" id="staticEmail" value="{{ userinfo?.name}}" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group pl-3 pr-3">
              <label for="fname">{{ "email" | translate }}</label>
              <input type="text" readonly class="form-control" id="staticEmail"
                     value="{{ userinfo?.emailAddress  }}" />
            </div>
          </div>
        </div>
        <form class="form">
          <div class="form-group row">
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="fname">{{ "company_type" | translate }}</label>
                <!-- <input type="text" class="form-control" id="staticEmail"
                    value="{{userinfo['typeCompany'] == 1 ? 'Contratista' : 'Transportista'}}" /> -->

                <ng-select [(ngModel)]="u_obj['typeCompany']" name="typecompany" [multiple]="false">
                  <ng-option *ngFor="let option of tipo_empresa; index as i" [value]="option.id">{{option.name |
                    translate}}</ng-option>
                </ng-select>

                <!-- <select class="form-control input-top-create" name="typecompany"
                  [(ngModel)]="u_obj['typeCompany']" required>
                  <ng-option *ngFor="let e of tipo_empresa; let i = index" [value]="e.id"> {{e['name']}}</ng-option>
                </select> -->

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "phone" | translate }}</label>
                <input type="text" class="form-control input-top-create" (keypress)="onlyNumberKey($event)"
                       [(ngModel)]="u_obj['Telephone']" name="Telephone" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "direccion" | translate }}</label>
                <textarea rows="3" class="form-control" name="Direction" [(ngModel)]="u_obj['Direction']" required
                          style="resize: none"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "identification" | translate }}
                  {{ "representative" | translate }}
                  {{ "legal" | translate }}*</label>
                <input type="text" maxlength="10" class="form-control" (keypress)="onlyNumberKey($event)"
                       [(ngModel)]="u_obj['com_legalidentification']" name="com_legalidentification" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "ci_representantelegal_si" | translate }}*</label>
                <input type="text" maxlength="10" (keypress)="onlyNumberKey($event)" class="form-control"
                       [(ngModel)]="u_obj['com_Securityidentification']" name="com_securityidentification" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "representative" | translate }}
                  {{ "legal" | translate }}*</label>
                <input type="text" class="form-control" [(ngModel)]="u_obj['com_legalrepresentative']"
                       name="com_legalrepresentative" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "representante_si" | translate }}*</label>
                <input type="text" class="form-control" [(ngModel)]="u_obj['com_Securityrepresentative']"
                       name="com_ecurityrepresentative" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "email" | translate }}
                  {{ "representative" | translate }}
                  {{ "legal" | translate }}*</label>
                <input type="email" class="form-control" [(ngModel)]="u_obj['com_legalmail']" name="com_legalmail"
                       required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group pl-3 pr-3">
                <label for="tname">{{ "mail_si" | translate }}*</label>
                <input type="email" class="form-control" [(ngModel)]="u_obj['com_Securitymail']" name="com_securitymail"
                       required />
              </div>
            </div>
            <div class="col-md-12" *ngIf="u_obj['typeCompany']==1">
              <label for="tname">{{ "templates" | translate }}</label>
              <div class="form-group pl-3 pr-3 text-center" style="border: 1px solid #0D345933;border-radius: 5px">
                  <ul style=" margin:10px" class="text-left">
                    <li style=" margin-top:10px; padding: 0">
                      <a [href]="rutaArchivoMEDEVAC" download="MEDEVAC.xlsx" >Descargar MEDEVAC</a>

                    </li >
                    <li style=" margin-top:10px; padding: 0">
                      <a [href]="rutaArchivoServicio" download="Entrega_de_Servicio.xlsx" >Descargar Formato del Informe Final entrega de servicio</a>
                    </li>
                    <li style=" margin-top:10px; padding: 0">
                      <a [href]="rutaArchivoReporte" download="Formato_Reporte_Contratistas.xlsx" >Descargar Formato del Reporte de Campo</a>
                    </li>
                    <li style=" margin-top:10px; padding: 0">
                      <a [href]="rutaArchivoIndice" download="GIS_Contratista_VF.xlsx" >Descargar Índice de Gestión Integral</a>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-md-12 text-right">
            <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal"
                    style="color: #ffffff" (click)="updateItem(NewCompanie, u_obj)">
              {{ "update" | translate }}
            </button>
          </div>
        </div>
        <br />

        <!--Seccion de Documentos-->
        <div class="row">
          <div class="col-md-12">
            <ngb-accordion #acc="ngbAccordion">

              <!--Carga de Documentos-->
              <ngb-panel>
                <ng-template ngbPanelTitle>
                  <span>
                    <b>{{
                      "menu_title_man_documento" | translate
                      }}</b></span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card-body col-12 container">
                    <div class="row">
                      <div class="col-12 table-responsive">
                        <table class="table" style="overflow-x:auto;">
                          <thead style="padding: 0">
                          <tr>
                            <th>{{ "name" | translate }}</th>
                            <th>{{ "desc" | translate }}</th>
                            <th>{{ "Obligatorio" | translate }}</th>
                            <th>{{ "emition_date" | translate }}</th>
                            <th>{{ "caducity_date" | translate }}</th>
                            <th>{{ "document" | translate }}</th>
                            <th rowspan="2">{{ "action" | translate }}</th>
                          </tr>
                          </thead>
                          <tbody *ngIf="rows != null">
                            <tr *ngFor="let row of rowdocument;index as i">
                              <td>
                                <div style="width: 150px;">
                                  {{ row.DocumentName }}
                                </div>
                              </td>
                              <td>
                                <div style="width: 150px;">
                                  {{ row.DocumentDescription }}
                                </div>
                              </td>
                              <td class="align-text-top">
                                <div class="form-check">
                                  <input class="form-check-input" [attr.checked]="
                                        row.Is_required == true
                                          ? 'checked'
                                          : null
                                      " id="{{ row.ParamDocumentID }}" name="{{ row.DocumentName }}" type="checkbox"
                                       disabled />
                              </div>
                            </td>
                            <td class="align-text-top">
                              <div>
                                <input type="date" [ngModel]="
                                        row.date_doc | date: 'yyyy-MM-dd'
                                      " (ngModelChange)="row.date_doc = $event" name="dt"
                                       class="form-control form-control-rounded" #searchDate />
                              </div>
                            </td>
                            <td class="align-text-top">
                              <div>
                                <input type="date" [ngModel]="
                                        row.date_doc_end | date: 'yyyy-MM-dd'
                                      " (ngModelChange)="row.date_doc_end  = $event" name="dte"
                                       class="form-control form-control-rounded" #searchDate />
                              </div>
                            </td>
                            <td class="align-text-top">
                              <div class="row" style="background-color: lavenderblush">
                                {{ "comp_max_doc_size" | translate }}
                                {{ "max_doc_size" | translate }} MB
                              </div>
                              <div class="form-group">
                                <label for="file"></label>
                                <input type="file" id="file" (change)="handleInputChange($event, i)" [disabled]="
                                        row.statusDocument == 'E' || row.statusDocument == null
                                        || row.statusDocument == 'R'? false : true" accept=".pdf,application/pdf"/>
                                </div>
                              </td>
                              <td class="action-buttons"  *ngIf="row.statusDocument==null else other_content">
                                <i  [id]="'guardarButton'+i" (click)="onExpSubmit(row)" style="cursor: pointer"
                                  *ngIf="!(row.statusDocument == 'E' || row.statusDocument == 'R' || row.statusDocument == null) ? false : true"
                                  class="fa fa-save text-info m-r-10" title="{{ 'save' | translate }}"></i>
                              </td>
                              <ng-template #other_content>
                                <td class="action-buttons" *ngIf="row.statusDocument!=null">
                                <i  id="correctIcon"
                                    class="fa fa-check-square m-r-10" title="{{ 'save' | translate }}" style="color: #49d416;"></i>
                              </td>
                              </ng-template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <!--Documentos Enviados-->
              <ngb-panel>
                <ng-template ngbPanelTitle>
                  <span>
                    <b>{{ "documents_send" | translate }}</b></span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card-body col-12 container">
                    <div class="table-responsive">
                      <table class="table">
                        <thead style="padding: 0">
                        <tr>
                          <th>{{ "document_upload" | translate }}</th>
                          <th>{{ "name_archive" | translate }}</th>
                          <th>{{ "emition_date" | translate }}</th>
                          <th>{{ "expiration_date" | translate }}</th>
                          <th>{{ "status" | translate }}</th>
                          <th>{{ "comment" | translate }}</th>
                          <th>{{ "action" | translate }}</th>
                        </tr>
                        </thead>
                        <tbody *ngIf="rowdocumentupload != null">
                        <tr *ngFor="let row1 of rowdocumentupload">
                          <td>
                            <div style="width: 150px;">
                              {{ row1.NameDoc }}
                            </div>
                          </td>
                          <td>
                            <div style="width: 150px;">
                              {{ row1.ImageFileName }}
                            </div>
                          </td>
                          <td>
                            <div style="width: 150px;">
                              {{ row1.DateDocument | date: "y-MM-dd" }}
                            </div>
                          </td>
                          <td>
                            <div style="width: 150px;">
                              {{ row1.ExpirationTime | date: "y-MM-dd" }}
                            </div>
                          </td>
                          <td>{{ row1.status_name }}</td>
                          <td>
                            <div style="width: 150px;">
                              {{ (row1.VerificateComment == null || row1.VerificateComment == "") ? "Sin novedad" :
                              row1.VerificateComment }}
                            </div>
                          </td>
                          <td class="action-buttons">
                            <i (click)="openViewModal(view, row1)" style="cursor:pointer;" class="mdi mdi-eye text-info m-r-10" title="{{'Visualize' | translate}}"></i>

                            <!-- <i (click)="downloadDocFile(row1)" style="cursor: pointer"
                              class="fa fa-cloud-download-alt m-r-10" title="{{ 'export' | translate }}">
                            </i> -->

                            <i *ngIf="row1.status_name == 'ACTIVO' || row1.status_name == 'RECHAZADO'"
                               (click)="deleteFile(row1, modaldelete, row1.NameDoc, row1.CompDocumentId)"
                               style="cursor: pointer" class="fa fa-trash-alt m-r-10"
                               title="{{ 'delete' | translate }}">
                            </i>


                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <div class="modal-footer">
              <div *ngIf="cargados != true" style="background-color: lavenderblush">
                <label class="col-sm-12 text-center control-label col-form-label">{{ "mensaje_status" | translate
                  }}</label>
              </div>
              <div *ngIf="cargados == true">
                <button [ladda]="isUpdating" (click)="PoliticsPrivate()" data-style="expand-" type="button"
                        class="btn button-modal" style="color: #ffffff">
                  {{ "verificate" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>



<!-- Modal Delete -->
<ng-template #modaldelete let-c="close" let-d="dismiss" let-modal>

  <!--Modal Header-->
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">
      {{ "confirmated" | translate }}
    </h4>

    <button (click)="modal.dismiss('Cross click')" type="button" class="close default-color-text" label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--Modal Body-->
  <div class="modal-body">
    <form class="form-horizontal">
      <div class="card-body">
        <div class="form-group">
          <h5> {{ "textDeleteDoc" | translate }} {{ this.docName }} ?</h5>
        </div>
      </div>
    </form>
  </div>

  <!--Modal Footer-->
  <div class="modal-footer">
    <button (click)="closeModal(modaldelete)" type="button" class="btn btn-custom-84888b">
      {{ "cancel" | translate }}
    </button>

    <button type="button" data-style="expand-left" class="btn btn-success" style="color: #ffffff;">
      <i (click)="confirmDeleteFile(this.position, modaldelete, this.CompDocumentId)"
         title="{{ 'confirm' | translate }}"> {{ 'confirm' | translate }}</i>
    </button>
  </div>

</ng-template>

<ng-template #view let-c="close" let-d="dismiss" let-modal>
  <div class="modal-header head-modal-bg">
    <h4 class="modal-title">{{'preview' | translate}}</h4>
    <div class="close default-color-text" style="align-items: right;font-size: 25px; margin-top: -5px;">
      <i (click)="downloadDocFile(this.row2)" style="cursor:pointer;" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}" title="{{'export' | translate}}"></i>
      <button type="button" class="close default-color-text" label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form class="form-horizontal">
      <ng-container *ngIf=" this.isPDF == true; else elseTemplate">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
                    style="width: 100%; height:800px" [external-link-target]="'blank'"></pdf-viewer>
      </ng-container>
      <ng-template #elseTemplate>
        <div style="display: flex;">
          <img style="justify-content: center;align-items: center; width: 100%;height:600px" [src]="pdfSrc"
               alt="">
        </div>
      </ng-template>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-84888b" (click)="closeModal(view)">{{'Close' | translate}}</button>
  </div>
</ng-template>
