<div class="card-info">
    <div class="card-flex" *ngFor="let row of items" [routerLink]="row.url" >
      <div class="card-body">
        <div class="container-values">
          <div class="icon"><i class="{{row.icon}}"></i></div>
          <div class="title">{{ row.title | translate }}</div>
        </div>
      </div>
    </div>
</div>

<!-- [hidden]="!hasPermission(row.perm)" -->























<!-- <div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <div >
                            <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;"  ><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #create1 let-c="close" let-d="dismiss" let-modal>
    <div class="modal-header modal-color" >
        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
        <button type="button" style="color: #ffffff;" class="close" label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6 ha-ibox">
                <h4>{{'select_work_perm' | translate}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <select class="custom-select custom-select-md" name="worker_perm" [(ngModel)]="c_obj['worker_perm']" >
                    <option [value]="null">{{'select' | translate}}</option>
                    <option *ngFor="let pw of list_work_perm" [value]="pw |json">{{pw.WorkAuthId}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-3 ha-ibox">
                <h4>{{'menu_mtrabajador' | translate}}</h4>
            </div>
        </div>
        <ngb-tabset [justify]="'start'">
            <ngb-tab>
            <ng-template ngbTabTitle><b>{{'act_nosecure' | translate}}</b></ng-template>
                <ng-template ngbTabContent>
                    <br>
                    <h4>{{'data_details_request'| translate}}</h4>
                    <div class="col-md-12 ha-ibox">
                        <h6>**{{'detail_employees'| translate}}</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'identification' | translate}} *</label>
                            <input type="text" maxlength="13"  class="form-control" name="NumberIdentification" [(ngModel)]="c_obj['NumberIdentification']" (keypress)="onlyNumberKey($event)" (blur)="GetEmployeeforIdentification(c_obj['NumberIdentification'],1)">
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'name' | translate}}</label>
                            <input type="text"  class="form-control" name="nameemployee" [(ngModel)]="c_obj['NameEmployee']" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'subcompany'|translate}}</label>
                            <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']" readonly>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'jobtitle' | translate}}</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'date'|translate}}</label>
                            <input type="text"  class="form-control" name="dateActInsecure" [(ngModel)]="c_obj['dateActInsecure']" readonly>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'menu_mycompanies' | translate}}</label>
                            <input type="text"  class="form-control" name="company" [(ngModel)]="c_obj['company']" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}}</label>
                            <input type="text"  class="form-control" name="level" [(ngModel)]="c_obj['level']"  readonly>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'perm_number' | translate}}</label>
                            <input type="text"  class="form-control" name="perm_number" [(ngModel)]="c_obj['perm_number']" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'user_sol'|translate}}</label>
                            <input type="text"  class="form-control" name="user_sol" [(ngModel)]="c_obj['user_sol']">
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'email_sol' | translate}}</label>
                            <input type="text"  class="form-control" name="email_sol" [(ngModel)]="c_obj['email_sol']" >
                        </div>
                    </div>
                    <hr>
                    <h4>{{'should_register'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'cmp_area'|translate}}*</label>
                            <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']"  title="{{'autocomplete'| translate}}" >
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'report_by' | translate}}*</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'fault_type' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['fault_type']"  (change)="getFouls(c_obj['fault_type'])">
                                <option [value]="null">{{'Select_fault' | translate}}</option>
                                <option *ngFor="let f of list_fouls" [value]="f.FoulTypeID">{{f.NameFoul}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label for="job_area" class="text-right control-label col-form-label"> {{'fault_associated' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="job_area" [(ngModel)]="c_obj['fault_associated']">
                                <option [value]="null">{{'Select_fault_detail' | translate}}</option>
                                <option *ngFor="let fd of list_fouls_detail" [value]="fd.ParaDetaFoulID">{{fd.Name}}</option>
                            </select>   
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'sanction' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['type_sanction']">
                                <option [value]="null">{{'Select_type_sanction' | translate}}</option>
                                <option *ngFor="let s of list_sanction" [value]="s.SanctionID">{{s.NameSan}}</option>
                            </select> 
                        </div>
                    </div>
                    <hr>
                    <h4>{{'evidence'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="uploadFile" class="text-right control-label col-form-label">{{'upload_img' | translate}} *</label>
                            <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple  (change)="handleInputChange($event)">
                            <small>{{'only_2_pic' | translate}}</small>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <div *ngIf="archivos.length > 0">
                                <label for="uploadFile" class="text-right control-label col-form-label">{{'images' | translate}}</label>
                                <ol>
                                    <li  *ngFor="let a of archivos">
                                        {{a.name}}
                                    </li>
                                </ol>
                            </div>          
                        </div>
                    </div>
                    <hr>
                    <h4>{{'finish'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'close_fault' | translate}}</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'samp_comment' | translate}}</label>
                            <textarea rows="4" type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle><b>{{'condi_nosecure' | translate}}</b></ng-template>
                <ng-template ngbTabContent>
                    <br>
                    <h4>{{'detail_inspection'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'category' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']">
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'date'|translate}}*</label>
                            <input type="date"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'cmp_area' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'report_by' | translate}}*</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" >
                        </div>
                    </div>
                    <hr>
                    <h4>{{'evidence'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="uploadFile" class="text-right control-label col-form-label">{{'upload_img' | translate}} *</label>
                            <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple>
                            <small>{{'only_2_pic' | translate}}</small>
                        </div>
                    </div>
                    <hr>
                    <h4>{{'finish'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'close_fault' | translate}}</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'samp_comment' | translate}}</label>
                            <textarea rows="4" type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle><b>{{'incident' | translate}}</b></ng-template>
                <ng-template ngbTabContent>
                    <br>
                    <h4>{{'detail_incident'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'date'|translate}}</label>
                            <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']" readonly>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'immediate_boss' | translate}}*</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'severity_of_injury' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']">
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']">
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'cmp_area' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>                    
                        <div class="col-md-6 ha-ibox">
                            <label>{{'report_by' | translate}}*</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']" >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'medt_take'|translate}}*</label>
                            <textarea type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'witness' | translate}}*</label>
                            <textarea type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                    </div>
                    <hr>
                    <h4>{{'evidence'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="uploadFile" class="text-right control-label col-form-label">{{'upload_img' | translate}} *</label>
                            <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple>
                            <small>{{'only_2_pic' | translate}}</small>
                        </div>
                    </div>
                    <hr>
                    <h4>{{'finish'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'close_fault' | translate}}</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'samp_comment' | translate}}</label>
                            <textarea rows="4" type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle><b>{{'accident' | translate}}</b></ng-template>
                <ng-template ngbTabContent>
                    <br>
                    <h4>{{'detail_accident'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'date'|translate}}</label>
                            <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']" readonly>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'immediate_boss' | translate}}*</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'severity_of_injury' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']">
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']">
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'cmp_area' | translate}} *</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>                    
                        <div class="col-md-6 ha-ibox">
                            <label>{{'report_by' | translate}}*</label>
                            <input type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']" >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'medt_take'|translate}}*</label>
                            <textarea type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'witness' | translate}}*</label>
                            <textarea type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                    </div>
                    <hr>
                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle><b>{{'oportunity_mejor' | translate}}</b></ng-template>
                <ng-template ngbTabContent>
                    <br>
                    <h4>{{'detail_mejor'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'date'|translate}}</label>
                            <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']" readonly>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'level' | translate}}*</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'cmp_area' | translate}}*</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label for="level" class="text-right control-label col-form-label"> {{'incid' | translate}}*</label>
                            <select class="custom-select custom-select-md" name="level" [(ngModel)]="c_obj['level']" >
                                <option [value]="null">{{'Select-level' | translate}}</option>
                            </select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label>{{'report_by'|translate}}*</label>
                            <input type="text"  class="form-control" name="NameSubCompany" [(ngModel)]="c_obj['namesubcompany']">
                        </div>
                    </div>
                    <hr>
                    <h4>{{'evidence'| translate}}</h4>
                    <div class="row">
                        <div class="col-md-6 ha-ibox">
                            <label for="uploadFile" class="text-right control-label col-form-label">{{'upload_img' | translate}}*</label>
                            <input type="file" class="form-control" accept="image/png, image/jpeg"  multiple>
                            <small>{{'only_2_pic' | translate}}</small>
                        </div>
                        <div class="col-md-6 ha-ibox">
                            <label>{{'observation' | translate}}*</label>
                            <textarea type="text"  class="form-control" name="position" [(ngModel)]="c_obj['position']"  title="{{'autocomplete'| translate}}" ></textarea>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
          </ngb-tabset>        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
        <button data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
    </div>
</ng-template> -->
