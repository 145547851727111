<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn buttons-pages" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn buttons-pages" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="table-head text-white">
                            <tr>
                                <th (click)="setOrder('ID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='DetailID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('field')" class="sh">
                                    {{'parameter' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Namegeneric')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('value')" class="sh">
                                    {{'desc' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Description')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'habidesa' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.ID}}</td>
                                <td>{{row.field}}</td>
                                <td>{{row.value}}</td>
                                <td class="align-text-top"> <div class="form-check">
                                    <input class="form-check-input" [attr.checked]="(row.Status == 'A') ? 'checked' : null" id="{{row.DetailID}}" name="{{row.Name}}" (change)="toggleEditable($event,row.ID)" type="checkbox">
                                  </div></td>
                                <td class="action-buttons" *ngIf="row.Status == 'A'">
                                    <!-- <i (click)="goTo('/paramgenedetail/'+row.ParId, {})" style="cursor:pointer;" class="ti-settings text-info m-r-10" title="{{'areas' | translate}}"></i> -->
                                    <i (click)="openUpdate(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'name' | translate}} *</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['field']" name="field" disabled>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">{{'type' | translate}}</label>
                                        <div class="col-sm-9">
                                            <select class="custom-select custom-select-md" [(ngModel)]="c_obj['UserType']"  name="UserType">
                                                <option value="E">{{'menu_mcompanies' | translate}}</option>
                                                <option value="T">{{'menu_mtrabajador' | translate}}</option>
                                                <option value="G">{{'menu_mtodos' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" [(ngModel)]="c_obj['value']" name="value" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header modal-color" >
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'name' | translate}} *</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.field" name="field" required>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">{{'type' | translate}}</label>
                                        <div class="col-sm-9">
                                            <select class="custom-select custom-select-md" name="planta" [(ngModel)]="u_obj.UserType">
                                                <option value="E">{{'menu_mcompanies' | translate}}</option>
                                                <option value="T">{{'menu_mtrabajador' | translate}}</option>
                                                <option value="G">{{'menu_mtodos' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" name="value" id="value" [(ngModel)]="u_obj.value" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn button-modal" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn button-modal" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Actualizar</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>