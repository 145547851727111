import { Component} from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import {  of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { isNumeric } from 'rxjs/util/isNumeric';
import { environment } from 'src/environments/environment';

const API_GET_JOB_TYPE = environment.Global.API_GET_JOB_TYPE;
const API_VERIFY_EXIST_EVAL_BY_EMPLOYEE_JOB = environment.Global.API_VERIFY_EXIST_EVAL_BY_EMPLOYEE_JOB;
@Component({
  selector: 'app-prorequest',
  templateUrl: './prorequest.component.html',
  styleUrls: ['./prorequest.component.css']
})
export class ProrequestComponent extends BaseCrudComponent {
  userinfo:any;
  row_emp:any = [];
  row_plantas:any;
  row_typejob:any;
  curr_parameter:any;
  loadingRequired = false;
  habilitar = false;
  evaluacion:any;
  ID:any;
  video;
  
  pageTotal2 = 0;
  page2 = 0;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.search_fields = ['RequestId','Ruc','namecompany','WhoRequest','status_eval'];
      this.c_obj['Request'] = [];
      this.curr_parameter = null;
    }


  async ngOnInit(){
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.pageSize = 5;
    this.pageSize2 = 5;
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        console.log(this.userinfo)
        this.getList();
    });
  }

  public getList()
  {
    var qst ="" ;
    this.loadingList = true;
    if(this.userinfo['ProfileType'] == "Administrador" &&  this.userinfo['ProfileID'] == 1)
    { 
      qst = "Request/All";
      this.habilitar=false;
    }
    else{
      qst = "Request/AllByCompany/"+this.userinfo.selectedCompanyId;
      this.habilitar=true;
    }

    
    this.GetPlantas();
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '','success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
            }
      }else {
        this.messageDialog(x.msg, '','error');
        return;
      }
    });
  }


  public GetTrabajadores()
  {
    var qst = this.read_url;

    if(isNumeric(this.userinfo.logon) )
    { 
      this.read_url = "Employee/GetEmployeesAndDocumentsByCompany/"+this.userinfo.selectedCompanyId;
    }
    else{
      this.read_url = "Employee/GetEmployeesAllCompany";
    }
    this.messageShowLoading();
    var qst = this.read_url;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_emp = rs;
          this.page2 = 1;
          if( Array.isArray(this.row_emp) ){
            this.pageTotal2 = this.row_emp.length;
          }
          this.messageDialog(x.msg, '' ,'success');
        }else {
          this.row_emp = [];
          return;
            }
      }else {
        return;
      }
    });
  }

  public updateItem(content:any, form:any){
    var validated = true;
    this.isUpdating = true;
    var qst = "Request/Update";//this.update_url;
  
    if( form == undefined ){
      form = {};
    }
  
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
    // if (form.PlantaID=="" || form.PlantaID==null || form.PlantaID==undefined)
    // {
    //     Swal.fire(this.translations['msg_all_required'], '', 'error');
    //     return;
    // }
    var to:any;    
    to = {};

    form.CompanyID=this.userinfo.selectedCompanyId;
    //form.CreatorUser = this.userinfo['userId'];//this.userinfo['logon'];
   // form.Status ="A";
    form.LastModifierUserID = this.userinfo['userId'];
    to = form;
    //to.durationtime=form.durationtime;
    
  
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update1');
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
        
      } 
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  
  }



  openUpdateModal(content:any, obj:any){
    this.GetTrabajadores();
    this.curr_parameter = 0;
    super.openUpdateModal(content, obj,'lg');
    this.loadingRequired = true;
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if(x != false){
          this.row_typejob = x;
      }
    });
  }

  openUpdateModal2(content:any, obj:any){
    this.curr_parameter = 0;
    super.openUpdateModal(content, obj,'lg');
    this.loadingRequired = true;
    this.GetEvaluation().subscribe(x => {
      this.loadingRequired = false;
      if(x != false){
          this.evaluacion = x;
      }
    });
  }

  public openCreateModal(content: any) {
    this.GetTrabajadores();
    this.curr_parameter = null;
    this.c_obj = {};
    this.c_obj['RequestDetails'] = [];
    this.modalService.open(content, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });
    this.loadingRequired = true;
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if(x != false){
        this.row_typejob = x;
      }
    });
  }

  public async createItem(content:any, form:any){
    this.create_url="Request/Create";
    var validated = true;
    if( form == undefined ){
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        
        return;
    }

    let to = {
      CompanyID: this.userinfo.selectedCompanyId,
      WhoRequest: form.WhoRequest,
      CreatorUserID: this.userinfo['userId'],
      RequestDetails: form.RequestDetails,
      Status: "A"
    }
    

    var qst = this.create_url;
    this.isCreating = true;
    // console.log(to);
    await this.srv.postCall(qst, to).toPromise().then( async data =>{
      if (data.val != null) {
        this.confirmDialog(data.msg, '','success'); 
        await this.AssigneItem(content,data.val["RequestId"]);
      }
    },catchError =>{
      catchError.message;
     
    });

  }

  public async AssigneItem(content:any, form:any){
    var qst = "Request/Assigne";
    var to:any;    
    to = {
      RequestId : form,
      AssignedUser : "Automatic",
      CompanyID : this.userinfo.selectedCompanyId
    };
    await this.srv.postCall(qst, to).toPromise().then(async x => {
      if (x.success) {
        this.c_obj = {};
        this.curr_parameter = 0;
        // this.confirmDialog(x.msg, '','success'); 
        await this.SendMail(to, content);
      }
    }); 
  }

  public async  SendMail(data:any, content){
    var qst = "Request/SenMail"; 
    var to:any;    
    to = {};
    to = data;   
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      if (x.success) {   
        this.confirmDialogWithModal(x.msg, '','success', content);
      } 
    }, err => {
      this.isCreating = false;
      this.confirmDialogWithModal(err, '','error', content);
    });
  }
  

  
  toggleEditable(event, id) {
    if ( event.target.checked ) {
       this.ActivarItem(id,1);
    }else {
     this.ActivarItem(id,2);
  
    }
  }

  ActivarItem(id:any, accion:any)
  {
    var to:any;
    to = {};
    to.RequestId = id;
    to.Status = accion;
    to.LastModifierUserID=this.userinfo['userId'];
    var qst = "Request/UpdateStatus";
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }

  GetPlantas()  
  {
    var qst = "ConsultasGenerales/ConsultaPlantasActivas";
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  GetTypeJob()  
  {
    var qst = API_GET_JOB_TYPE;
    return this.srv.getCall(qst).pipe( 
      mergeMap(data => { 
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        catchError(data => of(false))
      );
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }



  async addParameter(obj:any)
  {
    if (obj['NumberIdentification']==undefined  || obj['EmployeeId']==undefined){
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }
    let flag = false;
    let job = 0;
    if (obj.typeEmployeeName == "Conductor") {
      job = 6;
    } else {
      job = this.curr_parameter;
    }
    await this.srv.postCall(API_VERIFY_EXIST_EVAL_BY_EMPLOYEE_JOB, {JobTypeID: job})
          .toPromise().then(x => {
      if (!x.success) {
        flag = false;
      } else {
        flag = true;
      }
    }) 
    if (!flag) {
      this.messageDialog(this.translations.not_eval_job,'','error');
      this.emptyFields();
      return
    }
    if (obj.typeEmployeeName == "Trabajador") {
      if( this.curr_parameter == 0 || this.curr_parameter == null ){
        return Swal.fire(this.translations.msg_all_required, '', 'error');
      }
      else{
        let exists = obj['RequestDetails'].filter(f => {
          return f.NumberIdentification ==  obj['NumberIdentification'];
        });
        let newList = [];
        for (let l of obj['listWorksIdEmp']) {
          newList.push(Number(l.split('-')[0]));
        }
        // console.log(newList)
        if (newList.indexOf(this.curr_parameter) === -1) {
          if (newList.length === 1) {
            return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${obj['listWorksIdEmp'][0].split('-')[1]}`, 'error')
          } else {
            let works = '';
            for (let x of obj['listWorksIdEmp']) {
              works = works + x.split('-')[1] + ',';
            }
            return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${works.slice(0, -1)}`, 'error')
          }
        }
        if(exists.length>0)
        {
          return Swal.fire(this.translations.error, this.translations.identificaction_repeated,'error');;
        }
          let parm = this.row_typejob.filter(f => {
            return f.JobTypeID == this.curr_parameter;
          });
  
          if (parm.length > 0){
            let parameter = parm[0];
            obj['RequestDetails'].push(
              {
                NumberIdentification: obj['NumberIdentification'],
                NameEmployee:obj['NameEmployee'],
                position:obj['position'],
                EmployeeId:obj['EmployeeId'],
                JobTypeID:parameter['JobTypeID'],
                JobtypeName:parameter['Name']
              }
            );
          }
          this.emptyFields();
      }
    } else {
      let exists = obj['RequestDetails'].filter(f => {
        return f.NumberIdentification ==  obj['NumberIdentification'];
      });
      if(exists.length>0)
      {
        return Swal.fire(this.translations.error, this.translations.identificaction_repeated,'error');;
      }
      obj['RequestDetails'].push(
        {
          NumberIdentification: obj['NumberIdentification'],
          NameEmployee:obj['NameEmployee'],
          position:obj['position'],
          EmployeeId:obj['EmployeeId'],
          JobTypeID:6,
          JobtypeName:obj['position']
        }
      );
      this.emptyFields();
      
    }
    

    
  }

  public emptyFields() {
    this.c_obj['NumberIdentification']= undefined;
    this.c_obj['NameEmployee']=undefined;
    this.c_obj['position']=undefined;
    this.c_obj['EmployeeId']=undefined;
    this.c_obj['typeEmployeeName'] = undefined;
    this.c_obj['JobTypeID']=undefined;
    this.c_obj['JobtypeName']=undefined;
    this.curr_parameter = null;
  }

  public delParameter(obj:any, position:number){
    obj['RequestDetails'].splice(position, 1);
  }

  public delParameter2(obj:any, position:number){
    obj['RequestEvualuation'].splice(position, 1);
  }

  GetEmployeeforIdentification(obj:any,val:any)  
  {
    var qst = "Employee/GetIdentification/"+obj;

    if (obj=="")
    {
      return;
    }
    this.c_obj['listWorksIdEmp'] = [];
    this.u_obj['listWorksIdEmp'] = [];
    this.messageShowLoading();
    this.srv.getCall(qst).subscribe(x => {
      Swal.close();
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          if (x.val[0]['Status_doc']!='VALIDADO')
          {
            Swal.fire(this.translations.error, this.translations.msg_hability_request+" "+ x.val[0]['Status_doc'], 'error');
            this.c_obj['NumberIdentification']="";
            this.u_obj['NumberIdentification']="";
            return;
          }
          if (val == 1){
            this.c_obj['NameEmployee'] = x.val[0]['Name'];
            this.c_obj['typeEmployeeName'] = x.val[0]['typeEmployeeName'];
            this.c_obj['position'] = x.val[0]['position'];
            this.c_obj['EmployeeId'] = x.val[0]['EmployeeId'];
            this.c_obj['Status_doc'] = x.val[0]['Status_doc'];
            // console.log(this.c_obj)
            // console.log(x.val[0])
          }
          else{
            this.u_obj['NameEmployee']=x.val[0]['Name'];
            this.u_obj['typeEmployeeName']=x.val[0]['typeEmployeeName'];
            this.u_obj['position']=x.val[0]['position'];
            this.u_obj['EmployeeId']=x.val[0]['EmployeeId'];
            this.u_obj['Status_doc']=x.val[0]['Status_doc'];
          }
          for (let object of x.val[0]['_JobTypeEmployee']) {
            this.c_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-'+ object.Name);
            this.u_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-'+ object.Name);
          };
        }else {
          return;
            }
      }else {
        return;
      }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'WhoRequest': new FormControl(fields.WhoRequest, [Validators.required])
    });
  
    return mygroup.valid;
  }
  
  GetEvaluation()  
  {
    var qst = "Evaluation/AllActive";

    return this.srv.getCall(qst).pipe( 
      mergeMap(data => { 
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          //console.log("data:",data);
          //console.log("mydata:",mydata);
          
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        //timeout(MAX_TIME),
        catchError(data => of(false))
      );
  }



}
