import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

const API_GET_ALL_PROFILES = environment.Global.API_GET_ALL_PROFILES;
const API_GET_PROFILE_BY_ID = environment.Global.API_GET_PROFILE_BY_ID;
const API_CREATE_PROFILE = environment.Global.API_CREATE_PROFILE;
const API_UPDATE_PROFILE = environment.Global.API_UPDATE_PROFILE;
const API_UPDATE_STATUS_PROFILE = environment.Global.API_UPDATE_STATUS_PROFILE;
const API_GET_ALL_PAGES = environment.Global.API_GET_ALL_PAGES;
const API_GET_ALL_MENU = environment.Global.API_GET_ALL_MENU;
const API_CREATE_PERMISSION = environment.Global.API_CREATE_PERMISSION;
const API_GET_ALL_PERMISSIONS = environment.Global.API_GET_ALL_PERMISSIONS;
const API_GET_PERMISSIONS_BY_PROFILE_ID = environment.Global.API_GET_PERMISSIONS_BY_PROFILE_ID;
const API_UPDATE_PERMISSION = environment.Global.API_UPDATE_PERMISSION;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends BaseCrudComponent implements OnInit {
  loadingList = false;
  userinfo: any;
  listPages = [];
  listMenu = [];
  listPremissionByProfile = [];
  menuOption = -1;
  disabledGeneralChecked = false;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.pageSize = 5;
    this.search_fields = ['ProfileId','Name'];
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.getList();
    this.getMenus();
  }

  /**
   * Method to get all profiles from db
   */
  getList() {
    let url = API_GET_ALL_PROFILES;
    this.loadingList = true;
    this.messageShowLoading();
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' , 'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' , 'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
            }
      }else {
        this.messageDialog(x.msg, '' , 'error');
        return;
      }
    });
  }

  /**
   * Method to get all pages from web application
   */
  public getPages() {
    this.srv.getCall(API_GET_ALL_PAGES).subscribe(x => {
      if(x.success){
        if (x.val != null) {
          x.val.forEach(element => {
            element['create'] = false;
            element['read'] = false;
            element['update'] = false;
            element['delete'] = false;
          })
          this.listPages = x.val;
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.listPages = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  /**
   * Method to get all menus from db
   */
  public getMenus() {
    this.srv.getCall(API_GET_ALL_MENU).subscribe(x => {
      if(x.success){
        if (x.val != null) {
          x.val.forEach(element => {
            if (element.Name !== "Comunicados" && element.Name !== "Configuración") {
              this.listMenu.push(element);
            }
          });
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.listPages = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  /**
   * Method to open create modal
   * @param modal Modal container for create object
   */
  public openCreateModal(modal) {
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg', centered: true })
    this.getPages();
    this.menuOption = -1;
  }

  /**
   * Method to call ActivarItem
   * @param param0 profile id to eliminate profile
   */
  public deleteItem({ProfileId}) {
    this.ActivarItem(ProfileId,3);
  }

  /**
   * Method to active, inactive or eliminate profile
   * @param id profile id value
   * @param status number type to modifiy data in db
   */
  public ActivarItem(id, status) {
    var to:any;
    to = {};
    to.ProfileId = id;
    to.Status = status;
    to.LastModifierUser = this.userinfo['logon'];
    this.srv.postCall(API_UPDATE_STATUS_PROFILE, to).subscribe(x => {
      if(x.success){
        this.confirmDialog(x.msg, '' , 'success');
      }
    }, err => {
        Swal.fire('Error');
    });
  }

  /**
   * Method to active or inactive profile
   * @param event event from click function 
   * @param param1 profile id to active or inactive
   */
  public activarItem(event,{ProfileId}) {
    if ( event.target.checked ) {
      this.ActivarItem(ProfileId,1);
    }else {
      this.ActivarItem(ProfileId,2);
    }
  }

  /**
   * Method to create and valdiate form
   * @param fields forms fields
   * @returns return true if the form is valid  otherwise return false
   */
  public validateCreateProfile(fields:any): boolean {
    let profileForm:any = {};
    profileForm = new FormGroup({
        'nameProfile': new FormControl(fields.nameProfile, [Validators.required]),
        'descriptionProfile': new FormControl(fields.descriptionProfile, [Validators.required])
    });
    return profileForm.valid;
  }

  /**
   * Method to validate if user select at least one page for the profile
   * @returns return counter value
   */
  public validatePages () {
    let counter = 0;
    this.listPages.forEach(element => {
      let internalCounter = 0;
      element.create == true ? internalCounter += 1: internalCounter = internalCounter;
      element.read == true ? internalCounter +=  1: internalCounter = internalCounter;
      element.update == true ? internalCounter +=  1: internalCounter = internalCounter;
      element.delete == true ? internalCounter +=  1: internalCounter = internalCounter;
      if (internalCounter > 0) {
        counter += 1;
      } else {
        counter = counter;
      }
    });
    return counter
  }

  /**
   * Method to filter data based on user
   */
  public selectPages() {
    if ( this.menuOption == -1 ) {
      this.listPages.forEach( element => {
        element['create'] = false;
        element['read'] = false;
        element['update'] = false;
        element['delete'] = false;
        element['all'] = false;
      })
      this.disabledGeneralChecked = false;
    } else if ( this.menuOption == 0) {
      this.listPages.forEach( element => {
        element['create'] = true;
        element['read'] = true;
        element['update'] = true;
        element['delete'] = true;
        element['all'] = true;
      })
      this.disabledGeneralChecked = true;
    } else {
      this.listPages.forEach(element => {
        if (element.MenuId == this.menuOption) {
          element['create'] = true;
          element['read'] = true;
          element['update'] = true;
          element['delete'] = true;
          element['all'] = true;
        } else {
          element['create'] = false;
          element['read'] = false;
          element['update'] = false;
          element['delete'] = false;
          element['all'] = false;
        }
      })
    }
  }

  /**
   * Method to select all options for each row
   * @param event action from checkbox input
   * @param row row that contain crud
   */
  public selectAllByRow(event,row) {
    if ( event.target.checked ) {
      row['create'] = true;
      row['read'] = true;
      row['update'] = true;
      row['delete'] = true;
    }else {
      row['create'] = false;
      row['read'] = false;
      row['update'] = false;
      row['delete'] = false;
    }

  }

  /**
   *Method to create a new profile object or edit object, both contains permissions
   * @param profileid profile id to set in the object
   * @param mode if edit or create
   * @returns return an object with information about profile and his permissions
   */
  public createNewObject (profileid, mode) {
    if (mode == 'C') {
      let new_obj = {
        CreatorUser : this.userinfo['logon'],
        ProfileId : profileid,
        permissions : []
      }
      for ( let i = 0; i< this.listPages.length ; i++ ) {
        let crud = '';
        this.listPages[i].create == true ? crud += 'C': crud = crud;
        this.listPages[i].read == true ? (crud != '' ? crud += '|R' : crud +='R' ): crud = crud;
        this.listPages[i].update == true ? (crud != '' ? crud += '|U' : crud +='U') : crud = crud;
        this.listPages[i].delete == true ? (crud != '' ? crud += '|D' : crud +='D') : crud = crud;
        if (crud != '') {
          new_obj.permissions.push(
            {
              ModuleId: this.listPages[i].ModuleId,
              CRUD: crud 
            }
          )
        }
      }
      return new_obj;
    } else if (mode == 'E') {
      let edit_obj = {
        LastModifierUser : this.userinfo['logon'],
        ProfileId : this.u_obj['ProfileId'],
        permissions : []
      }

      for ( let i = 0; i< this.listPages.length ; i++ ) {
        let crud = '';
        this.listPages[i].create == true ? crud += 'C': crud = crud;
        this.listPages[i].read == true ? (crud != '' ? crud += '|R' : crud +='R' ): crud = crud;
        this.listPages[i].update == true ? (crud != '' ? crud += '|U' : crud +='U') : crud = crud;
        this.listPages[i].delete == true ? (crud != '' ? crud += '|D' : crud +='D') : crud = crud;
        if (crud != '') {
          edit_obj.permissions.push(
            {
              ModuleId: this.listPages[i].ModuleId,
              CRUD: crud 
            }
          )
        }
      }
      return edit_obj;
    }

  }

  /**
   * Method to create the profile in db
   * @param modal modal profile container with permissions
   * @param obj object with data for the new profile
   * @returns return an object if the profile was created successfully
   */
  public createItem(modal, obj) {
    this.isCreating = true;
    let validatedForm = this.validateCreateProfile(obj);
    if (!validatedForm ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    let counter = this.validatePages();
    if (counter == 0) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_select_permission'], '', 'error');
      return;
    }
    let new_profile = {
      CreatorUser : this.userinfo['logon'],
      Name: obj.nameProfile,
      Description: obj.descriptionProfile
    }

    this.srv.postCall(API_CREATE_PROFILE, new_profile).subscribe(x => {
      if(x.success){
        if (x.val !== null) {
          this.createPermission(x.val['ProfileId'], modal);
        }
      }
    }, err => {
        this.isCreating = false;
        Swal.fire('Error');
    });

  }
  
  /**
   * Method to create permissions for the profile
   * @param profileid profile id for each permission
   * @param modal modal profile container with permissions
   */
  public createPermission(profileid, modal) {
    let object = this.createNewObject(profileid, 'C');
    this.srv.postCall(API_CREATE_PERMISSION, object).subscribe(x => {
      if(x.success){
        this.isCreating = false;
        Swal.fire(
          {
            title: this.translations['profile_success'],
            icon:'success'
          }
        ).then((result) => {
          if (result.value) {    
            this.getList();
            this.closeModal(modal);
            this.menuOption = -1;
          }
        });
      }
    }, err => {
        this.isCreating = false;
        Swal.fire('Error');
    });
  }

  /**
   * Method to open update modal and pages, permissions
   * @param modal modal update container
   * @param row information from the profile selected
   */
  public async openUpdateModal(modal, row) {
    this.u_obj = {};
    this.u_obj['ProfileId'] = row.ProfileId;
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg', centered: true })
    this.u_obj['nameProfile'] = row.Name;
    this.u_obj['descriptionProfile'] = row.Description;
    this.getPages();
    await this.getPermissionsByProfileId(row);
    this.asignPermission();

  }

  /**
   * Method to assign profile permissions to list pages
   */
  public asignPermission () {
    setTimeout(()=>{
      this.listPremissionByProfile.forEach(element => {
        let crud = element.Crud.split('|');
        this.listPages.forEach(elementPage => {
          if (element.ModuleId == elementPage.ModuleId) {
            elementPage['create'] = crud.indexOf('C') !== -1 ? true : false;
            elementPage['read'] = crud.indexOf('R') !== -1 ? true : false;
            elementPage['update'] = crud.indexOf('U') !== -1 ? true : false;
            elementPage['delete'] = crud.indexOf('D') !== -1 ? true : false;
          }
        })
      })
    }, 1500); 
  }

  /**
   * Mehtod to get profiles permissions 
   * @param param0 profile id to get all permissions 
   */
  public async getPermissionsByProfileId({ProfileId}) {
    await this.srv.getCall(API_GET_PERMISSIONS_BY_PROFILE_ID + ProfileId).toPromise().then(x => {
      if(x.success){
        if (x.val != null) {
          this.listPremissionByProfile = x.val;
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.listPremissionByProfile = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  /**
   * Mehtod to edit profile with permissions
   * @param modal update container modal
   * @param obj object to update data in db
   * @returns return an object if the update was successfully
   */
  public async editItem(modal, obj) {
    this.isUpdating = true;
    let validatedForm = this.validateCreateProfile(obj);
    if (!validatedForm ){
      this.isUpdating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    let counter = this.validatePages();
    if (counter == 0) {
      this.isUpdating = false;
      Swal.fire(this.translations['msg_select_permission'], '', 'error');
      return;
    }
    let edit_profile_data = {
      ProfileId : this.u_obj['ProfileId'],
      LastModifierUser : this.userinfo['logon'],
      Name: this.u_obj['nameProfile'],
      Description: this.u_obj['descriptionProfile']
    }
    let object = this.createNewObject(this.u_obj['ProfileId'], 'E');
    await this.srv.postCall(API_UPDATE_PROFILE, edit_profile_data).toPromise().then(x => {
      if(x.success){
        this.srv.postCall(API_UPDATE_PERMISSION, object).subscribe(x => {          
          this.isUpdating = false;
          if(x.success){
            Swal.fire(
              {
                title: this.translations['profile_updated'],
                icon:'success'
              }
            ).then((result) => {
              if (result.value) {    
                this.getList();
                this.closeModal(modal);
                this.menuOption = -1;
              }
            });
          }
        }, err => {
            this.isUpdating = false;
            Swal.fire('Error');
        });
      }
    }, err => {
        this.isUpdating = false;
        Swal.fire('Error');
    });
    

  }
}
