import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

interface DocButton {
  path: string;
  class: string;
  title: string;
}

@Component({
  selector: 'app-approvedocumentsbase',
  templateUrl: './approvedocumentsbase.component.html',
  styleUrls: ['./approvedocumentsbase.component.css']
})
export class ApprovedocumentsbaseComponent extends BaseCrudComponent {
  
  public typeDoc: string;
  userinfo:any;
  docButton: DocButton;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
      super(srv, modalService, storage, router);

      this.route.params.subscribe(params => {
        this.typeDoc = params.type;
        this.docButton = this.getButtonDataById(params.type);
      });
      // translate.setTranslation(this.translate.currentLang, this.translations)
      

      this.read_url = Global.API_ALLCOMPANIESWITHDOCUMENT_TYPE;
      // console.log(this.read_url);
      this.search_fields = ['CompanyID','Ruc','Name'];
      this.c_obj['Company'] = [];
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
        var trans = translations.menu_docapproval_var.replace('{{type}}', types[this.typeDoc])
        console.log(trans);
        this.translations = translations;
        this.translations.menu_docapproval_var = trans;
        console.log(this.translations);
        super.ngOnInit();
    });
    console.log(this.translations);
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });

    await this.getList();
  }

  public async getList() {
    var qst = this.read_url;
    this.loadingList = true;
    let to = {
      userId: this.userinfo['userId'],
      type: this.typeDoc
    }
    this.messageShowLoading();
    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
        }else {
          // this.messageDialog(x.msg, '' ,'success');
          this.closeLoadingMessage();
          this.rows = [];
          return;
        }
      }else {
        this.messageDialog(x.msg, '' ,'error');
        return;
      }
    });
  }

  private getButtonDataById(type: string): DocButton{
    // console.log(type);
    let data : DocButton;

    switch(type){
      case 'comp' : {
        data = {
          path: '/approvedocuments/comp/',
          class: 'far fa-file text-info m-r-10',
          title: 'doc_company'
        }
        break;
      }
      case 'emp' : {
        data = {
          path: '/approvedocuments/emp/',
          class: 'far fa-file text-success m-r-10',
          title: 'doc_employee'
        }
        break;
      }
      case 'vhc' : {
        data = {
          path: '/approvedocuments/vhc/',
          class: 'far fa-file text-danger m-r-10',
          title: 'doc_vehicles'
        }
        break;
      }
      default: null
    }

    // console.log(data);
    return data;
  }
}

const types = {
  comp: 'compañia',
  emp: 'empresa',
  vhc: 'vehiculo'
}

export function blobToB64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}