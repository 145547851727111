export class Companieadmin {
    Name: string;
    Email: string;
    PassWord: string;
    fecha: string;
    Username: string;
    CreatorUserID;
    Ruc;
    Direction;
    Telephone;
    CompanyID;
    LastModifierUserID;
    ResetPass;
    typeCompany;
    IsRecurrent;

    constructor(){}
}
